import React, { Component } from 'react'
import { appContext } from '../../Utils/Context';
import { IS_ASSISTANT } from '../../Utils/Generic'
import './DoctorInformation.scss'
import DoctorProfileInformation from './DoctorInformation/DoctorProfileInformation'
import queryString from 'query-string';
import prepareEndpoint from '../../Utils/EndPoints';
import { getData, postData } from '../../Utils/API';
import { CREATE_ID } from '../../Utils/Generic';
import locationIcon from '../../Assets/icons/Ubicaciones hospital-direccion.svg'
import doctorIcon from '../../Assets/icons/doctor.svg'
import calendarIcon from '../../Assets/icons/popup-calendar.svg'
import localizationIcon from '../../Assets/icons/popup-localization.svg'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import FlowControl from './Flow/FlowControl';
import back from '../../Assets/icons/Agenda semana-atras.svg'
import next from '../../Assets/icons/Agenda semana-adelante.svg'
import Loading from '../Common/loading/Loading'
import { filter, findIndex } from 'lodash';
import BasicModal from '../Common/modal/BasicModal'
import Modal from '../Common/modal/Modal';
import { Checkbox } from 'pretty-checkbox-react';
import AppointmentRow from './AppointmentConfirmation/AppointmentRow'

const localizer = momentLocalizer(moment)
let allViews = ['week']
const messages = {
  agenda: 'Agenda',
  allDay: 'All Day',
  date: 'Fecha',
  day: 'Día',
  event: 'Evento',
  month: 'Mes',
  next: 'Siguiente',
  noEventsInRange: 'There are no events in this range.',
  previous: 'Anterior',
  time: 'Tiempo',
  today: 'Hoy',
  tomorrow: 'Mañana',
  week: 'Semana',
  showMore: function () { return '+ ver mas' }
}
class DoctorInformation extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
      loading: true,
      showServices: false,
      doctor: {
        userId: '',
        firstName: '',
        lastName: '',
        rate: 3.5,
        specialtyField: '',
        professionalLicense: '',
        specialties: [],
        academicAchievements: [],
        languages: [],
        memberPrivateMedicalAssociations: [],
        positionsInPublicAssociations: [],
        professionalExperiences: [],
        specialtyAreas: []
      },
      services: [
        {
          id: '',
          category: '',
          locationId: '',
          name: '',
          firstTimePrice: 0,
          price: 0
        }
      ],
      locationType: ['Hospital', 'Clínica', 'Consultorio'],
      locations: [{
        type: '',
        name: '',
        city: '',
        colony: '',
        id: '',
        officeNumber: '',
        state: '',
        streetAndNumber: '',
        zip: ''
      }],
      locationSelected: {
        type: '',
        name: '',
        city: '',
        colony: '',
        id: '-1',
        officeNumber: '',
        state: '',
        streetAndNumber: '',
        zip: ''
      },
      schedule: [],
      events: [],
      selectedEvent: null,
      isFirstTime: false,
      serviceIdSelected: '',
      //variables for reschedule
      locationId: null,
      serviceId: null,
      appointmentId: null,
      showReschedule: false,
      rescheduleLoaded: false,
      nextAppointment: null,
      defaultEvent: null
    };
    this.year = today.getFullYear();
    this.month = today.getMonth() + 1;
    this.day = today.getDate();
    this.view = 'week';
    this.numberFormat2 = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    this.flowRef = React.createRef();
  }

  async componentDidMount() {
    this.context.updateHeaderIcons(false);
    this.context.updateMainIcon(false);
    const qsValues = queryString.parse(this.props.location.search);
    const { doctorId, si, li, ai } = qsValues;
    if (!doctorId) {
      this.props.history.goBack();
    }
    this.locationId = li;
    this.serviceId = si;
    this.appointmentId = ai;
    /*     this.setState({ loading: true }); */
    this.getDoctor(doctorId);

    await this.getLocations(doctorId);

    await this.getServices(doctorId);

    await this.getAvailabilityDoctor(doctorId);

    this.setState({ loading: false });
  }

  onContinueWithService = () => {
    this.hideServicesModal();
    this.setDoctorData();
    this.flowRef.current.start();
  }
  selectFirsTime = e => {
    this.setState((prevState) => ({
      isFirstTime: !prevState.isFirstTime
    }));
  }
  hideRescheduleModal = () => {
    this.setState({ showReschedule: false, rescheduleLoaded: false });
  }
  goToAgenda = () => {
    this.props.history.push(`/patient/agenda`);
  }
  startFlow = async () => {
    this.setState({ loading: true });
    //is reschedule
    if (this.appointmentId) {
      this.setDoctorData();
      const url = `${prepareEndpoint('Appointment')}/${this.appointmentId}/Reschedule`;
      const data = new FormData();
      data.append("selectedDate", moment(this.state.selectedEvent?.start).format('YYYY-MM-DD'));
      data.append("selectedHour", this.getSelectedHour());
      const resp = await postData(url, data);

      if (resp.status === 200) {
        this.setState({ showReschedule: true });
      }
      else if (resp.data && resp.data.includes('availability')) {
        this.setState({ modalShowErrorAppointment: true });
      }
    }
    //new appointment
    else {
      const { locationSelected, services } = { ...this.state };

      const locationServices = filter(services, ['locationId', locationSelected.id]);
      locationServices.map(s => {
        s.selected = false;
        return s;
      })
      this.setState({ showServices: true, isFirstTime: false });
      this.setState({ locationServices })
    }
    this.setState({ loading: false });
  }

  startDafultFlow = () => {
    if (this.state.defaultEvent !== null) {
      const { locationSelected, services } = { ...this.state };

      const locationServices = filter(services, ['locationId', locationSelected.id]);
      locationServices.map(s => {
        s.selected = false;
        return s;
      })
      this.setState({ selectedEvent: this.state.defaultEvent, showServices: true, isFirstTime: false });
      this.setState({ locationServices })
    }
  }

  getPriceSelected = () => {
    let price = '';
    this.state.services.forEach(s => {
      if (price !== '')
        return;
      if (s.id === this.state.serviceIdSelected) {
        if (s.category === 'Consulta') {
          price = this.numberFormat2.format(s.price)
        }
        else {
          price = this.numberFormat2.format(0)
        }
      }
      else {
        price = ''
      }
    });

    return price;
  }

  getDateFormatted = () => {
    const date = new Date(moment(this.state.selectedEvent?.start)).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    return date.charAt(0).toUpperCase() + date.slice(1)
  }

  getHours = () => {
    return `${new Date(moment(this.state.selectedEvent?.start)).toLocaleString('es-MX', { timeStyle: 'short' })} hrs`;
  }

  getPreviousDateFormatted = () => {
    const { doctor } = { ...this.state };
    const date = new Date(doctor.previousDate).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    return date.charAt(0).toUpperCase() + date.slice(1)
  }

  getPreviousHours = () => {
    const { doctor } = { ...this.state };
    return `${doctor.previousHour.toLocaleString('es-MX', { timeStyle: 'short' })} hrs`;
  }
  setDoctorData = async () => {
    const { doctor, locationSelected } = { ...this.state };
    if (this.appointmentId) {
      const data = await this.GetAppointment(this.appointmentId);
      if (data) {
        doctor.previousDate = new Date(data.startDateTime.toString().slice(0, -1));
        doctor.previousHour = `${doctor.previousDate.getHours()}:${doctor.previousDate.getMinutes().toString().padStart(2, '0')}`;
        doctor.previousAppointment = this.appointmentId;
      }
    }
    doctor.selectedDate = moment(this.state.selectedEvent?.start).format('YYYY-MM-DD');
    doctor.selectedHour = this.getSelectedHour();
    doctor.doctorServices = this.state.locationServices;
    doctor.location = locationSelected.name;
    doctor.streetAndNumber = locationSelected.streetAndNumber;
    doctor.city = locationSelected.city;
    doctor.officeNumber = locationSelected.officeNumber;
    doctor.locationId = locationSelected.id;
    doctor.locationType = locationSelected.type;
    doctor.isFirstTime = this.state.isFirstTime;

    this.setState({ doctor });
    this.setState({ rescheduleLoaded: true });
  }


  getSelectedHour = () => {
    let datetext = this.state.selectedEvent?.start.toTimeString();
    datetext = datetext.split(' ')[0];
    return datetext.substring(0, datetext.length - 3);
  }

  GetAppointment = async (appointmentId) => {
    const url = `${prepareEndpoint('Appointment')}/${appointmentId}`;
    const resp = await getData(url);
    if (resp.status === 200) {
      return resp.data;
    }
    return null;
  }

  CustomToolbar = (toolbar) => {
    const goToBack = () => {

      toolbar.date.setDate(toolbar.date.getDate() - 7);
      toolbar.onNavigate('prev');
      //let value = new Date(this.year, this.month - 1, this.day);
      //value.setDate(value.getDate() - 7);
      let value = new Date(toolbar.date);
      var day = value.getTime() - (7 * 24 * 60 * 60 * 1000);
      value.setTime(day);
      this.onNavigate(value);
    };

    const goToNext = () => {
      this.onNavigate(new Date(toolbar.date));
      toolbar.date.setDate(toolbar.date.getDate() + 7);
      toolbar.onNavigate('next');
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      );
    };
    return (
      <div className={'toolbar-container'}>
        <div className={'back-next-buttons'}>
          <span className={'btn-back'} onClick={goToBack}>
            <img src={back} alt='back' />
          </span>
          <label className={'label-date'}>{label()}</label>
          <span className={'btn-next'} onClick={goToNext}>
            <img src={next} alt='next' />
          </span>
        </div>
      </div >
    );
  };


  getDoctor = async (doctorId) => {
    const url = prepareEndpoint('Doctor');
    const resp = await getData(`${url}/${doctorId}`);
    if (resp?.status !== 200) {
      return;
    }
    this.setState({
      doctor: resp.data
    });
  }

  getServices = async (doctorId) => {
    const url = prepareEndpoint('DoctorServices');
    const resp = await getData(`${url}/${doctorId}`);
    if (resp?.status !== 200) {
      return;
    }
    this.setState({
      services: Object.values(this.serviceId ? resp.data.filter(t => t.id === this.serviceId) : resp.data)
    });
  }

  getLocations = async (doctorId) => {
    const url = prepareEndpoint('DoctorLocations');
    const resp = await getData(`${url}/${doctorId}`);
    if (resp?.status !== 200) {
      return;
    }
    const data = this.locationId ? resp.data.filter(t => t.id === this.locationId) : resp.data;
    const locationType = [...new Set(data.map(item => item.type))].filter(t => t && t.length > 0);
    this.setState({
      locations: data,
      locationType: locationType
    });
    this.setState({ locationSelected: this.state.locations[0] });

    this.locationChanged(this.state.locations[0]);
  }

  locationChanged(location) {
    if (location) {
      this.setState({ locationSelected: location });
      let service = this.state.services.find(e => e.locationId === location.id);
      if (service) {
        this.setState({ serviceIdSelected: service.id });
      }
      else {
        this.setState({ serviceIdSelected: '' });
      }
      this.eventsUpdate(location, this.state.schedule);
    }
  }

  serviceChanged(id) {
    if (id) {
      this.setState({ serviceIdSelected: id });
      let service = this.state.services.find(e => e.id === id);
      if (service) {
        let location = this.state.locations.find(e => e.id === service.locationId);
        if (location) {
          this.eventsUpdate(location, this.state.schedule);
          this.setState({ locationSelected: location });
        }
      }
    }
  }

  getAvailabilityDoctor = async (doctorId) => {
    const url = prepareEndpoint('Doctor');
    let schedule = [];
    let dateToGet = new Date(this.year, this.month - 1, this.day, 23, 59, 59);
    let value = new Date();
    var day = value.getTime() - (7 * 24 * 60 * 60 * 1000);
    value.setTime(day);
    if (dateToGet > value) {
      const resp = await getData(`${url}/${doctorId}/AgendaAvailability?year=${this.year}&month=${this.month}&day=${this.day}`);
      if (resp?.status === 200) {
        const data = resp.data;

        data.forEach(d => {
          schedule.push(d)
        })
      }
    }
    this.setState({ schedule });
    this.eventsUpdate(this.state.locationSelected, schedule);
  }

  showLocation = (locationType, location) => {
    if (location.type === locationType) {
      return (
        <div key={locationType + location.id} className={this.state.locationSelected.type === locationType ? 'row pl-4 pt-4' : 'hidden'}>
          <div key={location.id} className='d-flex'>
            <img src={locationIcon} alt='location icon' className='img-15' />
            <div className='row ml-2'>
              <div>
                <p className='bold'>{location.name}</p>
                <p>{`${location.streetAndNumber}, ${location.city}`}</p>
                <p>{location.officeNumber}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  eventsUpdate = (location, schedule) => {
    const scheduledAll = schedule.length > 0 ? schedule : null;
    let events = [];
    if (scheduledAll !== null) {
      let scheduled = scheduledAll.find(s => s.locationId === location.id);
      if (scheduled) {
        let nextAppointment = null;
        let defaultEvent = null;
        let currentService = this.state.services.find(s => s.locationId === location.id && s.category === 'Consulta');

        scheduled.availability.forEach(day => {
          let startDate = new Date(day.availableDate);
          let endDate = new Date(startDate);


          day.availableTimes.forEach(e => {
            let time = e.start.split(':');
            startDate.setHours(time[0]);
            startDate.setMinutes(time.length > 1 ? time[1] : 0);
            startDate.setSeconds(0);
            time = e.end.split(':');
            endDate.setHours(time[0]);
            endDate.setMinutes(time.length > 1 ? time[1] : 0);
            endDate.setSeconds(0);

            let temStart = new Date(startDate);


            let dateText = temStart.toLocaleTimeString('es-MX');

            if (nextAppointment == null) {
              nextAppointment = {
                servicePrice: currentService?.category === 'Consulta' ? currentService.price : 0,
                location: location.name,
                startDate: `${temStart.toLocaleDateString('es-MX')} ${dateText.substring(0, dateText.length - 3)}`
              };

              let temEndTem = new Date(temStart);
              temEndTem.setHours(temEndTem.getHours() + 1);
              defaultEvent =
              {
                id: CREATE_ID(),
                start: new Date(temStart),
                end: new Date(temEndTem)
              };
            }
            else if (temStart < nextAppointment.startDate) {
              nextAppointment = {
                servicePrice: currentService?.category === 'Consulta' ? currentService.price : 0,
                location: location.name,
                startDate: `${temStart.toLocaleDateString('es-MX')} ${dateText.substring(0, dateText.length - 3)}`
              };

              let temEndTem = new Date(temStart);
              temEndTem.setHours(temEndTem.getHours() + 1);
              defaultEvent =
              {
                id: CREATE_ID(),
                start: new Date(temStart),
                end: new Date(temEndTem)
              };
            }


            while (temStart.getHours() < endDate.getHours()) {
              let temEnd = new Date(temStart);
              temEnd.setHours(temEnd.getHours() + 1);
              events.push(
                {
                  id: CREATE_ID(),
                  start: new Date(temStart),
                  end: new Date(temEnd)
                },
              )
              temStart.setHours(temStart.getHours() + 1);
            }
          });
        });
        this.setState({ nextAppointment, defaultEvent })
      }
    }

    this.setState({ events });
  }

  onNavigate = async (value) => {
    this.setState({ loading: true });
    this.month = value.getMonth() + 1;
    this.year = value.getFullYear();
    this.day = value.getDate();
    await this.getAvailabilityDoctor(this.state.doctor.userId);
    this.setState({ loading: false });
  }

  handleSelectEvent = (event) => {
    this.setState({ selectedEvent: event })
  }
  hideServicesModal = () => {
    this.setState({ showServices: false, locationServices: [] });
  }
  isContinueDisabled = () => {
    const { locationServices } = this.state;
    return filter(locationServices, ['selected', true]).length <= 0;
  }
  selectService = e => {
    const { id } = e.target;
    const { locationServices } = this.state;
    let index = findIndex(locationServices, ['id', id]);
    locationServices[index].selected = !locationServices[index].selected;
    this.setState({ locationServices })
  }

  render() {
    return (
      <div className='doctor-details'>
        {this.state.loading && <Loading />}
        <div className="row spaceForProfile">
          <DoctorProfileInformation doctor={this.state.doctor} services={this.state.services} locations={this.state.locations} locationType={this.state.locationType} onLocationChangeEvent={(location) => { this.locationChanged(location) }} onServiceChangeEvent={(id) => { this.serviceChanged(id) }}
            history={this.props.history} nextAppointment={this.state.nextAppointment}
            onStartFlowEvent={() => { this.startDafultFlow() }} />
          <div className="col-12 col-md-6 pr-0 pl-1">
            <div className="bg-green round-10">
              <div className='available-section text-center'>
                Disponibilidad
              </div>
              <div className="notes pt-1">Si requieres otro día y hora, puedes seleccionarlo del calendario</div>
              <div className='plr-3 pt-0 pb-3 agenda-section'>
                {
                  this.state.locationType.map(s => {
                    return this.state.locations.map(l => {
                      return this.showLocation(s, l);
                    })
                  })
                }
              </div>
              <div className='p-3 doctor-availability agenda'>
                <p>Selecciona una hora preferida para pedir una cita</p>
                <Calendar
                  culture={'es'}
                  selectable={'ignoreEvents'}
                  localizer={localizer}
                  views={allViews}
                  step={60}
                  timeslots={1}
                  events={this.state.events}
                  formats={
                    {
                      dayFormat: window.screen.width > 575 ? 'dddd DD' : 'DD'
                    }
                  }
                  style={{ height: 650, width: '95%' }}
                  components={{
                    toolbar: this.CustomToolbar
                  }}
                  messages={messages}
                  onSelectEvent={this.handleSelectEvent}
                  defaultView={this.view}
                  popup={true}
                  min={new Date(0, 0, 0, 6, 0, 0)}
                  max={new Date(0, 0, 0, 22, 0, 0)}
                />
              </div>
              <div className='p-3 text-center'>
                <p>
                  Precio de Consulta:<span>
                    {this.getPriceSelected()}</span>
                </p>



                <p>
                  {!IS_ASSISTANT() && <button className='btn primary-btn' onClick={this.startFlow}
                    disabled={this.state.locationSelected.id === '-1' || this.state.selectedEvent == null} >
                    Reservar Ahora
                  </button>}
                </p>
                <p>Para reservar, es necesario pagar tu consulta con anticipación</p>
              </div>
            </div>
          </div>
        </div>
        {this.state.showServices &&
          <Modal
            show={true}
            modalClosed={this.hideServicesModal}
            closeIcon={true}
            className={'services-modal'}
          >
            <h4 className='services-title'>Selecciona los servicios</h4>
            <div className='row services-headers'>
              <div className='col-8'>Servicio</div>
              <div className='col-4'>Precio</div>
            </div>

            <div className='row services'>
              {this.state.locationServices.map(s => {
                return (<>
                  <div className='col-8'>
                    <Checkbox
                      id={s.id}
                      onChange={this.selectService}
                      checked={s.selected}
                    >
                      {s.name || s.category}
                    </Checkbox>
                  </div>
                  <div className='col-4'>
                    ${s.category === 'Consulta' && this.state.isFirstTime ? s.firstTimePrice : s.price}
                  </div>
                </>)
              })}
            </div>
            <div className='btn-container'>
              <button disabled={this.isContinueDisabled()} type="button" className="btn primary-btn" onClick={() => { this.onContinueWithService() }}>Siguiente</button>
            </div>
          </Modal>}
        {this.state.showReschedule && this.state.rescheduleLoaded && <Modal
          show={this.state.rescheduleLoaded}
          modalClosed={this.hideRescheduleModal}
          closeIcon={true}
          className='rescheduleModal'>
          <div className='appointment-container'>
            <div className='row pb-2 d-flex justify-content-center'>
              <h2 className="text-center">Tu cita ha sido modificada</h2>
            </div>
            <AppointmentRow icon={this.state.doctor.picture ? `data:image/jpeg;base64,${this.state.doctor.picture}` : doctorIcon} title={`${this.state.doctor.firstName} ${this.state.doctor.lastName}`} description={this.state.doctor.specialtyField} profile={true} />
            <AppointmentRow icon={calendarIcon} title={this.getDateFormatted()} description={this.getHours()} title0={this.getPreviousDateFormatted()} description0={this.getPreviousHours()} />
            <AppointmentRow icon={localizationIcon} title={this.state.doctor.location} description={`${this.state.doctor.streetAndNumber}, ${this.state.doctor.city}`} secondLineInDescription={this.state.doctor.officeNumber} />
            <div className='text-center'>
              <button type="button" className="btn primary-btn center-height" onClick={() => { this.goToAgenda() }}>Ver Agenda</button>
            </div>
          </div>
        </Modal>}
        <FlowControl ref={this.flowRef}
          doctorInformation={this.state.doctor}
          location={this.state.locationSelected}
          appointmentDateTime={new Date(this.state.selectedEvent?.start)}
          price={this.state.services.find(s => s.locationId === this.state.locationSelected.id)?.price}
          history={this.props.history}
        //TODO: mandar patient name    patientName:'' 
        ></FlowControl>
        <BasicModal
          className='additional-modal'
          show={this.state.modalShowErrorAppointment}
          modalClosed={() => this.setState({ modalShowErrorAppointment: false })}
          title='Error al agendar la cita'
          subtitle='No se pudo agendar cita en este horario, favor de seleccionar otro'
        >
        </BasicModal>
      </div>
    )
  }
}
DoctorInformation.contextType = appContext;
export default DoctorInformation;