import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import './Account.scss'
import Agenda from './Agenda';
import Patient from './Patient';
import Profile from './Profile';
import ScheduleHours from './ScheduleHours';
import Services from './Services';
import './DrAccountContainer.scss'
import { IS_ASSISTANT, HAS_ASSISTANT_PERMISSION, IS_VALID_DR } from '../../../Utils/Generic'
import { ROLES } from '../../../Utils/Constants';

export default class DrAccountContainer extends Component {
  constructor(props) {
    super(props);
    let page = props.match.params.page;
    this.state = {
      showHeaderIcons: false,
      pages: [
        {
          path: 'services',
          name: 'Servicios'
        },
        {
          path: 'agenda',
          name: 'Agenda',
          referenceTo: 'schedule'
        },
        {
          path: 'patient',
          name: 'Mis pacientes'
        },
        {
          path: 'profile',
          name: 'Mi perfil'
        },
        {
          path: 'schedule',
          hide: 'true'
        },
      ],
      pageSelected: page.toLocaleLowerCase()
    }
  }
  componentDidMount() {
  }

  componentDidUpdate() {
    let { pageSelected } = this.state;
    let page = this.props.match.params.page;
    if (pageSelected !== page.toLocaleLowerCase()) {
      this.setState({ pageSelected: page });
    }
  }
  goTo = (path) => {
    this.props.history.push('/doctoraccount/' + path)
  }

  hidePage = (page) => {
    let hide = false;
    switch (page) {
      case 'Servicios':
        if (IS_ASSISTANT() && !HAS_ASSISTANT_PERMISSION(ROLES.AccessServices)) {
          hide = true;
        }
        break;
      case 'Agenda':
        if (IS_ASSISTANT() && !HAS_ASSISTANT_PERMISSION(ROLES.AccessAgenda)) {
          hide = true;
        }
        break;
      case 'Mis pacientes':
        if (IS_ASSISTANT() && !HAS_ASSISTANT_PERMISSION(ROLES.AccessPatients)) {
          hide = true;
        }
        break;
      case 'Mi perfil':
        if (IS_ASSISTANT() && !HAS_ASSISTANT_PERMISSION(ROLES.AccessDrProfile)) {
          hide = true;
        }
        break;
      default:
        break;
    }
    return hide;
  }
  render() {
    return (
      <div>
        {this.state.path}
        {!IS_VALID_DR() && <div className='messages'>
          <label className='title-center'>
            {'Tu perfil aún no se publica y se encuentra en revisión, ¡en breve nos pondremos en contacto contigo!'}
          </label>
        </div>
        }
        <div className='toggleButtons'>
          {this.state.pages.map(p => {
            return !p.hide && !this.hidePage(p.name) && <div
              key={p.name}
              className={(p.path === this.state.pageSelected || p.referenceTo === this.state.pageSelected) ? 'selected' : 'unselected'}
              onClick={() => { this.goTo(p.path) }}
            >
              {p.name}
            </div>
          })}

        </div>
        <Switch>
          {!this.hidePage('Servicios') && <Route path='/doctoraccount/services' component={Services} />
          }
          {!this.hidePage('Agenda') && <Route path='/doctoraccount/agenda' component={Agenda} />
          }
          {!this.hidePage('Agenda') && <Route path='/doctoraccount/schedule' component={ScheduleHours} />
          }
          {!this.hidePage('Mis pacientes') && <Route path='/doctoraccount/patient' component={Patient} />
          }
          {!this.hidePage('Mi perfil') && <Route path='/doctoraccount/profile' component={Profile} />
          }
        </Switch>
      </div>
    )
  }
}
