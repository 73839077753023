import React from 'react';
import Backdrop from '../backdrop/Backdrop';
import './Modal.scss';
import person from '../../../Assets/icons/Cerrar.svg'

const Modal = props => {
  return (
    <Backdrop
      show={props.show}
      clicked={props.modalClosed}
      className={props.backDropClass}
    >
      <div
        className={ 'Modal ' + ( props.className ? props.className :  '') }
        style={{
          transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: props.show ? '1' : '0'
        }}
      >
        <div className="modal-header mb-5">
          {props.closeIcon !== false && <button type="button" class="close" onClick={props.modalClosed} aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>}
        </div>
        {props.children}
      </div>
    </Backdrop>
  );
};

export default Modal;
