import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authConfig from '../Initialization/auth-config';
import { HAS_ROLE } from '../Utils/Generic'
/**
 * SecureRoute: extends base Route, allows for permission check on user navigation
 */
export function SecureRoute({ component: Component, authorize, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        const sessionAvailable = authConfig.sessionAvailable;
        if(!sessionAvailable){
          return     <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        }
        if(authorize && !HAS_ROLE(authorize)){
          return  <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        }
        return <Component {...props} />
      }}
    />
  );
}