import authConfig from '../Initialization/auth-config';
import { ROLES } from './Constants';
import moment from 'moment';

export const CREATE_ID = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};
export const IS_DOCTOR =  () => {
  return authConfig.role === ROLES.Doctor || authConfig.role === ROLES.DoctorAndPatient || IS_ASSISTANT();
}

export const IS_ADMIN =  () => {
  return authConfig.role === ROLES.Admin;
}

export const IS_ASSISTANT =  () => {
  let isAssistant = (authConfig.role & ROLES.Assistant) === ROLES.Assistant;
  return isAssistant;
}

export const HAS_ROLE =  (role) => {
  let allowed = false;

  role.forEach(element => {
    let hasRole = (authConfig.role & element) === element;
    if(hasRole){
      allowed = true;
    }
  });

  return allowed;
}

export const HAS_ASSISTANT_PERMISSION = (permission) => {
  let hasPermission = (authConfig.role & permission) === permission;
  return hasPermission;
}

export const IS_VALID_DR = () => {
  return true;//for now all drs are valid
  //return authConfig.validDr;
}

export const utcToLocal = (date) => {
  var utcDate = moment.utc(date).toDate();
  return moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss');
}

export const localToUtc = (date) => {
  var locaaleDate = moment(date);
  return moment(locaaleDate).utc().format('YYYY-MM-DD HH:mm');
}