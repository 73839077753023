import React, { useEffect, useState } from 'react'
import ProfileImage from '../../../Assets/icons/ICONO HOMBRE.svg'

import './LikeDislike.scss'
import ReactStars from 'react-stars'
import Loading from '../loading/Loading'
import prepareEndpoint from '../../../Utils/EndPoints'
import { deleteData, getData } from '../../../Utils/API'
import LikeDislike from './LikeDislike'
import { useHistory } from 'react-router-dom'

export function LikeList(props) {
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const history = useHistory()


  useEffect(() => {
    getLikes();
  }, []);

  async function getLikes() {
    setLoading(true);
    const url = prepareEndpoint('Favorites');
    const resp = await getData(url + '/DoctorDetails');
    if (resp.status === 200) {
      setDoctors(resp.data);
    }
    if (resp.status === 204) {
      setDoctors([]);
    }
    setLoading(false);

  }

  async function toggleLike(userId) {

    let url = `${prepareEndpoint('Favorites')}?favoriteUserId=${userId}`;
    let response = await deleteData(url);

    if (response && response.status === 200) {
      await getLikes();
      props.reload();
    } else {
      //@TODO: Handle error
    }

  }
  const goToDoctorAccount = (id) => {
    history.push(`/appointment/doctorInformation?doctorId=${id}`);
    props.close();
  }
  return (
    <div>
      {loading && <Loading />}
      <h1 className='text-center m-10 fav-title'>Favoritos</h1>
      <div className=''>
        {doctors.map((doctor, index) => {
          return (
            <div className='row' key={'like' + index}>
              <div className='col-12 col-lg-3 mr-3 d-flex align-items-center' onClick={() => { goToDoctorAccount(doctor.userId) }}>
                {doctor.img ?
                  <img className='img-Profile' src={`data:image/jpeg;base64,${doctor.img}` } alt={'as'} />
                  :
                  <img className='img-Profile' src={ProfileImage} alt={'as'} />
                }
              </div>
              <div className='col-10 col-lg-7 details-grid' onClick={() => { goToDoctorAccount(doctor.userId) }}>
                <div className='row my-0 py-0'>
                  <strong className='like-doctor fav-dr-name pointer'>
                    {doctor.firstName} {doctor.lastName}
                  </strong>
                </div>
                <div className='row my-0 py-0'>
                  <div className='like-doctor fav-dr-desc pointer'>
                    {doctor.specialtyField}
                  </div>
                </div>
                <div className='row my-0 py-0'>
                  <div className='like-doctor fav-dr-desc pointer'>
                    {`${doctor.locations[0].addressName} ${doctor.locations[0].consultorio?'consultorio':''} ${doctor.locations[0].consultorio}`}
                  </div>
                </div>
                <div className='row'>
                  <ReactStars
                    count={5}
                    value={doctor.rate}
                    size={24}
                    color1={'#FFF'}
                    color2={'#FDAD7B'}
                    edit={false}
                    half={false}
                  />
                </div>
              </div>
              <div className='col-1 like icons text-center' onClick={() => { toggleLike(doctor.userId) }}>
                <LikeDislike fill={true} />
              </div>
              <div className="divider-2"></div>
            </div>
          );
        })}
      </div>
    </div>
  )

}
