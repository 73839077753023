import React, { Component } from 'react';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import BasicRadio from '../../../Common/inputs/BasicRadio';
import '../ConciliationComponents/Conciliation.scss'
import downloadIcon from '../../../../Assets/icons/download.svg'
import SortIcons from '../../../Common/sortIcons';
import { Checkbox } from 'pretty-checkbox-react';
import prepareEndpoint from '../../../../Utils/EndPoints';
import { getData, getFile } from '../../../../Utils/API';
import Loading from '../../../Common/loading/Loading';
import _ from 'lodash';

export default class ConciliationInvoiceReceived extends Component {
  constructor(props){
    super(props);
    this.state = {
      invoiceType: [
        'Emitidas',
        'Recibidas'
      ],
      loading:false,
      invoiceTypeSelected:'doctor',
      period: 0,
      selectedDate: new Date(),
      data:[
        
      ],
      selectedInvoices:[]
    }
  }
  componentDidMount= async()=>{
    await this.loadInvoice();
  }
  loadInvoice = async (period, selectedDate, issuer)=>{
    this.setState({loading:true});
    try{
      period = period===undefined?this.state.period:period;
      issuer = issuer === undefined? this.state.invoiceTypeSelected:issuer;
      selectedDate = selectedDate===undefined?this.state.selectedDate:selectedDate;
      
      const url = `${prepareEndpoint('Invoice')}?issuerType=${issuer}&dateTime=${selectedDate.toLocaleDateString('en-us')}&temporality=${period}&fromPatient=true`;
      let response = await getData(url);
      if(response.status===200){
        const {data} = response;
        this.setState({data});
      }
    }
    finally{
      this.setState({loading:false});
    }
  }
  onDateChanged=async (date)=>{
    this.setState({selectedDate:date});
    this.loadInvoice(undefined, date);
  }
  onInvoiceSelected=(e, invoiceId)=>{
    const { checked } = e.target;
    const {selectedInvoices} = this.state;
    if(checked)
      selectedInvoices.push(invoiceId);
    else
      selectedInvoices.pop(invoiceId);
    
    this.setState({selectedInvoices});
  }
  downloadInvoices = ()=>{
    const{selectedInvoices} = this.state;
    if(selectedInvoices.length===0)
      return;

    selectedInvoices.forEach(async id=>{
      await this.getInvoicePdf(id);
    });
  }
  downloadAll =()=>{
    const{data}=this.state;
    if(data.length === 0)
      return;
    data.forEach(async invoice=>{
      await this.getInvoicePdf(invoice.appointmentId);
    });
  }
  getInvoicePdf = async(appointmentId) =>{
    const url = `${prepareEndpoint('Invoice')}/${appointmentId}/zip`;
    await getFile(url, '');
  }
  sortFunc=(a,b,field)=>{
    if(a[field]>b[field]) return 1;
    if(b[field]>a[field]) return -1;
  }
  sortBy = (type, order) => {
    let data = _.cloneDeep(this.state.data);
    data.sort((a,b) => {
      if(order === 'desc'){
        let x = a;
        a = b;
        b = x;
      }
      return this.sortFunc(a,b,type);
    });
    this.setState({data});
  }
  onMonthChange=async (date, closeFxn)=>{
    if(this.state.period===2){
      closeFxn(false)
      await this.onDateChanged(date)
    }
  }
  render(){
    return (
      <div className='container invoice-container'>
        {this.state.loading&&<Loading></Loading>}
        <div className='row'>
          <div className='col-2'>
            <BasicRadio radioValue="doctor" radioGroup="invoiceType" label="Doctor" isChecked={this.state.invoiceTypeSelected==='doctor'}
              onChangeFxn={async ()=>{this.setState({invoiceTypeSelected:'doctor'}); await this.loadInvoice(undefined, undefined, 'doctor')}}></BasicRadio>
          </div>
          <div className='col-2'>
            <BasicRadio radioValue="sanapp" radioGroup="invoiceType" label="Sanapp" isChecked={this.state.invoiceTypeSelected==='sanapp'}
              onChangeFxn={async ()=>{this.setState({invoiceTypeSelected:'sanapp'}); await this.loadInvoice(undefined, undefined, 'sanapp')}}></BasicRadio>
          </div>
          <div className='col-8'>
            <div className='row'>
              <label className='upper-label'>Fecha</label>
            </div>
            <div className='row period-container'>
              <div className='col-3 ml-0 pl-0'>
                <CustomDatePicker onDateChanged={this.onDateChanged} onMonthChange={this.onMonthChange}/>
              </div>
              <div className='col-auto ml-0 pl-0'>
                <BasicRadio radioValue={0} radioGroup="period" label="Día" isChecked={this.state.period===0}
                  onChangeFxn={async ()=>{this.setState({period:0}); await this.loadInvoice(0)}}></BasicRadio>
              </div>
              <div className='col-auto ml-0 pl-0'>
                <BasicRadio radioValue={1} radioGroup="period" label="Semana" isChecked={this.state.period===1}
                  onChangeFxn={async()=>{this.setState({period:1}); await this.loadInvoice(1)}}></BasicRadio>
              </div>
              <div className='col-auto ml-0 pl-0'>
                <BasicRadio radioValue={2} radioGroup="period" label="Mes" isChecked={this.state.period===2}
                  onChangeFxn={async ()=>{this.setState({period:2}); await this.loadInvoice(2)}}></BasicRadio>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-2 column-title'>
            <label className='text'>Transacción</label>
            <SortIcons onClickUp={()=>{this.sortBy('folio')}} onClickDown={()=>{this.sortBy('folio', 'desc')}} />           
          </div>
          <div className='col-2 column-title'>
            <label className='text'>Fecha</label>
            <SortIcons onClickUp={()=>{this.sortBy('date')}} onClickDown={()=>{this.sortBy('date', 'desc')}} />           
          </div>
          <div className='col-2 column-title'>
            <label className='text'>Doctor</label>
            <SortIcons onClickUp={()=>{this.sortBy('doctor')}}  onClickDown={()=>{this.sortBy('doctor', 'desc')}} />           
          </div>
          <div className='col-2 column-title'>
            <label className='text'> Servicio</label>
            <SortIcons onClickUp={()=>{this.sortBy('service')}} onClickDown={()=>{this.sortBy('service', 'desc')}} />           
          </div>
          <div className='col-1 px-0 column-title'>
            <label className='text'>Importe</label>
            <SortIcons onClickUp={()=>{this.sortBy('amount')}} onClickDown={()=>{this.sortBy('amount', 'desc')}}/>           
          </div>
          <div className='col-2 column-title'>
            <label className='text'>Factura</label>
            <SortIcons onClickUp={()=>{this.sortBy('certNumber')}} onClickDown={()=>{this.sortBy('certNumber', 'desc')}}/>           
          </div>
          <div className='col-1 px-0 column-title'>
            <label className='text'>Emisor</label>
            <SortIcons onClickUp={()=>{this.sortBy('issuer')}} onClickDown={()=>{this.sortBy('issuer', 'desc')}}/>           
          </div>
        </div>
        {this.state.data.map(d=> {
          return(
            <div className='row' key={`${d.appointmentId}-${d.folio}`}>
              <div className='col-2'>
                <Checkbox
                  id={d.appointmentId}
                  onChange={(e)=>{this.onInvoiceSelected(e, d.appointmentId)}}
                  checked={this.state.selectedInvoices.includes(d.appointmentId)}
                ></Checkbox>
                {d.folio}
              </div>
              <div className='col-2'>
                {d.textDate}
              </div>
              <div className='col-2'>
                {d.doctor}
              </div>
              <div className='col-2'>
                {d.service}
              </div>
              <div className='col-1 px-0'>
                ${d.amount}
              </div>
              <div className='col-2'>
                {d.certNumber}
              </div>
              <div className='col-1 px-0'>
                {d.issuer}
              </div>
            </div>
          )
        })}
        <div className='row pt-5 mt-5'>
          <div className='col-1'>
            <img src = {downloadIcon} className='icon-button' alt='download'onClick={this.downloadInvoices}></img>
          </div>
          <div className='col-10 pt-2 d-flex justify-content-center align-items-center'>
            <label className='link' onClick={this.downloadAll}>Descargar todo</label>
          </div>
        </div>
      </div>
    );
  }
}