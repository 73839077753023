import Cookies from 'universal-cookie';
import axios from 'axios';

class AuthConfig {
  requestInterceptorHandle = null;
  responseInterceptorHandle = null;
  _cookieOptions = { path: '/' };
  _sessionValid = true;
  TOKEN_KEY = 'SANAPP_TOKEN';
  USER_ID = 'USER_ID';
  AUTHORIZATION = 'Authorization'
  USER_EMAIL = 'USER_EMAIL';

  /**
   * Reads the auth token from the session cookie
   * @returns {string} authToken: The auth token of the current session
   */
  get authToken() {
    let cookies = new Cookies();
    let token = cookies.get(this.TOKEN_KEY) || null;
    return token;
  }

  set authToken(token) {
    let cookies = new Cookies();
    cookies.set(this.TOKEN_KEY, token, this._cookieOptions);
  }

  get sessionAvailable() {
    return this._sessionValid;
  }

  set sessionAvailable(valid) {
    this._sessionValid = valid;
  }
  get userId() {
    let cookies = new Cookies();
    let _userId = cookies.get(this.USER_ID) || null;
    return _userId;
  }

  set userId(id) {
    let cookies = new Cookies();
    cookies.set(this.USER_ID, id, this._cookieOptions);
  }
  
  get role() {
    return this._role;
  }

  set role(role) {
    this._role = role;
  }

  get email(){
    let cookies = new Cookies();
    let _email = cookies.get(this.USER_EMAIL) || undefined;
    return _email;
  }

  set email(email){
    let cookies = new Cookies();
    cookies.set(this.USER_EMAIL, email, this._cookieOptions);
  }

  get validDr() {
    return this._validDr;
  }

  set validDr(validDr) {
    this._validDr = validDr;
  }
  /**
   * Redirects user in event of unauthorized response
   */
  _redirectUnauthorized() {}

  /**
   * Registers a request interceptor that sets the AUTHORIZATION header on all outbound XHR requests
   */
  _initAuthRequestInterceptor() {
    this.responseInterceptorHandle = axios.interceptors.request.use(
      config =>{
        let token = this.authToken;
        if(token){
          config.headers[this.AUTHORIZATION] = 'Bearer ' + token;
        }
        return config;
      });            
  }

  /**
   * Registes response interceptor
   */
  _initAuthResponseInterceptor() {
    this.responseInterceptorHandle = axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
          return error;
          //@TODO: error need to give us status
          // this.sessionAvailable = false;
          //@TODO: use authservice logout function        
      }
    )
 
  }


  async initialize() {
    this._initAuthRequestInterceptor();
    this._initAuthResponseInterceptor();
  }
}

export default new AuthConfig();
