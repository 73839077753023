import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom'
import history from './history'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './authConfig'

const msalInstance = new PublicClientApplication(msalConfig);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Router basename={baseUrl} history={history}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Router>);

serviceWorkerRegistration.register();
