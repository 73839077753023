import _ from 'lodash';
import { Checkbox } from 'pretty-checkbox-react';
import React, { Component } from 'react';
import authConfig from '../../../../Initialization/auth-config';
import { getData, postData } from '../../../../Utils/API';
import prepareEndpoint from '../../../../Utils/EndPoints';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import BasicInput from '../../../Common/inputs/BasicInput';
import BasicRadio from '../../../Common/inputs/BasicRadio';
import Loading from '../../../Common/loading/Loading';
import SortIcons from '../../../Common/sortIcons';
import '../ConciliationComponents/Conciliation.scss'


export default class ConciliationRequestInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {
      fromDate: new Date(),
      toDate: new Date(),
      loading:false,
      selectedInvoices:[1],
      individualService:true,
      email: '',
      userEmail: '',
      showAlert:false,
      errors:{
        email:false
      },
      data:[
      ]
    }
  }
  async componentDidMount(){
    this.setEmail();
    await this.loadInvoice();
  }
  loadInvoice = async (fromDate, toDate)=>{
    this.setState({loading:true});
    try{
      fromDate = fromDate===undefined?this.state.fromDate:fromDate;
      toDate = toDate === undefined? this.state.toDate:toDate;
      
      const url = `${prepareEndpoint('Invoice')}/Pending?startDate=${fromDate.toLocaleDateString('en-us')}&endDate=${toDate.toLocaleDateString('en-us')}`;
      let response = await getData(url);
      if(response.status===200){
        const {data} = response;
        let selectedInvoices = [];
        this.setState({data, selectedInvoices});
      }
    }
    finally{
      this.setState({loading:false});
    }
  }
  isValidEmail = () => {
    const { email } = this.state;
    let re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return re.test(email) ? true : false;
  };
  setEmail = async ()=>{
    let userEmail = authConfig.email;
    if(!userEmail){
      const url = `${prepareEndpoint('UserEmail')}`;
      const resp = await getData(url);
      if (resp.status === 200) {
        userEmail =  resp.data;
      }
    }
    this.setState({userEmail});
  }
  clearErrors=()=>{
    const{errors} = this.state;
    errors.email = false;
    this.setState({errors});
  }
  onDateFromChanged=async (date)=>{
    this.setState({fromDate:date});
    await this.loadInvoice(date, undefined);
  }
  onDateToChanged=async (date)=>{
    this.setState({toDate:date});
    await this.loadInvoice(undefined, date);
  }
  onInvoiceSelected=(e, invoiceId)=>{
    const { checked } = e.target;
    const {selectedInvoices} = this.state;
    if(checked)
      selectedInvoices.push(invoiceId);
    else{
      const index = selectedInvoices.indexOf(invoiceId);
      selectedInvoices.splice(index,1);
    }
    
    this.setState({selectedInvoices});
  }
  sendEmail=async ()=>{
    const{errors, userEmail} = this.state;
    let {email} = this.state;
    this.clearErrors();
    if(!email && email.trim() === ''){
      email = userEmail;
    }
    else if(!this.isValidEmail())
    {
      errors.email = true;
      this.setState({errors});
      return;
    }
  
    await this.generateInvoice(email);
  }
  getSelectedAppointments=()=>{
    const{data, selectedInvoices}= this.state;
    const selected = [];
    selectedInvoices.forEach(id=>{
      var appointment= data.filter(d=> d.appointmentId === id);
      selected.push(appointment[0]);
    });

    return selected;
  }
  generateInvoice=async (email)=>{
    this.setState({loading:true});
    const multiple = !this.state.individualService;
    const data = this.getSelectedAppointments();
    try{
      const url = `${prepareEndpoint('Invoice')}/Pending`;
      var request = {email, data, multiple};
      let response = await postData(url, request);
      if(response.status===200){
        await this.loadInvoice();
      }
      else{
        this.showErrorAlert();
      }
    }
    finally{
      this.setState({loading:false});
    }
  }
  showErrorAlert(){
    this.setState({showAlert:true})
    setTimeout(() => {
      this.setState({showAlert:false})
    }, 5*1000);
  }
  onEmailChange=(value)=>{
    this.setState({email:value});
    this.clearErrors();
  }
  getMinDate = ()=>{
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  getMaxDate = ()=>{
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth()+1, 0);
  }
  sortByDate  = (a,b) => {
    if (a.date > b.date ) return 1;
    if (b.date  > a.date) return -1;
  }
  sortFunc=(a,b,field)=>{
    if(a[field]>b[field]) return 1;
    if(b[field]>a[field]) return -1;
  }
  sortBy = (type, order) => {
    let data = _.cloneDeep(this.state.data);
    if(type === 'date'){
      data.sort((a,b) => {
        if(order === 'desc'){
          let x = a;
          a = b;
          b = x;
        }
        return this.sortByDate(a,b);
      });
    }else{
      data.sort((a,b) => {
        if(order === 'desc'){
          let x = a;
          a = b;
          b = x;
        }
        return this.sortFunc(a,b,type);
      });
    }
    this.setState({data});
  }
  render(){
    return (
      <div className='container invoice-request-container'>
        <div className={`alert alert-danger fade ${this.state.showAlert ? 'show' : ''}`} role="alert">
          Ocurrió un error al solicitar la factura, revisa que tu RFC y datos de facturación sean correctos.
        </div>
        {this.state.loading&&<Loading></Loading>}
        <div className='row'>
          <div className='col-12'>
            <label className='text'>Selecciona el período del que deseas solicitar tu(s) factura(s)</label>
          </div>
        </div>
        <div className='row'>
          <div className='col-auto'>
            <label className='text'>Del:</label>
          </div>
          <div className='col-2 ml-0 px-0'>
            <CustomDatePicker minDate={this.getMinDate()} maxDate={this.getMaxDate()} onDateChanged={this.onDateFromChanged}/>
          </div>
          <div className='col-auto pl-0'>
            <label className='text'>Al:</label>
          </div>
          <div className='col-2 ml-0 pl-0'>
            <CustomDatePicker minDate={this.getMinDate()} maxDate={this.getMaxDate()} onDateChanged={this.onDateToChanged}/>
          </div>
        </div>
        <div className='row mt-4 table-header'>
          <div className='col-1 column-title pr-0'>
          </div>
          <div className='col-2 column-title px-0'>
            <label className='text'>Fecha</label>
            <SortIcons onClickUp={()=>{this.sortBy('date')}} onClickDown={()=>{this.sortBy('date', 'desc')}} />           
          </div>
          <div className='col-2 pr-0 column-title'>
            <label className='text'>Concepto</label>
            <SortIcons onClickUp={()=>{this.sortBy('concept')}}  onClickDown={()=>{this.sortBy('concept', 'desc')}} />           
          </div>
          <div className='col-1 column-title px-0'>
            <label className='text'> Monto</label>
            <SortIcons onClickUp={()=>{this.sortBy('amount')}} onClickDown={()=>{this.sortBy('amount', 'desc')}} />           
          </div>
          <div className='col-1 pr-0 column-title'>
            <label className='text'>Iva</label>
            <SortIcons onClickUp={()=>{this.sortBy('vat')}} onClickDown={()=>{this.sortBy('vat', 'desc')}}/>           
          </div>
          <div className='col-1 pr-0 column-title'>
            <label className='text'>Total</label>
            <SortIcons onClickUp={()=>{this.sortBy('total')}} onClickDown={()=>{this.sortBy('total', 'desc')}}/>           
          </div>
          <div className='col-2 pr-0 column-title'>
            <label className='text'>Serv. asociado</label>
            <SortIcons onClickUp={()=>{this.sortBy('service')}} onClickDown={()=>{this.sortBy('service', 'desc')}}/>           
          </div>
          <div className='col-2 pr-0 column-title'>
            <label className='text'>Doc. asociado</label>
            <SortIcons onClickUp={()=>{this.sortBy('doctor')}} onClickDown={()=>{this.sortBy('doctor', 'desc')}}/>           
          </div>
        </div>
        {
          this.state.data.map(d=> {
            return(
              <div className='row table-body' key={d.transactionNumber+'-'+d.date}>
                <div className='col-1 pr-0'>
                  <Checkbox
                    id={d.appointmentId}
                    onChange={(e)=>{this.onInvoiceSelected(e, d.appointmentId)}}
                    checked={this.state.selectedInvoices.includes(d.appointmentId)}
                  ></Checkbox>
                </div>
                <div className='col-2 column-title px-0'>
                  {d.textDate}          
                </div>
                <div className='col-2 pr-0 column-title'>
                  {d.concept}        
                </div>
                <div className='col-1 column-title px-0'>
                  {'$'+d.amount}        
                </div>
                <div className='col-1 pr-0 column-title'>
                  {'$'+d.vat}         
                </div>
                <div className='col-1 pr-0 column-title'>
                  {'$'+d.total}         
                </div>
                <div className='col-2 pr-0 column-title'>
                  {d.service}        
                </div>
                <div className='col-2 pr-0 column-title'>
                  {d.doctor}          
                </div>
              </div>
            )
          })
        }
        {
          this.state.selectedInvoices.length>0&&
          <>
            <div className='row mt-5 pt-2'>
              <div className='col-4'>
                <label className='text'>¿Cómo deseas recibir tu factura?</label>
              </div>
              <div className='col-8'>
                <label className='text'>{`Ingresa el correo electrónico en donde se enviará${this.state.selectedInvoices.length > 1 ? 'n' : ''} la${this.state.selectedInvoices.length > 1 ? 's' : ''} factura${this.state.selectedInvoices.length > 1 ? 's' : ''} que seleccionaste`}</label>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-4'>
                <BasicRadio radioValue="1" radioGroup="individualService" label="Servicios individuales" isChecked={this.state.individualService}
                  onChangeFxn={()=>{this.setState({individualService:true})}}></BasicRadio>
              </div>
              <div className='col-5'>
                <BasicInput type='email' onChangeFxn={(val) => { this.onEmailChange(val) }}
                  error={this.state.errors.email}
                  placeHolder={this.state.userEmail}
                  basicVal={this.state.email}></BasicInput>
              </div>
              <div className='col-1'>
                <button type='button' className='btn primary-btn' onClick={this.sendEmail}>Enviar</button>
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <BasicRadio radioValue="1" radioGroup="individualService" label="En una exhibición" isChecked={!this.state.individualService}
                  onChangeFxn={()=>{this.setState({individualService:false})}}></BasicRadio>
              </div>
            </div>
          </>
        }
      </div>
    );
  }
}