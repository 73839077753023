import React, { Component } from 'react';
import calendarIcon from '../../Assets/icons/popup-calendar.svg';
import localizationIcon from '../../Assets/icons/popup-localization.svg';
import priceIcon from '../../Assets/icons/popup-price.svg';
import AppointmentRow from '../AppointmentFlow/AppointmentConfirmation/AppointmentRow';
import BasicSelect from '../Common/inputs/BasicSelect';

const planes = [{ key: 'free', value: 'Libre' }, { key: 'basic', value: 'Básico' }, { key: 'Especializado', value: 'Especializado' }]

export default class ConfirmSubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedPlan: { key: 'free', value: 'Libre' }, showPolitics: false };
  }

  componentDidMount() {
    if (this.props.subscriptionType) {
      const plan = planes.find(p => p.key === this.props.subscriptionType);
      this.setState({ selectedPlan: plan });
    }
  }

  getTodaysDateRange1Month() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var mmPlus1 = String(today.getMonth() + 2).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    const todayPlus1Month = dd + '/' + mmPlus1 + '/' + yyyy;
    return 'Del ' + today + ' Al: ' + todayPlus1Month;
  }

    politics = () => {
      return (
        <>
          <div className='row pb-2 d-flex justify-content-center'>
            <h2 className="text-center">Políticas de cancelación</h2>
          </div>
          <div className='row justify-content-center ml-3'>
            <label className='text'>Para nosotros es muy valioso tu tiempo, por eso, hemos puesto a tu
                        disposición la posibilidad de reagendar tu cita hasta dos veces sin costo.</label>
          </div>
          <div className='row justify-content-center'>
            <label className='text'>A continuación, te enumeramos nuestras políticas de cancelación:</label>
          </div>
          <div className='row pl-5 pt-1'>
            <strong className='orange-text'>1</strong><label className='text pl-1'>No contamos con cancelaciones.</label>
          </div>
          <div className='row pl-5 pt-1'>
            <strong className='orange-text'>2</strong><label className='text pl-1'>No contamos con reembolsos.</label>
          </div>
          <div className='row pl-5 pt-1'>
            <strong className='orange-text'>3</strong><label className='text pl-1'>Tu cita no se podrá reagendar más de dos veces.</label>
          </div>
          <div className='row justify-content-center mt-5'>
            <label className='text'>Si requieres de asesoría personalizada, da click </label>
            <a
              href="https://api.whatsapp.com/send?phone=525569776510&text=Hola, necesito ayuda con sanapp"
              target="_blank"
              rel="noopener noreferrer"
              className='link_sopport'
            >
              <span className='pl-1'>aquí</span>
            </a>
          </div>
          <div className='row justify-content-center mt-3'>
            <button className='btn primary-btn' onClick={() => this.goBack()}>Regresar
            </button>
          </div>
        </>
      );
    }

    render() {
      return (
        <>
          <div className='appointment-container'>
            <div className='row pb-2 d-flex justify-content-center'>
              <h2 className="text-center">Confirma tus datos de afiliación</h2>
            </div>

            <div className='col-12' >
              <div className='row' >
                <div className='col-3'></div>
                <label className='col-3 text-align-end' style={{ textAlign: 'end' }}>Plan a adquirir: </label>
                <BasicSelect classes='col-3'
                  customOptions={planes}
                  selectedItem={this.state.selectedPlan.value}
                  onChangeFxn={val => this.setState({ selectedPlan: val.key !== 'Especializado' ? val : this.state.selectedPlan})}
                />
                <div className='col-3'></div>
              </div>
            </div>

            {this.renderSubscriptionDetails()}
            <div className='row justify-content-center mt-3'>
              <button className='btn primary-btn' onClick={() => this.props.onContinue(this.state.selectedPlan.key)} disabled={this.state.selectedPlan.key === 'Especializado'}>Continuar</button>
            </div>
          </div>
        </>
      );
    }

    renderSubscriptionDetails() {
      return (
        <>
          {(this.props.isPatient && this.state.selectedPlan.key === 'free') &&
                    <>
                      <AppointmentRow icon={calendarIcon} title='Beneficios' description='1 GB de almacenamiento.' secondLineInDescription='Gestiona tus citas desde tu agenda, accede a tu historial, expediente médico y almacena tus archivos' />
                      <AppointmentRow icon={localizationIcon} title='Vigencia' description={this.getTodaysDateRange1Month()} />
                      <AppointmentRow icon={priceIcon} title='Total a pagar' description='$0' link='Ver políticas de cancelación' onLinkClick={() => this.setState({ showPolitics: true })} />
                    </>
          }
          {(this.props.isPatient && this.state.selectedPlan.key === 'basic') &&
                    <>
                      <AppointmentRow icon={calendarIcon} title='Beneficios' description='Almacenamiento ilimitado.' secondLineInDescription='Disfruta de todos los beneficios del perfil libre y despreocúpate por el almacenamiento en nuestra nube.' />
                      <AppointmentRow icon={localizationIcon} title='Vigencia' description={this.getTodaysDateRange1Month()}  />
                      <AppointmentRow icon={priceIcon} title='Total a pagar' description='$149.00 + IVA al mes' link='Ver políticas de cancelación' onLinkClick={() => this.setState({ showPolitics: true })} />
                    </>
          }

          {(!this.props.isPatient && this.state.selectedPlan.key === 'free') &&
                    <>
                      <AppointmentRow icon={calendarIcon} title='Beneficios' description='1 GB de almacenamiento.' secondLineInDescription='Gestiona los servicios que ofreces como médico y las ubicaciones donde te localizan, maneja tu agenda y pacientes.' />
                      <AppointmentRow icon={localizationIcon} title='Vigencia' description={this.getTodaysDateRange1Month()}  />
                      <AppointmentRow icon={priceIcon} title='Total a pagar' description='$0' link='Ver políticas de cancelación' onLinkClick={() => this.setState({ showPolitics: true })} />
                    </>
          }
          {(!this.props.isPatient && this.state.selectedPlan.key === 'basic') &&
                    <>
                      <AppointmentRow icon={calendarIcon} title='Beneficios' description='Almacenamiento ilimitado.' secondLineInDescription='Disfruta de todos los beneficios del perfil libre y despreocúpate por el almacenamiento en nuestra nube.' />
                      <AppointmentRow icon={localizationIcon} title='Vigencia' description={this.getTodaysDateRange1Month()}  />
                      <AppointmentRow icon={priceIcon} title='Total a pagar' description='$499.00 + IVA al mes' link='Ver políticas de cancelación' onLinkClick={() => this.setState({ showPolitics: true })} />
                    </>
          }
        </>
      );
    }
}
