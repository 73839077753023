import React from 'react';
import Backdrop from '../backdrop/Backdrop';
import './Modal.scss';
import closeIcon from '../../../Assets/icons/Cerrar.svg'

const BasicModal = props => {
  return (
    <Backdrop
      show={props.show}
      clicked={props.modalClosed}
      className={props.backDropClass}
    >
      <div
        className={'Modal ' + (props.className ? props.className : '')}
        style={{
          transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: props.show ? '1' : '0'
        }}
      >
        <div className="modal-header mb-5">
          <button type="button" class="close" onClick={props.modalClosed} aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className='row d-flex justify-content-center'>
          <h3 className='text-center text'>{props.title}</h3>
        </div>
        {props.subtitle &&
                    <div className='extra-services'>
                      <div className='row pb-2 mt-2'>
                        <label className='text'>{props.subtitle}</label>
                      </div>
                    </div>
        }
        {props.children}
      </div>
    </Backdrop>
  );
};

export default BasicModal;
