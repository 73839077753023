import React, { Component } from 'react';
import { Checkbox } from 'pretty-checkbox-react';
import '../ConciliationComponents/Conciliation.scss';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import BasicRadio from '../../../Common/inputs/BasicRadio';
import Loading from '../../../Common/loading/Loading';
import SortIcons from '../../../Common/sortIcons';
import downloadIcon from '../../../../Assets/icons/download.svg';
import prepareEndpoint from '../../../../Utils/EndPoints';
import { getData, getFile } from '../../../../Utils/API';
import BasicSelect from '../../../Common/inputs/BasicSelect';

export default class ConciliationInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dateSelected: null,
      markedInvoices: [],
      invoiceType: [
        'Emitidas',
        'Recibidas'
      ],
      period: 'week',
      invoiceTypeSelected: 'Emitidas',
      data: [
        {
          appointmentId: '',
          transactionNumber: '',
          date: '',
          patientName: '',
          service: '',
          amount: '',
          invoice: '',
          issuer: 'Sanapp'
        }
      ]
    }
  }

  async getInvoicesAndUpdateState(stateField, stateValue) {
    if (stateField && stateField === 'invoiceTypeSelected' && (stateValue.includes('Seleccione') || stateValue.includes('Emitidas'))) {
      return;
    }

    await this.setState({ [stateField]: stateValue, loading: true });
    if (!this.state.invoiceTypeSelected || this.state.invoiceTypeSelected.includes('Seleccione')) {
      alert('Favor de seleccionar un tipo de factura.');
      this.setState({ loading: false });
      return;
    }

    const dateTimeToUse = !this.state.dateSelected ? new Date() : new Date(this.state.dateSelected);
    const dateTimeToQuery = `${('0' + (dateTimeToUse.getMonth() + 1)).slice(-2)}/${dateTimeToUse.getDate()}/${dateTimeToUse.getFullYear()}`;

    try {
      const url = 'api/Invoice?IssuerType=Doctor&issuerId=234&dateTime=' + dateTimeToQuery + '&temporality=' + this.state.period;
      const resp = await getData(url);
      if (resp.status === 200 && resp.data && resp.data.length > 0) {
        const data = resp.data.map(d => {
          return {
            appointmentId: d.appointmentId,
            transactionNumber: d.folio,
            date: d.date,
            patientName: d.patient,
            service: d.service,
            amount: d.amount,
            invoice: d.certNumber,
            issuer: 'Sanapp'
          }
        });
        this.setState({ data });
      }
    } catch (e) {

    } finally {
      this.setState({ loading: false });
    }
  }

  updateStateInvoiceChecked(checkbox, appointmentId) {
    const checked = checkbox?.target?.checked === true;
    let { markedInvoices } = this.state;
    const index = markedInvoices.indexOf(appointmentId);
    if (checked) {
      if (index === -1) {
        markedInvoices.push(appointmentId);
      }
    }
    else {
      if (index > -1) {
        markedInvoices.splice(index, 1);;
      }
    }
  }

    downloadMarkedInvoicesPdfs = async (downloadAll) => {
      let { markedInvoices } = this.state;
      if (downloadAll === true) {
        markedInvoices = this.state.data.map(d => d.appointmentId);
      }
      if (!markedInvoices || markedInvoices.length <= 0) {
        alert('Seleccione alguna factura.');
        return;
      }

      this.setState({ loading: true });

      for (var i = 0; i < markedInvoices.length; i++) {
        try {
          const url = `${prepareEndpoint('Invoice')}/${markedInvoices[i]}/zip`;
          await getFile(url, '');
        } catch (e) {
          console.log('Exception at downloadMarkedInvoicesPdfs: ', e)
        }
      }
      this.setState({ loading: false });
    }

    render() {
      return (
        <div className='container invoice-container'>
          {this.state.loading && <Loading />}
          <div className='row'>
            <div className='col-3'>
              <label className='text'>Tipo de facturas</label>
            </div>
            <div className='col-2'>
              <label className='text'>Fecha</label>
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <BasicSelect customOptions={this.state.invoiceType} selectedItem={this.state.invoiceTypeSelected}
                onChangeFxn={val => this.getInvoicesAndUpdateState('invoiceTypeSelected', val)}>

              </BasicSelect>
            </div>
            <div className='col-2 mr-2'>
              <CustomDatePicker onDateChanged={date => this.getInvoicesAndUpdateState('dateSelected', date)}></CustomDatePicker>
            </div>
            <div className='col-2 ml-4'>
              <BasicRadio radioValue="day" radioGroup="period" label="Día" isChecked={this.state.period === 'day'}
                onChangeFxn={() => { this.setState({ period: 'day' }) }}></BasicRadio>
            </div>
            <div className='col-2'>
              <BasicRadio radioValue="week" radioGroup="period" label="Semana" isChecked={this.state.period === 'week'}
                onChangeFxn={() => { this.setState({ period: 'week' }) }}></BasicRadio>
            </div>
            <div className='col-2'>
              <BasicRadio radioValue="month" radioGroup="period" label="Mes" isChecked={this.state.period === 'month'}
                onChangeFxn={() => { this.setState({ period: 'month' }) }}></BasicRadio>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-1 column-title'>
              <label className='text'>Transacción</label>
              <SortIcons onClickUp={() => { this.sortBy('fullName') }} onClickDown={() => { this.sortBy('fullName', 'desc') }} />
            </div>
            <div className='col-2 column-title' style={{ textAlign: 'center'} }>
              <label className='text'>Fecha</label>
              <SortIcons onClickUp={() => { this.sortBy('selectedDate') }} onClickDown={() => { this.sortBy('selectedDate', 'desc') }} />
            </div>
            <div className='col-2 column-title'>
              <label className='text'>Paciente</label>
              <SortIcons onClickUp={() => { this.sortBy('location') }} onClickDown={() => { this.sortBy('location', 'desc') }} />
            </div>
            <div className='col-2 column-title'>
              <label className='text'> Servicio</label>
              <SortIcons onClickUp={() => { this.sortBy('calculatedDistance') }} onClickDown={() => { this.sortBy('calculatedDistance', 'desc') }} />
            </div>
            <div className='col-1 px-0 column-title'>
              <label className='text'>Importe</label>
              <SortIcons onClickUp={() => { this.sortBy('price') }} onClickDown={() => { this.sortBy('price', 'desc') }} />
            </div>
            <div className='col-3 column-title' style={{ textAlign: 'center' }}>
              <label className='text'>Factura</label>
              <SortIcons onClickUp={() => { this.sortBy('price') }} onClickDown={() => { this.sortBy('price', 'desc') }} />
            </div>
            <div className='col-1 px-0 column-title'>
              <label className='text'>Emisor</label>
              <SortIcons onClickUp={() => { this.sortBy('price') }} onClickDown={() => { this.sortBy('price', 'desc') }} />
            </div>
          </div>
          {this.state.data && this.state.data.length > 0 && this.state.data.map((d, i) => {
            return (
              <div className='row' key={`${d.appointmentId}-${d.invoice}-${i}`}>
                <div className='col-1'>
                  <Checkbox onChange={c => this.updateStateInvoiceChecked(c, d.appointmentId)}></Checkbox>
                  {d.transactionNumber}
                </div>
                <div className='col-2' style={{ textAlign: 'center' }}>
                  {d.date}
                </div>
                <div className='col-2'>
                  {d.patientName}
                </div>
                <div className='col-2'>
                  {d.service}
                </div>
                <div className='col-1 px-0'>
                  {d.amount}
                </div>
                <div className='col-3' style={{ textAlign: 'center' }}>
                  {d.invoice}
                </div>
                <div className='col-1 px-0'>
                  {d.issuer}
                </div>
              </div>
            )
          })}
          <div className='row mt-5 d-flex align-items-center bottom-content' >
            <div className='col-1' onClick={this.downloadMarkedInvoicesPdfs}>
              <img src={downloadIcon} className='icon-button' alt='download'></img>
            </div>
            <div className='col-10 pt-2 d-flex justify-content-center align-items-center' onClick={() => this.downloadMarkedInvoicesPdfs(true)}>
              <label className='link clickable'>Descargar todo</label>
            </div>
          </div>
        </div>
      );
    }
}