import React, { Component } from 'react';

export default class AppointmentConfirmation extends Component {
  secondLineInDescription = () => {
    if (this.props.secondLineInDescription) {
      return (
        <div className='row py-0'>
          <p className='mb-0'>{this.props.secondLineInDescription}</p>
        </div>
      );
    }
  }

  link = () => {
    if (this.props.link && this.props.onLinkClick) {
      return (
        <div className='row py-0'>
          <p className='mb-0 link' onClick={this.props.onLinkClick}>{this.props.link}</p>
        </div>
      );
    }
  }

  body = () => {
    if (this.props.title0 && this.props.description0) {
      return (
        <div>
          <div className='row py-0'>
            <label className='text mb-0 text-invalid col pl-0'>{this.props.title0}</label>
            <label className='text mb-0 text-1 col'>{this.props.title}</label>
          </div>
          <div className='row py-0'>
            <p className='mb-0 text-invalid col pl-0'>{this.props.description0}</p>
            <p className='mb-0 col'>{this.props.description}</p>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          <div className='row py-0'>
            <label className='text mb-0'>{this.props.title}</label>
          </div>
          <div className='row py-0'>
            <p className='mb-0'>{this.props.description}</p>
          </div>
        </div>
      );
    }
  }

  render() {

    return (
      <><div className='row'>
        <div className='divider m-0 p-0'></div>
      </div>
        <div className='row data-row-container px-0 py-2 align-items-center'>
          <div className='col col-2 pr-0'>
            <img src={this.props.icon} alt='doctor icon' className={this.props.profile ? 'img-Profile' : ''}/>
          </div>
          <div className='col col-10 p-0'>
            {this.body()}
            {this.secondLineInDescription()}
            {this.link()}
          </div>
        </div></>
    );
  }
}
