import React, { Component } from 'react';
import valora from '../../Assets/img/Home-valora.svg'
import opciones from '../../Assets/img/Home-Opciones.svg'
import cita from '../../Assets/img/Tu-cita.svg'

import './Home.scss'

class HomeFooterMobile extends Component {
  
  render () {
    return (        
      <section className='home-footer mt-5'>
        <h2 className="text title">Obtén grandes beneficios:</h2>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <div className='d-flex justify-content-center'>
              <h4 className='text'>Valora tu mejor opción</h4>
            </div>
            <p>Selecciona un especialista de acuerdo a las recomendaciones de otros pacientes.</p>
            <div className='img-center'>
              <img src={valora} alt='valora'/>
            </div>
          </div>
          
          <div className='col-12 col-md-4 mt-4'>
            <div className='d-flex justify-content-center'>
              <h4 className='text'>Tu cita al instante</h4>
            </div>
            <p>Consigue tu cita médica con un especialista de forma fácil y rápida.</p>
            <div className='img-center'>
              <img src={cita} alt='cita'/>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-5'>
            <div className='d-flex justify-content-center'>
              <h4 className='text'>Opciones claras</h4>
            </div>
            <p>Una herramienta sencilla para obtener un servicio médico especializado.</p>
            <div className='img-center'>
              <img src={opciones} alt='opcione'/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeFooterMobile;