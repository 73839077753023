import React, { Component } from 'react'
import { getData, postData } from '../../../Utils/API';
import prepareEndpoint from '../../../Utils/EndPoints';
import authService from '../../../security/auth-service'
import IconInput from '../../Common/inputs/IconInput'
import Loading from '../../Common/loading/Loading'
import logo from '../../../Assets/logo.svg'
import magnifier from '../../../Assets/icons/Reservar-icono-lupa.svg'
import sortIcon from '../../../Assets/icons/Ordenar.svg'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import history from '../../../history'
import './DoctorValidation.scss'
import BasicSelect from '../../Common/inputs/BasicSelect';

export default class DoctorValidation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'CreatedDate',
      sortOrder: 0,//1= ascending, 0 = descending
      filter: '',
      dres: []
    };
  }

  async componentDidMount() {
    await this.getDoctors(this.state.pageIndex, this.state.sortField, this.state.sortOrder, this.state.filter);
    this.setState({ loading: false });
  }

  getDoctors = async (newPageIndex, newSortField, newSortOrder, filter) => {
    const url = prepareEndpoint('Doctor');
    const resp = await getData(`${url}/GetAll/${newPageIndex}/${this.state.pageSize}/${newSortField}/${newSortOrder}?filter=${filter}`);
    if (resp?.status !== 200) {
      return;
    }
    this.setState({
      dres: resp.data.listDoctors,
      totalCount: resp.data.totalDoctors
    });
  }


  goToReview = (doctorId) => {
    history.push(`/drreview?doctorId=${doctorId}`);
  }

  selectOption(option) {
    switch (option) {
    case 'Cerrar sesion':
      authService.logout();
      break;
    default:
    }
  }

  getDDOptions() {
    let options = ['Cerrar sesion'];

    return options;
  }

  changeSorting = async (newSorting) => {
    this.setState({ loading: true });
    var newSortOrder = this.state.sortOrder === 1 ? 0 : 1;
    this.setState({ sortField: newSorting });
    this.setState({ sortOrder: newSortOrder });
    await this.getDoctors(this.state.pageIndex, newSorting, newSortOrder, this.state.filter);
    this.setState({ loading: false });
  }

  search = async (search) => {
    this.setState({ loading: true });
    this.setState({ filter: search });
    this.setState({ pageIndex: 1 });
    await this.getDoctors(1, this.state.sortField, this.state.sortOrder, search);
    this.setState({ loading: false });
  }

  getPagination() {
    const list = [1]

    var numberOfPages = this.state.totalCount > 0 ? this.state.totalCount / this.state.pageSize : 0;
    for (var i = 2; i < numberOfPages + 1; i++) {
      list.push(i)
    }

    return (
      <div className='paginationSection'>
        <span onClick={() => this.changePage((this.state.pageIndex - 1), numberOfPages)}>&lt;</span>
        {list.map(i => <span key={i} onClick={() => this.changePage(i, numberOfPages)}>{i} </span>)}
        <span onClick={() => this.changePage(this.state.pageIndex + 1, numberOfPages)}>&gt;</span>
      </div>
    );
  }

  changePage = async (newPageIndex, numberOfPages) => {
    if (newPageIndex > 0 && newPageIndex <= numberOfPages + 1 && newPageIndex !== this.state.pageIndex) {
      this.setState({ loading: true });
      this.setState({ pageIndex: newPageIndex });
      await this.getDoctors(newPageIndex, this.state.sortField, this.state.sortOrder, this.state.filter);
      this.setState({ loading: false });
    }
  }

  getCurrentPageSize() {
    var isLastPage = this.state.pageSize > this.state.grouped.length;
    var maxSize = isLastPage ? (this.state.pageIndex === 1 ? 0 : this.getCurrentInitialCount()) + this.state.grouped.length : this.state.pageSize;
    var currentPageSize = isLastPage ? maxSize : this.state.pageIndex * maxSize
    return currentPageSize;
  }

  getCurrentInitialCount() {
    var currentInitialValue = this.state.pageIndex === 1 ? 1 : ((this.state.pageIndex * this.state.pageSize) - this.state.pageSize);
    return currentInitialValue;
  }

  saveNotes = async (drId, status, notes) => {
    this.setState({ loading: true });
    const url = `${prepareEndpoint('Doctor')}/ChangeDoctorStatus/${drId}`;
    await postData(url, { status: status, notes: notes });
    await this.getDoctors(this.state.pageIndex, this.state.sortField, this.state.sortOrder, this.state.filter);
    this.setState({ loading: false });
  }

  goToBuro = async (drId, license) => {
    this.setState({ loading: true });
    const url = `${prepareEndpoint('Doctor')}/GetCedulaDetails/${drId}`;
    const resp = await getData(url);
    if (resp?.status !== 200) {
      console.log(`data: ${JSON.stringify(resp.data)}`)
    }
    this.setState({ loading: false });
    window.open(`https://www.buholegal.com/${license}/`, '_blank', 'noreferrer');
  }

  render() {
    return (
      <>
        <nav className='navbar fixed-top'>
          <div className='container-fluid'>
            <div className='logo'>
              <img src={logo} alt="Icono: Logotipo en la insignia" />
            </div>

            <div className="form-inline">
              <h4>Bandeja de validación de médicos</h4>
            </div>

            <div className='nav-buttons form-inline account'>

              <BasicSelect
                placeholder='Administrador'
                btnClasses={'btn confirm-btn my-account-btn shine'}
                customOptions={this.getDDOptions()}
                onChangeFxn={this.selectOption}
              />
            </div>
          </div>
          <div className='container-fluid mt-3'>
            <div className="form-inline">
              <IconInput
                prependIcon={magnifier}
                placeHolder={'Buscar cédula o nombre'}
                classes='nav-input'
                inputVal={this.state.filter}
                onChange={this.search}
              />
            </div>
          </div>
        </nav>

        <div className='form-inline doctor-validation pt-5 pb-5'>
          {this.state.loading && <Loading />}

          <div className="divider"></div>

          <div className='col-12 pr-0 pl-0'>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Folio Solicitud
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('IdNumber')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Cuenta
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('IdNumber')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Fecha
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('CreatedDate')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Nombre Doctor
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('FirstName')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Especialidad
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('SpecialtyField')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Cédula
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('ProfessionalLicenseStatus')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Estatus
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('Status')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Autorización
                      </label>
                    </div>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.dres.map(p => {
                  return (
                    <Tr key={p.id} className='col-12 col-md-6 space table-divider'>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.idNumber}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.idNumber}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {`${new Date(p.createdDate).getDate().toString().padStart(2, '0')}-${(new Date(p.createdDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(p.createdDate).getFullYear().toString().padStart(4, '0')}`}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.firstName} {p.lastName}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.specialtyField}
                        </div>
                      </Td>
                      <Td>
                        {p.professionalLicenseStatus === 'No válida' && <div className='d-flex justify-content-space-flex-start messages pointer'>
                          <label onClick={() => this.goToBuro(p.userId, p.professionalLicense)} className='pointer'>
                            {p.professionalLicenseStatus}
                          </label>
                        </div>
                        }
                        {p.professionalLicenseStatus !== 'No válida' && <div className='d-flex justify-content-space-flex-start messages-green pointer'>
                          <label onClick={() => this.goToBuro(p.userId, p.professionalLicense)} className='pointer'>
                            {p.professionalLicenseStatus}
                          </label>
                        </div>
                        }
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.status}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          <label
                            className={
                              'options-text ' +
                              (p.professionalLicenseStatus === 'Válida' && (p.status === 'En Edición' || p.status === 'Revisión') ? 'active' : 'inactive')
                            }
                            onClick={() => this.saveNotes(p.userId, 'Liberado', p.notes)}
                          >
                            Autorizar
                          </label>
                        </div>
                        <div className='d-flex justify-content-space-flex-start'>
                          <label
                            className={
                              'options-text ' +
                              (p.professionalLicenseStatus === 'Válida' && (p.status === 'Liberado' || p.status === 'Rechazado') ? ' inactive' : ' active')
                            }
                            onClick={() => this.saveNotes(p.userId, 'Rechazado', p.notes)}
                          >
                            Rechazar
                          </label>
                        </div>
                        <div className='d-flex justify-content-space-flex-start'>
                          <label
                            className={
                              'options-text ' +
                              (p.professionalLicenseStatus === 'Válida' && (p.status === 'Liberado' || p.status === 'Rechazado') ? ' inactive' : ' active')
                            }
                            onClick={() => this.goToReview(p.userId)}
                          >
                            Editar
                          </label>
                        </div>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </div>

          <div className='paginationSection'>{this.getPagination()}</div>

        </div >

      </>
    )
  }
}