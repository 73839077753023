import React, { Component, Fragment } from 'react'
import './Inputs.scss'
import { isEmpty } from 'lodash'

export default class BasicSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }
  toggleDropdown = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };

  hideDropdown = () => {
    this.setState({
      isOpen: false
    })
  };
  onSelectOption = (e) => {
    const callBack = this.props.onChangeFxn;
    if(!callBack)
    {
      return;
    }
    const {value} = e;
    const {customOptions} = this.props;
    const isComplex = customOptions[0].value !== undefined;
    if (isComplex) {
      const val= customOptions.filter(obj=> obj.value === value);
      callBack(val[0]);
    }
    else{
      const val = customOptions.filter(obj=> obj===value);
      callBack(val[0]);
    }
  }
  getOptions=()=>{
    if(this.props.customOptions === undefined || isEmpty(this.props.customOptions)){
      return(
        <option
          id={'dropdown-item-' + this.props.selectedItem}
          key={this.props.selectedItem}
          value={this.props.selectedItem}
        >
          {
            !this.props.customOptionsFormatter ? this.props.selectedItem : this.props.customOptionsFormatter(this.props.selectedItem)
          }
        </option>
      );
    }
    return(
      this.props.customOptions?.map((val, key) => (
        <option
          id={'dropdown-item-' + key}
          key={val.key ? val.key : key}
          value={val.value ? val.value : val}
        >
          {
            !this.props.customOptionsFormatter ? val.value ? val.value : val : this.props.customOptionsFormatter(val.value ? val.value : val)
          }
        </option>
      ))
    );
  }
  render() {
    return (
      <Fragment>
        <div className={'basic-select form-group ' + (this.props.classes ? this.props.classes : '')}>
          <label className='text' hidden={!this.props.title}>
            {this.props.title}
          </label>
          <select className="form-control" value={this.props.selectedItem} name={this.props.name} onChange={(e)=>this.onSelectOption(e.target)}>
            {
              this.getOptions()
            }
          </select>
        </div>
      </Fragment>
    )
  }
}
