import React, { Component } from 'react';
import './ConciliationComponents/Conciliation.scss'
import documentIcon from '../../../Assets/icons/document.svg'
import invoiceIcon from '../../../Assets/icons/invoice.svg'
import signIcon from '../../../Assets/icons/sign.svg'
import IconButton from './ConciliationComponents/IconButton';
import ConciliationDocuments from './ConciliationComponents/ConciliationDocuments';
import ConciliationInvoice from './ConciliationComponents/ConciliationInvoice';
import ConciliationSign from './ConciliationComponents/ConciliationSign';


export default class Conciliation extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabSelected:0
    }
  }
  renderView=()=>{
    switch(this.state.tabSelected){
    case 0: return (<ConciliationDocuments></ConciliationDocuments>);
    case 1: return (<ConciliationInvoice></ConciliationInvoice>);
    case 3: return (<ConciliationSign/>)
    default: return(<label>Not Selected</label>);
    }
  }
  render(){
    return(
      <div className='conciliation-container'>
        <div className='row'>
          <div className='col-2 left-content '>
            <IconButton icon={documentIcon} label={'Documentos de la cuenta'} enabled={this.state.tabSelected ===0} clickFn={() => this.setState({ tabSelected: 0 })}></IconButton>
            <IconButton icon={invoiceIcon} label={'Factura'} enabled={this.state.tabSelected ===1} clickFn={() => this.setState({ tabSelected: 1 })}></IconButton>
            <IconButton icon={signIcon} label={'Firma'} enabled={this.state.tabSelected ===3} clickFn={() => this.setState({ tabSelected: 3 })}></IconButton>
          </div>
          <div className='col-10 right-content ml-4'>
            {this.renderView()}
          </div>
        </div>
      </div>
    );
  }
}