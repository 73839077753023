import React, { Component, Fragment } from 'react'
import './Inputs.scss'
import { isEmpty } from 'lodash'

export default class BasicDropDown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggleDropdown = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };

  hideDropdown = () => {
    this.setState({
      isOpen: false
    })
  };
  onSelectOption = (option) => {
    const callBack = this.props.onChangeFxn;
    if (callBack) {
      callBack(option);
    }
    this.hideDropdown();
  }
  render() {
    const displayValue = this.props.selectedItem ? this.props.selectedItem : this.props.placeholder;
    return (
      <Fragment>
        <div className={'basic-select form-group ' + (this.props.classes ? this.props.classes : '')}>
          <label className='text' hidden={!this.props.title}>
            {this.props.title}
          </label>
          <button
            className={`form-control ${this.props.error ? 'is-invalid' : ''} dd-toggle ${this.state.isOpen ? 'open' : ''
              } ${this.props.btnClasses}`}
            type='button'
            id={'dropdownMenuButton' + Math.random()}
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
            onClick={this.toggleDropdown}
            onBlur={this.hideDropdown}
            disabled={this.props.disabled ? 'disabled' : ''}
          >
            {displayValue}
          </button>
          <div
            className={`dropdown-menu ${this.state.isOpen ? 'show' : ''
              } dropdown-menu-list`}
            aria-labelledby='dropdownMenuButton'
          >
            {!isEmpty(this.props.customOptions) &&
              this.props.customOptions.map((option, idx) => {
                const optVal = option.value ? option.value : option;
                return (
                    <p
                    onMouseDown={() => { this.onSelectOption(option) }}
                    className='dropdown-menu-item'
                    id={'dropdown-item-' + idx}
                    value={option.value ? option.value : option}
                    key={option.key ? option.key : option}
                  >
                    {!this.props.customOptionsFormatter ? optVal : this.props.customOptionsFormatter(optVal)}
                  </p>
                )
              })}
          </div>
        </div>
      </Fragment>
    )
  }
}
