import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import outlook from '../../../Assets/icons/Agenda semana-outlook.svg'
import { graphConfig, loginRequest } from '../../../authConfig';
import { callMsGraph } from '../../../graph';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const OutlookSignInButton = (props) => {
  const { instance, accounts } = useMsal();

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      callMsGraph(response.accessToken, graphConfig.graphEventsEndpoint).then(response => {
        props.onGetEvents(response.value);
      });
    });
  }

  const handleLogin = () => {
    instance.loginPopup(loginRequest).then(()=>{
      RequestProfileData();
    })
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <>
      <AuthenticatedTemplate>
        <img src={outlook} alt='outlook' onClick={() => RequestProfileData('popup')} />   
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <img src={outlook} alt='outlook' onClick={() => handleLogin('popup')} />   
      </UnauthenticatedTemplate>
    </>
  )
}