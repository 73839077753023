import React, { Component } from 'react';
import Login from './Login';
import ModalStep from '../AppointmentFlow/Flow/ModalStep';
import EnterEmail from './EnterEmail';
import EnterCode from './EnterCode';
import ChangePassword from './ChangePassword';
import ConfirmSubscriptionModal from './ConfirmSubscriptionModal';
import Payment from '../Patient/Payments/Payments';
import WelcomeSubscriptionModal from './WelcomeSubscriptionModal';
import authConfig from '../../Initialization/auth-config';
import { postData } from '../../Utils/API';

class LoginSubscribeFlowControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            userEmail: '',
            validationCode: ''
        };
    }

    setEmail = (value) => {
        this.setState({ userEmail: value });
        this.nextStep();
    }
    setValidationCode = (value) => {
        this.setState({ validationCode: value });
        this.nextStep();
    }
    nextStep = () => {
        const { currentStep } = this.state;
        let nextStep = currentStep + 1;
        this.setState({ currentStep: nextStep });
    }

    resetFlow = () => {
        this.setState({ currentStep: 0 });
    }

    async start(requestedSubscription, currentSubscription) {
        if (authConfig.sessionAvailable) {
            if (requestedSubscription === 'free' && currentSubscription === 'basic') {
                await postData('api/ProcessPayment/CancelSubscription', { subscriptionType: requestedSubscription, isPatient: this.props.isPatient });
            }
            this.handleLogin();
            return;
        }
        this.setState({ currentStep: 1 });
    }

    goToProfile = () => {
        if (this.props.isPatient) {
            this.props.history.push('/Patient/Profile')
            return;
        }
        this.props.history.push('/Doctoraccount/Profile')
    }

    handleLogin = () => {
        if (this.props.subscriptionType === 'free') {
            this.goToProfile();
            return;
        }
        this.setState({ currentStep: 5 });
    }

    render() {

        return (
            <>
                <ModalStep stepNumber={1} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
                    <Login onSuccessLogin={this.handleLogin} onSucessRegister={() => this.setState({ currentStep: 5 })} isDoctor={!this.props.isPatient} onForgetPassword={this.nextStep} />
                </ModalStep>
                <ModalStep stepNumber={2} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
                    <EnterEmail onContinue={this.setEmail}></EnterEmail>
                </ModalStep>
                <ModalStep stepNumber={3} currentStep={this.state.currentStep} stepClosed={this.resetFlow} className='modal-code'>
                    <EnterCode onContinue={this.setValidationCode} email={this.state.userEmail}></EnterCode>
                </ModalStep>
                <ModalStep stepNumber={4} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
                    <ChangePassword onContinue={this.props.onSuccessLogin} email={this.state.userEmail} validationCode={this.state.validationCode}></ChangePassword>
                </ModalStep>

                <ModalStep stepNumber={5} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
                    <ConfirmSubscriptionModal onContinue={(subscriptionType) => subscriptionType === 'free' ? this.goToProfile() : this.nextStep()}
                        subscriptionType={this.props.subscriptionType}> isPatient={this.props.isPatient}</ConfirmSubscriptionModal>
                </ModalStep>
                <ModalStep stepNumber={6} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
                    <Payment onContinue={this.nextStep} isSubscribing={true} subscriptionType={this.props.subscriptionType} onSuccessPayment={this.nextStep} isPatient={this.props.isPatient}></Payment>
                </ModalStep>
                <ModalStep stepNumber={7} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
                    <WelcomeSubscriptionModal onContinue={this.goToProfile} ></WelcomeSubscriptionModal>
                </ModalStep>
            </>
        )
    }
}


export default LoginSubscribeFlowControl;
