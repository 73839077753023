import React, { Component } from 'react'
import CanvasDraw from 'react-canvas-draw';
import Modal from './Modal';
import './Modal.scss';
import './SignModal.scss'
import uploadIcon from '../../../Assets/icons/sign-upload.svg';
import handIcon from '../../../Assets/icons/sign-hand.svg';
export default class SignModal extends Component {
  constructor(props){
    super(props);
    this.state={
      img:''
    }
  }
  handleKeyChange=async (selectorFiles)=>{
    let reader = new FileReader();
    const file = selectorFiles[0];
    const that = this;
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = reader.result;

      that.setState({img});
    };
  }
  clear = ()=>{
    if(!this.state.img || this.state.img === '')
    {
      this.saveableCanvas.clear();
    }
    this.setState({img:''});
  }
  getCanvas=()=>{
    return(
      <CanvasDraw
        canvasWidth={750}
        canvasHeight={240}
        hideGrid={true}
        brushRadius={1}
        lazyRadius={0}
        ref={canvas=>this.saveableCanvas=canvas}
        style={{
          borderRadius:'15px'
        }}
      />
    );
  }
  complete=()=>{
    
    if(this.props.onChange){
      if(this.state.img && this.state.img !== '')
      {
        this.props.onChange(this.state.img);
        return;
      }
      const obj = JSON.parse(this.saveableCanvas.getSaveData());
      if(obj.lines.length ===0){
        this.props.onChange('');
        return;
      }
      const data = this.saveableCanvas.getDataURL('png', false, '#FFFFFF');
      this.props.onChange(data);
    }
  }
  render() {
    return (
      <Modal
        className='signModal'
        show={this.props.show}
        closeIcon={true}
        modalClosed = {this.props.modalClosed}
      >
        <form>
          <input
            ref={fileSelector=> (this.filePicker = fileSelector)}
            type='file'
            className='hide'
            accept='image/*'
            onChange={(e) => this.handleKeyChange(e.target.files)}
            onClick={(e) => {e.currentTarget.value = null}}
          />
        </form>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12'>
            <h3 className='text-center'>{(this.props.title && this.props.title.length > 0) ? this.props.title : 'Cambiar firma'}</h3>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12  d-flex align-items-center justify-content-center'>
            <label className='text'>
                {(this.props.initialText && this.props.initialText.length > 0) ? this.props.initialText : 'Elige o traza a mano una nueva firma para sustituirla por la actual'}
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 d-flex align-items-center justify-content-center'>
            <div className='canvas-container'>
              {
                this.state.img && 
                <div className='canvas-image'>
                  <img src={this.state.img} className='canvas-image' alt='sign'></img>
                </div>
              }
              {(!this.state.img || this.state.img === '')&&
                this.getCanvas()
              }
              <div className='col-12 d-flex align-items-center justify-content-center mb-3'>
                <img src={uploadIcon} alt='upload' onClick={() => { this.filePicker.click();}} className='btn-icon'></img>
                <img src={handIcon} alt='draw' onClick={this.clear} className='btn-icon ml-5'></img>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-3'>
          <div className='col-12 d-flex align-items-center justify-content-center'>
            <button type='button' className='btn primary-btn' onClick={this.complete}>
                {(this.props.confirmButtonText && this.props.confirmButtonText.length > 0) ? this.props.confirmButtonText : 'Aceptar'}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}