import React, { Component } from 'react';
import { appContext } from '../../../Utils/Context';
import Payments from '../../Patient/Payments/Payments';
import PaymentConfirmation from '../../Patient/Payments/PaymentConfirmation';
import Invoice from '../../Patient/Invoice/Invoice';
import AppointmentConfirmation from '../AppointmentConfirmation/AppointmentConfirmation';
import ModalStep from './ModalStep'
import SuccessReservation from '../../Common/modal/SuccessReservation';
import LoginFlowControl from '../../Login/LoginFlowControl';

class FlowControl extends Component {
  static contextType = appContext;
  constructor(props){
    super(props);
    this.state = {
      currentStep:0,
      patientName: '',
      lastDigits:''
    };
    this.flowRef = React.createRef();
  }
  
  nextStep =()=>{
    const {validSession} = this.context.state;
    const{currentStep} = this.state;
    let nextStep = currentStep+1;
    
    if(nextStep===2){
      if(validSession)
      {
        nextStep = nextStep+1;
      }
      else{
        this.flowRef.current.start();
      }
    }

    this.setState({currentStep: nextStep});
  }

  resetFlow =() =>{
    this.setState({currentStep: 0});
  }

  closeLogin =()=>{
    this.flowRef.current.resetFlow();
    this.setState({currentStep:3});
  }

  goToAgenda = ()=>{
    window.location.href = '/patient/agenda';
  }

  start(){
    this.setState({currentStep:1});
  }
  onUpdatePatient = (patientName) => {
    this.setState({patientName})
  }
  onUpdateCardDigits=(lastDigits)=>{
    this.setState({lastDigits});
  }
  render() {
   
    return(
      <>
        <ModalStep className='confirmation-step' stepNumber={1} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
          <AppointmentConfirmation onUpdatePatient={this.onUpdatePatient} doctorInformation={this.props.doctorInformation} price={this.props.price} onAppointmentConfirmed={this.nextStep}></AppointmentConfirmation>
        </ModalStep>
        <ModalStep className='confirmation-step' stepNumber={3} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
          <PaymentConfirmation doctorInformation={this.props.doctorInformation} price={this.props.price} onAppointmentConfirmed={this.nextStep}></PaymentConfirmation>
        </ModalStep>
        <ModalStep stepNumber={4} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
          <Payments onSuccessPayment={this.nextStep} onUpdateCardDigits={this.onUpdateCardDigits} doctorInformation={this.props.doctorInformation} patientName={this.state.patientName}></Payments>
        </ModalStep>
        <ModalStep stepNumber={5} currentStep={this.state.currentStep} stepClosed={this.goToAgenda}>
          <SuccessReservation onClickButton={this.goToAgenda} doctorInformation={this.props.doctorInformation} lastDigits={this.state.lastDigits}></SuccessReservation>
        </ModalStep>
        <ModalStep stepNumber={6} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
          <Invoice onSuccessSave={this.goToAgenda} />
        </ModalStep>
        <LoginFlowControl ref={this.flowRef}
          onSuccessLogin={this.closeLogin}
        ></LoginFlowControl>
      </>  
    )
  }
}


export default FlowControl;
