import React, { Component } from 'react';
import Login from './Login';
import ModalStep from '../AppointmentFlow/Flow/ModalStep';
import EnterEmail from './EnterEmail';
import EnterCode from './EnterCode';
import ChangePassword from './ChangePassword';
class LoginFlowControl extends Component {
 
  constructor(props){
    super(props);
    this.state = {
      currentStep:0,
      userEmail:'',
      validationCode:''
    };
  }
  
  setEmail = (value)=>{
    this.setState({userEmail:value});
    this.nextStep();
  }
  setValidationCode = (value)=>{
    this.setState({validationCode:value});
    this.nextStep();
  }
  nextStep =()=>{
    const{currentStep} = this.state;
    let nextStep = currentStep+1;
    this.setState({currentStep: nextStep});
  }

  resetFlow =() =>{
    this.setState({currentStep: 0});
  }

  start(){
    this.setState({currentStep:1});
  }

  render() {
   
    return(
      <>
        <ModalStep stepNumber={1} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
          <Login onSuccessLogin={this.props.onSuccessLogin} onSucessRegister={this.props.onSucessRegister} isDoctor={this.props.isDoctor} onForgetPassword={this.nextStep}/>
        </ModalStep>
        <ModalStep stepNumber={2} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
          <EnterEmail onContinue={this.setEmail}></EnterEmail>
        </ModalStep>
        <ModalStep stepNumber={3} currentStep={this.state.currentStep} stepClosed={this.resetFlow} className='modal-code'>
          <EnterCode onContinue={this.setValidationCode} email={this.state.userEmail}></EnterCode>
        </ModalStep>
        <ModalStep stepNumber={4} currentStep={this.state.currentStep} stepClosed={this.resetFlow}>
          <ChangePassword onContinue={this.props.onSuccessLogin} email={this.state.userEmail} validationCode={this.state.validationCode}></ChangePassword>
        </ModalStep>
      </>  
    )
  }
}


export default LoginFlowControl;
