import { confirmAlert } from 'react-confirm-alert'
import React from 'react';
import exclamationIcon from '../../../Assets/icons/admiracion.svg';

export default ({message='', closeOnEscape = true, buttons = [ { label: 'Aceptar'}, { label: 'Cancelar'  }]}) =>{
    confirmAlert({
        childrenElement:()=>
          <div>
            <div className='mb-1'> 
              <img
                src={exclamationIcon}
                height="35px"
                width="35px"
                alt="trashIcon"
              />
            </div>
            <div>
            {message}
            </div>
          </div>,
        closeOnEscape: true,
        buttons
      });
}