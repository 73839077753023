import React, { Component, Fragment } from 'react'
import { Footer } from './Footer';
import { Help } from './Help';
import { NavMenu } from './NavMenu'
export class Layout extends Component {
  static displayName = Layout.name;
  componentDidMount() {
  }
  render() {
    return (
      <Fragment>
        <NavMenu />
        <div className='container-fluid main-container '>
          {this.props.children}
        </div>
        <Help />
        {this.props.footer && <Footer />}
      </Fragment>
    )
  }
}
