import React, { useState } from 'react'
import ReactStars from 'react-stars'
import './History.scss'

const DetailHistory = props => {

  const[rate, setRate] = useState(props.rate);
  const ratingChanged = (newRating) => {
    setRate(newRating)
    props.onRatingChanged(newRating);
  }

  return (

    <div>
      {/* {props.data.rate === 0 && <h1>fgdfg</h1>} */}
      <ReactStars
        count={5}
        value={rate}
        onChange={ratingChanged}
        size={24}
        color1={'#FFF'}
        color2={'#FDAD7B'}
        edit={true}
        half={false}
      />
    </div>
  )
};

export default DetailHistory;