import React, { Component } from 'react'
import './DoctorProfileInformation.scss'


class InformationList extends Component {
  
  render() {
    if(!this.props.items || this.props.items.length===0)
    {
      return(<div/>);
    }

    return (
      <div className='row'>
        <div className='pl-3 pr-5 experience-container'>
          <label className='text mb-0'>{this.props.title}</label>
          <ul>
            {this.props.items.map(p => {
              return  <li key={p+Math.random()}>{p}</li>
            })}
          </ul>
        </div>
      </div>
      
    )
  }
}

export default InformationList;