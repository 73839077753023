import React, { Component, Fragment } from 'react';
import person from '../../../Assets/icons/Home-Nombre.svg';
import creditCardIcon from '../../../Assets/icons/tarjeta.svg';
import { getData, postData } from '../../../Utils/API';
import prepareEndpoint from '../../../Utils/EndPoints';
import BasicModal from '../../Common/modal/BasicModal'
import BasicRadio from '../../Common/inputs/BasicRadio';
import IconInput from '../../Common/inputs/IconInput';
import Loading from '../../Common/loading/Loading';
import './Payments.scss';
import { appContext } from '../../../Utils/Context';

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',
      cardHolderName: '',
      cvv: '',
      expirationMonth: '',
      expirationYear: '',
      selectedPaymentId: '',
      startDateTime: '',
      locationId: '',
      serviceId: '',
      existingPayments: [],
      loading: false,
      modalShowFillFields: false,
      modalShowCard16Digits: false,
      modalShowSuccessPayment: false,
      errors: {
        cardNumber: false,
        cardHolderName: false,
        cvv: false,
        expirationMonth: false,
        expirationYear: false
      }
    };
  }

    cardForm = null;

    componentDidMount() {
      this.getBanks();
      const mp = new window.MercadoPago('TEST-50d7f9de-847f-45af-9699-cf9a4f9ac329');//'APP_USR-b67badb7-a4d8-4ea3-a5c9-10c0ef8961a1');//'TEST-50d7f9de-847f-45af-9699-cf9a4f9ac329');//'TEST-89f292a1-73f0-45e8-8e66-291b76e91d56');
        
      this.cardForm = mp.cardForm({
        amount: '153',
        autoMount: true,
        form: {
          id: 'form-checkout',
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Nombre y apellido',
          },
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Número de tarjeta',
          },
          cardExpirationMonth: {
            id: 'form-checkout__cardExpirationMonth',
            placeholder: 'MM',
          },
          cardExpirationYear: {
            id: 'form-checkout__cardExpirationYear',
            placeholder: 'AAAA',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'CVC',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Installments',
          },
          
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Document Number',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Issuer',
          }
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return console.warn('Form Mounted handling error: ', error);
          },
          onError: e => {
            console.log('Error on MP submit payment: ', e);
            if (this.validateFields()) {
              this.setState({ loading: false });
              this.setState({ modalShowFillFields: true });
            }
          },
          onSubmit: event => {
            try {
              event.preventDefault();
              this.submittedForm(this.cardForm)
            } catch (e) {
              console.log('ERR SUB: ', e);
            }                    
          }
        }
      })
    }

    formButtonClicked = async (e) => {
      try {
        e.preventDefault();
        if (!this.validateFields()) {
          this.setState({ modalShowFillFields: true });
          return;
        } 
        await this.submittedForm(this.cardForm)
      } catch (e) {
        console.log('ERR CLIK: ', e);
      }             
    }

    getBanks = async () => {
      const url = prepareEndpoint('PatientBanks');
      const response = await getData(url);
      if (response.status !== 200 || !response.data || response.data.length <= 0) {
        return;
      }
      this.setState({ existingPayments: response.data });
    }


    submittedForm = async (cardForm) => {
      this.setState({ loading: true });
      let cardNumber = this.state.cardNumber.replaceAll(' ', '').replaceAll('-', '');
      this.setState({ cardNumber })

      const cardData = await cardForm.getCardFormData();
      if (!cardData || !cardData.token || cardData.token.length < 1) {
        const cardToken = await cardForm.createCardToken();
        cardData.token = cardToken.token;
        if (!cardData.token) {
          this.setState({ loading: false });
          this.setState({ modalShowFillFields: true });
          return;
        }

      }
      if (!cardData.paymentMethodId) {
        cardData.paymentMethodId = this.getCardType(this.state.cardNumber).toLowerCase();
        if (!cardData.paymentMethodId) {
          this.setState({ loading: false });
          this.setState({ modalShowCard16Digits: true });
          return;
        }
      }

      const url = prepareEndpoint(this.props.isSubscribing === true ? 'ProcessSubscriptionPayment' : 'ProcessAppointmentPayment');
      let response;
      let lastDigits = cardNumber.substring(cardNumber.length - 4);
      if (!this.props.isSubscribing) {
        const info = this.props.doctorInformation;
        const selectedTime = info.selectedHour.includes(':') ? info.selectedHour : info.selectedHour + ':00';
        const serviceId = info.doctorServices.filter(s => s.selected).map(s => s.id);
        const localDate = new Date(`${info.selectedDate} ${selectedTime}`);

        response = await postData(url, {
          PaymentMethodId: cardData.paymentMethodId,
          Token: cardData.token,
          locationId: info.locationId,
          serviceId,
          startDateTime: `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')} ${(localDate.getHours())}:${(localDate.getMinutes().toString().padStart(2, '0'))}`,
          patientName: this.props.patientName,
          isFirstTime: info.isFirstTime,
          LastFourDigits: lastDigits
        });
      }
      else {
        response = await postData(url, {
          patientName: this.props.patientName,
          isPatient: this.props.isPatient,
          LastFourDigits: lastDigits,
          SubscriptionType: this.props.subscriptionType,
          Token: cardData.token,
        });
      }

      this.setState({ loading: false });

      if (response.status !== 200 || !response.data || response.data.length <= 0) {
        if (response.data && response.data.includes('availability')) {
          this.setState({ modalShowErrorAppointment: true });
        }
        else {
          this.setState({ modalShowErrorPayment: true });
        }
        return;
      }
      if (this.props.onUpdateCardDigits) {
        this.props.onUpdateCardDigits(lastDigits);
      }
      if (this.props.onSuccessPayment) {
        this.props.onSuccessPayment();
        return;
      }
      this.setState({ modalShowSuccessPayment: true });
    }

    getCardType = (number) => {
      // visa
      var re = new RegExp('^4');
      if (number.match(re) != null)
        return 'Visa';

      // Mastercard 
      // Updated for Mastercard 2017 BINs expansion
      if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
        return 'master'; //MasterCard

      // AMEX
      re = new RegExp('^3[47]');
      if (number.match(re) != null)
        return 'AMEX';

      // Discover
      re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
      if (number.match(re) != null)
        return 'Discover';

      // Diners
      re = new RegExp('^36');
      if (number.match(re) != null)
        return 'Diners';

      // Diners - Carte Blanche
      re = new RegExp('^30[0-5]');
      if (number.match(re) != null)
        return 'Diners - Carte Blanche';

      // JCB
      re = new RegExp('^35(2[89]|[3-8][0-9])');
      if (number.match(re) != null)
        return 'JCB';

      // Visa Electron
      re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
      if (number.match(re) != null)
        return 'Visa Electron';

      return '';
    }

    addNewPayment = async () => {
      if (!this.validateFields()) {
        return;
      }

      this.setState({ loading: true });

      const url = prepareEndpoint('PatientBanks');
      const response = await postData(url, { number: this.state.cardNumber, HolderName: this.state.cardHolderName, cvv: this.state.cvv, ExpDate: this.state.expirationMonth });
      if (response.status < 200 || response.status >= 300) {
        alert('Lo sentimos no se puede registrar esta cuenta');
        this.setState({ loading: false });
        return;
      }

    }

    validateFields = () => {
      let errors = { ...this.state.errors }
      const {
        cardNumber,
        cardHolderName,
        cvv,
        expirationMonth,
        expirationYear
      } = this.state
      let formIsValid = true
      errors.cardNumber = errors.cardHolderName = errors.cvv = errors.expirationMonth = errors.expirationYear = false;
      if (!cardNumber || cardNumber.length < 10) {
        formIsValid = false
        errors.cardNumber = true
      }
      if (!cardHolderName) {
        formIsValid = false
        errors.cardHolderName = true
      }
      if (!cvv || cvv.length <= 2) {
        formIsValid = false
        errors.cvv = true
      }
      if (!expirationMonth) {
        formIsValid = false
        errors.expirationMonth = true
      }
      if (!expirationYear) {
        formIsValid = false
        errors.expirationYear = true
      }

      this.setState({ errors })
      return formIsValid
    };

    setFieldsValues = (existingPayment) => {
      if (!existingPayment) {
        this.setState({ cardNumber: '', expirationMonth: '', expirationYear: '', cardHolderName: '', selectedPaymentId: '' });
        return;
      }
      let { cardNumber, expirationMonth, expirationYear, cardHolderName, selectedPaymentId } = this.state;
      selectedPaymentId = existingPayment.id
      cardNumber = existingPayment?.number?.replaceAll(' ', '').replaceAll('-', '');
      expirationMonth = existingPayment?.expDate.substring(0, 2);
      expirationYear = '20' + existingPayment?.expDate.substring(existingPayment?.expDate.length - 2);
      cardHolderName = existingPayment?.holderName;
      this.setState({ selectedPaymentId, cardNumber, expirationMonth, expirationYear, cardHolderName });
    }

    render() {

      return (
        <div className='paymentContainer'>
          {this.state.loading && <Loading />}
          <h2 className="text-center margin-bottom-30px">Confirma tu pago</h2>

          {this.state.existingPayments.map((existingPayment) =>
            <Fragment key={existingPayment.id}>
              <div className="form-row">
                <div className='col-12'>
                  <BasicRadio radioValue={existingPayment.id} isChecked={this.state.selectedPaymentId === existingPayment.id} labelClassName='color-black'
                    onChangeFxn={() => this.setFieldsValues(existingPayment)} label={existingPayment.bank + ' ****' + existingPayment.number.substring(existingPayment.number.length - 4)} />
                </div>
              </div>
            </Fragment>
          )}

          <div className="form-row margin-bottom-30px">
            <div className='col-12'>
              <BasicRadio isChecked={this.state.selectedPaymentId === ''} labelClassName='color-black'
                onChangeFxn={() => this.setFieldsValues()} label='Nueva tarjeta de crédito o débito' />
            </div>
          </div>

          <form id="form-checkout" >
            <div className='row margin-bottom-15px'>
              <div className='col-lg-6 col-md-6 col-sm-12 margin-top-bottom-15px'>
                <IconInput numeric={true} maxLength={16} inputVal={this.state.cardNumber} onChange={val => this.setState({ cardNumber: val.replaceAll(' ', '').replaceAll('-', ''), })} id="form-checkout__cardNumber"
                  prependIcon={creditCardIcon} placeHolder='Número de tarjeta' error={this.state.errors.cardNumber} />
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 margin-top-bottom-15px'>
                <div className='row'>
                  <div className='col-6'>
                    <IconInput inputVal={this.state.expirationMonth} onChange={val => this.setState({ expirationMonth: val, })} id="form-checkout__cardExpirationMonth"
                      placeHolder={'MM'} maxLength='2' error={this.state.errors.expirationMonth} numeric={true} />
                  </div>
                  <div className='col-6'>
                    <IconInput inputVal={this.state.expirationYear} onChange={val => this.setState({ expirationYear: val, })} id="form-checkout__cardExpirationYear"
                      placeHolder='YYYY' error={this.state.errors.expirationYear} numeric={true} maxLength='4'/>
                  </div>
                </div>
              </div>
              <div className='col-lg-2 col-md-2 col-sm-12 margin-top-bottom-15px'>
                <IconInput inputVal={this.state.cvv} onChange={val => this.setState({ cvv: val })} id="form-checkout__securityCode"
                  placeHolder={'CVV'} inputType="password" numeric={true} maxLength='4' error={this.state.errors.cvv} />
              </div>
            </div>

            <div className='row margin-top-bottom-15px'>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <IconInput inputVal={this.state.cardHolderName} onChange={val => this.setState({ cardHolderName: val })} id="form-checkout__cardholderName"
                  prependIcon={person} placeHolder={'Nombre y apellido'} error={this.state.errors.cardHolderName} />
              </div>
            </div>

            <div className='row justify-center margin-top-bottom-15px'>
              <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12' >
                <button type="submit" onClick={e => this.formButtonClicked(e) } className="btn primary-btn btn-width-fill" id="form-checkout__submit">Confirmar pago</button>
              </div>
            </div>
            <select style={{ display: 'none' }} name="issuer" id="form-checkout__issuer"></select>
            
            <input style={{ display: 'none' }} type="text" name="identificationNumber" id="form-checkout__identificationNumber" />
            <select style={{ display: 'none' }} name="installments" id="form-checkout__installments"></select>
          </form>

          <BasicModal
            className='additional-modal'
            show={this.state.modalShowFillFields}
            modalClosed={() => this.setState({ modalShowFillFields: false })}
            title='Favor de llenar todos los campos'>
          </BasicModal>

          <BasicModal
            className='additional-modal'
            show={this.state.modalShowCard16Digits}
            modalClosed={() => this.setState({ modalShowCard16Digits: false })}
            title='Por favor cheque los 16 digitos de su tarjeta'>
          </BasicModal>

          <BasicModal
            className='additional-modal'
            show={this.state.modalShowErrorPayment}
            modalClosed={() => this.setState({ modalShowErrorPayment: false })}            
            title='Error al procesar su pago'
            subtitle='No se pudo procesar su pago, favor de intentar de nuevo'
          >
          </BasicModal>

          <BasicModal
            className='additional-modal'
            show={this.state.modalShowErrorAppointment}
            modalClosed={() => this.setState({ modalShowErrorAppointment: false })}            
            title='Error al agendar la cita'
            subtitle='No se pudo agendar cita en este horario, favor de seleccionar otro'
          >
          </BasicModal>

          <BasicModal
            className='additional-modal'
            show={this.state.modalShowSuccessPayment}
            modalClosed={() => this.setState({ modalShowSuccessPayment: false })}
            title='Pago procesado correctamente!'>
          </BasicModal>



        </div>
      );
    }
}

Payments.contextType = appContext;
export default Payments;