// let api = '/nc/core/api/{apiVersion}';
let endpoint = {
  AccountLogin: 'api/Account/Login',
  AccountRegister: 'api/Account/Register',
  RequestPasswordChange:'api/Account/RequestPasswordChange',
  ValidatePasswordCode:'api/Account/ValidateCode',
  ChangePassword:'api/Account/ChangePassword',
  Doctor: 'api/{apiVersion}Doctors',
  DoctorSign: 'api/{apiVersion}Doctors/SignImage',
  DoctorSearch: 'api/{apiVersion}Doctors/Search',
  DoctorLocations: 'api/{apiVersion}DoctorLocations',
  DoctorServices: 'api/{apiVersion}DoctorServices',
  UserEmail: 'api/Account/UserEmail',
  States: 'api/{apiVersion}Catalogs/States',
  CatalogBanks: 'api/{apiVersion}Catalogs/Banks',
  TokenStatus: 'api/{apiVersion}TokenStatus',
  UserRole: 'api/{apiVersion}TokenStatus/GetRole',
  Notifications: 'api/{apiVersion}Notifications',
  Schedules: 'api/{apiVersion}DoctorSchedules',
  Specialty: 'api/{apiVersion}Specialty',
  ZipCodes: 'api/{apiVersion}Catalogs/ZipCode',
  Patient:'api/{apiVersion}Patient',
  PatientBanks:'api/{apiVersion}Patient/Banks',
  Appointment:'api/{apiVersion}Appointments',
  ProcessAppointmentPayment:'api/ProcessPayment/Appointment',
  ProcessSubscriptionPayment: 'api/ProcessPayment/Subscription',
  MedicalHistory:'api/MedicalHistory',
  NameIdAndSpecialty:'api/Doctors/NameIdAndSpecialty',
  Favorites:'api/Favorites',
  Invoice: 'api/Invoice',
  UpdatePassword:'api/Account/UpdatePassword',
  GetTerminosCondiciones: 'api/Account/GetTerminosCondiciones'
};

const prepareEndpoint = (name, apiVersion) => {

  let suffix = endpoint[name] || '';

  apiVersion = apiVersion ? (apiVersion + '/') : '';

  suffix = suffix.replace('{apiVersion}', apiVersion);


  let url = '/' + suffix;

  return url;
};

export default prepareEndpoint;
