import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import '../../Dr/Account/Account.scss'
import Agenda from './Agenda'
import History from './History'
import PatientProfile from './Profile'
import MedicalFile from './MedicalFile'
import './PatientAccountContainer.scss'
import MedicalHistory from '../../MedicalHistory/MedicalHistory'
import ShareMedicalHistory from '../../MedicalHistory/ShareMedicalHistory'

export default class PatientAccountContainer extends Component {
  constructor(props) {
    super(props);
    let page = props.match.params.page;
    this.state = {
      showHeaderIcons: false,
      pages: [
        {
          path: 'agenda',
          name: 'Agenda'
        },
        {
          path: 'history',
          name: 'Historial'
        },
        {
          path: 'medical',
          name: 'Expediente médico',
          referenceTo: 'medicalHistory'
        },
        {
          path: 'profile',
          name: 'Mi Perfil',
        },
        {
          path:'medicalHistory',
          hide: true
        },
        {
          path:'shareMedicalHistory',
          hide: true
        }
      ],
      pageSelected: page.toLocaleLowerCase()
    }
  }
  componentDidMount() {
  }

  componentDidUpdate() {
    let { pageSelected } = this.state;
    let page = this.props.match.params.page;
    if (pageSelected !== page.toLocaleLowerCase()) {
      const newPage = this.state.pages.find(e=> e.path.toLowerCase() === page.toLowerCase());
      if(!newPage.hide){
        this.setState({ pageSelected: page });
      }
    }
  }
  goTo = (path) => {
    this.props.history.push('/patient/' + path)
  }
  render() {
    return (
      <div>
        <div className='toggleButtons'>
          {this.state.pages.map(p => {
            return !p.hide && <div
              key={p.name}
              className={(p.path === this.state.pageSelected || p.referenceTo === this.state.pageSelected) ? 'selected' : 'unselected'}
              onClick={() => { this.goTo(p.path) }}
            >
              {p.name}
            </div>
          })}

        </div>
        <div className=''>
          <Switch>
            <Route path='/patient/agenda' component={Agenda} />
            <Route path='/patient/history' component={History} />
            <Route path='/patient/medical' component={MedicalFile} />
            <Route path='/patient/profile' component={PatientProfile} />
            <Route path='/patient/medicalHistory' component={MedicalHistory}/>
            <Route path='/patient/shareMedicalHistory' component={ShareMedicalHistory}/>
          </Switch>
        </div>
 
      </div>
    )
  }
}
