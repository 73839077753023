import Modal from '../../Common/modal/Modal'
import React, { Component } from 'react';
export default class FlowStep extends Component {
  render() {
    const {children} = this.props;
    return (
      <Modal className={`modal-main ${this.props.className||''}`} show={this.props.currentStep === this.props.stepNumber}
        modalClosed={this.props.stepClosed} closeIcon='true'>
        {children}
      </Modal>
    );
  }
}
