import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import './Agenda.scss'
import back from '../../../Assets/icons/Agenda semana-atras.svg'
import next from '../../../Assets/icons/Agenda semana-adelante.svg'
import Modal from '../../Common/modal/Modal'
import history from '../../../history'
import prepareEndpoint from '../../../Utils/EndPoints';
import { getData, putData } from '../../../Utils/API';
import Loading from '../../Common/loading/Loading';
import doctor from '../../../Assets/icons/doctor.svg'
import locationIcon from '../../../Assets/icons/Ubicaciones hospital-direccion.svg'
import specialityIcon from '../../../Assets/icons/Agenda semana popup-especialidad.svg'
import hourIcon from '../../../Assets/icons/Agenda semana popup-horario.svg'
import calendarIcon from '../../../Assets/icons/Agenda semana popup-fecha.svg'
import gmail from '../../../Assets/icons/Agenda semana-gmail.svg'
import { OutlookSignInButton } from '../../Common/outlook/OutlookSignInButton';
import ApiCalendar from 'react-google-calendar-api';

const localizer = momentLocalizer(moment)
let allViews = ['month', 'week']
const messages = {
  agenda: 'Agenda',
  allDay: 'All Day',
  date: 'Fecha',
  day: 'Día',
  event: 'Evento',
  month: 'Mes',
  next: 'Siguiente',
  noEventsInRange: 'There are no events in this range.',
  previous: 'Anterior',
  time: 'Tiempo',
  today: 'Hoy',
  tomorrow: 'Mañana',
  week: 'Semana',
  showMore: function () { return '+ ver mas' }
}
export default class Agenda extends Component {
  constructor(props) {
    super(props);
    const todayDate = new Date();
    this.state = {
      events: [],
      appointment: {
        show: false,
        date: null,
        doctor: '',
        time: '',
        type: null,
        status: '',
        reschedules: 0
      },
      services: [],
      loading: false,
      isGoogleLoaded: false,
      picture: {
        file: null
      }
    }
    this.year = todayDate.getFullYear();
    this.month = todayDate.getMonth() + 1;
    this.view = 'week';
    this.eventsCache = {};
  }
  componentDidMount() {
    this.getEvents(false);
    window.addEventListener('focus', this.onFocus);
  }
  getEvents = async (force) => {
    const cacheKey = `${this.month}${this.year}`;
    let events = [];
    if (force || !this.eventsCache.hasOwnProperty(cacheKey)) {
      this.setState({ loading: true });
      const url = `${prepareEndpoint('Patient')}/GetAppointments?year=${this.year}&month=${this.month}`;
      const resp = await getData(url);
      if (resp.status === 200) {
        events = resp.data.appointments;
        events.map(e => {
          const startDateTime = new Date(e.startDateTime.toString().slice(0, -1));
          const endDateTime = new Date(e.endDateTime.toString().slice(0, -1));
          e.startDateTime = startDateTime;
          e.endDateTime = endDateTime;
          e.title = `Dr. ${e.doctorName} ${e.doctorLastName}`;
          e.start = startDateTime;
          e.end = endDateTime;
          e.time = `${e.start.getHours()}:${e.start.getMinutes().toString().padStart(2, '0')} hrs`;
          return e;
        })
        this.eventsCache[cacheKey] = events;
      } else {
        //@TODO: handle error
      }
    } else {
      events = this.eventsCache[cacheKey];
    }
    this.setState({ events, loading: false });
  }
  isBlocked = (title) => {
    return title.toLowerCase() === 'blocked';
  }
  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus)
  }
  onFocus = () => {
    if (ApiCalendar.sign) {
      this.setGoogleEvents();
    }
  }

  event = ({ event }) => {
    return event.title;
  }
  eventMonth = ({ event }) => {

    return (
      <span>
        <span>{event.title}</span>
        {event.desc && ' ' + event.desc}
      </span>
    )

  }
  setGoogleEvents() {
    if (!this.state.isGoogleLoaded) {
      let timeMax = new Date();
      timeMax.setDate(timeMax.getDate() + 10);
      ApiCalendar.listEvents({
        timeMin: new Date().toISOString(),
        showDeleted: false,
        maxResults: 100,
        orderBy: 'updated'
      }).then(({ result }) => {
        result.items.forEach(element => {
          this.setState({
            events: [
              ...this.state.events,
              {
                id: element.id,
                start: new Date(element.start.dateTime),
                end: new Date(element.end.dateTime),
                title: element.summary,
                CalendarSource: 1
              },
            ],
          })
        });
      });
      this.setState({ isGoogleLoaded: true });
    }
  }
  CustomToolbar = (toolbar) => {
    const goToBack = () => {
      const view = this.view;
      if (view === 'month') {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else {
        //then week
        toolbar.date.setDate(toolbar.date.getDate() - 7);
      }
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      if (this.view === 'month') {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else {
        //then week
        toolbar.date.setDate(toolbar.date.getDate() + 7);
      }
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };
    const goToweek = () => {
      toolbar.onView('week');
    };
    const goToMonth = () => {
      toolbar.onView('month');
    };
    const handleGoogleAuth = () => {
      if (ApiCalendar.sign) {
        this.setGoogleEvents();
      }
      else {
        ApiCalendar.handleAuthClick()
      }
    }
    const setOutlookEvents = (events) => {
      events.forEach(element => {
        this.setState({
          events: [
            ...this.state.events,
            {
              id: element.id,
              start: new Date(element.start.dateTime),
              end: new Date(element.end.dateTime),
              title: element.subject,
              CalendarSource: 2
            },
          ],
        })
      });
    }
    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      );
    };
    return (
      <div className={'toolbar-container'}>
        <div className='toolbar-header'>
          <div className='labels'>
            <span className={'btn-current'} onClick={goToCurrent}>Día</span>
            <span className={(this.view === 'week' ? 'selected' : '') + ' btn-next'} onClick={goToweek}>Semana</span>
            <span className={(this.view === 'month' ? 'selected' : '') + ' btn-back'} onClick={goToMonth}>Mes</span>
          </div>
          <div className='accounts'>
            <span>Vincular a mi cuenta de:</span>
            <img src={gmail} alt='gmail' onClick={handleGoogleAuth} />
            <OutlookSignInButton onGetEvents={setOutlookEvents} />
          </div>
        </div>


        <div className={'back-next-buttons'}>
          <span className={'btn-back'} onClick={goToBack}>
            <img src={back} alt='back' />
          </span>
          <label className={'label-date'}>{label()}</label>
          <span className={'btn-next'} onClick={goToNext}>
            <img src={next} alt='next' />
          </span>
        </div>
      </div >
    );
  };


  handleSelectEvent = (event) => {
    let { appointment } = this.state;
    let date = event.start
    appointment = {
      id: event.id,
      show: true,
      date: date.toString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
      doctor: event.title,
      time: event.time,
      type: event.type,
      doctorSpeciality: event.doctorSpeciality,
      locationName: event.locationName,
      locationCity: event.locationCity,
      locationStreetAndNumber: event.locationStreetAndNumber,
      locationZip: event.locationZip,
      locationColony: event.locationColony,
      locationOfficeNumber: event.locationOfficeNumber,
      CalendarSource: event.CalendarSource,
      patientName: event.patientName,
      status: event.status,
      doctorId: event.doctorUserId
    }
    this.GetDrPicture(appointment.doctorId);
    this.setState({ appointment })
  }
  onChangeView = (view) => {
    this.view = view;
  }
  onNavigate = value => {
    this.month = value.getMonth() + 1;
    this.year = value.getFullYear();
    this.getEvents(false);
  }

  closeAppointmentModal = () => {
    let { appointment } = this.state;
    appointment = {
      show: false
    }

    this.setState({ appointment })
  }

  CancelAppointment = async (appointmentId) => {
    const url = `${prepareEndpoint('Appointment')}/${appointmentId}/Cancel`;
    const resp = await putData(url);
    if (resp.status === 200) {
      await this.getEvents(true);
      this.closeAppointmentModal();
    }
  }

  CanBeModified = (appointmentId) => {
    const cacheKey = `${this.month}${this.year}`;
    let events = this.eventsCache[cacheKey];

    let appointmentTem = events?.find(e => e.id === appointmentId);
    let canbeModified = false;
    var today = new Date();

    if (appointmentTem && appointmentTem.status === 'Nueva' && appointmentTem.reschedules < 2 ) {
      var appDate = new Date(appointmentTem.startDateTime);
      canbeModified = appDate > today;
    }

    return canbeModified;
  }

  ReSchedule = (appointmentId) => {
    const cacheKey = `${this.month}${this.year}`;
    let events = this.eventsCache[cacheKey];

    let appointmentTem = events?.find(e => e.id === appointmentId);

    if (appointmentTem) {
      history.push(`/appointment/doctorInformation?doctorId=${appointmentTem.doctorUserId}&si=${appointmentTem.serviceId}&li=${appointmentTem.locationId}&ai=${appointmentId}`);
    }

  }

  async GetDrPicture(doctorId) {
    const url = `${prepareEndpoint('Doctor')}/ProfilePictureById/${doctorId}`;

    const resp = await getData(url);

    if (resp.status === 200) {
      let picture = {
        show: false,
        fromSelector: false,
        file: resp.data
      }
      this.setState({ picture });
    }
    else {
      let picture = {
        show: false,
        fromSelector: true,
        file: null
      }
      this.setState({ picture });
    }

    return;
  }

  render() {
    return (
      <div className='doctor-container agenda'>
        {this.state.loading && <Loading />}
        <Modal
          className='eventModal'
          show={this.state.appointment.show}
          modalClosed={this.closeAppointmentModal}
          closeIcon={true}
        >
          <div className='row col-12'>
            <div className='row col-12 modalHeader'>
              <div className='col-lg-4 col-md-4 col-sm-12 modalSubTitle'>
                <div>
                  <img src={calendarIcon} alt='calendar icon' className='img-30' />
                  <strong>Fecha</strong>
                </div>
                <div>
                  {new Date(this.state.appointment.date).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 modalSubTitle'>
                <div>
                  <img src={hourIcon} alt='hour icon' className='img-30' />
                  <strong>Hora</strong>
                </div>
                <div>
                  {this.state.appointment.time}
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 modalSubTitle'>
                <div>
                  <img src={specialityIcon} alt='speciality icon' className='img-30' />
                  <strong>Especialidad</strong>
                </div>
                <div>
                  {this.state.appointment.doctorSpeciality}
                </div>
              </div>
            </div>

            <div className='row col-12 modalBody'>
              <div className='col-lg-4 col-md-4 col-sm-12 modalSubTitle modalAlignCenter'>
                <div>
                  <img src={this.state.picture.file ? `data:image/jpeg;base64,${this.state.picture.file}` : doctor} alt='speciality icon' className='img-100' />
                  <div><strong>Doctor(a) {this.state.appointment.doctor}</strong></div>
                  <div>
                    {this.state.appointment.doctorSpeciality}
                  </div>
                </div>
              </div>

              <div className='col-lg-8 col-md-8 col-sm-12 modalSubTitle info-patient'>

                <div className='d-flex h-100px'>
                  <img src={locationIcon} alt='location icon' className='img-30' />
                  <div className='row ml-2'>
                    <div>
                      <p><strong>{this.state.appointment.locationName}</strong></p>
                      <p>{`${this.state.appointment.locationStreetAndNumber}, ${this.state.appointment.locationCity}`}</p>
                      <p>{this.state.appointment.locationOfficeNumber}</p>
                    </div>
                  </div>
                </div>

                <div>
                  <p><strong>Nombre del paciente: </strong>{this.state.appointment.patientName}</p>
                </div>
              </div>
            </div>

            <div className="text-center buttons-section col-12">
              {
                this.CanBeModified(this.state.appointment.id) ?
                  <button className='btn primary-btn center-height' onClick={e => this.ReSchedule(this.state.appointment.id)}>
                    Modificar Cita
                  </button>
                  :
                  <div className='messages'>
                    <label>
                      Tu cita ya no puede ser modificada
                    </label>
                    <p className='mt-3'><strong>
                      Si requieres de asesoría personalizada, da click</strong>
                    <a
                      href="https://api.whatsapp.com/send?phone=525569776510&text=Hola, necesito ayuda con sanapp"
                      target="_blank"
                      rel="noopener noreferrer"
                      className='link_sopport'
                    >
                      <span className='pl-1'>aquí</span>
                    </a>
                    </p>
                  </div>
              }
              {/* <button className='btn primary-btn center-height' onClick={e => this.CancelAppointment(this.state.appointment.id)}>
                Cancelar Cita
              </button> */}
              {/* <button className='btn primary-btn center-height' disabled={!this.CanBeModified(this.state.appointment.id)}>
                Check In
              </button> */}
            </div>
          </div>
        </Modal>
        <Calendar
          culture={'es-MX'}
          selectable={'ignoreEvents'}
          localizer={localizer}
          views={allViews}
          step={30}
          timeslots={1}
          events={this.state.events}
          style={{ height: 650, width: '100%' }}
          formats={
            {
              //dateFormat: window.screen.width > 575 ? 'dddd DD' : 'DD',
              dayFormat: window.screen.width > 575 ? 'ddd DD' : 'DD'
            }
          }
          components={{
            event: this.event,
            toolbar: this.CustomToolbar,
            month: {
              event: this.eventMonth
            }
          }}
          onSelectEvent={this.handleSelectEvent}
          onView={this.onChangeView}
          defaultView={this.view}
          popup={true}
          min={new Date(0, 0, 0, 6, 0, 0)}
          max={new Date(0, 0, 0, 22, 0, 0)}
          messages={messages}
          /* onRangeChange={this.onRateChange} */
          onNavigate={this.onNavigate}
        />
      </div>
    )
  }
}
