import moment from 'moment'
import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import location from '../../Assets/icons/location.svg'
import emptyNotification from '../../Assets/icons/Notificacion1.svg'
import notification from '../../Assets/icons/Notificacion2.svg'
import magnifier from '../../Assets/icons/Reservar-icono-lupa.svg'
import person from '../../Assets/icons/person.svg'
import logo from '../../Assets/logo.svg'
import authConfig from '../../Initialization/auth-config'
import authService from '../../security/auth-service'
import { getData } from '../../Utils/API'
import { appContext } from '../../Utils/Context'
import prepareEndpoint from '../../Utils/EndPoints'
import { IS_DOCTOR, IS_ADMIN, IS_ASSISTANT, HAS_ASSISTANT_PERMISSION } from '../../Utils/Generic'
import { ROLES } from '../../Utils/Constants';
import Modal from '../Common/modal/Modal'
import Calendar from './calendar/Calendar'
import BasicDropDown from './inputs/BasicDropDown'
import IconInput from './inputs/IconInput'
import NotificationsModal from './modal/NotificationsModal'
import LoginFlowControl from '../Login/LoginFlowControl';

import './NavMenu.scss'
import _ from 'lodash'
import { LikeList } from './likeDislike/likeList'

export function NavMenu() {
  const context = useContext(appContext);
  const history = useHistory()
  const [isDoctor, setIsDoctor] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showGoodByeModal, setShowGoodByeModal] = useState(false);
  const [showLikesModal, setShowLikesModal] = useState(false);

  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);


  const [address, setAddress] = useState('');
  const [dateSelected, setDate] = useState(new Date());
  const [specialty, setSpecialty] = useState('');
  const mounted = useRef();
  const flowRef = createRef();

  useEffect(() => {
    if (context.state.showHeaderIcons && !mounted.current) {
      mounted.current = true;
      setSpecialty(context.state.specialty)
      setAddress(context.state.address)
      setDate(context.state.date)
    }
    if (!context.state.showHeaderIcons) {
      mounted.current = false;
    }

    if (authConfig.sessionAvailable && notifications.length === 0) {
      getNotifications();
    }

  }, [address, context, dateSelected, notifications, specialty]);

  async function getNotifications() {
    const url = prepareEndpoint('Notifications');
    const resp = await getData(url);
    let notifications = [];
    if (resp.status === 200) {
      notifications = resp.data;
      if (notifications.length > 0) {
        setNotifications(notifications);
        setShowNotifications(true)
      }
      else {
        setShowNotifications(false)
      }
    }
  }

  function gotoHome() {
    setSpecialty('');
    setAddress('');
    setDate(new Date());
    context.updateDate(new Date());
    history.push('/')
  }
  function toggleShowCalendar() {
    setShowCalendar(!showCalendar)
  }
  function hideLogin() {
    flowRef.current.resetFlow()
  }
  function toggleShowLoginAsDoctor() {
    setIsDoctor(true);
    startFlow();
  }
  function toggleShowLoginAsPatient() {
    setIsDoctor(false);
    startFlow();
  }
  function toggleShowNotifications() {
    setShowNotifications(!showNotifications);
  }
  function toggleShowLikes() {
    setShowLikesModal(!showLikesModal);
    context.updateForce(false);
  }
  function startFlow() {
    flowRef.current.start();
  }
  function buttonDate() {
    return (<button onClick={toggleShowCalendar} className="form-control dd-toggle date-btn white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{formatDate(dateSelected)}</button>)
  }
  function updateLocation(address) {
    setAddress(address);
  }
  function placeSelected(address) {
    updateLocation(address);
  }
  function specialtyInputChange(specialtyKeyword) {
    setSpecialty(specialtyKeyword);
  }

  function selectOption(option) {
    context.updateMainIcon(true);
    switch (option) {
      case 'Cuenta Paciente':
      case 'Mi Perfil':
        history.push('/patient/profile');
        break;
      case 'Cuenta Doctor':
        history.push('/doctoraccount/agenda');
        break;
      case 'Mi cuenta':
        if (isDoctorPath()) {
          history.push('/doctoraccount/agenda');
        } else {
          history.push('/patient/profile');
        }
        break;
      case 'Cerrar sesion':
        setShowGoodByeModal(true);
        break;
      default:
    }
  }
  function logout() {
    authService.logout();
    setShowGoodByeModal(false);
  }
  function getCurrentPosition() {
    if (!_.isEmpty(context.state.cacheInfo)) {
      const { address } = context.state.cacheInfo;
      setAddress(address)
    }
    context.getPositionFromGeoLocation();
  }
  function suggestionClicked(suggestion) {
    setSpecialty(suggestion);
  }
  function formatDate(date) {
    date = new moment(date);
    let str = '';
    const today = new moment();
    const tomorrow = today.clone().add(1, 'day')
    if (date.isSame(today, 'day')) {
      str = 'Hoy';
    } else if (date.isSame(tomorrow, 'day')) {
      str = 'Mañana';
    } else {
      str = date.format('D / M');
    }
    return str;
  }
  function onChangeDay(date) {
    setDate(date);
    toggleShowCalendar();
  }
  function successLogin() {
    hideLogin();
    if (IS_DOCTOR()) {
      if (IS_ASSISTANT()) {
        if (HAS_ASSISTANT_PERMISSION(ROLES.AccessServices)) {
          history.push('/doctoraccount/services');
        } else if (HAS_ASSISTANT_PERMISSION(ROLES.AccessAgenda)) {
          history.push('/doctoraccount/agenda');
        } else if (HAS_ASSISTANT_PERMISSION(ROLES.AccessPatients)) {
          history.push('/doctoraccount/patient');
        } else if (HAS_ASSISTANT_PERMISSION(ROLES.AccessDrProfile)) {
          history.push('/doctoraccount/profile');
        } else {
          //doens't have permissions to see any page
          history.push('/');
        }
      } else {
        history.push('/doctoraccount/services');
      }
    } else if (IS_ADMIN()) {
      history.push('/drvalidation');
    }
    else {
      history.push('/patient/profile');
    }
  }
  function successRegister() {
    hideLogin();
    if (IS_DOCTOR()) {
      history.push('/drregister');
    } else {
      history.push('/patient/profile');
    }
  }
  function goToFindDoctors() {
    if (address || specialty) {
      context.updateSpecialty(!specialty || specialty.length <= 0 ? '' : specialty);
      context.getcoordinatesfromAddress(address);
      context.updateDate(dateSelected);
      history.push(`/appointment/doctors?address=${address}&specialty=${specialty}`);
    }
  }
  function forceUpdate() {
    context.updateForce(true);
  }
  function isDoctorPath() {
    const path = window.location.pathname;
    return path.includes('/doctoraccount') || path.includes('/drregister');
  }
  function getDDOptions() {
    let options = ['Cerrar sesion'];
    let isAssitant = IS_ASSISTANT();
    if (IS_DOCTOR()) {
      if (isDoctorPath()) {
        if (isAssitant) {
          if (HAS_ASSISTANT_PERMISSION(ROLES.AccessPatientProfile)) {
            options.unshift('Cuenta Paciente');
          }
        } else {
          options.unshift('Cuenta Paciente');
        }
      } else {
        options.unshift('Cuenta Doctor');
      }
    }
    else if (!isAssitant) {
      options.unshift('Mi Perfil');
    }
    return options;
  }

  function checkIfEnterToFindDoctors(event) {
    if (event && event.key === 'Enter') {
      goToFindDoctors();
    }
  }

  return (
    <nav className='navbar fixed-top'>
      <Calendar
        show={showCalendar} closeFx={toggleShowCalendar}
        onSelectDay={onChangeDay}
      />
      <div className='container-fluid'>
        {context.state.showMainIcon && <div className='logo' onClick={gotoHome}>
          <img src={logo} alt="Icono: Logotipo en la insignia" />
        </div>}

        {!context.state.showMainIcon && <div>
          <span></span>
        </div>}


        {context.state.showHeaderIcons && (<> <div className="form-inline">
          <IconInput
            onChange={specialtyInputChange}
            onKeyPress={checkIfEnterToFindDoctors}
            inputVal={specialty}
            prependIcon={person}
            placeHolder={'Especialidad o padecimiento'}
            suggestions={true}
            onSuggestionClick={suggestionClicked}
            appendIcon={magnifier}
            onAppendIconClick={goToFindDoctors}
            classes='nav-input'
          />
        </div>

          <div className='form-inline'>
            <IconInput
              autompleteAddress={true}
              onKeyPress={checkIfEnterToFindDoctors}
              prependIcon={location}
              placeHolder={'¿Dónde?'}
              appendChild={buttonDate()}
              childClasses={'relative'}
              onChange={updateLocation}
              onPlaceSelected={placeSelected}
              inputVal={address}
              iconClasses={'pointer'}
              clickPrepend={getCurrentPosition}
              classes='nav-input'
            />
          </div></>)}
        <div className='nav-buttons form-inline account'>
          {authConfig.sessionAvailable ?
            <>
              <div className='notifications icons' onClick={toggleShowNotifications}>
                <img src={notifications.length > 0 ? notification : emptyNotification} alt='blocked' />
              </div>
              <div className='like icons' onClick={toggleShowLikes}>
                <span className=" like-dislike"><i className="fas fa-heart"></i></span>
              </div>
              <BasicDropDown
                placeholder={'Cuenta ' + (isDoctorPath() || IS_ASSISTANT() ? 'Doctor' : 'Paciente')}
                btnClasses={'btn confirm-btn my-account-btn shine'}
                customOptions={getDDOptions()}
                onChangeFxn={selectOption}
              />
            </> :
            (<>
              <button type="button" className="btn btn-light" onClick={toggleShowLoginAsDoctor}>Soy Doctor</button>
              <button type="button" className="btn primary-btn" onClick={toggleShowLoginAsPatient}>Iniciar Sesión</button>
            </>)}

        </div>
        {showNotifications && <NotificationsModal show={showNotifications} className="modal-main col-12" closeFnx={toggleShowNotifications} notifications={notifications} />}
        <LoginFlowControl ref={flowRef}
          onSuccessLogin={successLogin} onSucessRegister={successRegister} isDoctor={isDoctor}
        ></LoginFlowControl>
        <Modal className="modal-main goodbye" show={showGoodByeModal} modalClosed={logout} closeIcon={true}>
          <div className='goodbye-container'>
            <h4>Gracias por confiar en</h4>
            <div className='logo'>
              <img src={logo} alt="Icono: Logotipo en la insignia" />
            </div>
            <h4>¡Vuelve pronto!</h4>
          </div>
        </Modal>
        <Modal className="modal-main dr-likes" show={showLikesModal} modalClosed={toggleShowLikes} closeIcon={true}>
          <LikeList close={toggleShowLikes} reload={forceUpdate} />
        </Modal>
      </div>
    </nav>
  )

}
