import React, { Component } from 'react';
import BasicInput from '../Common/inputs/BasicInput';
import authService from '../../security/auth-service';
import Loading from '../Common/loading/Loading';

export default class EnterEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
      loading:false
    };
  }

  setLoading = value =>{
    this.setState({loading:value});
  }
  setError = error => {
    this.setState({ error: error });
  };
  componentDidMount() {}
  isValidForm = () => {
    const { email } = this.state;
    let re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    return email && email.trim() !== '' && re.test(email) ? true : false;
  };
  onContinue = async () => {
    this.setLoading(true);
    if (
      !this.isValidForm() ||
      !(await authService.requestPasswordChange(this.state.email))
    ) {
      this.setError('Por favor revise que la dirección de correo sea correcta');
      this.setLoading(false);
      return;
    }

    if (this.props.onContinue) {
      this.props.onContinue(this.state.email);
    }
    this.setLoading(false);
  };
  setEmail = email => {
    this.setError('');
    this.setState({ email: email });
  };
  render() {
    return (
      <div className="email-container">
        {this.state.loading && <Loading />}
        <div className="row d-flex mb-0 justify-content-center">
          <h2 className="text-center">Recupera tu contraseña</h2>
        </div>
        <div className="col-md-12 mt-0 d-flex align-items-center px-0">
          <label className="text-center">
            Escribe la dirección de correo asociado a tu cuenta sanapp
          </label>
        </div>
        <div className="col-md-12 d-flex justify-content-center mt-2 px-0">
          <label className="text">Dirección de correo electrónico</label>
        </div>
        <div className="row mb-0">
          <BasicInput
            classes="col-md-12 px-0"
            onChangeFxn={val => this.setEmail(val)}
            basicVal = {this.state.email}
          ></BasicInput>
        </div>
        <div
          className={
            this.state.error !== ''
              ? 'row errorContainer f-flex justify-content-center'
              : 'hidden'
          }
        >
          <p>{this.state.error}</p>
        </div>
        <div className="row justify-content-center mt-3">
          <button
            className="btn primary-btn clickable"
            onClick={() => this.onContinue()}
          >
            Continuar
          </button>
        </div>
      </div>
    );
  }
}
