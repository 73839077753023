import React, { Component } from 'react';
import BasicRadio from '../../../Common/inputs/BasicRadio';
import BasicModal from '../../../Common/modal/BasicModal';
import '../ConciliationComponents/Conciliation.scss';
import CustomDatePicker from '../../../../components/Common/CustomDatePicker';
import downloadIcon from '../../../../Assets/icons/download.svg';
import emailIcon from '../../../../Assets/icons/email.svg';
import BasicInput from '../../../Common/inputs/BasicInput';
import authConfig from '../../../../Initialization/auth-config';
import prepareEndpoint from '../../../../Utils/EndPoints';
import { getData, getFile } from '../../../../Utils/API';

export default class ConciliationDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailOptions: false,
      selectedDate: new Date(),
      isSheetSelected: true,
      email: '',
      userEmail: '',
      errors: {
        email: false
      }
    }
  }
  componentDidMount() {
    this.setEmail();
  }
  setEmail = async () => {
    let userEmail = authConfig.email;
    if (!userEmail) {
      const url = `${prepareEndpoint('UserEmail')}`;
      const resp = await getData(url);
      if (resp.status === 200) {
        userEmail = resp.data;
      }
    }
    this.setState({ userEmail });
  }
  onDateChanged = (date) => {
    this.setState({ selectedDate: date });
  }
  isValidEmail = () => {
    const { email } = this.state;
    let re =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return re.test(email) ? true : false;
  };
  sendEmail = () => {
    const { errors, userEmail } = this.state;
    let { email } = this.state;
    this.clearErrors();
    if (!email && email.trim() === '') {
      email = userEmail;
    }
    else if (!this.isValidEmail()) {
      errors.email = true;
      this.setState({ errors });
      return;
    }

    this.setState({ showEmailSentModal: email });
  }
  clearErrors = () => {
    const { errors } = this.state;
    errors.email = false;
    this.setState({ errors });
  }

  download = async () => {
    let date = this.state.selectedDate;
    let action = this.state.isSheetSelected ? 'GetConcilacion' : 'GetEdoCuenta';
    let file = this.state.isSheetSelected ? 'HojaConcilidacion.pdf' : 'EstadoCuenta.pdf';
    const url = `${prepareEndpoint('Doctor')}/${action}/${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    await getFile(url, file);
  };

  render() {
    return (
      <div className='container documents-container'>
        <div className='row'>
          <div className='col-3'>
            <BasicRadio radioValue="sheet" radioGroup="conciliation" label="Hoja de conciliación" isChecked={this.state.isSheetSelected}
              onChangeFxn={() => { this.setState({ isSheetSelected: true }) }}></BasicRadio>
          </div>
          <div className='col-3'>
            <BasicRadio radioValue="balance" radioGroup="conciliation" label="Estado de cuenta" isChecked={!this.state.isSheetSelected}
              onChangeFxn={() => { this.setState({ isSheetSelected: false }) }}></BasicRadio>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 pt-3'>
            <label className='text'>Selecciona el día para descargar tu {this.state.isSheetSelected ? 'hoja de conciliación' : 'estado de cuenta'}.</label>
          </div>
        </div>
        <div className='row'>
          <div className='col-auto'>
            <CustomDatePicker onDateChanged={this.onDateChanged} />
          </div>
        </div>
        <div className='row content'>

        </div>
        <div className='row d-flex align-items-center botom-section'>
          <div className='col-1'>
            <img src={downloadIcon} className='icon-button' alt='download' onClick={() => { this.download() }}></img>
          </div>
          <div className='col-1'>
            <img src={emailIcon} className='icon-button' alt='email' onClick={() => this.setState({ showEmailOptions: !this.state.showEmailOptions })}></img>
          </div>
          <div className={`col-8 ${this.state.showEmailOptions ? '' : 'hidden'}`}>
            <div className='row'>
              <div className='col-12'>
                <label className='text'>Ingresa el correo al que deseas que se envíe tu {this.state.isSheetSelected ? 'hoja de conciliación' : 'estado de cuenta'}.</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-11'>
                <BasicInput type='email' onChangeFxn={(val) => { this.clearErrors(); this.setState({ email: val }) }}
                  error={this.state.errors.email}
                  placeHolder={this.state.userEmail}
                  basicVal={this.state.email}></BasicInput>
              </div>
              <div className='col-1'>
                <button type='button' className='btn primary-btn' onClick={this.sendEmail}>Enviar</button>
              </div>
            </div>
          </div>
        </div>
        <BasicModal
          className='additional-modal'
          show={this.state.showEmailSentModal && this.state.showEmailSentModal.length > 2}
          modalClosed={() => this.setState({ showEmailSentModal: '' })}
          title={'Email enviado a: ' + this.state.showEmailSentModal}>
        </BasicModal>
      </div>
    );
  }
}