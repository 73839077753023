import React from 'react';

import './LikeDislike.scss';
const LikeDislike = props => {
  return (
    <span className={`${props.classes ? props.classes : ''} like-dislike`}>
      <i className={`${props.fill ? 'fas' : 'far'} fa-heart`}></i>
    </span>
  );
};

export default LikeDislike;
