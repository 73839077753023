import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import SelectDoctor from './SelectDoctor';
import DoctorInformation from './DoctorInformation';

export default class AppointmentFlowContainer extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  componentDidMount(){
  }

  componentDidUpdate(){
  }
  render() {
    return (
      <div> 
        <Switch>
          <Route path='/appointment/doctors' component={SelectDoctor} />
          <Route path='/appointment/doctorInformation' component={DoctorInformation} />
        </Switch>     
      </div>
    )
  }
}
