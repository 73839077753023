import './MedicalHistory.scss'
import React, { Component } from 'react';
import HistoryView from './HistoryView';
import BasicInput from '../Common/inputs/BasicInput';
import BasicModal from '../Common/modal/BasicModal'
import MedicalHistoryYesNoQuestion from './MedicalHistoryYesNoQuestion';
import icono_flecha from '../../Assets/icons/Flecha.svg';
import icono_flechad from '../../Assets/icons/flecha-d.svg';
import prepareEndpoint from '../../Utils/EndPoints';
import Loading from '../Common/loading/Loading';
import { getData, postData } from '../../Utils/API';
import { convertDateToStringYYYMMddd } from '../../Utils/stringHelpers';
import BasicRadio from '../Common/inputs/BasicRadio';
import MedicalDetailedQuestion from './MedicalDetailedQuestion';
import BasicSelect from '../Common/inputs/BasicSelect';

export default class MedicalHistory extends Component {
  //ToDo: styles responsive
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentView: 0,
      headers: [
        '',
        'Heredofamiliares',
        'Personales no patológicos',
        'Personales patológicos',
        'Personales patológicos',
        'Gineco - Obstétricos',
        'Interrogatorio por aparatos y/o sistemas',
        'Interrogatorio por aparatos y/o sistemas',
        'Interrogatorio por aparatos y/o sistemas',
        'Interrogatorio por aparatos y/o sistemas',
        'Comentarios adicionales'
      ],
      isFormDirty: false,
      medicalHistory: this.getDefaultHistory(),
      showSuccessSaved: false
    };
  }

  async componentDidMount() {
    try {
      await this.getPreviousHistory();
      const url = `${prepareEndpoint('Patient')}`;
      const resp = await getData(url);
      if (resp.status >= 200 && resp.status < 300) {
        let { medicalHistory } = this.state;
        medicalHistory.patientName = resp.data.firstName + ' ' + resp.data.lastName;
        medicalHistory.patientGender = resp.data.gender;
        if (true) {//!medicalHistory.birthDate && resp.data.birthDate) {
          medicalHistory.birthDate = await convertDateToStringYYYMMddd(resp.data.birthDate);
        }
        this.setState({medicalHistory});
      }
    } catch (e) {
      console.log('Error at compDidMount: ', e);
    }        
    this.setState({ loading: false })
  }

    goToMedical = () => {
      this.props.history.push('/patient/medical')
    }
    postHistory = async (e, displayConfirmationAlert) => {
      if (e) {
        e.preventDefault();
      }
      this.setState({ loading: true });
      const { medicalHistory } = this.state;
      const url = `${prepareEndpoint('MedicalHistory')}`;
      const resp = await postData(url, medicalHistory);
      if (resp.status >= 200 && resp.status < 300) {
        if (displayConfirmationAlert === true) {
          this.setState({ showSuccessSaved: true})
        }
      }
      this.setState({ loading: false })
    }
    getPreviousHistory = async () => {
      const url = `${prepareEndpoint('MedicalHistory')}`;
      const resp = await getData(url);
      if (resp.status >= 200 && resp.status < 300) {
        const { data } = resp;
        data.sympthom = data.sympthom ?? this.getDefaultSympthom();
        data.covidSympthom = data.covidSympthom ?? this.getDefaultCovidSympthom();
        data.heredorelatives = data.heredorelatives ?? this.getDefaultHeredorelatives();
        data.nonPersonalPathologycal = data.nonPersonalPathologycal ?? this.getDefaultNonPersonalPathologycal();
        data.personalPathological = data.personalPathological ?? this.getDefaultPersonalPathological();
        data.eyes = data.eyes ?? this.getDefaultEyes();
        data.ears = data.ears ?? this.getDefaultEars();
        data.respiratorySystem = data.respiratorySystem ?? this.getDefaultRespiratorySystem();
        data.cardiovascularSystem = data.cardiovascularSystem ?? this.getDefaultCardiovascularSystem();
        data.genitourinarySystem = data.genitourinarySystem ?? this.getDefaultGenitourinarySystem();
        data.gastrointestinalSystem = data.gastrointestinalSystem ?? this.getDefaultGastrointestinalSystem();
        data.endocrineSystem = data.endocrineSystem ?? this.getDefaultEndocrineSystem();
        data.neurologicalSystem = data.neurologicalSystem ?? this.getDefaultNeurologicalSystem();
        data.pathological = data.pathological ?? this.getDefaultPathological();
        data.gynecologist = data.gynecologist ?? this.getDefaultGynecologist();
        this.setState({ medicalHistory: data });
      }
    }
    getDefaultHistory = () => {
      return {
        'patientName': '',
        //'weight': 0,
        //'height': 0,
        //'birthDate': '',
        'sympthom': this.getDefaultSympthom(),
        'covidSympthom': this.getDefaultCovidSympthom(),
        'heredorelatives': this.getDefaultHeredorelatives(),
        'nonPersonalPathologycal': this.getDefaultNonPersonalPathologycal(),
        'personalPathological': this.getDefaultPersonalPathological(),
        'pathological': this.getDefaultPathological(),
        'gynecologist': this.getDefaultGynecologist(),
        'eyes': this.getDefaultEyes(),
        'ears': this.getDefaultEars(),
        'respiratorySystem': this.getDefaultRespiratorySystem(),
        'cardiovascularSystem': this.getDefaultCardiovascularSystem(),
        'genitourinarySystem': this.getDefaultGenitourinarySystem(),
        'gastrointestinalSystem': this.getDefaultGastrointestinalSystem(),
        'endocrineSystem': this.getDefaultEndocrineSystem(),
        'neurologicalSystem': this.getDefaultNeurologicalSystem(),
        'additionalDetails': ''
      };
    }
    getDefaultGynecologist = () => {
      return {
        'firstMestruationAge': 0,
        'hasRegularMenstruation': null,
        'menstruationFrecuency': 0,
        'lastMestruationDate': '',
        'hasMentrualCramps': null,
        'lastCytologyDate': '',
        'lastCytologyResult': '',
        'lastBreastExaminationDate': '',
        'lastBreastExaminationResult': '',
        'sexualPartners': 0,
        'actualContraceptiveMethodUsed': '',
        'childbirthNumber': 0,
        'cesareanNumber': 0,
        'abortionNumber': 0,
        'pregnancyProblems': '',
        'other': ''
      }

    }
    getDefaultPathological = () => {
      return {
        'hasAllergies': null,
        'medicines': '',
        'food': '',
        'animals': '',
        'otherAllergy': '',
        'previousHospitalizations': this.getDefaultMedicalQuestion(),
        'surgicalHistory': this.getDefaultMedicalQuestion(),
        'previousTransfusions': this.getDefaultMedicalQuestion(),
        'fractures': this.getDefaultMedicalQuestion(),
        'trauma': this.getDefaultMedicalQuestion()
      }

    }
    getDefaultNonPersonalPathologycal = () => {
      return {
        'alcohol': this.getDefaultMedicalQuestion(),
        'smoke': this.getDefaultMedicalQuestion(),
        'addictions': this.getDefaultMedicalQuestion(),
        'medicines': this.getDefaultMedicalQuestion(),
        'animals': this.getDefaultMedicalQuestion(),
        'workout': this.getDefaultMedicalQuestion(),
        'ocupation': ''
      }
    }
    getDefaultEndocrineSystem = () => {
      return {
        'wheightLoss': null,
        'coldIntolerance': null,
        'shaking': null,
        'hoarseness': null,
        'drowsiness': null,
        'drySkin': null,
        'other': ''
      }
    }
    getDefaultNeurologicalSystem = () => {
      return {
        'headache': null,
        'dizziness': null,
        'coordinationProblem': null,
        'paresis': null,
        'numbness': null,
        'other': ''
      }
    }
    getDefaultGenitourinarySystem = () => {
      return {
        'painfulUrination': null,
        'increasedUrinationFrecuency': null,
        'increareUrineVolume': null,
        'nocturia': null,
        'hematuria': null,
        'other': ''
      }
    }
    getDefaultGastrointestinalSystem = () => {
      return {
        'appetiteLoss': null,
        'sickness': null,
        'vomit': null,
        'difficultySwallowing': null,
        'pyrosis': null,
        'diarrhea': null,
        'stools': null,
        'other': ''
      }

    }
    getDefaultSympthom = () => {
      return {
        hasLoseSmell: null,
        hasLostTaste: null,
        hasHeadaches: null,
        hasMusclePain: null,
        hasJointPain: null,
        hasThroatPain: null,
        cough: null,
        fever: null,
        hasChills: null,
        hasAbdominalPain: null,
        hasVomit: null,
        hasDiarrea: null,
        hasPainChest: null,
        hasTroubleBreathing: null,
        hasBlueLips: null,
        hasRunnyNose: null,
        anorexy: null,
        nightSweat: null,
        fatigue: null,
        other: null,
      };
    }
    getDefaultCovidSympthom = () => {
      return {
        hasTraveledLately: null,
        hasInteractingWithCovidPeople: null,
        hasCovidVaccines: null,
        lastVaccineBrand: '',
        hasSympthoms: null,
      };
    }
    getDefaultHeredorelatives = () => {
      return {
        'diabetes': this.getDefaultMedicalQuestion(),
        'cholesterol': this.getDefaultMedicalQuestion(),
        'hypertension': this.getDefaultMedicalQuestion(),
        'heartAttack': this.getDefaultMedicalQuestion(),
        'stroke': this.getDefaultMedicalQuestion(),
        'thyroid': this.getDefaultMedicalQuestion(),
        'osteoporosis': this.getDefaultMedicalQuestion(),
        'parkinson': this.getDefaultMedicalQuestion(),
        'alzheimer': this.getDefaultMedicalQuestion(),
        'mentalIllness': this.getDefaultMedicalQuestion(),
        'drugsAbuse': this.getDefaultMedicalQuestion(),
        'bloodDisease': this.getDefaultMedicalQuestion(),
        'breastCancer': this.getDefaultMedicalQuestion(),
        'colonCancer': this.getDefaultMedicalQuestion(),
        'ovarianCancer': this.getDefaultMedicalQuestion(),
        'prostateCancer': this.getDefaultMedicalQuestion(),
        'heartSurgery': this.getDefaultMedicalQuestion(),
        'other': ''
      }
    }
    getDefaultPersonalPathological = () => {
      return {
        'diabetes': this.getDefaultMedicalQuestion(),
        'hypertension': this.getDefaultMedicalQuestion(),
        'cholesterol': this.getDefaultMedicalQuestion(),
        'hepatitisA': this.getDefaultMedicalQuestion(),
        'hepatitisB': this.getDefaultMedicalQuestion(),
        'hepatitisC': this.getDefaultMedicalQuestion(),
        'liverIssues': this.getDefaultMedicalQuestion(),
        'pancreatitis': this.getDefaultMedicalQuestion(),
        'renalInsufficiency': this.getDefaultMedicalQuestion(),
        'cancer': this.getDefaultMedicalQuestion(),
        'depression': this.getDefaultMedicalQuestion(),
        'thyroid': this.getDefaultMedicalQuestion(),
        'migraine': this.getDefaultMedicalQuestion(),
        'boodClots': this.getDefaultMedicalQuestion(),
        'asthma': this.getDefaultMedicalQuestion(),
        'copd': this.getDefaultMedicalQuestion(),
        'tuberculosis': this.getDefaultMedicalQuestion(),
        'aids': this.getDefaultMedicalQuestion(),
        'std': this.getDefaultMedicalQuestion(),
        'other': this.getDefaultMedicalQuestion()
      };
    }
    getDefaultEyes = () => {
      return {
        'visionLoss': this.getDefaultMedicalQuestion(),
        'squint': this.getDefaultMedicalQuestion(),
        'glaucoma': this.getDefaultMedicalQuestion(),
        'myopia': this.getDefaultMedicalQuestion(),
        'astigmatism': this.getDefaultMedicalQuestion(),
        'glasses': this.getDefaultMedicalQuestion(),
        'eyeSurgery': this.getDefaultMedicalQuestion(),
        'other': ''
      }
    }
    getDefaultEars = () => {
      return {
        'vertigo': this.getDefaultMedicalQuestion(),
        'auditionLoss': this.getDefaultMedicalQuestion(),
        'other': this.getDefaultMedicalQuestion()
      }
    }
    getDefaultMedicalQuestion = () => {
      return {
        'value': null,
        'detail': ''
      }
    }
    getDefaultRespiratorySystem = () => {
      return {
        'respiratoryDistress': null,
        'cough': null,
        'expectoration': null,
        'hemoptysis': null,
        'other': ''
      }
    }
    getDefaultCardiovascularSystem = () => {
      return {
        'dyspnoea': null,
        'orthopnea': null,
        'lowerExtremityEdema': null,
        'chestPain': null,
        'other': ''
      }
    }
    updateValue = (fieldName, value) => {
      const { medicalHistory } = this.state;
      medicalHistory[fieldName] = value;
      this.setState({ medicalHistory, isFormDirty: true });
    }
    updateNestedValue = (objectName, fieldName, value) => {
      const { medicalHistory } = this.state;
      const nestedObject = medicalHistory[objectName];
      nestedObject[fieldName] = value;
      this.setState({ medicalHistory, isFormDirty: true });
    }

    updateBoolean = (objectName, fieldName, e, isMedicalQuestion) => {
      let value = e.currentTarget.value === 'true';
      if (isMedicalQuestion) {
        this.updateMedicalQuestionValue(objectName, fieldName, value);
      }
      else {
        value = value === true && this.state.medicalHistory[objectName][fieldName] === true ? false : value;
        this.updateNestedValue(objectName, fieldName, value)
      }
    }

    updateMedicalQuestionValue = (objectName, fieldName, value) => {
      const { medicalHistory } = this.state;
      const nestedObject = medicalHistory[objectName];
      nestedObject[fieldName]['value'] = value;
      this.setState({ medicalHistory, isFormDirty: true });
    }
    updateMedicalQuestionDetail = (objectName, fieldName, value) => {
      const { medicalHistory } = this.state;
      const nestedObject = medicalHistory[objectName];
      nestedObject[fieldName]['detail'] = value;
      this.setState({ medicalHistory });
    }
    nextView = () => {
      if (this.state.isFormDirty) {
        this.postHistory();
      }        
      let { currentView } = this.state;
      if (currentView < this.state.headers.length - 1) currentView++;
      if (currentView === 5 && this.state.medicalHistory.patientGender === 'male') currentView++;

      this.setState({ currentView: currentView, isFormDirty: false });
    };
    previousView = () => {
      let { currentView } = this.state;
      if (currentView > 0) currentView--;
      if (currentView === 5 && this.state.medicalHistory.patientGender === 'male') currentView--;

      this.setState({ currentView: currentView });
    };
    setView = (e, number) => {
      if (number === 5 && this.state.medicalHistory.patientGender === 'male') return;
      this.setState({ currentView: number });
    }
    getNavigation = () => {
      return this.state.headers.map((val, i, arr) => {
        return <span key={`${val}-${i}`} className={`dot clickable ${this.state.currentView === i ? 'active' : ''}`} value={i} onClick={e => this.setView(e, i)}></span>
      });
    }

    render() {
      return (
        <div className='history-container background-light'>
          {this.state.loading && <Loading />}
          <div className='clickable'>
            <img src={icono_flecha} className='configIcon pr-2' alt='configuracion' onClick={this.goToMedical} />
            <label className='text clickable' onClick={this.goToMedical}>Historial clínico {this.state.currentView > 0 && '|'} <span className='text light clickable'>{this.state.headers[this.state.currentView]}</span></label>
          </div>
          <HistoryView
            currentView={this.state.currentView}
            viewNumber={0}
          >
            <div className='row'>
              <label className='text'>Identificación del paciente</label>
            </div>
            <div className='row'>
              <div className='pt-1 pl-0 col-12 col-lg-auto mr-5'>
                <label className='text'>Nombre:</label>
              </div>
              <BasicInput maxLength={50} classes='col' max basicVal={this.state.medicalHistory.patientName} onChangeFxn={(val) => this.updateValue('patientName', val)} />
              <div className='pt-1 pr-0 col-12 col-lg-auto'>
                <label className='text'>Peso (kg):</label>
              </div>
              <BasicInput classes='pr-0 col' numeric={true} basicVal={this.state.medicalHistory.weight} onChangeFxn={(val) => this.updateValue('weight', val)} maxLength='3' />
            </div>
            <div className='row'>
              <div className='pt-1 pl-0 col-12 col-lg-auto'>
                <label className='text'>Fecha de nacimiento:</label>
              </div>
              <BasicInput type='date' classes='col' basicVal={this.state.medicalHistory.birthDate} onChangeFxn={val => this.updateValue('birthDate', val)} maxLength={20} />
              <div className='pt-1 col-12 col-lg-auto'>
                <label className='text'>Estatura (cm):</label>
              </div>
              <BasicInput classes='col pr-0' numeric={true} basicVal={this.state.medicalHistory.height} onChangeFxn={(val) => this.updateValue('height', val)} maxLength='3' />
              <div className='divider'></div>
            </div>

            <div className='row'>                
              <div className='col-12 col-lg-6'>
                <div className='row'>
                <div className='col-12'  style={{margin:'inherit'}}>
                    <label className='text'>Síntomas COVID</label>
                </div>
                  <div className='col-6'></div>
                  <div className='col-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Viajaste al extranjero en las últimas dos semanas?' object={'covidSympthom'} field={'hasTraveledLately'} isChecked={this.state.medicalHistory.covidSympthom.hasTraveledLately} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='En las últimas 2 semanas has interactuado con personas confirmadas de COVID?' object={'covidSympthom'} field={'hasInteractingWithCovidPeople'} isChecked={this.state.medicalHistory.covidSympthom.hasInteractingWithCovidPeople} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Cuentas con vacuna COVID?' object={'covidSympthom'} field={'hasCovidVaccines'} isChecked={this.state.medicalHistory.covidSympthom.hasCovidVaccines} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-6 pl-0 form-group'>
                    <label className='text light'>Última dosis</label>
                  </div>
                  <div className='col-4'>
                    <BasicSelect
                      customOptions={['Astra Zeneca', 'CanSino', 'J&J', 'Moderna', 'Pfizer', 'Sputnik',]}
                      placeholder={'Selecciona una vacuna'}
                      selectedItem={this.state.medicalHistory.covidSympthom.lastVaccineBrand}
                      onChangeFxn={val => this.updateNestedValue('covidSympthom', 'lastVaccineBrand', val)}
                    />
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Durante la última semana has presentado síntomas?' object={'covidSympthom'} field={'hasSympthoms'} isChecked={this.state.medicalHistory.covidSympthom.hasSympthoms} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                      </div>
                                        
              <div className='col-12 col-lg-6'>
                <div className='row'>
                <div className='col-12'  style={{margin:'inherit'}}>
                    <label className='text'>Selecciona los síntomas que presentas:</label>
                </div>
                  <div className='col-4'>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Pérdida de olfato' object={'sympthom'} field={'hasLoseSmell'} isChecked={this.state.medicalHistory.sympthom.hasLoseSmell} onClick={this.updateBoolean} singleRadioOption={true} ></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Pérdida del gusto' object={'sympthom'} field={'hasLostTaste'} isChecked={this.state.medicalHistory.sympthom.hasLostTaste} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Dolor de cabeza' object={'sympthom'} field={'hasHeadaches'} isChecked={this.state.medicalHistory.sympthom.hasHeadaches} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Dolor muscular' object={'sympthom'} field={'hasMusclePain'} isChecked={this.state.medicalHistory.sympthom.hasMusclePain} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Dolor de articulaciones' object={'sympthom'} field={'hasJointPain'} isChecked={this.state.medicalHistory.sympthom.hasJointPain} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Dolor y/o ardor de garganta' object={'sympthom'} field={'hasThroatPain'} isChecked={this.state.medicalHistory.sympthom.hasThroatPain} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='tos' object={'sympthom'} field={'cough'} isChecked={this.state.medicalHistory.sympthom.cough} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='fiebre' object={'sympthom'} field={'fever'} isChecked={this.state.medicalHistory.sympthom.fever} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Escalofríos' object={'sympthom'} field={'hasChills'} isChecked={this.state.medicalHistory.sympthom.hasChills} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Dolor abdominal' object={'sympthom'} field={'hasAbdominalPain'} isChecked={this.state.medicalHistory.sympthom.hasAbdominalPain} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Vómito' object={'sympthom'} field={'hasVomit'} isChecked={this.state.medicalHistory.sympthom.hasVomit} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Diarrea' object={'sympthom'} field={'hasDiarrea'} isChecked={this.state.medicalHistory.sympthom.hasDiarrea} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Dolor de pecho' object={'sympthom'} field={'hasPainChest'} isChecked={this.state.medicalHistory.sympthom.hasPainChest} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Dificultad para respirar' object={'sympthom'} field={'hasTroubleBreathing'} isChecked={this.state.medicalHistory.sympthom.hasTroubleBreathing} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Labios azules' object={'sympthom'} field={'hasBlueLips'} isChecked={this.state.medicalHistory.sympthom.hasBlueLips} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='row'>
                      <MedicalHistoryYesNoQuestion label='Escurrimiento nasal' object={'sympthom'} field={'hasRunnyNose'} isChecked={this.state.medicalHistory.sympthom.hasRunnyNose} onClick={this.updateBoolean} singleRadioOption={true}></MedicalHistoryYesNoQuestion>
                    </div>
                    <div className='col-auto'>
                      <label className='text light'>Otros</label>
                    </div>
                    <BasicInput classes='col pr-0' basicVal={this.state.medicalHistory.sympthom.other} onChangeFxn={(val) => this.updateNestedValue('sympthom', 'other', val)} />
                  </div>

                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={1}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-6 pl-0 form-group'>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                  <div className='col d-flex justify-content-center'>
                    <label className='text'>Parentesco</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Diabetes' showInput={true} object={'heredorelatives'} field={'diabetes'} isChecked={this.state.medicalHistory.heredorelatives.diabetes.value} detail={this.state.medicalHistory.heredorelatives.diabetes.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Colesterol alto' showInput={true} object={'heredorelatives'} field={'cholesterol'} isChecked={this.state.medicalHistory.heredorelatives.cholesterol.value} detail={this.state.medicalHistory.heredorelatives.cholesterol.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Hipertensión' showInput={true} object={'heredorelatives'} field={'hypertension'} isChecked={this.state.medicalHistory.heredorelatives.hypertension.value} detail={this.state.medicalHistory.heredorelatives.hypertension.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Ataque cardiaco' showInput={true} object={'heredorelatives'} field={'heartAttack'} isChecked={this.state.medicalHistory.heredorelatives.heartAttack.value} detail={this.state.medicalHistory.heredorelatives.heartAttack.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Cirugía cardiaca' showInput={true} object={'heredorelatives'} field={'heartSurgery'} isChecked={this.state.medicalHistory.heredorelatives.heartSurgery.value} detail={this.state.medicalHistory.heredorelatives.heartSurgery.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Derrame cerebral' showInput={true} object={'heredorelatives'} field={'stroke'} isChecked={this.state.medicalHistory.heredorelatives.stroke.value} detail={this.state.medicalHistory.heredorelatives.stroke.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Problemas de la tiroides' showInput={true} object={'heredorelatives'} field={'thyroid'} isChecked={this.state.medicalHistory.heredorelatives.thyroid.value} detail={this.state.medicalHistory.heredorelatives.thyroid.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Osteoporosis' showInput={true} object={'heredorelatives'} field={'osteoporosis'} isChecked={this.state.medicalHistory.heredorelatives.osteoporosis.value} detail={this.state.medicalHistory.heredorelatives.osteoporosis.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Enfermedad de Parkinson' showInput={true} object={'heredorelatives'} field={'parkinson'} isChecked={this.state.medicalHistory.heredorelatives.parkinson.value} detail={this.state.medicalHistory.heredorelatives.parkinson.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-6 pl-0 form-group'>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                  <div className='col d-flex justify-content-center'>
                    <label className='text'>Parentesco</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Enfermedad de Alzheimer' showInput={true} object={'heredorelatives'} field={'alzheimer'} isChecked={this.state.medicalHistory.heredorelatives.alzheimer.value} detail={this.state.medicalHistory.heredorelatives.alzheimer.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Enfermedad mental' showInput={true} object={'heredorelatives'} field={'mentalIllness'} isChecked={this.state.medicalHistory.heredorelatives.mentalIllness.value} detail={this.state.medicalHistory.heredorelatives.mentalIllness.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Abuso de alcohol / drogas' showInput={true} object={'heredorelatives'} field={'drugsAbuse'} isChecked={this.state.medicalHistory.heredorelatives.drugsAbuse.value} detail={this.state.medicalHistory.heredorelatives.drugsAbuse.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Enfermedad de sangre' showInput={true} object={'heredorelatives'} field={'bloodDisease'} isChecked={this.state.medicalHistory.heredorelatives.bloodDisease.value} detail={this.state.medicalHistory.heredorelatives.bloodDisease.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Cáncer de mama' showInput={true} object={'heredorelatives'} field={'breastCancer'} isChecked={this.state.medicalHistory.heredorelatives.breastCancer.value} detail={this.state.medicalHistory.heredorelatives.breastCancer.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Cáncer o colon' showInput={true} object={'heredorelatives'} field={'colonCancer'} isChecked={this.state.medicalHistory.heredorelatives.colonCancer.value} detail={this.state.medicalHistory.heredorelatives.colonCancer.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Cáncer de ovario' showInput={true} object={'heredorelatives'} field={'ovarianCancer'} isChecked={this.state.medicalHistory.heredorelatives.ovarianCancer.value} detail={this.state.medicalHistory.heredorelatives.ovarianCancer.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Cáncer de prostata' showInput={true} object={'heredorelatives'} field={'prostateCancer'} isChecked={this.state.medicalHistory.heredorelatives.prostateCancer.value} detail={this.state.medicalHistory.heredorelatives.prostateCancer.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto'>
                    <label className='text light'>Otro:</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.heredorelatives.other} onChangeFxn={val => this.updateNestedValue('heredorelatives', 'other', val)}></BasicInput>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={2}>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='row'>
                      <div className='col-6'></div>
                      <div className='col-3'>
                        <label className='text'>Sí</label>
                      </div>
                      <div className='col-3'>
                        <label className='text'>No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Consumes alcohol?' isMedical={true} object={'nonPersonalPathologycal'} field={'alcohol'} isChecked={this.state.medicalHistory.nonPersonalPathologycal.alcohol.value} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-lg-3 form-group'>
                    <label className='text light'>Frecuencia</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.nonPersonalPathologycal.alcohol.detail} onChangeFxn={val => this.updateMedicalQuestionDetail('nonPersonalPathologycal', 'alcohol', val)}></BasicInput>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Fumas?' isMedical={true} object={'nonPersonalPathologycal'} field={'smoke'} isChecked={this.state.medicalHistory.nonPersonalPathologycal.smoke.value} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-lg-3 form-group'>
                    <label className='text light'>Frecuencia</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.nonPersonalPathologycal.smoke.detail} onChangeFxn={val => this.updateMedicalQuestionDetail('nonPersonalPathologycal', 'smoke', val)}></BasicInput>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Tienes alguna adicción?' isMedical={true} object={'nonPersonalPathologycal'} field={'addictions'} isChecked={this.state.medicalHistory.nonPersonalPathologycal.addictions.value} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-lg-3 form-group'>
                    <label className='text light'>Especifica</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.nonPersonalPathologycal.addictions.detail} onChangeFxn={val => this.updateMedicalQuestionDetail('nonPersonalPathologycal', 'addictions', val)}></BasicInput>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Tomas medicamentos actualmente?' isMedical={true} object={'nonPersonalPathologycal'} field={'medicines'} isChecked={this.state.medicalHistory.nonPersonalPathologycal.medicines.value} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-lg-3 form-group'>
                    <label className='text light'>Indica cuál(es)</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.nonPersonalPathologycal.medicines.detail} onChangeFxn={val => this.updateMedicalQuestionDetail('nonPersonalPathologycal', 'medicines', val)}></BasicInput>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Convives con animales?' isMedical={true} object={'nonPersonalPathologycal'} field={'animals'} isChecked={this.state.medicalHistory.nonPersonalPathologycal.animals.value} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-lg-3 form-group'>
                    <label className='text light'>Indica cuál(es)</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.nonPersonalPathologycal.animals.detail} onChangeFxn={val => this.updateMedicalQuestionDetail('nonPersonalPathologycal', 'animals', val)}></BasicInput>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Realizas alguna actividad física?' isMedical={true} object={'nonPersonalPathologycal'} field={'workout'} isChecked={this.state.medicalHistory.nonPersonalPathologycal.workout.value} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-lg-3 form-group'>
                    <label className='text light'>Indica cuál(es)</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.nonPersonalPathologycal.workout.detail} onChangeFxn={val => this.updateMedicalQuestionDetail('nonPersonalPathologycal', 'workout', val)}></BasicInput>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-auto'>
                <label className='text light'>¿Cuál es tu ocupación?</label>
              </div>
              <BasicInput classes='col' basicVal={this.state.medicalHistory.nonPersonalPathologycal.ocupation} onChangeFxn={val => this.updateNestedValue('nonPersonalPathologycal', 'ocupation', val)}></BasicInput>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={3}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-6 pl-0 form-group'>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                  <div className='col d-flex justify-content-center'>
                    <label className='text'>Detalle</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Diabetes' showInput={true} object={'personalPathological'} field={'diabetes'} isChecked={this.state.medicalHistory.personalPathological.diabetes.value} detail={this.state.medicalHistory.personalPathological.diabetes.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Hipertensión' showInput={true} object={'personalPathological'} field={'hypertension'} isChecked={this.state.medicalHistory.personalPathological.hypertension.value} detail={this.state.medicalHistory.personalPathological.hypertension.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Colesterol alto' showInput={true} object={'personalPathological'} field={'cholesterol'} isChecked={this.state.medicalHistory.personalPathological.cholesterol.value} detail={this.state.medicalHistory.personalPathological.cholesterol.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Hepatitis A' showInput={true} object={'personalPathological'} field={'hepatitisA'} isChecked={this.state.medicalHistory.personalPathological.hepatitisA.value} detail={this.state.medicalHistory.personalPathological.hepatitisA.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Hepatitis B' showInput={true} object={'personalPathological'} field={'hepatitisB'} isChecked={this.state.medicalHistory.personalPathological.hepatitisB.value} detail={this.state.medicalHistory.personalPathological.hepatitisB.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Hepatitis C' showInput={true} object={'personalPathological'} field={'hepatitisC'} isChecked={this.state.medicalHistory.personalPathological.hepatitisC.value} detail={this.state.medicalHistory.personalPathological.hepatitisC.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Problemas de hígado' showInput={true} object={'personalPathological'} field={'liverIssues'} isChecked={this.state.medicalHistory.personalPathological.liverIssues.value} detail={this.state.medicalHistory.personalPathological.liverIssues.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Pancreatitis' showInput={true} object={'personalPathological'} field={'pancreatitis'} isChecked={this.state.medicalHistory.personalPathological.pancreatitis.value} detail={this.state.medicalHistory.personalPathological.pancreatitis.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Insuficiencia renal' showInput={true} object={'personalPathological'} field={'renalInsufficiency'} isChecked={this.state.medicalHistory.personalPathological.renalInsufficiency.value} detail={this.state.medicalHistory.personalPathological.renalInsufficiency.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Cáncer' showInput={true} object={'personalPathological'} field={'cancer'} isChecked={this.state.medicalHistory.personalPathological.cancer.value} detail={this.state.medicalHistory.personalPathological.cancer.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-6 pl-0 form-group'>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                  <div className='col d-flex justify-content-center'>
                    <label className='text'>Detalle</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Depresión o ansiedad' showInput={true} object={'personalPathological'} field={'depression'} isChecked={this.state.medicalHistory.personalPathological.depression.value} detail={this.state.medicalHistory.personalPathological.depression.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Problemas de tiroides' showInput={true} object={'personalPathological'} field={'thyroid'} isChecked={this.state.medicalHistory.personalPathological.thyroid.value} detail={this.state.medicalHistory.personalPathological.thyroid.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Migraña' showInput={true} object={'personalPathological'} field={'migraine'} isChecked={this.state.medicalHistory.personalPathological.migraine.value} detail={this.state.medicalHistory.personalPathological.migraine.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Coágulos de sangre' showInput={true} object={'personalPathological'} field={'boodClots'} isChecked={this.state.medicalHistory.personalPathological.boodClots.value} detail={this.state.medicalHistory.personalPathological.boodClots.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Asma' showInput={true} object={'personalPathological'} field={'asthma'} isChecked={this.state.medicalHistory.personalPathological.asthma.value} detail={this.state.medicalHistory.personalPathological.asthma.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='EPOC' showInput={true} object={'personalPathological'} field={'copd'} isChecked={this.state.medicalHistory.personalPathological.copd.value} detail={this.state.medicalHistory.personalPathological.copd.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Tuberculosis' showInput={true} object={'personalPathological'} field={'tuberculosis'} isChecked={this.state.medicalHistory.personalPathological.tuberculosis.value} detail={this.state.medicalHistory.personalPathological.tuberculosis.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='VIH o SIDA' showInput={true} object={'personalPathological'} field={'aids'} isChecked={this.state.medicalHistory.personalPathological.aids.value} detail={this.state.medicalHistory.personalPathological.aids.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='ETS' showInput={true} object={'personalPathological'} field={'std'} isChecked={this.state.medicalHistory.personalPathological.std.value} detail={this.state.medicalHistory.personalPathological.std.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Otro' showInput={true} object={'personalPathological'} field={'other'} isChecked={this.state.medicalHistory.personalPathological.other.value} detail={this.state.medicalHistory.personalPathological.other.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={4}>

            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-6 pl-0 form-group'>
                    <label className='text'>Alergias</label>
                  </div>
                  <div className='col-3 px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Tienes alergias?' object={'pathological'} field={'hasAllergies'} isChecked={this.state.medicalHistory.pathological.hasAllergies} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-12 col-lg-3 pl-0 form-group'>
                    <label className='text light'>Medicamentos:</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.pathological.medicines} onChangeFxn={val => this.updateNestedValue('pathological', 'medicines', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-12 col-lg-3 pl-0 form-group'>
                    <label className='text light'>Comida:</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.pathological.food} onChangeFxn={val => this.updateNestedValue('pathological', 'food', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-12 col-lg-3 pl-0 form-group'>
                    <label className='text light'>Animales/insectos:</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.pathological.animals} onChangeFxn={val => this.updateNestedValue('pathological', 'animals', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-12 col-lg-3 pl-0 form-group'>
                    <label className='text light'>Otro:</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.pathological.otherAllergy} onChangeFxn={val => this.updateNestedValue('pathological', 'otherAllergy', val)}></BasicInput>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-6 form-group'>
                    <label className='text'>Otros</label>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                  <div className='col d-flex justify-content-center'>
                    <label className='text'>Especificar</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Hospitalizaciones previas' showInput={true} object={'pathological'} field={'previousHospitalizations'} isChecked={this.state.medicalHistory.pathological.previousHospitalizations.value} detail={this.state.medicalHistory.pathological.previousHospitalizations.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Antecedentes quirúrgicos' showInput={true} object={'pathological'} field={'surgicalHistory'} isChecked={this.state.medicalHistory.pathological.surgicalHistory.value} detail={this.state.medicalHistory.pathological.surgicalHistory.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Transfusiones previas' showInput={true} object={'pathological'} field={'previousTransfusions'} isChecked={this.state.medicalHistory.pathological.previousTransfusions.value} detail={this.state.medicalHistory.pathological.previousTransfusions.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Fracturas' showInput={true} object={'pathological'} field={'fractures'} isChecked={this.state.medicalHistory.pathological.fractures.value} detail={this.state.medicalHistory.pathological.fractures.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Traumatismo' showInput={true} object={'pathological'} field={'trauma'} isChecked={this.state.medicalHistory.pathological.trauma.value} detail={this.state.medicalHistory.pathological.trauma.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={5}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 pl-0 form-group'>
                    <label className='text'>Ciclo menstrual</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Primera menstruación:</label>
                  </div>
                  <BasicInput numeric={true} classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.firstMestruationAge} onChangeFxn={val => this.updateNestedValue('gynecologist', 'firstMestruationAge', val)}></BasicInput>
                  <div className='col-md-1 col-lg-3'>
                    <label className='text light small'>años de edad</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Ciclos regulares:</label>
                  </div>
                  <div className='col-6 col-md-2 col-lg-2'>
                    <BasicRadio radioValue={true} radioGroup='hasRegularMenstruation' isChecked={this.state.medicalHistory.gynecologist.hasRegularMenstruation} onChangeFxn={e => this.updateBoolean('gynecologist', 'hasRegularMenstruation', e, false)} label='si' />
                  </div>
                  <div className='col-6 col-md-2 col-lg-2'>
                    <BasicRadio radioValue={false} radioGroup='hasRegularMenstruation' isChecked={!this.state.medicalHistory.gynecologist.hasRegularMenstruation} onChangeFxn={e => this.updateBoolean('gynecologist', 'hasRegularMenstruation', e, false)} label='No' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Frecuencia de la menstruación:</label>
                  </div>
                  <BasicInput classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.menstruationFrecuency} onChangeFxn={val => this.updateNestedValue('gynecologist', 'menstruationFrecuency', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Fecha de la última menstruación:</label>
                  </div>
                  <BasicInput classes='col pl-0' type='date' basicVal={this.state.medicalHistory.gynecologist.lastMestruationDate} onChangeFxn={val => this.updateNestedValue('gynecologist', 'lastMestruationDate', val)} maxLength={20}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Cólicos menstruales:</label>
                  </div>
                  <div className='col-6 col-md-2 col-lg-2 form-group'>
                    <BasicRadio radioValue={true} radioGroup='hasMentrualCramps' isChecked={this.state.medicalHistory.gynecologist.hasMentrualCramps} onChangeFxn={e => this.updateBoolean('gynecologist', 'hasMentrualCramps', e, false)} label='si' />
                  </div>
                  <div className='col-6 col-md-2 col-lg-2 form-group'>
                    <BasicRadio radioValue={false} radioGroup='hasMentrualCramps' isChecked={!this.state.medicalHistory.gynecologist.hasMentrualCramps} onChangeFxn={e => this.updateBoolean('gynecologist', 'hasMentrualCramps', e, false)} label='No' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Fecha de última citología:</label>
                  </div>
                  <BasicInput classes='col pl-0' type='date' basicVal={this.state.medicalHistory.gynecologist.lastCytologyDate} onChangeFxn={val => this.updateNestedValue('gynecologist', 'lastCytologyDate', val)} maxLength={20}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Resultado de última citología:</label>
                  </div>
                  <BasicInput classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.lastCytologyResult} onChangeFxn={val => this.updateNestedValue('gynecologist', 'lastCytologyResult', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Fecha de última exploración mamaria:</label>
                  </div>
                  <BasicInput classes='col pl-0' type='date' basicVal={this.state.medicalHistory.gynecologist.lastBreastExaminationDate} onChangeFxn={val => this.updateNestedValue('gynecologist', 'lastBreastExaminationDate', val)} maxLength={20}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Resultado de última exploración mamaria:</label>
                  </div>
                  <BasicInput classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.lastBreastExaminationResult} onChangeFxn={val => this.updateNestedValue('gynecologist', 'lastBreastExaminationResult', val)}></BasicInput>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 form-group'>
                    <label className='text'>Vida sexual</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto form-group'>
                    <label className='text light'>Número de parejas sexuales:</label>
                  </div>
                  <BasicInput numeric={true} classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.sexualPartners} onChangeFxn={val => this.updateNestedValue('gynecologist', 'sexualPartners', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto form-group'>
                    <label className='text light'>Método de planificación actual:</label>
                  </div>
                  <BasicInput classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.actualContraceptiveMethodUsed} onChangeFxn={val => this.updateNestedValue('gynecologist', 'actualContraceptiveMethodUsed', val)}></BasicInput>
                </div>
                <div className='row my-4 form-group'>
                </div>
                <div className='row'>
                  <div className='col-auto pt-1 form-group'>
                    <label className='text'>Embarazos</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto form-group'>
                    <label className='text light'>Número de partos:</label>
                  </div>
                  <BasicInput numeric={true} classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.childbirthNumber} onChangeFxn={val => this.updateNestedValue('gynecologist', 'childbirthNumber', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto form-group'>
                    <label className='text light'>Número de cesáreas:</label>
                  </div>
                  <BasicInput numeric={true} classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.cesareanNumber} onChangeFxn={val => this.updateNestedValue('gynecologist', 'cesareanNumber', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto form-group'>
                    <label className='text light'>Número de abortos:</label>
                  </div>
                  <BasicInput numeric={true} classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.abortionNumber} onChangeFxn={val => this.updateNestedValue('gynecologist', 'abortionNumber', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto form-group'>
                    <label className='text light'>Problemas asociados al embarazo:</label>
                  </div>
                  <BasicInput classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.pregnancyProblems} onChangeFxn={val => this.updateNestedValue('gynecologist', 'pregnancyProblems', val)}></BasicInput>
                </div>
                <div className='row'>
                  <div className='col-auto form-group'>
                    <label className='text light'>Otros:</label>
                  </div>
                  <BasicInput classes='col pl-0' basicVal={this.state.medicalHistory.gynecologist.other} onChangeFxn={val => this.updateNestedValue('gynecologist', 'other', val)}></BasicInput>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={6}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-6 pl-0 form-group'>
                    <label className='text'>Ojos</label>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                  <div className='col d-flex justify-content-center'>
                    <label className='text'>Parentesco</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Disminución o pérdida de la visión sin causa aparente' showInput={true} object={'eyes'} field={'visionLoss'} isChecked={this.state.medicalHistory.eyes.visionLoss.value} detail={this.state.medicalHistory.eyes.visionLoss.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Estrabismo' showInput={true} object={'eyes'} field={'squint'} isChecked={this.state.medicalHistory.eyes.squint.value} detail={this.state.medicalHistory.eyes.squint.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Glaucoma' showInput={true} object={'eyes'} field={'glaucoma'} isChecked={this.state.medicalHistory.eyes.glaucoma.value} detail={this.state.medicalHistory.eyes.glaucoma.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Miopía' showInput={true} object={'eyes'} field={'myopia'} isChecked={this.state.medicalHistory.eyes.myopia.value} detail={this.state.medicalHistory.eyes.myopia.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='Astigmatismo' showInput={true} object={'eyes'} field={'astigmatism'} isChecked={this.state.medicalHistory.eyes.astigmatism.value} detail={this.state.medicalHistory.eyes.astigmatism.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='¿Usas lentes?' showInput={true} object={'eyes'} field={'glasses'} isChecked={this.state.medicalHistory.eyes.glasses.value} detail={this.state.medicalHistory.eyes.glasses.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion label='¿Te han operado de los ojos?' showInput={true} object={'eyes'} field={'eyeSurgery'} isChecked={this.state.medicalHistory.eyes.eyeSurgery.value} detail={this.state.medicalHistory.eyes.eyeSurgery.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Otro</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.eyes.other} onChangeFxn={val => this.updateNestedValue('eyes', 'other', val)}></BasicInput>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-6 form-group'>
                    <label className='text'>Oidos</label>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                  <div className='col d-flex justify-content-center'>
                    <label className='text'>Parentesco</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Vértigo' showInput={true} object={'ears'} field={'vertigo'} isChecked={this.state.medicalHistory.ears.vertigo.value} detail={this.state.medicalHistory.ears.vertigo.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Disminución de la audición' showInput={true} object={'ears'} field={'auditionLoss'} isChecked={this.state.medicalHistory.ears.auditionLoss.value} detail={this.state.medicalHistory.ears.auditionLoss.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
                <div className='row'>
                  <MedicalDetailedQuestion classes='col-12 col-md-5 col-lg-6' label='Otro' showInput={true} object={'ears'} field={'other'} isChecked={this.state.medicalHistory.ears.other.value} detail={this.state.medicalHistory.ears.other.detail} onChange={this.updateBoolean} onChangeInput={this.updateMedicalQuestionDetail}></MedicalDetailedQuestion>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={7}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-6 form-group'>
                    <label className='text'>Sistema respiratorio</label>
                  </div>
                  <div className='col-3 px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Dificultad respiratoria' object={'respiratorySystem'} field={'respiratoryDistress'} isChecked={this.state.medicalHistory.respiratorySystem.respiratoryDistress} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Tos' object={'respiratorySystem'} field={'cough'} isChecked={this.state.medicalHistory.respiratorySystem.cough} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Expectoración(expulsión de moco, esputo o líquidos)' object={'respiratorySystem'} field={'expectoration'} isChecked={this.state.medicalHistory.respiratorySystem.expectoration} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Hemoptisis(expulsión de sangre en el esputo o flema)' object={'respiratorySystem'} field={'hemoptysis'} isChecked={this.state.medicalHistory.respiratorySystem.hemoptysis} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Otro</label>
                  </div>
                  <BasicInput classes='col pr-5' basicVal={this.state.medicalHistory.respiratorySystem.other} onChangeFxn={val => this.updateNestedValue('respiratorySystem', 'other', val)}></BasicInput>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-12 col-md-5 col-lg-8 form-group'>
                    <label className='text'>Sistema cardiovascular</label>
                  </div>
                  <div className='col-auto px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-auto px-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Disnea de esfuerzo' object={'cardiovascularSystem'} field={'dyspnoea'} isChecked={this.state.medicalHistory.cardiovascularSystem.dyspnoea} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Ortopea (dificultad para respirar al estar acostado)' object={'cardiovascularSystem'} field={'orthopnea'} isChecked={this.state.medicalHistory.cardiovascularSystem.orthopnea} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Edema de extremidades inferiores(Hinchazón)' object={'cardiovascularSystem'} field={'lowerExtremityEdema'} isChecked={this.state.medicalHistory.cardiovascularSystem.lowerExtremityEdema} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Dolor de pecho' object={'cardiovascularSystem'} field={'chestPain'} isChecked={this.state.medicalHistory.cardiovascularSystem.chestPain} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Otro</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.cardiovascularSystem.other} onChangeFxn={val => this.updateNestedValue('cardiovascularSystem', 'other', val)}></BasicInput>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={8}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-6 pl-0 form-group'>
                    <label className='text'>Sistema gastrointestinal</label>
                  </div>
                  <div className='col-3 px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Falta de apetito' object={'gastrointestinalSystem'} field={'appetiteLoss'} isChecked={this.state.medicalHistory.gastrointestinalSystem.appetiteLoss} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Náuseas' object={'gastrointestinalSystem'} field={'sickness'} isChecked={this.state.medicalHistory.gastrointestinalSystem.sickness} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Vómito' object={'gastrointestinalSystem'} field={'vomit'} isChecked={this.state.medicalHistory.gastrointestinalSystem.vomit} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Dificultat al deglutir o tragar alimentos' object={'gastrointestinalSystem'} field={'difficultySwallowing'} isChecked={this.state.medicalHistory.gastrointestinalSystem.difficultySwallowing} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Pirosis (Sensación de ardor en el esófago)' object={'gastrointestinalSystem'} field={'pyrosis'} isChecked={this.state.medicalHistory.gastrointestinalSystem.pyrosis} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Diarrea' object={'gastrointestinalSystem'} field={'diarrhea'} isChecked={this.state.medicalHistory.gastrointestinalSystem.diarrhea} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Deposiciones o heces negras' object={'gastrointestinalSystem'} field={'stools'} isChecked={this.state.medicalHistory.gastrointestinalSystem.stools} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Otro</label>
                  </div>
                  <BasicInput classes='col pr-5' basicVal={this.state.medicalHistory.gastrointestinalSystem.other} onChangeFxn={val => this.updateNestedValue('gastrointestinalSystem', 'other', val)}></BasicInput>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-6 pl-0 form-group'>
                    <label className='text'>Sistema genitourinario</label>
                  </div>
                  <div className='col-3 px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Dolor al orinar' object={'genitourinarySystem'} field={'painfulUrination'} isChecked={this.state.medicalHistory.genitourinarySystem.painfulUrination} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Aumento de frecuencia al orinar' object={'genitourinarySystem'} field={'increasedUrinationFrecuency'} isChecked={this.state.medicalHistory.genitourinarySystem.increasedUrinationFrecuency} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Aumento de volumen en la orina' object={'genitourinarySystem'} field={'increareUrineVolume'} isChecked={this.state.medicalHistory.genitourinarySystem.increareUrineVolume} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='¿Orinas varias veces por la noche?' object={'genitourinarySystem'} field={'nocturia'} isChecked={this.state.medicalHistory.genitourinarySystem.nocturia} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Prescencia de sangre en la orina' object={'genitourinarySystem'} field={'hematuria'} isChecked={this.state.medicalHistory.genitourinarySystem.hematuria} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Otro</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.genitourinarySystem.other} onChangeFxn={val => this.updateNestedValue('genitourinarySystem', 'other', val)}></BasicInput>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={9}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-6 pl-0 form-group'>
                    <label className='text'>Sistema endócrino</label>
                  </div>
                  <div className='col-3 px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Disminución de peso' object={'endocrineSystem'} field={'wheightLoss'} isChecked={this.state.medicalHistory.endocrineSystem.wheightLoss} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Intolerancia al frío y al calor' object={'endocrineSystem'} field={'coldIntolerance'} isChecked={this.state.medicalHistory.endocrineSystem.coldIntolerance} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Temblor' object={'endocrineSystem'} field={'shaking'} isChecked={this.state.medicalHistory.endocrineSystem.shaking} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Ronquera' object={'endocrineSystem'} field={'hoarseness'} isChecked={this.state.medicalHistory.endocrineSystem.hoarseness} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Somnolencia' object={'endocrineSystem'} field={'drowsiness'} isChecked={this.state.medicalHistory.endocrineSystem.drowsiness} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Sequedad en la piel' object={'endocrineSystem'} field={'drySkin'} isChecked={this.state.medicalHistory.endocrineSystem.drySkin} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Otro</label>
                  </div>
                  <BasicInput classes='col pr-5' basicVal={this.state.medicalHistory.endocrineSystem.other} onChangeFxn={val => this.updateNestedValue('endocrineSystem', 'other', val)}></BasicInput>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-6 pl-0 form-group'>
                    <label className='text'>Sistema neurológico</label>
                  </div>
                  <div className='col-3 px-3'>
                    <label className='text'>Sí</label>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='text'>No</label>
                  </div>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Cefálea' object={'neurologicalSystem'} field={'headache'} isChecked={this.state.medicalHistory.neurologicalSystem.headache} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Mareos' object={'neurologicalSystem'} field={'dizziness'} isChecked={this.state.medicalHistory.neurologicalSystem.dizziness} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Problemas de coordinación' object={'neurologicalSystem'} field={'coordinationProblem'} isChecked={this.state.medicalHistory.neurologicalSystem.coordinationProblem} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Paresia (debilidad del músculo)' object={'neurologicalSystem'} field={'paresis'} isChecked={this.state.medicalHistory.neurologicalSystem.paresis} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <MedicalHistoryYesNoQuestion label='Sensación de hormigueo, adormecimiento en las manos, pies, brazos y piernas' object={'neurologicalSystem'} field={'numbness'} isChecked={this.state.medicalHistory.neurologicalSystem.numbness} onChange={this.updateBoolean}></MedicalHistoryYesNoQuestion>
                </div>
                <div className='row'>
                  <div className='col-auto pl-0 form-group'>
                    <label className='text light'>Otro</label>
                  </div>
                  <BasicInput classes='col' basicVal={this.state.medicalHistory.neurologicalSystem.other} onChangeFxn={val => this.updateNestedValue('neurologicalSystem', 'other', val)}></BasicInput>
                </div>
              </div>
            </div>
          </HistoryView>
          <HistoryView currentView={this.state.currentView} viewNumber={10}>
            <div className='row'>
              <div className='col-12 col-md-12 col-lg-12 pl-0 form-group'>
                <label className='text light'>Ingresa los detalles adicionales que consideres importantes anexar en tus antecedentes médicos</label>
              </div>
            </div>
            <div className='row'>
              <textarea
                rows='7'
                value={this.state.medicalHistory.additionalDetails}
                onChange={e => this.updateValue('additionalDetails', e.target.value)}>
              </textarea>
            </div>
            <div className='row'>
              <div className='col-12 px-0 d-flex justify-content-center my-5'>
                <button className='btn primary-btn' onClick={e => this.postHistory(e, true)}>Terminar</button>
              </div>
            </div>
          </HistoryView>
          <div className='row'>
            <div className='d-flex justify-content-center col-12'>
              {this.state.currentView > 0 && <img src={icono_flecha} className='nav-arrow clickable' style={{ maxWidth: '10px' }} alt='izquierda' onClick={this.previousView} />}
              {
                this.getNavigation()
              }
              {this.state.currentView < this.state.headers.length - 1 && <img src={icono_flechad} className='nav-arrow clickable' style={{ maxWidth: '10px' }} alt='derecha' onClick={this.nextView} />}
            </div>
          </div>
          <BasicModal
            className='additional-modal'
            show={this.state.showSuccessSaved}
            modalClosed={() => this.setState({ showSuccessSaved: false })}
            title='Guardado exitosamente!'
            closeIcon={true} >
          </BasicModal>
        </div>
      );
    }
}
