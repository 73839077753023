import React from 'react';

import './Modal.scss';
import done from '../../../Assets/icons/Reservacion-listo.svg'

const SuccessReservation = props => {
  const getPriceFormatted=() => {
    const {doctorInformation} = props;
    let service = 0;
    const services = doctorInformation.doctorServices;
    services.forEach(s => {
      if(s.selected){
        service += (s.category === 'Consulta' && doctorInformation.isFirstTime )? s.firstTimePrice : s.price;
      }
    });
      service += 99;//Flat fee amount sanapp cuota
    return '$' + service.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return (
    <div className='successReservation'>
      <div className='d-flex align-items-center justify-content-center'>
        <img src={done} alt='sucess'/>
      </div> 
      <div className='d-flex align-items-center justify-content-center mb-1'>
        <h3 className='text'>¡Tu cita ha sido reservada con éxito!</h3>
      </div> 
      <div className='d-flex align-items-center justify-content-center mt-0'>
        <p>
          Se ha hecho una reserva en tu tarjeta con terminación <span className='bold'>{props.lastDigits}</span> por un monto de <span className='bold'>{getPriceFormatted()}</span>
        </p>
      </div>
      <div className='d-flex align-items-center justify-content-center'>
        <button onClick={props.onClickButton} className='btn primary-btn'>{'Continuar a mi agenda'}</button>
      </div>
      <div className='d-flex align-items-center justify-content-center mt-0 mb-0'>
        {/*<p className='bold mb-0'>*/}
        {/*  ¡No olvides realizar tu Check In cuando llegues al consultorio del Doctor!*/}
        {/*</p>*/}
      </div>   
    </div>
  );
};

export default SuccessReservation;
