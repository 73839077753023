import { range } from 'lodash';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import backIcon from '../../Assets/icons/Agenda semana-atras.svg'
import nextIcon from '../../Assets/icons/Agenda semana-adelante.svg'
import '../Common/CustomDatePicker.scss';
import { getMonth, getYear } from 'date-fns';

const CustomDatePicker = (props) => {
  const CustomInputLabel = React.forwardRef(({ value, onClick }, ref) => {
    return <button className='custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  });
  const dateRef = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [minDate] = useState(props.minDate === undefined? new Date(2020,1): props.minDate);
  const [maxDate] = useState(props.maxDate === undefined? new Date(): props.maxDate);

  const years = range(2020, getYear(new Date())+1, 1);
  const onDateChanged=(date)=>{
    setStartDate(date);
    props.onDateChanged && props.onDateChanged(date);
  }
  const onMonthChange=(date)=>{
    if(props.onMonthChange){
      props.onMonthChange(date, dateRef.current.setOpen)
      setStartDate(date);
    }
  }
  const months = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          className='row d-flex justify-content-center align-items-center picker-header'
        >
          <div className='col-3 pr-0'>
            <img className={`button ${prevMonthButtonDisabled?'inactive':'active'}`} src={backIcon} alt='back icon' onClick={!prevMonthButtonDisabled? decreaseMonth:undefined}></img>
          </div>
          <div className='col-3 px-0'>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className='col-3 pl-1 pr-0'>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className='col-3 pl-0'>
            <img className={`button ${nextMonthButtonDisabled? 'inactive':'active'}`} src={nextIcon} alt='back icon' onClick={!nextMonthButtonDisabled? increaseMonth:undefined}></img>
          </div>      
        </div>
      )}
      selected={startDate}
      onChange={(date) => onDateChanged(date)}
      maxDate = {maxDate}
      minDate = {minDate}
      onMonthChange={onMonthChange}
      customInput={<CustomInputLabel/>}
      dateFormat='dd-MM-yyyy'
      ref={dateRef}
    />
  );
};

export default CustomDatePicker;