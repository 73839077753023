import React, { Component } from 'react';
import BasicInput from '../Common/inputs/BasicInput';
import authService from '../../security/auth-service';
import './Login.scss';
import Loading from '../Common/loading/Loading';

export default class EnterCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      error:'',
      loading:false,
      mailText:'enviado'
    };
  }
  setLoading = value =>{
    this.setState({loading:value});
  }
  componentDidMount() {}

  setError = error => {
    this.setState({ error: error });
  };

  setCode=code=>{
    this.setError('');
    this.setState({code:code});
  }

  requestNewCode = async () => {
    this.setLoading(true);
    let {mailText} = this.state;
    if (!await authService.requestPasswordChange(this.props.email)) {
      this.setError('Ocurrió un problema, intenta más tarde');
      this.setLoading(false);
      return;
    }
    this.setLoading(false);
    mailText = 'reenviado';
    this.setState({mailText});
  };

  onContinue = async () => {
    this.setLoading(true);
    if (this.state.code.length<6||!await authService.isCodeValid(this.props.email, this.state.code)) {
      this.setError('Por favor verifique que el código sea correcto.');
      this.setLoading(false);
      return;
    }
    if (this.props.onContinue) {
      this.props.onContinue(this.state.code);
    }
    this.setLoading(false);
  };

  replaceRange=(s, start, end, substitute)=> {
    return s.substring(0, start) + substitute + s.substring(end);
  }
  maskEmail=()=>
  {
    const email = this.props.email;
    const emailArray = email.split('@');
    let masked=emailArray[0].substring(0,3);
    
    return `${masked}******@${emailArray[1]}`;
  }

  render() {
    return (
      <div className="code-container">
        {this.state.loading && <Loading />}
        <div className="row pb-2 d-flex justify-content-center">
          <h2 className="text-center">Verificación</h2>
        </div>
        <div className="row d-flex justify-content-center px-2">
          <label className="text-center">{`Hemos ${this.state.mailText} un correo electrónico a ${this.maskEmail()}. Escríbelo a continuación.`}</label>
        </div>
        <div className="row  d-flex justify-content-center mt-3">
          <label className="text">Introducir código</label>
        </div>
        <div className="row d-flex justify-content-center">
          <BasicInput
            classes="col-md-10 px-0"
            onChangeFxn={val => this.setCode(val)}
            numeric={true}
            maxLength = {6}
            type='password'
            basicVal={this.state.code}
          ></BasicInput>
        </div>
        <div
          className={
            this.state.error !== ''
              ? 'row errorContainer f-flex justify-content-center'
              : 'hidden'
          }
        >
          <p>{this.state.error}</p>
        </div>
        <div className="row justify-content-center mt-3">
          <button className="btn primary-btn" onClick={() => this.onContinue()}>
            Continuar
          </button>
        </div>
        <div className="row d-flex justify-content-center pt-3">
          <label className="link" onClick={() => this.requestNewCode()}>
            Reenviar código
          </label>
        </div>
      </div>
    );
  }
}
