import React, { Component } from 'react';
import { deleteData, getData, postData } from '../../../../Utils/API';
import prepareEndpoint from '../../../../Utils/EndPoints';
import SignModal from '../../../Common/modal/SignModal';
import '../ConciliationComponents/Conciliation.scss'
export default class ConciliationSign extends Component {
  constructor(props){
    super(props);
    this.state={
      img:'',
      showSelectionModal:false
    }
  }
  componentDidMount=()=>{
    this.loadImage();
  }
  loadImage= async ()=>{
    const url = prepareEndpoint('DoctorSign');
    const respose = await getData(url);
    if(respose.status !== 200){
      this.setState({showSelectionModal:true});
      return
    }
    if(respose.data === '')
    {
      this.setState({showSelectionModal:true});
    }else{
      this.setState({img:respose.data});
    }
  }
  onImageChange=async (img)=>{
    this.setState({img, showSelectionModal:false});
    if(img && img!==''){
      var url = prepareEndpoint('DoctorSign');
      await postData(url, {img:img});
    }
  }
  onDeleteImage= async()=>{
    this.setState({img:''});
    var url = prepareEndpoint('DoctorSign');
    await deleteData(url);
  }
  render(){
    return (
      <><div>
        <SignModal modalClosed={()=>{this.setState({showSelectionModal:false})}} show={this.state.showSelectionModal} onChange={this.onImageChange}></SignModal>
      </div><div className='container sign-container mt-5'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 d-flex align-items-center justify-content-center'>
            {(!this.state.img || this.state.img==='')&&<div className='img-empty'></div>}
            {(this.state.img && this.state.img!=='')&&<img src={this.state.img} alt='sign'></img>}
          </div>
        </div>
        <div className='row d-flex align-items-center justify-content-center mt-4'>
          <div className='col-12 d-flex align-items-center justify-content-center'>
            <button type='button' className='btn primary-btn' onClick={() => {this.setState({showSelectionModal: true}) } }>Cambiar</button>
            <button type='button' className='btn primary-btn ml-5' onClick={this.onDeleteImage} >Eliminar</button>
          </div>
        </div>
      </div></>
    );
  }
}