import React, { Component } from 'react';
import './ConciliationComponents/Conciliation.scss'
import invoiceIcon from '../../../Assets/icons/invoice.svg'
import ConciliationInvoiceReceived from './ConciliationComponents/ConciliationInvoiceReceived';
import ConciliationRequestInvoice from './ConciliationComponents/ConciliationRequestInvoice';
import IconButton from '../../Dr/Account/ConciliationComponents/IconButton';

export default class Conciliation extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabSelected:0
    }
  }
  renderView=()=>{
    switch(this.state.tabSelected){
    case 0: return (<ConciliationInvoiceReceived></ConciliationInvoiceReceived>);
    case 1: return (<ConciliationRequestInvoice></ConciliationRequestInvoice>);
    default: return(<label>Not Selected</label>);
    }
  }
  render(){
    return(
      <div className='conciliation-container'>
        <div className='row'>
          <div className='col-2 left-content'>
            <IconButton icon={invoiceIcon} label={'Facturas recibidas'} enabled={this.state.tabSelected ===0} clickFn={() => this.setState({ tabSelected: 0 })}></IconButton>
            <IconButton icon={invoiceIcon} label={'Solicitar facturas'} enabled={this.state.tabSelected ===1} clickFn={() => this.setState({ tabSelected: 1 })}></IconButton>
          </div>
          <div className='col-10 right-content ml-4'>
            {this.renderView()}
          </div>
        </div>
      </div>
    );
  }
}