import React, { Component } from 'react';
import done from '../../Assets/icons/Reservacion-listo.svg'
import '../Common/modal/Modal.scss';

export default class WelcomeSubscriptionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plan: 'free'
        };
    }

    render() {
        return (
            <div className='appointment-container text-center'>
                <div>
                    <img src={done} alt='sucess' style={{ maxWidth:'130px'} } />
                </div> 
                <div className='row pb-2 d-flex justify-content-center'>
                    <div className='col-12'>
                        <h2 className="text-center">Bienvenido al Plan Básico,</h2>
                    </div>
                    <div className='col-12'>
                        <h2 className="text-center">comienza a disfrutar de los beneficios</h2>
                    </div>
                </div>
                <label>
                    Se ha hecho un cargo recurrente de <strong>$149.00</strong>  a tu tarjeta con terminación <strong>6280</strong>  con renovación automática los días 29 de cada mes.
                </label>
                <div className='row justify-content-center mt-3'>
                    <button className='btn primary-btn' onClick={this.props.onContinue}>Confirmar pago</button>
                </div>
            </div>
        );
    }
}
