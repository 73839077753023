import React from 'react'

export default class BasicRadio extends React.Component {
  render() {
    return (
      <><div className={'radioContainer' + (this.props.classes ? this.props.classes : '')} >
        <label className="content-input">
          <input type="radio" 
            id={this.props.id || ''} 
            value={this.props.radioValue} 
            name={this.props.radioGroup}
            checked={this.props.isChecked}
            onChange={e => this.props.onChangeFxn ? this.props.onChangeFxn(e) : null} 
            onClick={e => this.props.onClick ? this.props.onClick(e) : null} /><span className={this.props.labelClassName ? this.props.labelClassName : '' }>{this.props.label}</span>
        </label>
      </div></>
    )
  }
}
