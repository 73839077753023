import React from 'react';
import { useHistory } from 'react-router-dom';
import logoFooter from '../../Assets/icons/Home-logo footer.svg';
import * as pwaShortcutInstall from '../../Initialization/pwaShortcutInstall';

export function Footer(){
  const history = useHistory()
  
  const prompInstallation = async () => {
    console.log('pwaShortcutInstall: ', pwaShortcutInstall);
    pwaShortcutInstall.prompInstallation();
  }
  return (
    <footer className='footer'>
      <div className='logo' onClick={() => history.push('/') }>
        <img src={logoFooter} alt='logo-footer' />
      </div>
      <div></div>
      <div className='footer-links'>
        <span className='pointer'>Ayuda</span> 
        <span onClick={prompInstallation} className='pointer'>Crear acceso directo</span>
      </div>
    </footer> 
  )
  
}
