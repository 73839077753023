import React from 'react';

import './Backdrop.scss';

const backDrop = props => {
  return props.show ? (
    <div
      className={props.className ? `Backdrop ${props.className}` : 'Backdrop'}
    >
      <div className='backdrop-click' onClick={props.clicked} />
      {props.children}
    </div>
  ) : null;
};

export default backDrop;
