import React, { Component } from 'react';
import checkinIcon from '../../Assets/icons/CheckMarkIcon.png';
import bronzeMedalIcon from '../../Assets/icons/BronzeMedalIcon.png';
import loadingCircleIcon from '../../Assets/icons/loadingCircleIcon.png';
import './Home.scss';
import { appContext } from '../../Utils/Context';
import LoginSubscribeFlowControl from '../Login/LoginSubscribeFlowControl';
import Loading from '../Common/loading/Loading';
import authConfig from '../../Initialization/auth-config';
import prepareEndpoint from '../../Utils/EndPoints';
import { getData } from '../../Utils/API';

class SubscriptionPrices extends Component {
    static displayName = SubscriptionPrices.name;

    constructor(props) {
        super(props)
        this.state = { loading: true, isPatient: null, isPatientFormSelected: true, subscriptionType: '' }
        this.flowRef = React.createRef();
    }

    async componentDidMount() {
        try {
            if (!authConfig.sessionAvailable || !authConfig.role) {
                return;
            }

            const isPatient = authConfig.role === 2;
            const endpointAlias = isPatient ? 'Patient' : 'Doctor';
            this.setState({ isPatientFormSelected: isPatient, isPatient });
            await this.getPatientOrDoctor(endpointAlias);

        } catch (e) {
            console.log('Error at componentDidMount: ', e);
        }
        finally { this.setState({ loading: false }); }
    }

    getPatientOrDoctor = async (endpointAlias) => {
        const url = prepareEndpoint(endpointAlias);
        const resp = await getData(url);
        if (resp?.status === 200 && resp.data?.id) {
            let data = resp.data?.subscriptionDetails?.subscriptionType || {};
            this.setState({ subscriptionType: data });
        }
    }

    startFlow = async (requestedSubscription) => {
        const currentSubscription = this.state.subscriptionType;
        await this.setState({ subscriptionType: requestedSubscription });
        this.flowRef.current.start(requestedSubscription, currentSubscription);
    }

    render() {
        return (
            <div className='home-page'>
                {this.state.loading && <Loading />}
                <h2 className="text title">Conoce nuestros planes y maximiza el rendimiento de tu cuenta</h2>

                <div className='row'>
                    <div className='col-4'></div>
                    <div className='toggleButtons col-4'>
                        <div className={this.state.isPatientFormSelected ? 'selectedGreen' : 'unselected'} onClick={() => this.setState({ isPatientFormSelected: true })} >
                            Soy Paciente
                        </div>
                        <div className={!this.state.isPatientFormSelected ? 'selectedGreen' : 'unselected'} onClick={() => this.setState({ isPatientFormSelected: false })} >
                            Soy Doctor
                        </div>
                    </div>
                    <div className='col-4'></div>
                </div>

                <div className='row'>

                    {this.state.isPatientFormSelected ?
                        //Patient Free Form
                        <div className='col-lg-4 col-md-6 col-sm-12 margin-bottom-30px'>
                            <div className='pricingForms'>

                                <h2 className="text title" style={{ marginBottom: 'auto' }}>
                                    <img src={bronzeMedalIcon} alt='BronzeMedalIcon' style={{ height: '35px', marginRight: '5px', verticalAlign: 'bottom' }} />
                                    Libre</h2>
                                <p className="text title" style={{ marginTop: 'auto' }}>Solo regístrate y obten los siguientes beneficios</p>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Agenda</label>
                                    <p className="textPriceInfoGray">Gestiona tus citas médicas sin necesidad de llamar. Es fácil, cómodo y muy rápido.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Historial</label>
                                    <p className="textPriceInfoGray">Revisa tus citas médicas pasadas, califícalas y factúralas con tan solo unos cuantos clics.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Expediente médico</label>
                                    <p className="textPriceInfoGray">Registra tu historial clínico y agrega tus estudios médicos para consultarlos cuando tú quieras.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>1 GB de almacenamiento</label>
                                    <p className="textPriceInfoGray">Guarda tus archivos en la nube de Sanapp. No te preocupes, tus datos estarán seguros con nosotros.</p>
                                </div>

                                <div>
                                    <label className="" style={{ fontSize: 'x-large' }}>Precio:</label>
                                    <label className="textPriceRaw" style={{ fontSize: 'xx-large' }}>$0</label>
                                </div>

                                <div className='col-12 buttonContainer'>
                                    <button type="button" className="btn primary-btn" disabled={this.state.isPatient === true && this.state.subscriptionType === 'free'}
                                        onClick={() => this.startFlow('free')}>{this.state.isPatient === true && this.state.subscriptionType === 'free' ? 'Subscrito' : 'Regístrate'}</button>
                                </div>
                            </div>
                        </div>
                        ://Doctor Free Form
                        <div className='col-lg-4 col-md-6 col-sm-12 margin-bottom-30px'>
                            <div className='pricingForms'>

                                <h2 className="text title" style={{ marginBottom: 'auto' }}>
                                    <img src={bronzeMedalIcon} alt='BronzeMedalIcon' style={{ height: '35px', marginRight: '5px', verticalAlign: 'bottom' }} />
                                    Libre</h2>
                                <p className="text title" style={{ marginTop: 'auto' }}>Solo regístrate y obten los siguientes beneficios</p>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Servicios</label>
                                    <p className="textPriceInfoGray">Gestiona tus servicios médicos y ubicaciones para que
                                        tus pacientes te encuentren más rápido y fácil.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Agenda</label>
                                    <p className="textPriceInfoGray">Consulta y modifica cuándo y dónde atenderas a tus pacientes.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Tu perfil</label>
                                    <p className="textPriceInfoGray">Dinos en qué te especializas, en dónde te pueden encontrar tus pacientes y dónde se realizarán tus
                                        pagos y facturarán tus cobros. Así de sencillo.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Rendimiento</label>
                                    <p className="textPriceInfoGray">Revisa y descarga tus documentos fiscales, en el momento que los necesites.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Cuenta Paciente</label>
                                    <p className="textPriceInfoGray">¿Necesitas registrarte como paciente? Hazlo en Sanapp sin problema.</p>
                                </div>

                                <div>
                                    <label className="" style={{ fontSize: 'x-large' }}>Precio:</label>
                                    <label className="textPriceRaw" style={{ fontSize: 'xx-large' }}>$0</label>
                                </div>

                                <div className='col-12 buttonContainer'>
                                    <button type="button" className="btn primary-btn" disabled={this.state.isPatient === false && this.state.subscriptionType === 'free'}
                                        onClick={() => this.startFlow('free')}>{this.state.isPatient === false && this.state.subscriptionType === 'free' ? 'Subscrito' : 'Regístrate'}</button>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.isPatientFormSelected ?
                        //Patient Basic Form
                        <div className='col-lg-4 col-md-6 col-sm-12 margin-bottom-30px'>
                            <div className='pricingForms'>

                                <h2 className="text title" style={{ marginBottom: 'auto' }}>
                                    <img src={bronzeMedalIcon} alt='BronzeMedalIcon' style={{ height: '35px', marginRight: '5px', verticalAlign: 'bottom' }} />
                                    Básico</h2>
                                <div style={{ height: '50px' }}></div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Almacenamiento ilimitado</label>
                                    <p className="textPriceInfoGray">Disfruta de todos los beneficios del perfil libre y
                                        despreocúpate por el almacenamiento en nuestra nube.</p>
                                </div>
                                <div style={{ height: '235px' }}></div>

                                <div>
                                    <label className="" style={{ fontSize: 'x-large' }}>Precio:</label>
                                    <label className="textPriceRaw" style={{ fontSize: 'xx-large' }}>$149</label>
                                </div>

                                <div className='col-12 buttonContainer'>
                                    <button type="button" className="btn primary-btn" disabled={this.state.isPatient === true && this.state.subscriptionType === 'basic'}
                                        onClick={() => this.startFlow('basic')}>{this.state.isPatient === true && this.state.subscriptionType === 'basic' ? 'Subscrito' : 'Subscríbete'}</button>
                                </div>
                            </div>
                        </div>
                        :
                        //Doctor Basic Form
                        <div className='col-lg-4 col-md-6 col-sm-12 margin-bottom-30px'>
                            <div className='pricingForms'>

                                <h2 className="text title" style={{ marginBottom: 'auto' }}>
                                    <img src={bronzeMedalIcon} alt='BronzeMedalIcon' style={{ height: '35px', marginRight: '5px', verticalAlign: 'bottom' }} />
                                    Básico</h2>
                                <div style={{ height: '50px' }}></div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Mis pacientes</label>
                                    <p className="textPriceInfoGray">Además de disfrutar de los beneficios del perfil libre,
                                        mantén el control de toda la información de tus pacientes.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Perfil Asistente médico</label>
                                    <p className="textPriceInfoGray">¿Sin tiempo para agendar una cita a tu paciente? agrega un perfil de tu asistente y despreocúpate.</p>
                                </div>
                                <div>
                                    <img src={checkinIcon} alt='checkinIcon' style={{ height: '12px', marginRight: '5px' }} />
                                    <label className="text title" style={{ margin: 'auto' }}>Onboarding pacientes y asistentes</label>
                                    <p className="textPriceInfoGray">Cuida de tus pacientes, nosotros hacemos el resto.</p>
                                </div>
                                <div style={{ height: '200px' }}></div>
                                <div>
                                    <label className="" style={{ fontSize: 'x-large' }}>Precio:</label>
                                    <label className="textPriceRaw" style={{ fontSize: 'xx-large' }}>$499</label>
                                    <label className="textPriceRaw" style={{ fontSize: 'x-large' }}>+ IVA al mes</label>
                                </div>

                                <div className='col-12 buttonContainer'>
                                    <button type="button" className="btn primary-btn" disabled={this.state.isPatient === false && this.state.subscriptionType === 'basic'}
                                        onClick={() => this.startFlow('basic')}>{this.state.isPatient === false && this.state.subscriptionType === 'basic' ? 'Subscrito' : 'Subscríbete'}</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='col-lg-4 col-md-6 col-sm-12 margin-bottom-30px'>
                        <div className='pricingForms'>

                            <h2 className="text title" style={{ marginBottom: 'auto' }}>
                                <img src={bronzeMedalIcon} alt='BronzeMedalIcon' style={{ height: '35px', marginRight: '5px', verticalAlign: 'bottom' }} />
                                Especializado</h2>
                            <p className="text title" style={{ marginTop: 'auto' }}></p>
                            <div style={{ height: '100px' }}></div>
                            <div>
                                <img src={loadingCircleIcon} alt='loadingCircleIcon' style={{ height: '40px' }} />

                                <p className="textPriceInfoGray">Proximamente.</p>
                            </div>
                            <div style={{ height: this.state.isPatientFormSelected ? '220px' : '320px' }}></div>
                            <div className='col-12 buttonContainer'>
                                <button type="button" className="btn primary-btn" disabled={true} >Subscríbete</button>
                            </div>
                        </div>
                    </div>

                </div>
                <br /><br /><br />
                <LoginSubscribeFlowControl ref={this.flowRef} history={this.props.history} isPatient={this.state.isPatientFormSelected} subscriptionType={this.state.subscriptionType}>
                </LoginSubscribeFlowControl>
            </div>

        );
    }
}
SubscriptionPrices.contextType = appContext;
export default SubscriptionPrices;