/* eslint-disable indent */

var deferredPrompt;

export function addEventListenerBeforeinstallprompt() {
    window.addEventListener('beforeinstallprompt', (e) => {
        try {
            console.log('addEventListener.beforeinstallprompt: ', e);
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
        }
        catch (ex) {
            console.log('Exception at window.addEventListener.beforeinstallprompt: ', ex)
        }
    });
}

export function prompInstallation() {
    console.log('deferredPrompt: ', deferredPrompt);
    if (deferredPrompt !== undefined) {
        // The user has had a postive interaction with our app and Chrome
        // has tried to prompt previously, so let's show the prompt.
        deferredPrompt.prompt();

        // Follow what the user has done with the prompt.
        deferredPrompt.userChoice.then(function (choiceResult) {

            console.log(choiceResult.outcome);

            if (choiceResult.outcome === 'dismissed') {
                console.log('User cancelled home screen install');
            }
            else {
                console.log('User added to home screen');
            }

            // We no longer need the prompt.  Clear it up.
            deferredPrompt = null;
        });
    }
}