import axios from 'axios'

export async function getData(url, config = {}) {
  try {
    const response = await axios.get(url, config)

    return { status: response.status, data: response.data }

  } catch (error) {
    return { status: (error.response || {}).status, data: error.data }
  }
}

export async function postData(url = '', param = {}, config = {}) {
  try {
      const { response, data } = await axios.post(url, param, config);
      const status = !response ? 200 : response.status;
      const dataValue = !response || !response.data ? data : response.data;
      return { status, data: dataValue };

  } catch (error) {
    return { status: (error.response || {}).status, data: error.data }
  }
}

export async function putData(url = '', param = {}, config = {}) {
  try {
    const response = await axios.put(url, param, config)

    return { status: response.status, data: response.data }

  } catch (error) {
    return { status: (error.response || {}).status, data: error.data }
  }
}

export async function deleteData(url = '', param = {}, config = {}) {
  try {
    const response = await axios.delete(url, param, config)

    return { status: response.status, data: response.data }

  } catch (error) {
    return { status: (error.response || {}).status, data: error.data }
  }
}

export async function getFile(url, fileName) {
  try {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      if (response?.data !== undefined) {
        if(fileName==='') fileName= getFileName(response.headers['content-disposition']);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    });

  } catch (error) {
    return { status: (error.response || {}).status, data: error.data }
  }
}

function getFileName(header){
  var filename = '';
  var disposition = header;
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) { 
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}

