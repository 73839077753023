import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from './components/Common/Layout'
import Home from './components/Home/Home'
import SubscriptionPrices from './components/Home/SubscriptionPrices'
import DrRegister from './components/Dr/Register/DoctorRegister'
import DrValidation from './components/Dr/Register/DoctorValidation'
import DrReview from './components/Dr/Register/DoctorReview'
import { appContext } from './Utils/Context'
import history from './history'
import queryString from 'query-string';
import './Main.scss'
import DrAccountContainer from './components/Dr/Account/DrAccountContainer'
import PatientAccountContainer from './components/Patient/Account/PatientAccountContainer'
import authConfig from './Initialization/auth-config'
import * as pwaShortcutInstall from './Initialization/pwaShortcutInstall';
import Loading from './components/Common/loading/Loading'
import { getData } from './Utils/API'
import { ROLES } from './Utils/Constants';
import prepareEndpoint from './Utils/EndPoints'
import { SecureRoute } from './security/secure-route'
import Geocode from 'react-geocode';
import { MAPS_API_KEY } from './Utils/Constants'
import AppointmentFlowContainer from './components/AppointmentFlow/AppointmentFlowContainer'
import { withRouter } from 'react-router-dom';
import authService from './security/auth-service'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdate: false,
      showHeaderIcons: false,
      showFooter: true,
      validSession: true,
      appInitDone: false,
      isDoctor: true,
      isPatient: false,
      userInfo: {},
      lat: 0,
      lng: 0,
      address: '',
      specialty: '',
      date: new Date(),
      cacheInfo:{},
      showMainIcon: true
    }

    Geocode.setApiKey(MAPS_API_KEY);
    Geocode.setLanguage('es');
    Geocode.setRegion('mx');
    // according to the google docs in description, ROOFTOP param returns the most accurate result.
    Geocode.setLocationType('ROOFTOP');
  }
  componentDidMount() {
    try {
      authConfig.initialize().then(async () => {
        this.initHeaderInputsBehavior();
        await this.checkHealth();
        this.getAddressAndCoordinates();
      });

      pwaShortcutInstall.addEventListenerBeforeinstallprompt();
    } catch (e) {
      console.log('Error at App.js.', e);
    }
    
  }
  getAddressAndCoordinates = () => {
    try {
      const qsValues = queryString.parse(this.props.location.search);
      let { address, specialty } = qsValues;
      this.updateSpecialty(specialty);
      if (address) {
        this.getcoordinatesfromAddress(address);
        this.getPositionFromGeoLocation(this.getAddressFromCoordinates);

      } else {
        this.getPositionFromGeoLocation(this.getAddressFromCoordinatesAndUpdate);
      }
    } catch {
      this.setState({ appInitDone: true });
    }
  }
  getPositionFromGeoLocation = (sucess) => {
    if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(sucess || this.getAddressFromCoordinatesAndUpdate,
            this.errorFromLocalization, { maximumAge: 0, timeout: 10000, enableHighAccuracy: true });
    }
  }
  errorFromLocalization = () => {
    this.setState({ appInitDone: true });
  }
  getcoordinatesfromAddress = async (address) => {
      if (!address || address.length <= 0) {
          this.updateAddressInfo('', null, null);
          return;
      }
    const response = await Geocode.fromAddress(address);
    if(response.status === 'OK'){
      const { lat, lng } = response.results[0].geometry.location;
      this.updateAddressInfo(address, lat, lng);
    }
  }
  getAddressFromCoordinates = (position) => {
    const { latitude, longitude } = position.coords;
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {

        let address = response.results[0].formatted_address;
        var array = address.split(',');
        if (array.length > 0) {
          address = address.replace(`${array[0]}, `, '');
        }
        this.setState({ cacheInfo:{address, lat: latitude, lng: longitude}});
      },
      (error) => {
        this.errorFromLocalization();
      }
    );
  }
  getAddressFromCoordinatesAndUpdate = (position) => {
    const { latitude, longitude } = position.coords;
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {

        let address = response.results[0].formatted_address;
        var array = address.split(',');
        if (array.length > 0) {
          address = address.replace(`${array[0]}, `, '');
        }
        this.updateAddressInfo(address, latitude, longitude);
        this.setState({ cacheInfo:{address, lat: latitude, lng: longitude}});

      },
      (error) => {
        this.errorFromLocalization();
      }
    );
  }
  updateAddressInfo = (address, lat, lng) => {
    this.setState({ address, lat, lng, appInitDone: true });
  }
  updateDoctorId = (doctorId) => {
    this.setState({ doctorId });
  }
  updateDoctorAppointmentInfo = (doctorId, loationId, serviceId) => {
    this.setState({ doctorId, loationId, serviceId });
  }
  componentDidUpdate() {
    let { sessionAvailable } = authConfig;
    let { validSession } = this.state;
    if (validSession !== sessionAvailable) {
      this.setState({ validSession: sessionAvailable });
    }
  }
  checkHealth = async () => {
      if (!authConfig.authToken || authConfig.authToken.length < 5) {
          authConfig.sessionAvailable = false;
          return;
      }
    let url = prepareEndpoint('TokenStatus');
    const response = await getData(url);
    if (response && response.status !== 200) {
      authConfig.sessionAvailable = false;
    }else{
      await authService.roles();
    }

    return Promise.resolve(response);
  };
  initHeaderInputsBehavior = () => {
    const that = this;
    if (history.location.pathname !== '/appointment/doctorInformation') {
      this.setState({ showMainIcon: true });
    }
    if (history.location.pathname !== '/') {
      this.setState({ showHeaderIcons: true });
    }
    history.listen((location, action) => {
      let { showHeaderIcons, showFooter } = that.state
      if (location.pathname !== '/' && showHeaderIcons === false) {
        showHeaderIcons = true;
      } else if (location.pathname === '/' && showHeaderIcons === true) {
        showHeaderIcons = false;
      }
      this.setState({ showHeaderIcons, showFooter });
    });
  }
  updateIsDoctor = (value) => {
    this.setState({ isDoctor: value });
  }
  updateIsPatient = (value) => {
    this.setState({ isPatient: value });
  }
  updateSpecialty = (specialty) => {
    this.setState({ specialty });
  }
  updateDate = (date) => {
    this.setState({ date });
  }
  updateForce = (forceUpdate) => {
    this.setState({ forceUpdate });
  }
  updateHeaderIcons = (showHeaderIcons) => {
    this.setState({ showHeaderIcons });
  }
  updateMainIcon = (showMainIcon) => {
    this.setState({ showMainIcon });
  }
  render() {
    const appComponent = this.state.appInitDone ? (
      <appContext.Provider
        value={{
          state: this.state,
          updateIsDoctor: this.updateIsDoctor,
          updateIsPatient: this.updateIsPatient,
          updateLocation: this.updateLocation,
          getcoordinatesfromAddress: this.getcoordinatesfromAddress,
          updateSpecialty: this.updateSpecialty,
          updateDoctorAppointmentInfo: this.updateDoctorAppointmentInfo,
          updateDate: this.updateDate,
          getPositionFromGeoLocation: this.getPositionFromGeoLocation,
          updateForce: this.updateForce,
          updateHeaderIcons: this.updateHeaderIcons,
          updateMainIcon: this.updateMainIcon
        }}>
        <Layout
          footer={this.state.showFooter}
        >
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/SubscriptionPrices' component={SubscriptionPrices} />
            <Route path='/drregister' component={DrRegister} />
            <SecureRoute path='/drvalidation' component={DrValidation} authorize={[ROLES.Admin]} />
            <SecureRoute path='/drreview' component={DrReview} authorize={[ROLES.Admin]} />
            <SecureRoute path='/doctoraccount/:page' authorize={[ROLES.Doctor, ROLES.Assistant]} component={DrAccountContainer} />
            <SecureRoute path='/patient/:page' component={PatientAccountContainer} />
            <Route path='/appointment/:page' component={AppointmentFlowContainer} />
          </Switch>
        </Layout>
      </appContext.Provider>
    ) : (<Loading height='100px' width='100px' />)
    return appComponent
  }
}
export default withRouter(App)