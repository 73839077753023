export const LOCATION_TYPES = ['Hospital', 'Clínica', 'Consultorio']
export const BANKS = [
  'BANAMEX',
  'SERFIN',
  'ATLÁNTICO',
  'CITIBANK',
  'UNIÓN',
  'BBVA BANCOMER',
  'INDUSTRIAL',
  'SANTANDER',
  'INTERBANCO',
  'BBVA SERVICIOS',
  'HSBC',
  'GE MONEY',
  'SURESTE',
  'CAPITAL',
  'BAJÍO',
  'IXE',
  'INBURSA',
  'INTERACCIONES',
  'MIFEL',
  'SCOTIABANK',
  'PRONORTE',
  'QUADRUM',
  'BANREGIO',
  'INVEX',
  'BANSI',
  'AFIRME',
  'ANÁHUAC',
  'PROMEX',
  'BANPAÍS',
  'BANORTE/IXE',
  'ORIENTE',
  'BANCEN',
  'CREMI',
  'INVESTA BANK',
  'AZTECA',
  'AUTOFIN',
  'COMPARTAMOS',
  'BANCO FAMSA',
  'BANCO MULTIVA',
  'BM ACTINVER',
  'WAL-MART',
  'INTERCAM BANCO',
  'BANCOPPEL',
  'ABC CAPITAL'
]
export const MAPS_API_KEY = 'AIzaSyAsMrBm2K65YlqN3A7zOm1lPLwDCihx3_w';
export const ROLES = {
  None: 0,
  Doctor: 1,
  Patient: 2,
  DoctorAndPatient: 3, 
  Assistant: 1 << 2,//4
  AccessServices: 1 << 3,//8
  AccessAgenda: 1 << 4,//16
  AccessPatients: 1 << 5,//32
  AccessDrProfile: 1 << 6,//64
  AccessPatientProfile: 1 << 7,//128
  Admin: 1 << 8,//256
}

export const NOTIFICATIONS = {
  None: 0,
  New: 1,
  Reminder: 2,
  Modify: 1 << 2,//4
  Appointment: 1 << 3,//8
  Payment: 1 << 4,//16
  CheckIn: 1 << 5,//32
  Review: 1 << 6,//64
}