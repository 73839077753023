import React, { Component } from 'react';
import BasicInput from '../Common/inputs/BasicInput';
import BasicRadio from '../Common/inputs/BasicRadio';

export default class MedicalDetailedQuestion extends Component {
  render() {
    return (
      <><div className={`${this.props.classes || 'col-12 col-md-5 col-lg-6 pl-0'} form-group`}>
        <label className='text light'>{this.props.label}</label>
      </div><div className='col-auto px-2'>
        <BasicRadio radioValue={true} radioGroup={`${this.props.object}_${this.props.field}`} isChecked={this.props.isChecked === true} onChangeFxn={e=>this.props.onChange(this.props.object, this.props.field, e, this.props.showInput|this.props.isMedical)}/>
      </div><div className='col-auto px-2'>
        <BasicRadio radioValue={false} radioGroup={`${this.props.object}_${this.props.field}`} isChecked={this.props.isChecked === false} onChangeFxn={e=>this.props.onChange(this.props.object, this.props.field, e, this.props.showInput|this.props.isMedical)} />
      </div>
      <BasicInput classes='col' basicVal={this.props.detail} onChangeFxn={val=>this.props.onChangeInput(this.props.object, this.props.field, val)}></BasicInput>
      </>
    );
  }
}
