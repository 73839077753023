import React, { Component } from 'react';
import BasicInput from '../Common/inputs/BasicInput';
import authService from '../../security/auth-service';
import Loading from '../Common/loading/Loading';

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      error: '',
      loading:false
    };
  }
  setLoading = value =>{
    this.setState({loading:value});
  }
  componentDidMount() {}

  setError = error => {
    this.setState({ error: error });
  };

  isFormValid = () => {
    const { newPassword, confirmPassword } = this.state;
    if (
      !newPassword ||
      !confirmPassword ||
      newPassword.trim() === '' ||
      confirmPassword.trim() === ''
    ) {
      this.setError('Las contraseñas no pueden estar vacias');
      return false;
    }

    if (newPassword !== confirmPassword) {
      this.setError('Las contraseñas no coinciden');
      return false;
    }

    return true;
  };

  setNewPassword = value => {
    this.setError('');
    this.setState({ newPassword: value });
  };

  setConfirmPassword = value => {
    this.setError('');
    this.setState({ confirmPassword: value });
  };

  onContinue = async () => {
    this.setLoading(true);
    if (!this.isFormValid()) {
      this.setLoading(false);
      return;
    }
    if (
      !(await authService.changePassword(
        this.props.email,
        this.props.validationCode,
        this.state.newPassword,
        this.state.confirmPassword
      ))
    ) {
      this.setError('Por favor verifique que los datos sean correctos');
      this.setLoading(false);
      return;
    }
    const response = await authService.logIn(
      {email:this.props.email,
        password:this.state.newPassword}
    );
    if (
      response.status !== 200 ||
      !response.data ||
      response.data.length <= 0
    ) {
      this.setError('No pudimos iniciar sesión, intenta iniciando con la nueva contraseña.');
    } else if (this.props.onContinue) {
      this.props.onContinue();
    }
    this.setLoading(false);
  };

  render() {
    return (
      <div className="change-password-container">
        {this.state.loading && <Loading />}
        <div className="row pb-2 d-flex justify-content-center">
          <h2 className="text-center">Crea tu nueva contraseña</h2>
        </div>
        <div className="row d-flex justify-content-center px-2">
          <label className="text-center">
            Te solicitaremos esta contraseña cada vez que inicies sesión
          </label>
        </div>
        <div className="row  d-flex justify-content-center mt-3">
          <label className="text">Nueva contraseña</label>
        </div>
        <div className="row d-flex justify-content-center">
          <BasicInput
            classes="col-md-10 px-0"
            onChangeFxn={val => this.setNewPassword(val)}
            maxLength={50}
            type='password'
            basicVal={this.state.newPassword}
          ></BasicInput>
        </div>
        <div className="row  d-flex justify-content-center">
          <label className="text">Vuelve a escribir la contraseña</label>
        </div>
        <div className="row d-flex justify-content-center">
          <BasicInput
            classes="col-md-10 px-0"
            onChangeFxn={val => this.setConfirmPassword(val)}
            maxLength={50}
            type='password'
            basicVal={this.state.confirmPassword}
          ></BasicInput>
        </div>
        <div
          className={
            this.state.error !== ''
              ? 'row errorContainer f-flex justify-content-center'
              : 'hidden'
          }
        >
          <p>{this.state.error}</p>
        </div>
        <div className="row justify-content-center mt-3">
          <button className="btn primary-btn" onClick={() => this.onContinue()}>
            Guardar cambios e iniciar sesión
          </button>
        </div>
      </div>
    );
  }
}
