import React, { Component } from 'react';
import '../ConciliationComponents/Conciliation.scss'

export default class IconButton extends Component {
    clickFn=()=>{
      if(this.props.enabled)
        return
      this.props.clickFn &&
        this.props.clickFn();      
    }
    render(){
      return(
        <div className={'row icon-container d-flex align-items-center justify-content-center' }>
          <div className='col-auto'>
            <div className='row d-flex align-items-center justify-content-center'>
              <img src={this.props.icon} alt='documentIcon' className={`icon ${(!this.props.enabled? 'inactive':'active')}`} onClick={()=>this.clickFn()}></img>
            </div>
            <div className='row d-flex align-items-center justify-content-center'>
              <label className={`icon-text ${(!this.props.enabled? 'inactive':'active')}` } onClick={()=>this.clickFn()}>{this.props.label}</label>
            </div>
          </div>
        </div>
      );
    }
}