import React from 'react'

export default  function SortIcons(props){
  function clickIcons(){
    if(props.onClickUp){
      props.onClickUp();
    }
  }
  function clickIconsDown(){
    if(props.onClickDown){
      props.onClickDown();
    }
  }
  return (
    <span className='sort-icons' >
      <i className='fas fa-chevron-up' onClick={clickIcons}></i>
      <i className='fas fa-chevron-down'  onClick={clickIconsDown}></i>
    </span>   
  )
  
}
