import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import './ScheduleHours.scss'
import { CREATE_ID } from '../../../Utils/Generic';
import prepareEndpoint from '../../../Utils/EndPoints';
import { getData, putData } from '../../../Utils/API';
import Loading from '../../Common/loading/Loading'
import configIcon from '../../../Assets/icons/Configuracion.svg'
import BasicSelect from '../../Common/inputs/BasicSelect';

const localizer = momentLocalizer(moment)
let allViews = ['week']
export default class ScheduleHours extends Component {

  constructor(props) {
    super(props)
    this.state = {
      events: [],
      selectedLocation: {
        value: '',
        key: ''
      },
      locations: [],
      schedule: [],
      loading: false
    }
    this.view = 'week';
    this.id = null;
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getHours();
    const locations = await this.getLocations();
    if (locations.length > 0) {
      let selectedLocation = locations[0];
      this.setState({ selectedLocation });
      this.updateCalendar(selectedLocation);
    }
    this.setState({ loading: false });
  }
  getHours = async () => {
    let url = prepareEndpoint('Schedules');
    const response = await getData(url);
    if (response && response.status === 200) {
      const data = response.data;
      let schedule = [];
      data.forEach(d => {
        schedule.push(d)
      })
      this.setState({ schedule })
    }
  };
  getLocations = async () => {
    const url = prepareEndpoint('DoctorLocations');
    const response = await getData(url);
    let locations = [];

    if (response && response.status === 200) {
      for (var i = 0; i < response.data.length; i++) {
        let location = {
          key: response.data[i].id,
          value: response.data[i].name || ' '
        };
        locations.push(location);
      }
      await this.setState({ locations })
    }
    return locations;
  }
  updateCalendar = (selectedLocation) => {
    const scheduled = this.state.schedule.find(e => selectedLocation && selectedLocation.key === e.locationId);
    let events = [];
    if (scheduled) {
      let d = new Date();
      let dayDate = d.getDay();
      let diff = d.getDate() - dayDate + (dayDate === 0 ? -6 : 1);
      let startDate = new Date(d.setDate(diff));
      let endDate = new Date(startDate);
      let day;
      //from monday to Saturday (dayOfWeek 1 to 6)
      for (let i = 1; i < 7; i++) {
        day = scheduled.daysAvailability.find(e => i === e.dayOfWeek);
        if (day) {
          // eslint-disable-next-line no-loop-func
          day.times.forEach(e => {
            let time = e.start.split(':');
            startDate.setHours(time[0]); startDate.setMinutes(time[1]); startDate.setSeconds(0);
            let start = startDate
            time = e.end.split(':');
            endDate.setHours(time[0]); endDate.setMinutes(time[1]); endDate.setSeconds(0);
            let end = endDate;
            events.push(
              {
                id: CREATE_ID(),
                start: new Date(start),
                end: new Date(end)
              },
            )

          });
        }
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
      }
      //for last day (Sunday) in spanish but first in english (0)
      day = scheduled.daysAvailability.find(e => 0 === e.dayOfWeek);
      if (day) {
        // eslint-disable-next-line no-loop-func
        day.times.forEach(e => {
          let time = e.start.split(':');
          startDate.setHours(time[0]); startDate.setMinutes(time[1]); startDate.setSeconds(0);
          let start = startDate

          time = e.end.split(':');
          endDate.setHours(time[0]); endDate.setMinutes(time[1]); endDate.setSeconds(0);
          let end = endDate;
          events.push(
            {
              id: CREATE_ID(),
              start: new Date(start),
              end: new Date(end)
            },
          )

        });
      }
    }
    this.setState({ events })
  }
  changeLocation = selectedLocation => {
    this.setState({ selectedLocation });
    this.updateCalendar(selectedLocation);
  }
  event = ({ event }) => {
    return '';
  }
  CustomToolbar = (toolbar) => {
    return <span></span>;
  };
  handleSelectSlot = ({ start, end }) => {
    const events = [...this.state.events];
    let toRemove = []
    events.forEach(e => {
      if ((start < e.start || start.getTime() === e.start.getTime()) && end > e.start) {
        if (end < e.end || end.getTime() === e.end.getTime()) {
          end = e.end
        }
        toRemove.push(e.id);
      } else if (end > e.end && start < e.end) {
        if (start > e.start || start.getTime() === e.start.getTime()) {
          start = e.start
        }
        toRemove.push(e.id);
      }
    })
    toRemove.forEach(r => {
      let index = events.findIndex(e => e.id === r);
      if (index >= 0) {
        events.splice(index, 1);
      }
    })
    this.setState({
      events: [
        ...events,
        {
          id: CREATE_ID(),
          start,
          end
        },
      ],
    })
  }
  onSelectFullday = (start) => {
    let end = new Date(start);
    start.setHours(6, 0, 0)
    end.setHours(22, 0, 0);
    this.setState({
      events: [
        ...this.state.events,
        {
          id: CREATE_ID(),
          start,
          end,
        },
      ],
    })
  }
  handleSelectEvent = (event) => {
    let id = event.id;
    let events = [...this.state.events];
    let pos = events.findIndex(e => e.id === id)
    events.splice(pos, 1);
    this.setState({ events });
  }
  goToCalendar = () => {
    this.props.history.push('/doctoraccount/agenda')
  }
  saveHours = async () => {
    this.setState({ loading: true });
    const url = prepareEndpoint('Schedules');
    const { selectedLocation } = this.state
    let daysAvailability = [];
    const events = this.state.events.slice().sort((a, b) => a.start - b.start)
    events.forEach(e => {
      const start = e.start;
      const end = e.end;
      const dayOfWeek = start.getDay();
      let dayIndex = daysAvailability.findIndex(d => { return parseInt(d.dayOfWeek) === parseInt(dayOfWeek) })

      const startMinutes = start.getMinutes();
      const startMinutesFormatted = startMinutes === 0 ? '00' : startMinutes.toString();
      const endMinutes = end.getMinutes();
      const endMinutesFormatted = endMinutes === 0 ? '00' : endMinutes.toString();

      if (dayIndex !== -1) {
        daysAvailability[dayIndex].times.push({
          start: `${start.getHours()}:${startMinutesFormatted}`,
          end: `${end.getHours()}:${endMinutesFormatted}`
        })
      } else {
        daysAvailability.push({
          dayOfWeek,
          times: [
            {
              start: `${start.getHours()}:${startMinutesFormatted}`,
              end: `${end.getHours()}:${endMinutesFormatted}`
            }
          ]
        })
      }
    })
    let data = {
      locationId: selectedLocation.key,
      daysAvailability
    };
    await putData(url, data);

    await this.getHours();

    this.setState({ loading: false });
  }
  render() {
    return (
      <div className='doctor-container config background-light'>
        {this.state.loading && <Loading />}
        <div className='config' onClick={this.goToCalendar}>
          <img src={configIcon} className='configIcon' alt='configuracion' /> <span className='options-text text'>Configuración</span>
        </div>
        <p>Aquí puedes seleccionar los días y horarios en que estarás disponible para dar tus consultas y servicios</p>
        <div className='row calendar-container'>
          <div className='col-12 col-lg-7 calendar'>
            <Calendar
              culture={'es'}
              selectable={'ignoreEvents'}
              localizer={localizer}
              views={allViews}
              step={60}
              timeslots={1}
              events={this.state.events}
              formats={
                {
                  dayFormat: 'ddd'
                }
              }
              style={{ height: 650, width: '95%' }}
              components={{
                // event: this.event,
                toolbar: this.CustomToolbar,
                // month:{
                //   event: this.eventMonth
                // }
              }}
              onSelectSlot={this.handleSelectSlot}
              onDrillDown={this.onSelectFullday}
              onSelectEvent={this.handleSelectEvent}
              defaultView={this.view}
              popup={true}
              min={new Date(0, 0, 0, 6, 0, 0)}
              max={new Date(0, 0, 0, 22, 0, 0)}
            />
          </div>
          <div className='col-12 col-lg-5'>
            <div className='options row col-12'>
              <div className='col-12'>Ubicación</div>
              <div className='col-12 col-lg-7'>
                <BasicSelect
                  customOptions={this.state.locations}
                  selectedItem={this.state.selectedLocation && this.state.selectedLocation.value ? this.state.selectedLocation.value : ''}
                  onChangeFxn={this.changeLocation}
                />
              </div>
              <div className='col-12 col-lg-5'>
                <button className='btn primary-btn' onClick={this.saveHours}> Guardar</button>
              </div>
            </div>

          </div>
        </div>

      </div>
    )
  }
}
