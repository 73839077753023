import React, { Component } from 'react'
import Modal from './Modal';
import './Modal.scss';
import './PrescriptionModal.scss'

export default class PrescriptionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prescription: {
                file: null,
                fromSelector: false,
            }
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <Modal
                className='prescriptionModal'
                show={this.props.show}
                modalClosed={this.props.closePrescriptionModal}
                appointmentId={this.props.appointmentId}
                closeIcon={true}

            >
                <h3 className='text-center'>Receta</h3>

                {this.props.file ?
                    <div className='d-flex justify-content-center img-wrapper'>
                        <img alt='' src={`data:image/jpeg;base64,${this.props.file}`} />
                    </div>
                    :
                    <div className='scroll bkg-white'>
                        <div className='text-center'>
                            <span>Ningun receta cargada</span>
                        </div>
                    </div>
                }

            </Modal>
        );
    }
}