import './ShareMedicalHistory.scss'
import React, { Component } from 'react';
import { Checkbox } from 'pretty-checkbox-react';
import icono_flecha from '../../Assets/icons/Flecha.svg';
import prepareEndpoint from '../../Utils/EndPoints';
import Loading from '../Common/loading/Loading';
import BasicModal from '../Common/modal/BasicModal'
import SignModal from '../Common/modal/SignModal';
import { getData, postData } from '../../Utils/API';
import folder1 from '../../Assets/icons/Expediente medico-carpeta.svg'
import folder2 from '../../Assets/icons/Expediente medico-carpeta 2.svg'
import fileIcon from '../../Assets/icons/File-Sheet.svg';
import BasicSelect from '../Common/inputs/BasicSelect';

export default class ShareMedicalHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctors: [{ key: '', value: 'Selecciona un nombre' }],
      selectedDoctorId: '',
      showSignatureConfirmModal: false,
      rootfolders: [
        {
          name: '',
          description: '',
          isShared: false,
          totalChecked: 0,
          subFolders: [
            {
              name: '',
              description: '',
              isShared: false,
              totalChecked: 0,
              medicalStudies: [
                {
                  isShared: false,
                  name: '',
                  date: new Date(),
                  description: '',
                  fileImageName: '',
                  path: ''
                }
              ]
            }
          ]
        }
      ],
      folderSelected: '',
      subfolderSelected: '',
      foldersSelected: [
        {
          name: '',
          description: '',
          medicalStudies: [
            {
              name: '',
              date: new Date(),
              description: '',
              fileImageName: '',
              path: ''
            }
          ]
        }
      ],
      showSignatureModal: false,
      showSelectDoctorModal: false
    };
    this.fileInputRef = React.createRef();
  }

  async componentDidMount() {
    try {
      await this.SetDoctors();
    } catch (e) {
      console.log('Mount error: ', e)
    }

    this.setState({ loading: false });
  }

  async SetDoctors() {
    const doctors = await this.GetDoctorsFromApi();
    if (doctors) {
      this.setState({ doctors })
    }
  }

  async SetFolders(doctorId) {
    const details = await this.GetMedicalFile(doctorId);
    this.setState({ rootfolders: details.rootfolders })
  }

  async GetDoctorsFromApi() {
    const url = `${prepareEndpoint('NameIdAndSpecialty')}`;
    const resp = await getData(url);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      const { data } = resp;
      const doctors = data.map(d => {
        return {
          key: d.id, value: d.fullName + ' | ' + d.specialty
        }
      });
      return doctors;
    }
  }

    goToMedical = () => {
      this.props.history.push('/patient/medical')
    }

    async GetMedicalFile(doctorId) {
      if (!doctorId) {
        this.setState({showSelectDoctorModal:true})
        return;
      }

      const url = `${prepareEndpoint('MedicalHistory')}/Files/Shared?doctorId=${doctorId}`;
      const resp = await getData(url);
      let details = {
        rootfolders: []
      };

      if (resp.status !== 200) {
        return details;
      }
      details.rootfolders = resp.data.rootFolders.map(r => {
        return {
          name: r.name,
          description: r.description,
          isShared: r.isShared,
          subFolders: r.subFolders.map(sf => {
            return {
              name: sf.name,
              description: sf.description,
              isShared: sf.isShared,
              medicalStudies: sf.medicalStudies.map(m => {
                return {
                  name: m.name,
                  date: new Date(m.date),
                  description: m.description,
                  isShared: m.isShared,
                  fileImageName: m.fileImageName,
                  path: m.path
                }
              })
            }
          })
        }
      });
      for (var i = 0; i < details.rootfolders.length; i++) {
        this.setTotalsChecked(details.rootfolders[i]);
      }
      return details;
    }

    async updateSelectedDoctorId(doctorId) {
      if (!doctorId) {
        alert('Seleccione un doctor')
        return;
      }
      await this.setState({ loading: true, selectedDoctorId: doctorId });
      try {
        await this.SetFolders(doctorId);
      } catch (e) {
        console.log('Error at updateSelectedDoctorId: ', e);
      }
      this.setState({ loading: false });
    }

    updateFoldersSelected = (value) => {
      let folderSelected = this.state.rootfolders.find(x => x.name === value);
      if (folderSelected) {
        this.setState({ foldersSelected: folderSelected.subFolders });
      }
    }

    selectRootFolder = (folder) => {
      if (this.state.folderSelected === folder) {
        this.setState({ folderSelected: '', foldersSelected: [] });
        return;
      }
      this.setState({ folderSelected: folder });
      this.updateFoldersSelected(folder);
      this.updateState('', 'subfolderSelected');
    }

    checkRootFolder = (folder) => {
      if (!folder || !this.state.rootfolders) {
        return;
      }

      let rootfolders = this.state.rootfolders;
      let folderSelectedIndex = rootfolders.findIndex(x => x.name === folder);
      if (folderSelectedIndex !== -1) {
        const checkedValue = !rootfolders[folderSelectedIndex].isShared;
        rootfolders[folderSelectedIndex].isShared = checkedValue;
        for (var i = 0; i < rootfolders[folderSelectedIndex].subFolders.length; i++) {
          rootfolders[folderSelectedIndex].subFolders[i].isShared = checkedValue;
          for (var j = 0; j < rootfolders[folderSelectedIndex].subFolders[i].medicalStudies.length; j++) {
            rootfolders[folderSelectedIndex].subFolders[i].medicalStudies[j].isShared = checkedValue;
          }
        }
        this.setTotalsChecked(rootfolders[folderSelectedIndex]);
        this.setState({ rootfolders });
      }
    }

    checkSubFolder = (subfolderName, rootfolderName) => {
      if (!subfolderName || !rootfolderName) {
        return;
      }

      let rootfolders = this.state.rootfolders;
      let folderSelectedIndex = rootfolders.findIndex(x => x.name === rootfolderName);
      const subfolderIndex = rootfolders[folderSelectedIndex].subFolders.findIndex(x => x.name === subfolderName);
      if (subfolderIndex !== -1) {
        const checkedValue = !rootfolders[folderSelectedIndex].subFolders[subfolderIndex].isShared;
        rootfolders[folderSelectedIndex].subFolders[subfolderIndex].isShared = checkedValue;
        for (var j = 0; j < rootfolders[folderSelectedIndex].subFolders[subfolderIndex].medicalStudies.length; j++) {
          rootfolders[folderSelectedIndex].subFolders[subfolderIndex].medicalStudies[j].isShared = checkedValue;
        }
        this.setTotalsChecked(rootfolders[folderSelectedIndex]);
        this.setState({ rootfolders });
      }
    }

    checkMedicalStudy = (medicalStudyName, subfolderName, rootfolderName) => {
      if (!medicalStudyName || !rootfolderName) {
        return;
      }

      let rootfolders = this.state.rootfolders;
      let folderSelectedIndex = rootfolders.findIndex(x => x.name === rootfolderName);
      const subfolderIndex = rootfolders[folderSelectedIndex].subFolders.findIndex(x => x.name === subfolderName);
      if (subfolderIndex !== -1) {
        const medicalStudyIndex = rootfolders[folderSelectedIndex].subFolders[subfolderIndex].medicalStudies.findIndex(m => m.name === medicalStudyName);
        if (medicalStudyIndex !== -1) {
          const checkedValue = !rootfolders[folderSelectedIndex].subFolders[subfolderIndex].medicalStudies[medicalStudyIndex].isShared;
          rootfolders[folderSelectedIndex].subFolders[subfolderIndex].medicalStudies[medicalStudyIndex].isShared = checkedValue;
          this.setTotalsChecked(rootfolders[folderSelectedIndex]);
          this.setState({ rootfolders });
        }
      }
    }

    setTotalsChecked = (rootfolder) => {
      if (!rootfolder || !rootfolder.subFolders) {
        return;
      }

      let rootTotalChecked = 0;
      for (var i = 0; i < rootfolder.subFolders.length; i++) {
        if (!rootfolder.subFolders[i].medicalStudies) {
          continue;
        }
        let subTotalChecked = 0;
        for (var j = 0; j < rootfolder.subFolders[i].medicalStudies.length; j++) {
          subTotalChecked += rootfolder.subFolders[i].medicalStudies[j].isShared ? 1 : 0;
        }
        rootfolder.subFolders[i].isShared = subTotalChecked > 0;
        rootfolder.subFolders[i].totalChecked = subTotalChecked;
        rootTotalChecked += subTotalChecked;
      }
      rootfolder.isShared = rootTotalChecked > 0;
      rootfolder.totalChecked = rootTotalChecked;
    }

    getCurrentDoctor() {
      if (!this.state.selectedDoctorId || !this.state.doctors) {
        return null;
      }
      const doc = this.state.doctors.find(d => d.key === this.state.selectedDoctorId);
      if (doc && doc.value) {
        return doc.value.split('|')[0];
      }
      return null;
    }

    updateState = (val, field) => {
      if (field === 'subfolderSelected') {
        const currentSubfolderSelected = this.state['subfolderSelected'];
        if (val === currentSubfolderSelected) {
          val = '';
        }
      }

      this.setState({ [field]: val });
    }

    uploadFile = async (img) => {
      this.setState({ loading: true });
      try {
        if (!this.state.selectedDoctorId || !img) {
          this.setState({ selectFileModalShow: true})
          return;
        }

        let resp = await this.postShareMedicalFile();
        if (resp && resp.status !== 200) {
          this.setState({ showBasicError: true });
          return;
        }

        let formData = new FormData();
        formData.append('formFile', img);
        const url = `${prepareEndpoint('MedicalHistory')}/Files/ShareSignature`;
        resp = await postData(url, { img: img });
        if (!resp || resp.status !== 200) {
          this.setState({ showBasicError: true });
          return;
        }

        this.setState({ showSignatureModal: false, showSignatureConfirmModal: true });

      } catch (e) {
        console.log('Error at uploadFile: ', e)
      } finally {
        this.setState({ loading: false });
      }

    }

    postShareMedicalFile = async () => {
      try {
        const { rootfolders, selectedDoctorId } = this.state;
        const url = `${prepareEndpoint('MedicalHistory')}/Files/ShareDetails`;
        const resp = await postData(url, { rootfolders, doctorId: selectedDoctorId });
        return resp;
      } catch (e) {
        console.log('Error at postShareMedicalFile: ', e);
      }
    }

    formatDoctorOptions(option) {
      const val = option.value ? option.value : option;
      const values = val.split('|');
      const val1 = values[0];
      const val2 = values.length > 1 ? values[1] : '';
      return (<p className='text'><strong>{val1}</strong> | {val2}</p>);
    }

    getFolderSelectedDetails = (folder, rootfolderName) => {
      const displayOnlyFiles = rootfolderName === 'Historial Clínico';
      return (
        <div key={folder.name} className='col-12 col-md-10 col-lg-10 pr-0 pl-0'>
          {!displayOnlyFiles &&
                    <div>
                      <div className='col-1'></div>
                      <div className='col-6'>
                        <div className={`folders clinical-link ${this.state.subfolderSelected === folder.name ? 'selected' : ''}`}
                          onClick={e => { this.updateState(folder.name, 'subfolderSelected'); }}>
                          <Checkbox checked={folder.isShared} onChange={() => {
                            this.checkSubFolder(folder.name, rootfolderName);
                          }} ></Checkbox>
                          <img src={this.state.subfolderSelected === folder.name ? folder2 : folder1} alt='blocked' />
                          <label className='text'> {folder.name} </label>
                          <label className='text'>&nbsp;{!folder.totalChecked || folder.totalChecked === 0 ? '' : '(' + folder.totalChecked + ')'} </label>
                        </div>
                      </div>
                    </div>
          }
          <div>
            {folder.medicalStudies?.map(m => {
              if (this.state.subfolderSelected !== folder.name && !displayOnlyFiles) {
                return null;
              }
              return (
                <div key={m.name}>
                  <div className='col-1'></div>
                  <div className='col-6'>
                    <div className={this.state.subfolderSelected !== folder.name && !displayOnlyFiles ? 'hidden' : 'pl-3'}>
                      <Checkbox checked={m.isShared}
                        onChange={() => {
                          this.checkMedicalStudy(m.name, folder.name, rootfolderName);
                        }} >
                        <img src={fileIcon} style={{ height: '15px', width: '15px' }} alt='medical file' />
                        {m.name}
                      </Checkbox>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      );
    }

    getFoldersView = () => {
      return (
        <div>
          <div className='row'>
            <label className='col-12 text'>Información a compartir</label>
          </div>
          <div className="row">
            <div className="col">
              {this.state.rootfolders &&
                            this.state.rootfolders.map(rootfolder => {
                              return (
                                <div key={rootfolder.name} className={`clinical-link ${this.state.folderSelected === rootfolder.name ? 'active' : ''}`} >
                                  <div className='folders row'>
                                    <div className='col-1'>
                                      <div className='row'>
                                        <div className='col-6'>
                                          <Checkbox id='rootfolderId' onChange={e => this.checkRootFolder(rootfolder.name, e)} checked={rootfolder.isShared} >
                                          </Checkbox>
                                        </div>
                                        <div className='icon-folder col-6' onClick={e => this.selectRootFolder(rootfolder.name)}>
                                          <img src={this.state.folderSelected === rootfolder.name ? folder2 : folder1} alt='blocked' />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='icon-text col-9' onClick={e => this.selectRootFolder(rootfolder.name)}>
                                      <label className='text'> {rootfolder.name + ' '} </label>
                                      <label className='text'>&nbsp;{!rootfolder.totalChecked || rootfolder.totalChecked === 0 ? '' : '(' + rootfolder.totalChecked + ')'} </label>
                                    </div>
                                    <div className='icon-text col-1'>
                                    </div>
                                  </div>
                                  <div>
                                    {this.state.folderSelected === rootfolder.name &&
                                                rootfolder.subFolders.map(subfolder => {
                                                  return this.getFolderSelectedDetails(subfolder, rootfolder.name);
                                                })
                                    }
                                  </div>
                                </div>
                              )
                            })}
            </div>
          </div>
          <div className='row' style={{ justifyContent: 'center' }}>
            <div className='text-center'>
              <button type='button' className='btn primary-btn' onClick={e => this.setState({ showSignatureModal: true })} >Guardar compartido de archivos</button>
            </div>
          </div>
        </div>
      );
    }

    confirmationModal = () => {
      return (
        <SignModal modalClosed={() => { this.setState({ showSignatureModal: false }) }} show={this.state.showSignatureModal}
          onChange={this.uploadFile} title='Compartir informacion'
          initialText={'Registra tu firma digital para otorgar al Dr. ' + this.getCurrentDoctor() + ' tu autorización para la lectura de la información que previamente seleccionaste'} confirmButtonText='Compartir'>
        </SignModal>
            
      );
    }

    render() {
      return (
        <div className='history-container background-light'>
          {this.state.loading && <Loading />}
          <div className=''>
            <div className='clickable'>
              <img src={icono_flecha} className='configIcon pr-2' alt='configuracion' onClick={this.goToMedical} />
              <label className='text clickable' onClick={this.goToMedical}>Compartir información<span className='text light clickable'></span></label>
            </div>

            <div className='row'>
              <label className='col-12 text'>Instrucciones</label>
              <label className='col-12'><strong className='text'>1 </strong> Selecciona el Doctor a quién le permitirás el acceso a tu información</label>
              <label className='col-12'><strong className='text'>2 </strong> Selecciona la información que compartirás o dejarás de compartir y da clic en el botón correspondiente</label>
              <div className='col-12' >
                <div className='row' >
                  <label className='col-4 text-align-end' style={{ textAlign: 'end' }}>Doctor</label>
                  <BasicSelect classes='col-6'
                    customOptions={this.state.doctors}
                    customOptionsFormatter={this.formatDoctorOptions}
                    selectedItem={this.state.doctors.find(d => d.key === this.state.selectedDoctorId)?.value}
                    onChangeFxn={val => { this.updateSelectedDoctorId(val.key) }}
                    placeholder='Selecciona un nombre'
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.selectedDoctorId && this.getFoldersView()}

          {this.confirmationModal()}

          <BasicModal
            className='additional-modal'
            show={this.state.showSignatureConfirmModal}
            modalClosed={() => this.setState({ showSignatureConfirmModal: false })}
            title='Archivos compartidos exitosamente!'>
          </BasicModal>

          <BasicModal
            className='additional-modal'
            show={this.state.selectFileModalShow}
            modalClosed={() => this.setState({ selectFileModalShow: false })}
            title='Favor de seleccionar un archivo'>
          </BasicModal>
          <BasicModal
            className='additional-modal'
            show={this.state.showSelectDoctorModal}
            modalClosed={() => this.setState({ showSelectDoctorModal: false })}
            title='Favor de seleccionar un doctor'>
          </BasicModal>
          <BasicModal
            className='additional-modal'
            show={this.state.showBasicError}
            modalClosed={() => this.setState({ showBasicError: false })}
            title='No se pudo compartir la informacion, por favor intente de nuevo.'>
          </BasicModal>
        </div>
      );
    }
}
