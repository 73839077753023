import React from 'react';

import Modal from './Modal';
import './Modal.scss';
import done from '../../../Assets/icons/Reservacion-listo.svg'

const SuccessModal = props => {
  return (
    <Modal
      show={props.show}
      modalClosed={props.closeFnx}
      closeIcon={true}
      className={'text-center success-modal'}
    >
      <div>
        <h3>¡Tu registro ha sido exitoso!</h3>
      </div>
      <div>
        <img src={done} alt='sucess' />
      </div>
      <div>
        <button onClick={props.btnFnx} className='btn primary-btn'>{props.btnTxt || 'Aceptar'}</button>
      </div>

      {props.showLabelMessage && <div className='messages'>
        <label>
          {props.labelMessage}
        </label>
      </div>
      }
    </Modal>
  );
};

export default SuccessModal;
