import React, { Component } from 'react';
import './AppointmentConfirmation.scss'
import doctorIcon from '../../../Assets/icons/doctor.svg'
import calendarIcon from '../../../Assets/icons/popup-calendar.svg'
import localizationIcon from '../../../Assets/icons/popup-localization.svg'
import priceIcon from '../../../Assets/icons/popup-price.svg'
import AppointmentRow from './AppointmentRow'

export default class AppointmentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorInformation: {
        firstName: '',
        lastName: ''
      },
      location: {
        name: 'Hospital Sedna',
        streetAndNumber: 'Periferico Sur 5246',
        colony: 'Doctores',
        officeNumber: 'TorreA - Consultorio 1',
        state: 'CDMX',
        city: 'CDMX'
      },
      appointmentDateTime: new Date(),
      price: 500,
      specialty: '',
      mainContent: 1
    }
  }

  componentDidMount() {
    this.setState({ doctorInformation: this.props.doctorInformation || this.state.doctorInformation });
    this.setState({ specialty: this.props.doctorInformation.specialtyField || this.state.doctorInformation.specialtyField });
  }

  cancelationPolicies = () => {
    this.setState({ mainContent: 2 });
  }

  getPriceFormatted = () => {
    const { doctorInformation } = this.props;
    let service = 0;
    const services = doctorInformation.doctorServices;
    services.forEach(s => {
      if (s.selected) {
        service += (s.category === 'Consulta' && doctorInformation.isFirstTime) ? s.firstTimePrice : s.price;
      }
    });
    return '$' + service.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  getDateFormatted = () => {
    const { selectedDate } = this.props.doctorInformation;
    const date = new Date(selectedDate.replace(/-/g, '/')).toLocaleDateString('es-MX', { dateStyle: 'full' });
    return date.charAt(0).toUpperCase() + date.slice(1)
  }

  getHours = () => {
    const { selectedHour } = this.props.doctorInformation;
    return `${selectedHour.toLocaleString('es-MX', { timeStyle: 'short' })} hrs`;
  }

  isPreviousAppointment = () => {
    const { previousAppointment } = this.props.doctorInformation;

    return previousAppointment ? true : false;
  }

  onAppointmentConfirmed = () => {
    if (this.props.onAppointmentConfirmed) {
      this.props.onAppointmentConfirmed()
    }
  }

  goBack = () => {
    this.setState({ mainContent: 1 });
  }

  politics = () => {
    return (
      <>
        <div className='row pb-2 d-flex justify-content-center'>
          <h2 className="text-center">Políticas de cancelación</h2>
        </div>
        <div className='row justify-content-center ml-3'>
          <label className='text'>Para nosotros es muy valioso tu tiempo, por eso, hemos puesto a tu
            disposición la posibilidad de reagendar tu cita hasta dos veces sin costo.</label>
        </div>
        <div className='row justify-content-center'>
          <label className='text'>A continuación, te enumeramos nuestras políticas de cancelación:</label>
        </div>
        <div className='row pl-5 pt-1'>
          <strong className='orange-text'>1</strong><label className='text pl-1'>No contamos con cancelaciones.</label>
        </div>
        <div className='row pl-5 pt-1'>
          <strong className='orange-text'>2</strong><label className='text pl-1'>No contamos con reembolsos.</label>
        </div>
        <div className='row pl-5 pt-1'>
          <strong className='orange-text'>3</strong><label className='text pl-1'>Tu cita no se podrá reagendar más de dos veces.</label>
        </div>
        <div className='row justify-content-center mt-5'>
          <label className='text'>Si requieres de asesoría personalizada, da click </label>
            <a
              href="https://api.whatsapp.com/send?phone=525569776510&text=Hola, necesito ayuda con sanapp"
              target="_blank"
              rel="noopener noreferrer"
              className='link_sopport'
            >
              <span className='pl-1'>aquí</span>
            </a>
        </div>
        <div className='row justify-content-center mt-3'>
          <button className='btn primary-btn' onClick={() => this.goBack()}>Regresar
          </button>
        </div>
      </>
    );
  }
  render() {
    return (
      <div className='appointment-container'>
        {this.state.mainContent === 2 && this.politics()}
        {this.state.mainContent === 1 && <>
          <div className='row pb-2 d-flex justify-content-center'>
            <h2 className="text-center">Confirma los datos de tu cita</h2>
          </div>
          <AppointmentRow icon={this.state.doctorInformation.picture ? `data:image/jpeg;base64,${this.state.doctorInformation.picture}` : doctorIcon} title={`${this.state.doctorInformation.firstName} ${this.state.doctorInformation.lastName}`} description={this.state.specialty} profile={true} />
          <AppointmentRow icon={calendarIcon} title={this.getDateFormatted()} description={this.getHours()} />
          <AppointmentRow icon={localizationIcon} title={this.state.doctorInformation.location} description={`${this.state.doctorInformation.streetAndNumber}, ${this.state.doctorInformation.city}`} secondLineInDescription={this.state.doctorInformation.officeNumber} />
          <AppointmentRow icon={priceIcon} title='Total a pagar' description={this.getPriceFormatted()} link='Ver políticas de cancelación' onLinkClick={this.cancelationPolicies} />
          <div className='row justify-content-center mt-3'>
            <button className='btn primary-btn' onClick={() => this.onAppointmentConfirmed()}>Reservar Cita</button>
          </div>
        </>
        }
      </div>
    );
  }
}
