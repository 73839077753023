import React, { Component } from 'react';
import mercadoPagoIcon from '../../../Assets/icons/MP logo icon.png'
import './Payments.scss';

export default class PaymentConfirmation extends Component {
    constructor(props) {
        super(props);
        this.sanappCuota = 99;
        this.state = {
            doctorInformation: {}
        }
    }

    componentDidMount() {
        this.setState({ doctorInformation: this.props.doctorInformation || this.state.doctorInformation });
    }

    formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    onAppointmentConfirmed = () => {
        if (this.props.onAppointmentConfirmed) {
            this.props.onAppointmentConfirmed()
        }
    }

    getTotalService = (service) => {
        const price = service.category === 'Consulta' && this.props.doctorInformation.isFirstTime ? service.firstTimePrice : service.price;
        return this.formatNumberWithCommas(price);
    }

    getTotal = () => {
        const { doctorInformation } = this.props;
        let service = 0;
        const services = doctorInformation.doctorServices;
        services.forEach(s => {
            if (s.selected === true) {
                service += (s.category === 'Consulta' && doctorInformation.isFirstTime) ? s.firstTimePrice : s.price;
            }
        });
        return this.formatNumberWithCommas(service + this.sanappCuota);
    }

    render() {
        return (
            <div className='appointment-container'>
                <div>
                    <div className='row pb-2 d-flex justify-content-center margin-bottom-30px'>
                        <h2 className="text-center">Detalles del pago</h2>
                    </div>
                    <div className='col-12 row margin-top-bottom-10px'>
                        <div className='col-6'>
                            <h4 className="text-end">Total:</h4>
                        </div>
                        <div className='col-6'>
                            <h4 className="text-start">${this.getTotal()}</h4>
                        </div>
                    </div>
                    <div className='row margin-top-bottom-10px'>
                        <div className='divider m-0 p-0'></div>
                    </div>
                </div>

                <div className='margin-top-bottom-10px'>
                    {(this.props.doctorInformation.doctorServices && this.props.doctorInformation.doctorServices.length > 0) &&
                        this.props.doctorInformation.doctorServices.map(s => {
                            if (!s.selected) {
                                return null;
                            }
                            return (
                                <div className='col-12 row' key={s.category + s.price}>
                                    <div className='col-6 text-end'>
                                        <label className="text">{s.name && s.name.length > 0 ? s.name : s.category}:</label>
                                    </div>
                                    <div className='col-6'>
                                        <label className="text-start">${this.getTotalService(s)}</label>
                                    </div>
                                </div>
                            )
                        })}
                    <div className='row margin-top-bottom-10px'>
                        <div className='divider m-0 p-0'></div>
                    </div>
                    <div className='col-12 row margin-top-bottom-10px'>
                        <div className='col-6 text-end'>
                            <label className="text">Cuota por servicio (IVA incluido)</label>
                        </div>
                        <div className='col-6'>
                            <label className="text-start">${this.sanappCuota}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='divider m-0 p-0'></div>
                    </div>
                    <div className='col-12 row margin-top-bottom-10px'>
                        <div className='col-6 text-end'>
                            <label className="text">Monto a pagar:</label>
                        </div>
                        <div className='col-6'>
                            <label className="text text-start">${this.getTotal()}</label>
                        </div>
                    </div>
                </div>

                <br />

                <div className='col-12 text-center'>
                    <span style={{ color: 'dimgray', fontSize: 'smaller' }}>A través de Mercado Pago {"   "}</span>
                    <img src={mercadoPagoIcon} style={{ height: '15px' }} alt='Mercado Pago icon' />
                </div>
                <div className='row justify-content-center mt-3'>
                    <button className='btn primary-btn' onClick={this.onAppointmentConfirmed}>Pagar</button>
                </div>
            </div>
        );
    }
}
