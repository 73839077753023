import React, { Component } from 'react';
import BasicInput from '../Common/inputs/BasicInput';
import BasicRadio from '../Common/inputs/BasicRadio';

export default class MedicalHistoryYesNoQuestion extends Component {
    render() {
        return (
            <>
                <div className='col-6 pl-0 form-group'>
                    <label className='text light'>{this.props.label}</label>
                </div>
                <div className='col-3 px-2'>
                    <BasicRadio radioValue={true} radioGroup={`${this.props.object}_${this.props.field}`} isChecked={this.props.isChecked === true}
                        onChangeFxn={e => this.props.onChange ? this.props.onChange(this.props.object, this.props.field, e, this.props.showInput | this.props.isMedical) : null}
                        onClick={e => this.props.onClick ? this.props.onClick(this.props.object, this.props.field, e, this.props.showInput | this.props.isMedical) : null} />
                </div>
                {!this.props.singleRadioOption &&
                    <div className='col-3 px-2'>
                        <BasicRadio radioValue={false} radioGroup={`${this.props.object}_${this.props.field}`} isChecked={this.props.isChecked === false}
                        onChangeFxn={e => this.props.onChange ? this.props.onChange(this.props.object, this.props.field, e, this.props.showInput | this.props.isMedical) : null}
                        onClick={e => this.props.onClick ? this.props.onClick(this.props.object, this.props.field, e, this.props.showInput | this.props.isMedical) : null} />
                    </div>
                }
                {
                    this.props.showInput &&
                    <BasicInput classes='col-12 col-md-2 col-lg-2' basicVal={this.props.detail} onChangeFxn={val => this.props.onChangeInput(this.props.object, this.props.field, val)}></BasicInput>
                }
            </>
        );
    }
}
