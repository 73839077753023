import React, { Component } from 'react'
import ayuda from "../../Assets/icons/whatsapp.png";

export class Help extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <div className="help d-flex justify-content-around">
                <a
                    href={'https://api.whatsapp.com/send?phone=525569776510&text=Hola, necesito ayuda con sanapp'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={ayuda} alt="help" />
                </a>
            </div>
        )
    }
}