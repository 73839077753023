import React, { Component } from 'react';
import BasicModal from '../../Common/modal/BasicModal'
import BasicInput from '../../Common/inputs/BasicInput';
import './Services.scss';
import saveIcon from '../../../Assets/icons/guardar.svg';
import trashIcon from '../../../Assets/icons/eliminar.svg';
import plusIcon from '../../../Assets/icons/mas.svg'
import prepareEndpoint from '../../../Utils/EndPoints';
import { getData, deleteData, putData, postData } from '../../../Utils/API';
import Loading from '../../Common/loading/Loading';
import confirmAlert from '../../Common/confirmAlert/confirmAlert'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { cloneDeep, filter, findIndex } from 'lodash';
import Conciliation from './Conciliation';
import SignModal from '../../Common/modal/SignModal';
import BasicSelect from '../../Common/inputs/BasicSelect';

export default class DoctorServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected:0,
      loading: true,
      modalShowUnableToDeleteLocation: false,
      showSelectionModal:false,
      locationNames: [
        {
          key: '0',
          value: '- Agregar una ubicación -',
        },
      ],
      locations: [
        {
          id: '',
          type: 'Hospital',
          name: '',
          streetAndNumber: '',
          phone: '',
          city: '',
          zip: '',
          colony: '',
          colonysByZipCode: [''],
          consultorio: '',
          nameTitle: 'Nombre',
          errors: {
            type: false,
            name: false,
            city: false,
            streetAndNumber: false,
            phone: false,
            zip: false,
            colony: false,
            consultorio: false,
          },
        },
      ],
      services: [
        {
          id: '',
          category: 'Consulta',
          durationHours: '1',
          durationMinutes: '00',
          locationId: '0',
          name: '',
          firstTimePrice: '$',
          price: '$',
          errors: {
            category: false,
            name: false,
            durationHours: false,
            durationMinutes: false,
            locationId: false,
            firstTimePrice: false,
            price: false,
          },
        },
      ],
    };
    this.locationTypes = ['Hospital', 'Clínica', 'Consultorio'];

    this.categories = ['Consulta', 'Estudio', 'Tratamiento', 'Cirugía', 'Otro'];
    this.horas = ['0 horas', '1 hora', '2 horas', '3 horas', '4 horas', '5 horas', '6 horas'];
    this.minutos = ['00 minutos', '15 minutos', '30 minutos', '45 minutos'];
    this.formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 0,
    });
  }
  loadImage= async ()=>{
    const url = prepareEndpoint('DoctorSign');
    const respose = await getData(url);
    if(respose.status !== 200){
      this.setState({showSelectionModal:true});
      return
    }
    if(respose.data === '')
    {
      this.setState({showSelectionModal:true});
    }
  }
  onImageChange=async (img)=>{
    this.setState({showSelectionModal:false});
    if(img && img!==''){
      var url = prepareEndpoint('DoctorSign');
      await postData(url, {img:img});
    }
  }
  OnDelete = (deleteFunction) => {
    const buttons = [
      {
        label: 'Aceptar',
        onClick: deleteFunction
      },
      {
        label: 'Cancelar'
      }
    ];
    confirmAlert({ message: '¿Estás seguro que quieres borrar este elemento?', buttons })
  }
  async componentDidMount() {
    try {
      this.loadImage();
      this.trySetLocationsFromApi();
      await this.trySetServicesFromApi();
    } catch (e) {
      console.log('Exception at did mount: ', e)
    }
    finally {
      this.setState({ loading: false })
    }
  }
  setDefaultLocation = () => {
    this.setState({
      locations: [
        {
          id: '',
          type: 'Hospital',
          name: '',
          streetAndNumber: '',
          phone: '',
          city: '',
          zip: '',
          colony: '',
          colonysByZipCode: [''],
          consultorio: '',
          nameTitle: 'Nombre del Hospital',
          errors: {
            type: false,
            name: false,
            city: false,
            streetAndNumber: false,
            phone: false,
            zip: false,
            colony: false,
            consultorio: false,
          },
        },
      ],
    });
  };
  setDefaultService = () => {
    this.setState({
      services: [
        {
          id: '',
          category: 'Consulta',
          durationHours: '1',
          durationMinutes: '00',
          locationId: '0',
          firstTimePrice: '$',
          price: '$',
          name: '',
          errors: {
            category: false,
            name: '',
            durationHours: false,
            durationMinutes: false,
            locationId: false,
            firstTimePrice: false,
            price: false,
          },
        },
      ],
    });
  };
  async trySetServicesFromApi() {
    const url = prepareEndpoint('DoctorServices');
    const response = await getData(url);
    if (response.status !== 200 || !response.data || response.data.length <= 0) {
      return;
    }

    for (var i = 0; i < response.data.length; i++) {
      response.data[i].durationHours = this.getHoursLabel(response.data[i].duration.split(':')[0]);
      response.data[i].durationMinutes = response.data[i].duration.split(':')[1] + ' minutos';
      response.data[i].firstTimePrice = this.formatter.format(response.data[i].firstTimePrice);
      response.data[i].price = this.formatter.format(response.data[i].price);
      response.data[i].errors = {
        category: false,
        durationHours: false,
        durationMinutes: false,
        locationId: false,
        firstTimePrice: false,
        price: false,
        name: false,
      };
    }

    this.setState({ services: response.data });
  }

  getHoursLabel = hours => {
    hours = hours.replace('0', '');
    return `${hours} hora${hours !== '1' ? 's' : ''}`
  }

  async trySetLocationsFromApi() {
    const url = prepareEndpoint('DoctorLocations');
    const response = await getData(url);
    if (response.status !== 200 || !response.data || response.data.length <= 0) {
      return;
    }

    let locationNames = [];
    locationNames.push( { key: 0, value: 'Seleccionar ubicación' } );
    for (var i = 0; i < response.data.length; i++) {
      let location = {
        key: response.data[i].id,
        value: !response.data[i].name
          ? '-Nombrar esta ubicación -'
          : response.data[i].name,
      };
      locationNames.push(location);
      response.data[i].errors = {
        type: false,
        name: false,
        city: false,
        streetAndNumber: false,
        phone: false,
        zip: false,
        colony: false,
        consultorio: false,
      };
      response.data[i].nameTitle = this.getLocationName(response.data[i].type)
    }
    this.setState({ locations: response.data, locationNames });
  }

  updateStateLocations = (val, field, index) => {
    let locations = [...this.state.locations];
    locations[index][field] = val;
    locations[index].nameTitle = this.getLocationName(locations[index]['type']);
    if (locations[index].errors[field]) {
      locations[index].errors[field] = false;
    }
    this.setState({ locations });
  };

  getLocationName = (locationType) => {
    const name = 'Nombre';
    if (locationType === 'Clínica') {
      return `${name} de la ${locationType}`;
    }

    return `${name} del ${locationType}`;
  }

  getColonysByZipCode = async (zipCode, index) => {
    const url = prepareEndpoint('ZipCodes');
    const resp = await getData(`${url}/${zipCode}`);

    let zipCodes = [];
    if (resp.status === 200) {
      zipCodes = resp.data;

      if (zipCodes.length > 0) {
        let locations = [...this.state.locations];
        locations[index].colony = zipCodes[0].description;
        locations[index].colonysByZipCode = zipCodes.map(z => z.description);
        if (locations[index].errors.colony) {
          locations[index].errors.colony = false;
        }
        this.setState({ locations });
      }
    }
  };

  updateStateServices = (val, field, index) => {
    let services = [...this.state.services];
    services[index][field] = val;
    if (services[index].errors[field]) {
      services[index].errors[field] = false;
    }
    this.setState({ services });
  };

  formatPrice = (val, field, index) => {
    val = val.replace('$', '').replace(',', '').replace(' ', '');
    val = this.formatter.format(val);
    this.updateStateServices(val, field, index);
  };

  submitLocationsForm = async index => {
    let validForm = this.validateLocationFields(index);
    if (!validForm) {
      return;
    }

    this.setState({ loading: true });
    let payload = this.createLocationObj(index);
    const url = prepareEndpoint('DoctorLocations');
    const resp = await putData(url, payload);

    if (resp.status === 200) {
      this.setState({ showSucessModal: true });
    }

    let locations = [...this.state.locations];
    locations[index].id = resp.data;

    let locationNames = [];
    for (var i = 0; i < locations.length; i++) {
      let location = {
        key: locations[i].id,
        value: locations[i].name,
      };
      locationNames.push(location);
    }

    this.setState({ loading: false, locations, locationNames });
  };

  submitForm = async index => {
    let validForm = this.validateServiceFields(index);
    if (!validForm) {
      return;
    }

    this.setState({ loading: true });
    let payload = this.createObj(index);
    const url = prepareEndpoint('DoctorServices');
    const resp = await putData(url, payload);
    if (resp.status === 200) {
      this.setState({ showSucessModal: true });
    }

    let services = [...this.state.services];
    services[index].id = resp.data;

    this.setState({ loading: false, services });
  };

  createObj = index => {
    let {
      id,
      category,
      durationHours,
      durationMinutes,
      locationId,
      firstTimePrice,
      price,
      name
    } = this.state.services[index];
    return {
      id,
      category,
      duration: durationHours.split(' ')[0] + ':' + durationMinutes.split(' ')[0],
      locationId,
      firstTimePrice: firstTimePrice.replace('$', '').replace(',', '').replace(' ', ''),
      price: price.replace('$', '').replace(',', '').replace(' ', ''),
      name
    };
  };

  createLocationObj = index => {
    let { id, type, city, name, streetAndNumber, zip, colony, consultorio, phone } =
      this.state.locations[index];
    return {
      id,
      type,
      name,
      streetAndNumber,
      phone,
      city,
      zip,
      colony,
      consultorio,
    };
  };

  validateServiceFields = index => {    
    let services = [...this.state.services];
    let formIsValid = true;
    if (!services[index].name && services[index].category !== 'Consulta') {
      formIsValid = false;
      services[index].errors.name = true;
    }
    if (!services[index].category) {
      formIsValid = false;
      services[index].errors.category = true;
    }
    if (!services[index].durationHours) {
      formIsValid = false;
      services[index].errors.durationHours = true;
    }
    if (!services[index].durationMinutes) {
      formIsValid = false;
      services[index].errors.durationMinutes = true;
    }
    if (!services[index].locationId || services[index].locationId === '0') {
      alert('Favor de seleccionar ubicación');
      formIsValid = false;
      services[index].errors.locationId = true;
    }
    if ((!services[index].firstTimePrice || services[index].firstTimePrice.length <= 1) && services[index].category === 'Consulta') {
      formIsValid = false;
      services[index].errors.firstTimePrice = true;
    }
    if (!services[index].price || services[index].price.length <= 1) {
      formIsValid = false;
      services[index].errors.price = true;
    }
    this.setState({ services });
    return formIsValid;
  };

  validateLocationFields = index => {
    let locations = [...this.state.locations];
    let formIsValid = true;
    if (!locations[index].type) {
      formIsValid = false;
      locations[index].errors.type = true;
    }
    if (!locations[index].city) {
      formIsValid = false;
      locations[index].errors.city = true;
    }
    if (!locations[index].name) {
      formIsValid = false;
      locations[index].errors.name = true;
    }
    if (!locations[index].streetAndNumber) {
      formIsValid = false;
      locations[index].errors.streetAndNumber = true;
    }
    if (!locations[index].zip) {
      formIsValid = false;
      locations[index].errors.zip = true;
    }
    if (!locations[index].colony) {
      formIsValid = false;
      locations[index].errors.colony = true;
    }
    if (!locations[index].consultorio) {
      formIsValid = false;
      locations[index].errors.consultorio = true;
    }
    this.setState({ locations });
    return formIsValid;
  };

  addNewLocationToUI = () => {
    let locations = [...this.state.locations];

    let newService = {
      id: '',
      type: 'Hospital',
      name: '',
      nameTitle: 'Nombre del Hospital',
      streetAndNumber: '',
      phone: '',
      zip: '',
      colony: '',
      consultorio: '',
      errors: {
        type: false,
        name: false,
        streetAndNumber: false,
        phone: false,
        zip: false,
        colony: false,
        consultorio: false,
      },
    };
    locations.push(newService);
    this.setState({ locations });
  };

  addNewServiceToUI = () => {
    let services = cloneDeep(this.state.services);
    const consultaIndex = findIndex(services, ['category', 'Consulta']);
    let newService = {
      id: '',
      category: consultaIndex !== -1 ? 'Estudio' : 'Consulta',
      durationHours: '1',
      durationMinutes: '00',
      locationId: this.state.locationNames ? this.state.locationNames[0].key : '',
      firstTimePrice: '$',
      price: '$',
      errors: {
        category: false,
        name: false,
        durationHours: false,
        durationMinutes: false,
        locationId: false,
        firstTimePrice: false,
        price: false,
      },
    };
    services.push(newService);
    this.setState({ services });
  };

  removeEmptyLocation = index => {
    let locations = [...this.state.locations];
    if (locations.length === 1) {
      this.setDefaultLocation();
      return;
    }
    locations.splice(index, 1);
    this.setState({ locations });
    return;
  }

  deleteLocation = async index => {
    if (!this.state.locations[index].id) {
      return this.removeEmptyLocation(index);
    }
    let locations = [...this.state.locations];

    let service = this.state.services.find(
      s => s.locationId === locations[index].id
    );
    if (service?.category) {
      this.setState({ modalShowUnableToDeleteLocation: true });
      return;
    }

    this.setState({ loading: true });
    const url = prepareEndpoint('DoctorLocations');
    const resp = await deleteData(url + '?locationId=' + locations[index].id);
    if (resp.status !== 200) {
      alert('Lo sentimos, no se pudo borrar este servicio');
      return;
    }

    locations.splice(index, 1);
    let locationNames = [...this.state.locationNames];
    locationNames.splice(index, 1);

    this.setState({ loading: false, showSucessModal: true, locations, locationNames });
    if (locations.length === 0) {
      this.setDefaultLocation();
    }
  };

  removeEmptyService = index => {
    let services = [...this.state.services];
    if (services.length === 1) {
      this.setDefaultService();
      return;
    }

    services.splice(index, 1);
    this.setState({ services });
  }

  deleteService = async index => {
    if (!this.state.services[index].id) {
      return this.removeEmptyService(index);
    }

    this.setState({ loading: true });

    let services = [...this.state.services];

    const url = prepareEndpoint('DoctorServices');
    const resp = await deleteData(url + '?serviceId=' + services[index].id);
    if (resp.status !== 200) {
      alert('Lo sentimos, no se pudo borrar este servicio');
      return;
    }

    services.splice(index, 1);
    this.setState({ loading: false, showSucessModal: true, services });
    if (services.length === 0) {
      this.setDefaultService();
    }
  };

  getLocationNameSelectedItem = (locationNames, locationId) => {
    if (!locationId || locationId === '0') {
      return;
    }
    let location = locationNames.find(l => l.key === locationId);
    return location?.value;
  };

  getCategories = (serviceId, locationId) => {
    const { services } = this.state;
    const categories = cloneDeep(this.categories);

      const servicesInLocation = services.filter(s => s.locationId === locationId && s.id !== serviceId);
      if (servicesInLocation && servicesInLocation.some(s => s.category === 'Consulta')) {
          categories.splice(categories.indexOf('Consulta'), 1);
      }      
    return categories;
    }

  getLocationNames = (category) => {
    let locationNames = cloneDeep(this.state.locationNames)
    const { services } = this.state;

    if (category === 'Consulta') {
      let consultas = filter(services, ['category', 'Consulta']);
      const locationsConsultas = consultas.map(service => {
        return this.getLocationNameSelectedItem(
          locationNames,
          service.locationId
        )
      })
      const locationsToDeleteSet = new Set(locationsConsultas);

      locationNames = locationNames.filter((location) => {
        return !locationsToDeleteSet.has(location.value);
      });
    }
    return locationNames;
  }

    updateLocationId(locationName, index) {
        const location = this.state.locationNames.find(l => l.key === locationName.key);
        this.updateStateServices(location.key, 'locationId', index);
    }

  render() {
    let activeView;
    if (this.state.tabSelected === 1) {
      activeView = this.state.services.map((service, index) => (
        <form ref={this.myRef} key={'services' + index }>
          <div className='form-row'>
            <div className="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-3 my-2">
              <label className='text location-title'>Servicio {index + 1}</label>
            </div>
          </div>
          <div className="form-row">
            <div className="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-2">
              <BasicSelect
                customOptions={this.categories}
                title={'Categoría'}
                selectedItem={service.category}
                onChangeFxn={val => { this.updateStateServices(val, 'category', index); }}
              />
            </div>
            {
              this.state.services[index].category !== 'Consulta' && (
                <BasicInput
                  classes="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-2"
                  label="Nombre"
                  onChangeFxn={val => {
                    this.updateStateServices(val, 'name', index);
                  }}
                  error={service.errors.name}
                  basicVal={service.name}
                />
              )
            }
            <div className="col-12 col-sm-6 col-xs-6 col-md-1 col-lg-1">
              <BasicSelect
                customOptions={this.horas}
                title={'Duración'}
                selectedItem={service.durationHours}
                onChangeFxn={val => {
                  this.updateStateServices(val, 'durationHours', index);
                }}
              />
            </div>
            <div className="col-12 col-sm-6 col-xs-6 col-md-1 col-lg-2 mt-2">
              <BasicSelect
                customOptions={this.minutos}
                title={' '}
                selectedItem={service.durationMinutes}
                onChangeFxn={val => {
                  this.updateStateServices(val, 'durationMinutes', index);
                }}
              />
            </div>
            <div className="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-2">
              <BasicSelect
                customOptions={this.state.locationNames}
                title={'Ubicaciones'}
                selectedItem={this.getLocationNameSelectedItem(this.state.locationNames, service.locationId                 )}
                onChangeFxn={val => { this.updateLocationId(val, index); }}
                error={service.errors.locationId}
              />
            </div>
            {this.state.services[index].category === 'Consulta' && (
              <BasicInput
                classes="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-2"
                label="Precio primera consulta"
                onChangeFxn={val => this.updateStateServices(val, 'firstTimePrice', index)}
                onBlurFxn={val => this.formatPrice(val, 'firstTimePrice', index)}
                maxLength={8}
                numeric={true}
                error={service.errors.firstTimePrice}
                basicVal={service.firstTimePrice}
                placeHolder='$'
              />
            )}
            <BasicInput
              classes="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-2"
              label={`Precio ${this.state.services[index].category === 'Consulta' ? 'consulta' : ''}`}
              numeric={true}
              onChangeFxn={val => this.updateStateServices(val, 'price', index)}
              onBlurFxn={val => this.formatPrice(val, 'price', index)}
              maxLength={8}
              error={service.errors.price}
              basicVal={service.price}
              placeHolder='$'
            />
            <div className="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-1 center pt-3">
              <img
                className="clickable mx-2"
                src={saveIcon}
                onClick={() => this.submitForm(index)}
                height="25px"
                width="25px"
                alt="saveIcon"
              />
              <img
                src={trashIcon}
                className='clickable'
                height="25px"
                width="25px"
                onClick={() => this.OnDelete(() => this.deleteService(index))}
                alt="trashIcon"
              />
            </div>
          </div>
          {index === this.state.services.length - 1 && (
            <div className='plus-Icon'>
              <img alt='' src={plusIcon} onClick={this.addNewServiceToUI} />
            </div>
          )}
        </form>
      ));
    } else if(this.state.tabSelected === 0){
      activeView = this.state.locations.map((location, index) => (
        <form ref={this.myRef} key={'location' + index }>
          <div className='form-row'>
            <div className="col-12 col-sm-6 col-xs-6 col-md-3 col-lg-3 my-2">
              <label className='text location-title'>Ubicación {index + 1}</label>
            </div>
          </div>
          <div className="form-row">
            <div className="col-12 col-xs-6 col-md-3">
              <BasicSelect
                customOptions={this.locationTypes}
                title={'Tipo de Ubicación'}
                selectedItem={location.type}
                onChangeFxn={val => {
                  this.updateStateLocations(val, 'type', index);
                }}
              />
            </div>
            <BasicInput
              classes="col-12 col-xs-6 col-md-3"
              label={location.nameTitle}
              onChangeFxn={val => {
                this.updateStateLocations(val, 'name', index);
              }}
              error={location.errors.name}
              basicVal={location.name}
            />
            <BasicInput
              classes="col-12 col-xs-6 col-md-4"
              label="Calle y número"
              onChangeFxn={val => {
                this.updateStateLocations(val, 'streetAndNumber', index);
              }}
              error={location.errors.streetAndNumber}
              basicVal={location.streetAndNumber}
            />
            <BasicInput
              classes="col-12 col-xs-6 col-md-2"
              label="Teléfono"
              onChangeFxn={val => {
                this.updateStateLocations(val, 'phone', index);
              }}
              basicVal={location.phone}
            />
            <BasicInput
              classes="col-12 col-xs-6 col-md-3"
              label="Ciudad"
              onChangeFxn={val => {
                this.updateStateLocations(val, 'city', index);
              }}
              error={location.errors.city}
              basicVal={location.city}
              maxLength={30}
            />
            <BasicInput
              classes="col-12 col-xs-6 col-md-3"
              label="C.P."
              onChangeFxn={val => {
                this.updateStateLocations(val, 'zip', index);
              }}
              onBlurFxn={val => {
                this.getColonysByZipCode(val, index);
              }}
              error={location.errors.zip}
              basicVal={location.zip}
              maxLength={5}
              numeric={true}
            />
            <div className="col-12 col-xs-6 col-md-2">
              <BasicSelect
                customOptions={this.state.locations[index].colonysByZipCode}
                title={'Colonia'}
                selectedItem={this.state.locations[index].colony || ''}
                onChangeFxn={val => {
                  this.updateStateLocations(val, 'colony', index);
                }}
                error={location.errors.colony}
              />
            </div>
            <BasicInput
              classes="col-12 col-xs-6 col-md-2"
              label="Consultorio"
              onChangeFxn={val => {
                this.updateStateLocations(val, 'consultorio', index);
              }}
              error={location.errors.consultorio}
              basicVal={location.consultorio}
            />
            <div className="col-12 col-xs-6 col-md-2 center pt-3">
              <img
                className="clickable mx-2"
                src={saveIcon}
                onClick={() => this.submitLocationsForm(index)}
                height="25px"
                width="25px"
                alt="saveIcon"
              />
              <img
                src={trashIcon}
                className='clickable'
                height="25px"
                width="25px"
                onClick={() => this.OnDelete(() => this.deleteLocation(index))}
                alt="trashIcon"
              />
            </div>
          </div>
          {index === this.state.locations.length - 1 && (
            <div className='plus-Icon'>
              <img alt='' src={plusIcon} onClick={this.addNewLocationToUI} />
            </div>
          )}
        </form>
      ));
    }else{
      activeView = (
        <Conciliation></Conciliation>
      );
    }
    return (
      <div className='doctor-container services'>
        <div>
          <SignModal modalClosed={()=>{this.setState({showSelectionModal:false})}} show={this.state.showSelectionModal} onChange={this.onImageChange}></SignModal>
        </div>
        <label
          className={
            'options-text ' +
            (this.state.tabSelected === 0 ? ' inactive' : ' active')
          }
          onClick={e => this.setState({ tabSelected: 0 })}
        >
          Ubicaciones
        </label>
        <label
          className={
            'options-text ' +
            (this.state.tabSelected === 1 ? ' inactive' : ' active')
          }
          onClick={e => this.setState({ tabSelected: 1 })}
        >
          Configuración
        </label>
        <label className={'options-text'+
            (this.state.tabSelected === 2 ? ' inactive' : ' active')
        }
        onClick={e => this.setState({ tabSelected: 2 })}
        >
              Conciliación de cuenta
        </label>

        {this.state.loading && <Loading />}
        {activeView}
        <BasicModal
          className='additional-modal'
          show={this.state.modalShowUnableToDeleteLocation}
          modalClosed={() => this.setState({ modalShowUnableToDeleteLocation: false })}
          title='No se puede borrar ubicación'
          subtitle='No se puede borrar la ubicación porque existe un servicio configurado con esta misma ubicación.'
          closeIcon={true} >
        </BasicModal>
      </div>
    );
  }
}
