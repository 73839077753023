/* eslint-disable no-useless-escape */
import IconButton from '@mui/material/IconButton';
import Tooltip , { tooltipClasses } from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import React, { Component } from 'react';
import { getData, postData } from '../../../Utils/API';
import prepareEndpoint from '../../../Utils/EndPoints';
import BasicInput from '../../Common/inputs/BasicInput';
import BasicRadio from '../../Common/inputs/BasicRadio';
import Loading from '../../Common/loading/Loading';
import SuccessModal from '../../Common/modal/SuccessModal';
import './Invoice.scss'
import { ReactComponent as info } from '../../../Assets/icons/info.svg'
import { styled } from '@mui/material/styles';
import BasicModal from '../../Common/modal/BasicModal';

export default class Invoice extends Component {
  constructor(props) {
    super(props);
    const defaultInformation = {
      rfc: '',
      email: '',
      zip: '',
      street:'',
      suburb: '',
      invoiceCompanyName: ''
    };
    this.state = {
      invoiceInformation: {...defaultInformation},
      profileInformation: {...defaultInformation},
      tempInformation:  {...defaultInformation},
      source: '',
      errors: {
        rfc: false,
        email: false,
        zip: false,
        street: false,
        suburb: false,
        invoiceCompanyName: false
      },
      loading: false,
      showSucessModal: false,
      showError: false
    }
    this.myRef = React.createRef()
  }

  componentDidMount()
  {
    const {patientInformation} = this.props;
    if(!patientInformation)
    {
      this.getPatient();
    }
    else{
      this.setData(patientInformation);
    }
  }
  setData(invoiceInformation)
  {
    const source = invoiceInformation && invoiceInformation.rfc!==undefined && invoiceInformation.rfc!==null? 'profile':'invoice';
    this.setState({source:source});
    //estos datos se deben llenar con lo que venga en el perfil
    const profileInformation = invoiceInformation && source==='profile'?
      {
        rfc: invoiceInformation.rfc.toUpperCase(),
        email: invoiceInformation.email,
        zip: invoiceInformation.zip,
        street:invoiceInformation.street,
        suburb: invoiceInformation.suburb,
        invoiceCompanyName: invoiceInformation.invoiceCompanyName,
      }:{
        rfc: '',
        email: '',
        zip: '',
        street:'',
        suburb: '',
        invoiceCompanyName: ''
      };
    invoiceInformation = !invoiceInformation ? profileInformation : invoiceInformation;
    this.setState({invoiceInformation: invoiceInformation})
    this.setState({profileInformation: {...profileInformation}})
  }
  getPatient = async () => {
    const url = prepareEndpoint('Patient');
    const resp = await getData(url);

    if (resp?.status !== 200) {
      return;
    }

    const { invoiceInformation } = resp.data;

    this.setData(invoiceInformation);
  }

  onSourceChange (e)
  {
    const source =  e.currentTarget.value;
    this.setState({source: source});
    if(source === 'profile'){
      this.setState({tempInformation: this.state.invoiceInformation});
      this.setState({invoiceInformation: this.state.profileInformation})
      this.setState({errors:{
        rfc: false,
        email: false,
        zip: false,
        street: false,
        suburb: false,
        invoiceCompanyName: false
      }})
    }
    else{
      this.setState({invoiceInformation: this.state.tempInformation});
    }
  }
  updateState = (val, field) => {
    let invoiceInformation={...this.state.invoiceInformation};
    invoiceInformation[field] =val;

    this.setState({ invoiceInformation: invoiceInformation })
    this.cleanError(field)
  }
  closeModal = () => {
    this.setState({ showSucessModal: false })
    this.props.toggleShowInvoiceInformation();
  }
  validateFields = () => {
    let errors = { ...this.state.errors }
    const {
      rfc,
      email,
      zip,
      street,
      suburb,
      invoiceCompanyName
    } = this.state.invoiceInformation
    const rfcExp = new RegExp('^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$')
    // eslint-disable-next-line quotes
    const emailExp = new RegExp("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$");
    
    let formIsValid = true
    if (!rfc || !rfcExp.test(rfc.toUpperCase())) {
      formIsValid = false
      errors.rfc = true
    }
    if (!email || !emailExp.test(email)) {
      formIsValid = false
      errors.email = true
    }
    if (!zip) {
      formIsValid = false
      errors.zip = true
    }
    if (!street) {
      formIsValid = false
      errors.street = true
    }
    if (!suburb) {
      formIsValid = false
      errors.suburb = true
    }
    if (!invoiceCompanyName) {
      formIsValid = false
      errors.invoiceCompanyName = true
    }
    this.setState({ errors })
    return formIsValid
  };
  cleanError = type => {
    let errors = { ...this.state.errors }
    if (errors[type]) {
      errors[type] = false
    }
    this.setState({ errors })
  };
  submitForm = async (e) => {
    e.preventDefault()
    let validForm = this.validateFields()
    if (validForm) {
      const useExistingInformation = this.state.source==='profile';
      this.setState({ loading: true });
      const {invoiceInformation} = this.state;
      const appointmentId = this.props.appointmentId? `/${this.props.appointmentId}`:'';
      const url = `${prepareEndpoint('Invoice')}${appointmentId}?useExistingInformation=${useExistingInformation}`;
      const resp = await postData(url, invoiceInformation);

      if (resp.status >= 200 && resp.status < 300) {
        if(this.props.onSuccessSave!==undefined){
          this.setState({ loading: false });
          this.props.onSuccessSave();
          return;
        }
        this.setState({ loading: false });
        this.setState({ showSucessModal: true})
      }
      else
      {
        this.setState({showError:true});
      }
      this.setState({ loading: false });
    } else {
      this.myRef.current.scrollIntoView()
    }
  }
  
  render() {
    const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#878787',
        fontSize: 13,
        maxWidth: 150,
        borderRadius:0,
      },
    }));
    return (
      <div>
        {this.state.loading && <Loading />}
        <BasicModal
          className='additional-modal'
          show={this.state.showError}
          modalClosed={() => this.setState({ showError: false })}
          title='Ocurrió un error'
          subtitle='Ocurrió un error al guardar la información.'
          closeIcon={true} >
        </BasicModal>
        <SuccessModal show={this.state.showSucessModal} closeFnx={this.closeModal} btnFnx={this.closeModal} btnTxt={'Aceptar'} />
        <h2 className="text-center">Ingresa los datos correspondientes para generar tu factura</h2>
        <form ref={this.myRef}>
          <div className="form-row">
            <div className='col-12 col-md-3 mt-3'>
              <label className='text'>Datos de facturación</label>
            </div>
          </div>
          <div className="form-row">
            <div className='col-6 col-md-4'>
              <BasicRadio radioValue="profile" radioGroup="source"  isChecked={this.state.source === 'profile' } onChangeFxn={e => this.onSourceChange(e)} label='Usar los datos de mi perfil'/>
            </div>
            <div className='col-6 col-md-3'>
              <BasicRadio radioValue="invoice" radioGroup="source"  isChecked={this.state.source === 'invoice' } onChangeFxn={e => this.onSourceChange(e)} label='Ingresar Datos'/>
            </div>
          </div>
          <div className="form-row mt-4">
            <BasicInput
              classes='col-12 col-md-4 col-lg-4' label='Razón Social' disabled={this.state.source === 'profile'}
              onChangeFxn={(val) => { this.updateState(val, 'invoiceCompanyName') }}
              error={this.state.errors.invoiceCompanyName}
              basicVal={this.state.invoiceInformation.invoiceCompanyName}
              maxLength={50}
            />
            <BasicInput
              classes='col-12 col-md-4 col-lg-4' label='RFC' disabled={this.state.source === 'profile'}
              onChangeFxn={(val) => { this.updateState(val.toUpperCase(), 'rfc') }}
              error={this.state.errors.rfc}
              basicVal={this.state.invoiceInformation.rfc}
              maxLength={13}
            />
            <BasicInput
              classes='col-12 col-md-4 col-lg-4' label='Correo' disabled={this.state.source === 'profile'}
              onChangeFxn={(val) => { this.updateState(val, 'email') }}
              error={this.state.errors.email}
              basicVal={this.state.invoiceInformation.email}
              maxLength={50}
            />
          </div>
          <div className="form-row">
            <BasicInput
              classes='col-12 col-md-4 col-lg-4' label='C.P.' disabled={this.state.source === 'profile'}
              onChangeFxn={(val) => { this.updateState(val, 'zip') }}
              error={this.state.errors.zip}
              basicVal={this.state.invoiceInformation.zip}
              numeric={true}
              maxLength={5}
            />
            <BasicInput
              classes='col-12 col-md-4 col-lg-4' label='Colonia' disabled={this.state.source === 'profile'}
              onChangeFxn={(val) => { this.updateState(val, 'suburb') }}
              error={this.state.errors.suburb}
              basicVal={this.state.invoiceInformation.suburb}
              maxLength={50}
            />
            <BasicInput
              classes='col-12 col-md-4 col-lg-4' label='Calle y número' disabled={this.state.source === 'profile'}
              onChangeFxn={(val) => { this.updateState(val, 'street') }}
              error={this.state.errors.street}
              basicVal={this.state.invoiceInformation.street}
            />
          </div>
          <div className='row mt-3'>
            <div className='col-12 d-flex justify-content-center'>
              <button className='btn primary-btn generate-btn' onClick={this.submitForm}>Generar factura</button>
              <LightTooltip title="La factura se enviará al correo que indicaste" arrow placement='right-end' >
                <IconButton disableRipple size='small'>
                  <SvgIcon component={info} inheritViewBox fontSize="inherit" />
                </IconButton>
              </LightTooltip>
            </div>
          </div>
         
        </form>
      </div>
    );
  }
}
