import React, { Component } from 'react'
import queryString from 'query-string';
import Loading from '../../Common/loading/Loading'
import BasicInput from '../../Common/inputs/BasicInput'
import { FormField } from '../../Common/forms/FormField'
import { getData, postData } from '../../../Utils/API';
import prepareEndpoint from '../../../Utils/EndPoints';
import authService from '../../../security/auth-service'
import logo from '../../../Assets/logo.svg'
import addIcon from '../../../Assets/icons/agregar.svg'
import history from '../../../history'
import './DoctorRegister.scss'
import BasicSelect from '../../Common/inputs/BasicSelect';

export default class DoctorReview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctor: {
        userId: '',
        firstName: '',
        lastName: '',
        rate: 3.5,
        specialtyField: '',
        professionalLicense: '',
        specialties: [],
        academicAchievements: [],
        languages: [],
        memberPrivateMedicalAssociations: [],
        positionsInPublicAssociations: [],
        professionalExperiences: [],
        specialtyAreas: [],
        adminNotes: ''
      },
      locations: [],
    };
  }

  async componentDidMount() {
    const qsValues = queryString.parse(this.props.location.search);
    const { doctorId } = qsValues;
    if (!doctorId) {
      this.props.history.goBack();
    }
    this.getDoctor(doctorId);
    this.getLocations(doctorId);
    this.setState({ loading: false });
  }

  getDoctor = async (doctorId) => {
    const url = prepareEndpoint('Doctor');
    const resp = await getData(`${url}/${doctorId}`);
    if (resp?.status !== 200) {
      return;
    }
    this.setState({
      doctor: resp.data
    });
  }

  getLocations = async (doctorId) => {
    const url = prepareEndpoint('DoctorLocations');
    const resp = await getData(`${url}/${doctorId}`);
    if (resp?.status !== 200) {
      return;
    }
    const data = resp.data;
    this.setState({
      locations: data
    });
  }

  goToValidation = () => {
    history.push('/drvalidation')
  }

  notesUpdate = (value) => {
    let doctor = { ...this.state.doctor }
    doctor.adminNotes = value;
    this.setState({ doctor: doctor });
  }

  selectOption(option) {
    switch (option) {
    case 'Cerrar sesion':
      authService.logout();
      break;
    default:
    }
  }

  saveNotes = async (status) => {
    const url = `${prepareEndpoint('Doctor')}/ChangeDoctorStatus/${this.state.doctor.userId}`;
    await postData(url, { status: status, notes: this.state.doctor.adminNotes });
    this.goToValidation();
  }

  getDDOptions() {
    let options = ['Cerrar sesion'];

    return options;
  }

  render() {
    return (
      <>
        <nav className='navbar fixed-top'>
          <div className='container-fluid'>
            <div className='logo'>
              <img src={logo} alt="Icono: Logotipo en la insignia" />
            </div>

            <div className='nav-buttons form-inline account'>

              <BasicSelect
                placeholder='Administrador'
                btnClasses={'btn confirm-btn my-account-btn shine'}
                customOptions={this.getDDOptions()}
                onChangeFxn={this.selectOption}
              />
            </div>
          </div>
        </nav>

        <div className='doctor-container profile pb-5'>
          {this.state.loading && <Loading />}

          <div className='d-flex justify-content-space-flex-start'>
            <label
              className='options-text active mb-3'
              onClick={() => this.goToValidation()}
            >
              &#60; Regresar a Bandeja de validación de médicos
            </label>
          </div>


          <div className={this.state.doctor.picture ? 'picture-profile withPicture' : 'picture-profile'}>
            <FormField text={'Generales'} icon={this.state.doctor.picture ? `data:image/jpeg;base64,${this.state.doctor.picture}` : addIcon}>
              <form>
                <div className="form-row">
                  <BasicInput
                    classes='col-12 col-md-4 col-lg-3' label='Nombre(s)'
                    basicVal={this.state.doctor.firstName}
                    disabled={true}
                  />
                  <BasicInput
                    classes='col-12 col-md-4 col-lg-3' label='Apellido Paterno'
                    basicVal={this.state.doctor.lastName}
                    disabled={true}
                  />
                  <BasicInput
                    classes='col-12 col-md-4 col-lg-3' label='Apellido Materno'
                    basicVal={this.state.doctor.motherName}
                    disabled={true}
                  />
                  <BasicInput
                    classes='col-12 col-md-4 col-lg-3' label='Teléfono'
                    basicVal={this.state.doctor.phone}
                    disabled={true}
                  />
                  <BasicInput
                    classes='col-12 col-md-4 col-lg-3' label='Especialidad'
                    basicVal={this.state.doctor.specialtyField}
                    disabled={true}
                  />
                  <BasicInput classes='col-12 col-md-4 col-lg-3' label='Cédula'
                    basicVal={this.state.doctor.professionalLicense}
                    disabled={true}
                  />
                  <BasicInput classes='col-12 col-md-4 col-lg-3' label='Puesto Actual'
                    basicVal={this.state.doctor.currentPosition}
                    disabled={true}
                  />
                </div>
              </form>
            </FormField>
          </div>

          <FormField text={'Ubicación'}>
            <div className="form-row">
              {this.state.locations.map((location, index) =>
                <>
                  <div className='col-12 col-md-6 col-lg-2'>
                    <BasicInput label='Tipo de Ubicación'
                      basicVal={location.type}
                      disabled={true}
                    />
                    <BasicInput
                      classes='col-12 display-contents'
                      basicVal={location.city}
                      disabled={true}
                    />
                  </div>
                  <div className='col-12 col-md-6 col-lg-9 row address-container'>
                    <div className='col-12'>
                      <label className='text'>Dirección</label>
                    </div>
                    <BasicInput
                      classes='col-12 col-lg-4'
                      basicVal={location.name}
                      disabled={true}
                    />
                    <BasicInput
                      classes='col-12 col-lg-4'
                      basicVal={location.zip}
                      disabled={true}
                    />
                    <BasicInput
                      classes='col-12 col-lg-4'
                      basicVal={location.colony}
                      disabled={true}
                    />
                    <BasicInput
                      classes='col-12 col-lg-4'
                      basicVal={location.streetAndNumber}
                      disabled={true}
                    />
                    <BasicInput
                      classes='col-12 col-lg-4'
                      basicVal={location.consultorio}
                      disabled={true}
                    />
                    <BasicInput
                      classes='col-12 col-lg-4'
                      basicVal={location.phone}
                      disabled={true}
                    />
                  </div>
                  <br /><br />
                </>
              )}
            </div>
          </FormField>

          <FormField text={'Formación Profesional'}>
            <form >
              <div className="form-row">
                <div className='col-12'>
                  <label className='text'>Experto en:</label>
                  <p>Enlista las 4 principales áreas de especialización o experiencia</p>
                </div>
                <BasicInput
                  classes='col-12 col-md-6 col-lg-3'
                  basicVal={this.state.doctor.specialties[0]}
                  disabled={true}
                />
                <BasicInput
                  classes='col-12 col-md-6 col-lg-3'
                  basicVal={this.state.doctor.specialties[1]}
                  disabled={true}
                />
                <BasicInput
                  classes='col-12 col-md-6 col-lg-3'
                  basicVal={this.state.doctor.specialties[2]}
                  disabled={true}
                />
                <BasicInput
                  classes='col-12 col-md-6 col-lg-3'
                  basicVal={this.state.doctor.specialties[3]}
                  disabled={true}
                />
              </div>
              <div className="form-row">
                <div className='col-12'>
                  <label className='text' htmlFor='des'>Doctor de Alto nivel</label>
                  <p>Describe brevemente tu trayectoria médica</p>
                </div>
                <div className='col-12'>
                  <textarea
                    id='des' rows='5'
                    value={this.state.summary}
                    disabled={true}>
                  </textarea>
                </div>

                <label className='col-12 text' > Experiencia profesional</label>
                {this.state.doctor.professionalExperiences.map((professionalExp, index) =>
                  <>
                    <BasicInput
                      classes='col-10'
                      label=''
                      basicVal={professionalExp}
                      disabled={true}
                      maxLength={200}
                    />
                  </>
                )}

                <label className='col-12 text' > Cargos en asociaciones públicas</label>
                {this.state.doctor.positionsInPublicAssociations.map((positionInPublicAssociation, index) =>
                  <><BasicInput
                    classes='col-10'
                    label=''
                    basicVal={positionInPublicAssociation}
                    disabled={true}
                    maxLength={200}
                  />
                  </>
                )
                }

                <label className='col-12 text' > Logros académicos destacados</label>
                {this.state.doctor.academicAchievements.map((academicAchievement, index) =>
                  <>
                    <BasicInput
                      classes='col-10'
                      label=''
                      basicVal={academicAchievement}
                      disabled={true}
                      maxLength={200}
                    />
                  </>
                )
                }

                <label className='col-12 text' >Miembro de asociaciones médicas privadas</label>
                {this.state.doctor.memberPrivateMedicalAssociations.map((memberPrivateMedicalAssociation, index) =>
                  <>
                    <BasicInput
                      classes='col-10'
                      label=''
                      basicVal={memberPrivateMedicalAssociation}
                      disabled={true}
                      maxLength={200}
                    />
                  </>
                )
                }

                <label className='col-12 text' >Idiomas</label>
                {this.state.doctor.languages.map((language, index) =>
                  <>
                    <BasicInput
                      classes='col-10'
                      label=''
                      basicVal={language}
                      disabled={true}
                      maxLength={50}
                    />
                  </>
                )
                }

                <label className='col-12 text' >Enfermedades, pruebas y tratamientos</label>
                {this.state.doctor.specialtyAreas.map((specialtyArea, index) =>
                  <>
                    <BasicInput
                      classes='col-10'
                      label=''
                      basicVal={specialtyArea}
                      disabled={true}
                      maxLength={200}
                    />
                  </>
                )
                }
              </div>

            </form>
          </FormField>
          <div className='doctor-validation col-12 row no-gutters pl-0 pr-0'>
            <div className='col-11'>
              <textarea
                id='com' rows='3' placeholder='Comentarios'
                value={this.state.doctor.adminNotes !== null ? this.state.doctor.adminNotes : ''}
                onChange={e => this.notesUpdate(e.target.value)}
              >
              </textarea>
            </div>
            <div className='col-1'>
              <label
                className={
                  'col-12 options-text ' +
                  (this.state.doctor.professionalLicenseStatus === 'Válida' && (this.state.doctor.status === 'En Edición' || this.state.doctor.status === 'Revisión') ? 'active' : 'inactive')
                }
                onClick={() => this.saveNotes('Liberado')}
              >
                Autorizar
              </label>
              <label
                className={
                  'col-12 options-text ' +
                  (!true ? ' inactive' : ' active')
                }
                onClick={() => this.saveNotes('Rechazado')}
              >
                Rechazar
              </label>

              <label
                className={
                  'col-12 options-text ' +
                  (!true ? ' inactive' : ' active')
                }
                onClick={() => this.saveNotes('En Edición')}
              >
                Editar
              </label>
            </div>
          </div>
        </div >

      </>
    )
  }
}