import React, { Component } from 'react'
import Loading from '../../Common/loading/Loading'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Modal from '../../Common/modal/Modal'
import prepareEndpoint from '../../../Utils/EndPoints'
import { getData, getFile, postData, putData } from '../../../Utils/API'
import DetailHistory from './DetailHistory'
import './History.scss'
import Invoice from '../Invoice/Invoice'
import ReactStars from 'react-stars'
import BasicInput from '../../Common/inputs/BasicInput'
import sortIcon from '../../../Assets/icons/Ordenar.svg'
import FileViewer from "react-file-viewer";
import Conciliation from './Conciliation'
import history from '../../../history'

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentSelected:'',
      loading: false,
      activeTab: 0,
      grouped: [
        {
          selectedRate:0,
          key: '',
          doctorName: '',
          doctorLastName: '',
          doctorSpeciality: '',
          lastAppointment: '',
          lastDate: new Date(),
          doctorRate: 0,
          appointments: [
            {
              id: '',
              doctorName: '',
              doctorLastName: '',
              doctorSpeciality: '',
              doctorRate: 0,
              startDateTime: new Date(),

              hours: '',
              locationName: '',
              status: '',

              serviceCategory: '',
              serviceName: '',
              servicePrice: '',
              rate: 0,
              canceledReason: '',
              hasInvoice:false,
              createdDate : new Date(),
              reschedules: 0
            }
          ]
        }
      ],
      prescription: {
        show: false,
        file: null
      },
      showInvoiceInformation: false,
      showDetail: false,
      selectedGroup: {
        key: '',
        doctorName: '',
        doctorLastName: '',
        doctorSpeciality: '',
        lastAppointment: '',
        lastDate: new Date(),
        doctorRate: 0,
        appointments: [
          {
            id: '',
            doctorName: '',
            doctorLastName: '',
            doctorSpeciality: '',
            doctorRate: 0,
            startDateTime: new Date(),

            hours: '',
            locationName: '',
            status: '',

            serviceCategory: '',
            serviceName: '',
            servicePrice: '',
            rate: 0,
            canceledReason: '',
            hasInvoice:false,
            createdDate:new Date(),
            reschedules: 0
          }
        ]
      },
      currentPatiend: {
        id: '',
        rfc: '',
        email: '',
        zip: '',
        suburb: '',
        street: '',
        invoiceInformation: {
          rfc: '',
          email: '',
          zip: '',
          suburb: '',
          street: ''
        }
      },
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'DoctorName',
      sortOrder: 1,//1= ascending, 0 = descending
      filter: ''
    };
    this.fileInputRef = React.createRef();
  }
  async componentDidMount() {
    this.setState({ loading: true });
    const details = await this.GetAppointments(this.state.pageIndex, this.state.sortField, this.state.sortOrder);
    this.setState({ grouped: details.appointments })
    this.setState({ loading: false });
    this.getPatient();
  }

  async GetAppointments(newPageIndex, newSortField, newSortOrder) {
    const url = `${prepareEndpoint('Patient')}/GetGroupedAppointments/${newPageIndex}/${this.state.pageSize}/${newSortField}/${newSortOrder}?filter=${this.state.filter}`;
    const resp = await getData(url);
    let details = {
      appointments: []
    };
    if (resp.status === 200) {
      const options2 = { style: 'currency', currency: 'USD' };
      const numberFormat2 = new Intl.NumberFormat('en-US', options2);

      this.setState({ totalCount: resp.data.appointmentsGroupedTotalCount });
      details.appointments = resp.data.appointmentsGrouped.map(a => {
        return {
          key: a.key,
          doctorName: a.doctorName,
          doctorLastName: a.doctorLastName,
          doctorSpeciality: a.doctorSpeciality,
          lastAppointment: a.lastAppointment,
          lastDate: new Date(a.lastDate),
          doctorRate: a.doctorRate,
          appointments: a.appointments.map(d => {
            return {
              id: d.id,
              doctorName: d.doctorName,
              doctorLastName: d.doctorLastName,
              doctorSpeciality: d.doctorSpeciality,
              doctorRate: d.doctorRate,
              startDateTime: this.getDate(new Date(d.startDateTime.toString().slice(0, -1))),

              hours: this.getTime(new Date(d.startDateTime.toString().slice(0, -1))),
              locationName: d.locationName,
              status: d.status,

              serviceCategory: d.serviceCategory,
              serviceName: d.serviceName,
              servicePrice: `${numberFormat2.format(d.servicePrice)}`,
              rate: d.rate,
              canceledReason: d.canceledReason,
              hasInvoice: d.hasInvoice,
              createdDate: this.getDate(new Date(d.createdDate)),
              reschedules: d.reschedules
            }
          })
        }
      });
    }

    return details;
  }

  getTime = (eventDateTime) => {
    const startDateTime = new Date(eventDateTime.toString().slice(0, -1));
    return `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`;
  }

  getDate = (eventDateTime) => {
    return new Date(eventDateTime);
  }

  getPatient = async () => {
    const url = prepareEndpoint('Patient');
    const resp = await getData(url);

    if (resp?.status !== 200) {
      return;
    }

    const { invoiceInformation } = resp.data;

    this.setState({ currentPatient: invoiceInformation });
  }

  async GetPrescription(appointmentId) {
    const url = `${prepareEndpoint('Appointment')}/${appointmentId}/Prescription`;

    const resp = await getData(url);

    if (resp.status === 200) {
      let prescription = {
        show: true,
        appointmentId: appointmentId,
        fromSelector: false,
        file: resp.data
      }
      this.setState({ prescription });
    }
    else {
      let prescription = {
        show: true,
        appointmentId: appointmentId,
        fromSelector: true,
        file: null
      }
      this.setState({ prescription });
    }

    return;
  }

  closePrescriptionModal = () => {
    let prescription = {
      show: false,
      appointmentId: '',
      fromSelector: true,
      file: null
    }
    this.setState({ prescription });
    this.setState({ showDetail: true });
  }

  showPrescription = appointmentId => async () => {
    this.setState({ showDetail: false });
    this.setState({ loading: true });

    await this.GetPrescription(appointmentId);

    this.setState({ loading: false });
  }

  closeDetailModal = () => {
    this.setState({ selectedGroup: {} });
    this.setState({ showDetail: false });
  }

  ShowDetailModal = (doctorId) => {
    let details = this.state.grouped.find(x => x.key === doctorId);
    if (details) {
      this.setState({ selectedGroup: details });
      this.setState({selectedRate: details.doctorRate});
      this.setState({ showDetail: true });
    }
  }

  goToDoctorDetails = (doctorId) => {
    history.push(`/appointment/doctorInformation?doctorId=${doctorId}`);
  }

  toggleShowInvoiceInformation = (appointmentId) => {
    this.setState({ showInvoiceInformation: !this.state.showInvoiceInformation,  appointmentSelected: appointmentId});
    if (this.state.showInvoiceInformation) {
      this.setState({ showDetail: true });
    }
    else {
      this.setState({ showDetail: false });
    }
  }

  async getInvoicePdf (appointmentId) {
    const url = `${prepareEndpoint('Invoice')}/${appointmentId}/zip`;
    await getFile(url, '');
  }

  setInvoiceAsTrue=()=>{
    const {selectedGroup,appointmentSelected} = this.state;
    const apt2 = selectedGroup.appointments.find(a=> a.id === appointmentSelected);
    if(apt2){
      apt2.hasInvoice = true;
    }
    this.setState({selectedGroup});
    this.toggleShowInvoiceInformation();
  }
  uploadFile = () => {
    this.fileInputRef.current.click();
  }

  handleChange(selectorFiles) {
    let prescription = {
      show: true,
      appointmentId: this.state.prescription.appointmentId,
      fromSelector: true,
      file: selectorFiles[0]
    }
    this.setState({ prescription });

    const formData = new FormData();
    formData.append("formFile", selectorFiles[0]);

    const url = `${prepareEndpoint('Appointment')}/${this.state.prescription.appointmentId}/UploadPrescription`;
    postData(url, formData);
  }

  canCreateInvoice(date){
    return date.getMonth() === (new Date()).getMonth();
  }

  async OnRatingChanged(rateId, newRating) {
    const url = `${prepareEndpoint('Appointment')}/${rateId}/PatientRate/${newRating}`;

    const resp = await putData(url);
    if (resp.status === 200) {
      this.setState({ loading: true });
      const details = await this.GetAppointments(this.state.pageIndex, this.state.sortField, this.state.sortOrder);
      this.setState({ grouped: details.appointments });
      let selectedGroup = details.appointments.find(x => x.key === this.state.selectedGroup.key);
      if (selectedGroup) {
        this.setState({selectedRate: selectedGroup.doctorRate});
        this.setState({ selectedGroup: selectedGroup });
      }

      this.setState({ loading: false });
    }
  }

  getPagination() {
    const list = [1]

    var numberOfPages = this.state.totalCount > 0 ? this.state.totalCount / this.state.pageSize : 0;
    for (var i = 2; i < numberOfPages + 1; i++) {
      list.push(i)
    }

    return (
      <div className='paginationSection'>
        <span onClick={() => this.changePage((this.state.pageIndex - 1), numberOfPages)}>&lt;</span>
        {list.map(i => <span key={i} onClick={() => this.changePage(i, numberOfPages)}>{i} </span>)}
        <span onClick={() => this.changePage(this.state.pageIndex + 1, numberOfPages)}>&gt;</span>
      </div>
    );
  }

  onSearchInput = async () => {
    this.setState({ loading: true });
    const details = await this.GetAppointments(this.state.pageIndex, this.state.sortField, this.state.sortOrder);
    this.setState({ grouped: details.appointments });
    this.setState({ loading: false });
  }

  changePage = async (newPageIndex, numberOfPages) => {
    if (newPageIndex > 0 && newPageIndex <= numberOfPages + 1 && newPageIndex !== this.state.pageIndex) {
      this.setState({ loading: true });
      this.setState({ pageIndex: newPageIndex });
      const details = await this.GetAppointments(newPageIndex, this.state.sortField, this.state.sortOrder);
      this.setState({ grouped: details.appointments });
      this.setState({ loading: false });
    }
  }

  changeSorting = async (newSorting) => {
    this.setState({ loading: true });
    var newSortOrder = this.state.sortOrder === 1 ? 0 : 1;
    this.setState({ sortField: newSorting });
    this.setState({ sortOrder: newSortOrder });
    const details = await this.GetAppointments(this.state.pageIndex, newSorting, newSortOrder);
    this.setState({ grouped: details.appointments });
    this.setState({ loading: false });
  }

  getCurrentPageSize() {
    var isLastPage = this.state.pageSize > this.state.grouped.length;
    var maxSize = isLastPage ? (this.state.pageIndex === 1 ? 0 : this.getCurrentInitialCount()) + this.state.grouped.length : this.state.pageSize;
    var currentPageSize = isLastPage ? maxSize : this.state.pageIndex * maxSize
    return currentPageSize;
  }

  getCurrentInitialCount() {
    var currentInitialValue = this.state.pageIndex === 1 ? 1 : ((this.state.pageIndex * this.state.pageSize) - this.state.pageSize);
    return currentInitialValue;
  }

  render() {
    return (
      <div className='doctor-container background-light'>
        {this.state.loading && <Loading />}

        <Modal
          className='prescriptionModal'
          show={this.state.prescription.show}
          modalClosed={this.closePrescriptionModal}
          closeIcon={true}
          appointmentId={this.state.prescription.appointmentId}
        >
          <h3 className='text-center'>Receta</h3>

          {this.state.prescription.file ?
            this.state.prescription.fromSelector ?
              <FileViewer fileType={this.state.prescription.file.type.split('/')[1]} filePath={URL.createObjectURL(this.state.prescription.file)} onError={e => {
                console.log(e, "error in file-viewer");
              }} />
              :
              <div className='d-flex justify-content-center img-wrapper'>
                <img alt='' src={`data:image/jpeg;base64,${this.state.prescription.file}`} />
              </div>
            :
            <div className='scroll bkg-white'>
              <div className='text-center full-Height'>
                <label>Ningun receta cargada</label>
              </div>
            </div>
          }

          <form>
            <input
              ref={this.fileInputRef}
              type='file'
              className='hide'
              accept='image/*,application/pdf'
              onChange={(e) => this.handleChange(e.target.files)} />
          </form>
          <div className='text-center'>
            <label className='text'>Formatos permitidos: .png, .jpg</label>
          </div>
          <div className='text-center'>
            <button type='button' className='btn primary-btn' onClick={this.uploadFile}>Cargar Receta</button>
          </div>
        </Modal>

        <Modal className='modal-main' show={this.state.showInvoiceInformation} modalClosed={this.toggleShowInvoiceInformation} closeIcon={true}>
          <Invoice toggleShowInvoiceInformation={this.toggleShowInvoiceInformation} patientInformation={this.state.currentPatient} onSuccessSave={this.setInvoiceAsTrue} appointmentId={this.state.appointmentSelected} />
        </Modal>

        <Modal
          className='detailsModal'
          show={this.state.showDetail}
          modalClosed={this.closeDetailModal}
          closeIcon={true}
        >
          <h3 className='text-center'>Ficha del especialista</h3>

          <div className='row without-margin'>
            <div className='width70'>
              <div className="row without-margin">
                <label className='text space-right mb-0'>Especialista:</label>
                {this.state.selectedGroup.doctorName} {this.state.selectedGroup.doctorLastName}
              </div>
              <div className="row without-margin">
                <label className='text space-right mb-0'>Especialidad:</label>
                {this.state.selectedGroup.doctorSpeciality}
              </div>
              <div className="row without-margin">
                <label className='text space-right mb-0'>Calificación:</label>
                <div className="inline-stars">
                  <ReactStars
                    count={5}
                    value={this.state.selectedRate}
                    size={24}
                    color1={'#FFF'}
                    color2={'#FDAD7B'}
                    edit={false}
                    half={false}
                  />
                </div>
              </div>
            </div>

            <button className='btn primary-btn center-height' onClick={() => this.goToDoctorDetails(this.state.selectedGroup.key)} >
              Volver a reservar
            </button>
          </div>
          <div className='divider mb-0 mt-0'></div>

          <label className='text-patient'>Historial de Citas</label>

          <div className='table-container'>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <div className='text-th'>
                      <label className='text-patient'>
                        Fecha
                      </label>
                    </div>
                  </Th>
                  <Th>
                    <div className='text-th'>
                      <label className='text-patient'>
                        Horario
                      </label>
                    </div>
                  </Th>
                  <Th>
                    <div className='text-th'>
                      <label className='text-patient'>
                        Ubicación
                      </label>
                    </div>
                  </Th>
                  <Th>
                    <div className='text-th'>
                      <label className='text-patient'>
                        Precio
                      </label>
                    </div>
                  </Th>
                  <Th>
                    <div className='text-th'>
                      <label className='text-patient'>
                        Estatus
                      </label>
                    </div>
                  </Th>
                  <Th>
                    <div className='text-th'>

                    </div>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.selectedGroup?.appointments?.map(p => {
                  return (
                    <Tr key={p.id} className='col-12 col-md-6 space vertical-baseline'>
                      <Td>
                        <div className='text-th'>
                          {`${p.startDateTime.getDate().toString().padStart(2, '0')}-${(p.startDateTime.getMonth() + 1).toString().padStart(2, '0')}-${p.startDateTime.getFullYear()}`}
                        </div>
                      </Td>
                      <Td>
                        <div className='text-th'>
                          {p.hours}
                        </div>
                      </Td>
                      <Td>
                        <div className='text-th'>
                          {p.locationName}
                        </div>
                      </Td>
                      <Td>
                        <div className='text-th'>
                          {p.servicePrice}
                        </div>
                      </Td>
                      <Td>
                        <div className='text-th'>
                          {p.status}
                        </div>
                      </Td>
                      <Td>
                      <div className='row without-margin' style={{justifyContent:'center'}}>
                          <button className='btn single-btn button-static-width dynamic-btn' onClick={this.showPrescription(p.id)}>
                            Digitalizar Receta
                          </button>
                          {false /*!p.hasInvoice*/ && <button disabled={!this.canCreateInvoice(p.createdDate)} className='btn single-btn button-static-width' onClick={()=>this.toggleShowInvoiceInformation(p.id)}>
                            Facturar
                          </button>}
                          {false /*p.hasInvoice*/ && <button className='btn single-btn button-static-width' onClick={()=>this.getInvoicePdf(p.id)}>
                            Descargar Factura
                          </button>}                        

                            <div className='div-stars'>
                              <DetailHistory rateId={p.id} rate={p.rate}
                                onRatingChanged={
                                  (newRating) => { 
                                    this.OnRatingChanged(p.id, newRating) 
                                  }
                                  }/>
                            </div>
                        </div>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </div>
        </Modal>
        <div className='row d-flex justify-content-space-between'>
            <label className={`link ml-3 ${this.state.activeTab === 0? 'active':'inactive'}`} onClick={()=>{this.setState({activeTab:0})}}>Especialistas</label>
            <label className={`link ml-3 ${this.state.activeTab === 1? 'active':'inactive'}`} onClick={()=>{this.setState({activeTab:1})}}>Conciliación de cuenta</label>
        </div>
        {this.state.activeTab === 0 && 
          <div>
            <div className='row mt-2 d-flex justify-content-space-between'>
          <div className='col-12 col-md-3 mt-3 d-flex justify-content-flex-start'>
            <label className='text-patient'>Nombre o apellido del especialista</label>
          </div>
          <BasicInput
            classes='col-12 col-md-6 mt-2'
            onChangeFxn={(val) => { this.setState({ filter: val }) }}
            basicVal={this.state.filter}
          />
          <div className="col-12 col-md-3 mt-2">
            <button className='btn primary-btn col-xl' onClick={() => { this.onSearchInput() }}>
              Realizar búsqueda
            </button>
          </div>
        </div>

        <div className='divider'></div>
        <div className='col-12 col-md-3 pl-0 d-flex justify-content-flex-start'>
          <label className='text-patient'>
            Registro {this.state.totalCount > 0 ? this.getCurrentInitialCount() : 0}{this.state.totalCount > 0 ? `- ${this.getCurrentPageSize()}` : ''} de {this.state.totalCount}
          </label>
        </div>
        {this.state.totalCount === 0 ? <div className='text-th'><label>No se encontraron resultados</label></div>
          : <div className='col-12 pr-0 pl-0'>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Nombre
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('DoctorName')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Apellido
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('DoctorLastName')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Especialidad
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('DoctorSpeciality')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Antigüedad
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('LastDate')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Última cita
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('LastDate')} />
                    </div>
                  </Th>
                  <Th>
                    <div className='th-icon d-flex justify-content-space-flex-start'>
                      <label className='text-patient'>
                        Calificación
                      </label>
                      <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('DoctorRate')} />
                    </div>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.grouped.map(p => {
                  return (
                    <Tr key={p.key} className='col-12 col-md-6 space'>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.doctorName}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.doctorLastName}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.doctorSpeciality}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {p.lastAppointment}
                        </div>
                      </Td>
                      <Td>
                        <div className='d-flex justify-content-space-flex-start'>
                          {`${p.lastDate.getDate().toString().padStart(2, '0')}-${(p.lastDate.getMonth() + 1).toString().padStart(2, '0')}-${p.lastDate.getFullYear()}`}
                        </div>
                      </Td>
                      <Td>
                        <div className='row'>
                          <div>
                            <ReactStars
                              count={5}
                              value={p.doctorRate}
                              size={24}
                              color1={'#FFF'}
                              color2={'#FDAD7B'}
                              edit={false}
                              half={false}
                            />
                          </div>
                          <button className='btn single-btn space-left mt-1' onClick={e => this.ShowDetailModal(p.key)}>
                            Ver
                          </button>
                        </div>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </div>
        }

        <div className='divider'></div>
        <div className='paginationSection'>{this.getPagination()}</div>
          </div>
        }        
        
      {this.state.activeTab=== 1 &&
        <Conciliation></Conciliation>
      }  
      </div>
    )
  }
}