const toCurrency = (price) => {
  let mexicanPeso = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  return mexicanPeso.format(price);
};

export { toCurrency };
