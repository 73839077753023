import customHistory from '../history';
import authConfig from '../Initialization/auth-config';
import { getData, postData, getFile } from '../Utils/API';
import prepareEndpoint from '../Utils/EndPoints';

/**
 * Central service for auth related tasks.
 */
class AuthService {
  _setCurrentUser(user, email) {
    authConfig.authToken = user.token;
    authConfig.userId = user.userId;
    authConfig.sessionAvailable = true;
    authConfig.role = user.roleType;
    authConfig.validDr = user.validDr;
    authConfig.email = email;
    authConfig.initialize();
  }
  async logIn({ email, password }) {
    const url = prepareEndpoint('AccountLogin');
    const response = await postData(url, { email, password });
    if (response && response.status === 200) {
      this._setCurrentUser(response.data, email);
    }
    return response;
  }
  async register({ email, password, roleType, isAssistant }) {
    const url = prepareEndpoint('AccountRegister');
    const response = await postData(url, { email, password, roleType });
    if (response && response.status === 200 && !isAssistant) {
      this._setCurrentUser(response.data, email);
    }
    return response;    
  }

  async terminos() {
    const url = prepareEndpoint('GetTerminosCondiciones');
    const response = await getFile(url, 'TerminosYCondicionesSanApp2023.pdf');
    return response;    
  }

  roles() {
    const urlRole = prepareEndpoint('UserRole');
    return getData(urlRole).then(respRole => {
      if (respRole.status === 200) {
        let role = respRole.data;
        authConfig.role = role;
      }
    });
  }
  async requestPasswordChange(userEmail) {
    const urlCode = prepareEndpoint('RequestPasswordChange') + `?userEmail=${userEmail}`;
    var response = await getData(urlCode);
    return response.status === 200;
  }
  async isCodeValid(userEmail, validationCode) {
    const urlCode = prepareEndpoint('ValidatePasswordCode') + `?userEmail=${userEmail}&recoveryCode=${validationCode}`;
    var response = await getData(urlCode);
    return response.data;
  }
  async changePassword(email, recoveryCode, newPassword, repeatPassword) {
    const url = prepareEndpoint('ChangePassword');
    var response = await postData(url, { email, recoveryCode, newPassword, repeatPassword });
    return response && response.status === 200;
  }

  async updatePassword(email, newPassword, repeatPassword) {
    const url = prepareEndpoint('UpdatePassword');
    var response = await postData(url, { email, newPassword, repeatPassword });
    return response && response.status === 200;
  }

  /**
   * Method to end session and clean up cookies
   */
  logout() {
    //cleanup
    authConfig.userId = null;
    authConfig.authToken = null;
    authConfig.sessionAvailable = false;
    authConfig.email = null;
    customHistory.push('/');
  }

}

export default new AuthService();
