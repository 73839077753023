import React, {Component} from 'react'
import DatePicker,{ registerLocale, setDefaultLocale } from  'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../modal/Modal'
import es from 'date-fns/locale/es';
import backDisabled from '../../../Assets/icons/BackArrowDisabled.svg';
import back from '../../../Assets/icons/Agenda semana-atras.svg'
import next from '../../../Assets/icons/Agenda semana-adelante.svg';
import './Calendar.scss'
import moment from 'moment';

registerLocale('es', es)
setDefaultLocale('es')
export default class Calendar extends Component {

  constructor(props){
    super(props)
    this.state ={
      startDate: new Date()
    }
  }

  isNonPast  = (date) => {
    return !this.isOldDate(date);
  };
  isOldDate = (date, options= {operation: 'isBefore', type :'day'}) => {
    const today = new moment();
    const currentDate = new moment(date);
    return currentDate[options.operation](today, options.type);
  }
  selectDay =  date => {
    if (typeof this.props.onSelectDay === 'function') {
      this.props.onSelectDay(date);
    }
  }
  goMonthBack = (date,callBack) => {
    if(!this.isOldDate(date, {operation: 'isSameOrBefore', type:'month'})){
      callBack()
    }
  }
  render() {
    return (
      <Modal show={this.props.show} modalClosed={this.props.closeFx} closeIcon={true} className='modal-main calendar'>
        <div>
          <h3 className='pretty-title text-center'>Selecciona una fecha para continuar</h3>
          <div className="divider-3"></div>
          <DatePicker
            locale="es"
            renderCustomHeader={({
              monthDate,
              customHeaderCount,
              decreaseMonth,
              increaseMonth,
            }) => (
              <div>
                <button
                  aria-label="Previous Month"
                  className={
                    'react-datepicker__navigation react-datepicker__navigation--previous'
                  }
                  style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
                  onClick={()=> { this.goMonthBack(monthDate, decreaseMonth)}}
                >
                  <img src={this.isOldDate(monthDate, {operation: 'isSameOrBefore', type:'month'}) ? backDisabled : back} alt='back' />
                </button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString('es-MX', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
                <button
                  aria-label="Next Month"
                  className={
                    'react-datepicker__navigation react-datepicker__navigation--next'
                  }
                  style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
                  onClick={increaseMonth}
                >
                  <img src={next} alt='next' />

                </button>
              </div>
            )}
            selected={this.state.startDate}
            onChange={this.selectDay}
            startDate={this.state.startDate}
            inline
            disabledKeyboardNavigation
            monthsShown={2}
            filterDate={this.isNonPast}
            dayClassName={(date) =>
              this.isOldDate(date) ? 'disabled' : ''
            }
          />
        </div>
      </Modal>

    )
  }
}