import React from 'react'
import './FormField.scss'

export function FormField({ children, icon, text, secondaryText, secondaryTextUrl, iconEvent, history, leftClassName }) {

  return (
    <div className='row field no-gutters'>
      <div className='col-lg-2 col-md-2 col-sm-2 col-xs-12 col-margin'>
        <div className={`left-s border-rounded session-link${leftClassName !== undefined? ` ${leftClassName}`:''}`}>
          {icon && <img src={icon} alt='menuIcon' onClick={iconEvent} />}
          <p>{text}</p>
          {(secondaryText && history) &&
                        <span onClick={() => history.push(secondaryTextUrl)}>{secondaryText}</span>
          }
        </div>
      </div>
      <div className='col-lg-10 col-md-10 col-sm-10 col-xs-12'>
        <div className='right-s border-rounded'>
          {children}
        </div>
      </div>
    </div>
  )

}
