import React, { Component } from 'react';
import './Login.scss'
import IconInput from '../Common/inputs/IconInput'
import lockIcon from '../../Assets/icons/Resumen profesional pop up 2-contrasena.svg'
import infoIcon from '../../Assets/icons/Resumen profesional pop up 2-info.svg'
import FacebookLogo from '../../Assets/icons/FacebookLogo.svg'
import GoogleLogo from '../../Assets/icons/GoogleLogo.svg'
import Loading from '../Common/loading/Loading'
import authService from '../../security/auth-service';
import { ROLES } from '../../Utils/Constants';
import hideIcon from '../../Assets/icons/eye.svg';
import showIcon from'../../Assets/icons/eye-closed.svg';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
      isTermsAndConditionsChecked: false,
      userOrPasswordIncorrectMessage: '',
      showPassword:false,
      errors:{
        email:false,
        password: false,
        isTermsAndConditionsChecked:false,
        userOrPasswordIncorrect:false,
        registerError:false
      }
    };
  }

  isValidForm =(isRegister)=>{
    const {email, password, isTermsAndConditionsChecked, errors} = this.state;
    let isValidForm = true;
    if(!email || email.trim()==='')
    {
      errors.email = true;
      isValidForm = false;
    }
    if(!password || password.trim()==='')
    {
      errors.password = true;
      isValidForm = false;
    }
    if(isRegister && !isTermsAndConditionsChecked)
    {
      errors.isTermsAndConditionsChecked = true;
      isValidForm=false;
    }
    this.setState({errors: errors});
    return isValidForm;
  }
  cleanError = type => {
    const {errors} = this.state;
    if (errors[type]) {
      errors[type] = false
    }
    this.setState({ errors })
  };
  clearErrors = () =>{
    const {errors} = this.state;
    errors.email=false;
    errors.isTermsAndConditionsChecked = false;
    errors.password = false;
    errors.registerError= false;
    errors.userOrPasswordIncorrect = false;
    this.setState({ errors })
  }
  updateState = (val, field) => {
    this.setState({ [field]: val })
    this.cleanError(field)
  }
  checkTermsAndConditions = async ()=>{
    const {errors} = this.state;
    errors.isTermsAndConditionsChecked = false;
    await authService.terminos();
    this.setState({isTermsAndConditionsChecked: !this.state.isTermsAndConditionsChecked, errors});
  }
  login = async () => {
    this.clearErrors();
    if(!this.isValidForm(false))
    {
      return
    }

    const {email, password, errors} = this.state;

    this.setState({ loading: true });
    const response = await authService.logIn({email, password} );
    if (response.status !== 200 || !response.data || response.data.length <= 0) {

      errors.userOrPasswordIncorrect = true;
      this.setState({ loading: false, errors: errors, userOrPasswordIncorrectMessage: 'Email y/o contraseña incorrectos.' });
      return;

    }
    if(this.props.onSuccessLogin){
      this.props.onSuccessLogin();
    }
    this.setState({ loading: false });
  }
  register = async () => {
    this.clearErrors();
    if(!this.isValidForm(true))
    {
      return
    }

    const {email, password, errors} = this.state;
    this.setState({ loading: true });
    const roleType = this.props.isDoctor ? ROLES.Doctor : ROLES.Patient;
    const isAssistant = false;
    const response = await authService.register({email, password, roleType, isAssistant });
    if (response.status !== 200 || !response.data || response.data.length <= 0) {
      let userOrPasswordIncorrectMessage = 'Email y/o contraseña incorrectos.';
      if (response.data && response.data.message && response.data.message.includes('already taken')) {
        userOrPasswordIncorrectMessage = 'El nombre de usuario ya existe.';
      }

      errors.userOrPasswordIncorrect = true;
      this.setState({ loading: false, errors: errors, userOrPasswordIncorrectMessage });
      return;

    }else if(this.props.onSucessRegister){
      
      this.props.onSucessRegister();

    }
    this.setState({ loading: false });
  }
  render() {

    return (
      <div className='login-container'>
        {this.state.loading && <Loading />}
        <h2 className="text-center">Bienvenido{this.props.isDoctor ? ', Doctor' : ''}</h2>
        <form>
          <div className='row margin-top-bottom-15px'>
            <div className='col-12'>
              <IconInput inputVal={this.state.email} 
                onChange={val => this.updateState(val, 'email')}
                error={this.state.errors.email}
                prependIcon={infoIcon} placeHolder={'Ingresa tu correo electrónico'} />
            </div>
          </div>
          <div className='row margin-top-bottom-15px'>
            <div className='col-12'>
              <IconInput inputVal={this.state.password} 
                onChange={val => this.updateState(val, 'password')}
                error={this.state.errors.password}
                prependIcon={lockIcon} placeHolder={'Ingresa tu contraseña'} inputType={this.state.showPassword?'text':'password'} appendIcon={this.state.showPassword?hideIcon:showIcon} onAppendIconClick={()=> this.setState({showPassword:!this.state.showPassword})}/>
            </div>
          </div>
          <div className={this.state.errors.isTermsAndConditionsChecked?'row ml-4 errorContainer':'hidden'}>
            <p>Por favor acepta los términos y condiciones.</p>
          </div>
          <div className={this.state.errors.userOrPasswordIncorrect?'row ml-4 errorContainer':'hidden'}>
            <p>{this.state.userOrPasswordIncorrectMessage ? this.state.userOrPasswordIncorrectMessage : 'Error al validar usuario, intente de nuevo.'}</p>
          </div>
          <div className={this.state.errors.registerError?'row ml-4 errorContainer':'hidden'}>
            <p>Lo sentimos no se puede registrar esta cuenta.</p>
          </div>
          <div className='row mt-4 pt-3 mb-2'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 font-size-10px'>
              <label><input className='margin-right-10px' type="checkbox" checked={this.state.isTermsAndConditionsChecked} onChange={() => this.checkTermsAndConditions()} />Acepto los <span className='link'>términos y condiciones</span> de servicio de Sanapp y reconozco haber leído su <span className='link'> política de privacidad.</span></label>
            </div>
          </div>
          <div className='row flex j-center'>
            <div className='' >
              <button type="button" className="btn confirm-btn btn-width-fill" onClick={() => this.login()}>Acceder a mi cuenta</button>
            </div>
            <div className='' >
              <button type="button" className="btn primary-btn btn-width-fill" onClick={() => this.register()}>Registrate</button>
            </div>
            <div className='' >
              <div className='social-media-container'>
                <img src={FacebookLogo} className='facebook-logo clickable' alt='prependIcon' />
                <img src={GoogleLogo} className='google-logo' alt='prependIcon' />
              </div>
              <p className='social-media-label'>Inicia sesión con tus redes sociales</p>

            </div>

          </div>
          {this.props.onForgetPassword && <div className='row'>
            <div className='col-8 pt-1 ml-3'>
              <label className='font-size-12px link' onClick={()=>this.props.onForgetPassword()}>
              Olvidé mi contraseña
              </label>
            </div>
            <div className='col-4'></div>
          </div>}
        </form>
      </div>
    );
  }
}
