import React,{useState} from 'react'
import { usePlacesWidget } from 'react-google-autocomplete';
import { getData } from '../../../Utils/API';
import prepareEndpoint from '../../../Utils/EndPoints';
import './Inputs.scss'

export default  (props) => {
  let [suggestions, setSuggestions] =useState([])
  const onAppendIconClick =()=>
  {
    if(props.onAppendIconClick)
    {
      props.onAppendIconClick();
    }
  }
  const onChange = (event) => {
    let value = event.target.value;
    if(props.suggestions){
      const url = prepareEndpoint('Specialty');
      if (!value || value.length <= 3) {
        setSuggestions([])
      }else{
        getData(url + '?keyword=' + value).then(resp => {
          if (!resp || resp.status !== 200) {
            setSuggestions([])
          }else{
            setSuggestions(resp.data);
          }
        })
      }
    }
    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
  }
  const suggestionClicked = suggestion => {
    if (typeof props.onSuggestionClick === 'function') {
      props.onSuggestionClick(suggestion);
    }
    setSuggestions([])
  }
  const clearSuggestion = () => {
    setTimeout(() => {
      setSuggestions([])
    }, 250);    
    
  }
  const { ref } = usePlacesWidget({
    onPlaceSelected: (place) => {
      if (typeof props.onChange === 'function') {
        props.onPlaceSelected(place.formatted_address);
      }
    },
    options: {
      types: [],
      componentRestrictions: { country: 'mx' },
    },
  });
  const clickPrepend = () => {
    if(props.clickPrepend){
      props.clickPrepend();
    }
  }
  const numeric=(e)=> {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  return (
  // make sure to add space when pacing class prop or it will
  // merge into existing class
    <div
      className={
        'input-group icon-input ' +
          (props.classes ? props.classes : '') + 
          (props.prependIcon ? '' : 'round-lt') + 
          ((props.appendIcon || props.appendChild) ? '' : 'round-rt')
      }
    >
      {  props.prependIcon && <div className='input-group-prepend'>
        <span onClick={clickPrepend} className={'input-group-text '+(props.iconClasses ? props.iconClasses : '')}>
          <img src={props.prependIcon} alt='prependIcon'/>
        </span>
      </div>}
      <input
        id={props.id}
        type={!props.inputType ? 'text' : props.inputType}
        className={'form-control ' + (props.error ? 'is-invalid' : '')}
        autoFocus={props.autoFocus}
        placeholder={props.placeHolder}
        aria-label={props.placeHolder}
        onBlur={clearSuggestion}
        onChange={e => onChange(e)}
        onKeyPress={e => {
          if (props.numeric) {
            numeric(e);
          }
          if(props.onKeyPress){
            props.onKeyPress(e);
          }
        }}
        value={props.inputVal}
        maxLength={props.maxLength}
        ref={props.autompleteAddress ? ref : null}
      />
      {   (props.appendIcon || props.appendChild) &&      <div className='input-group-append'>
        <span className={'input-group-text ' +
          (props.childClasses ? props.childClasses : '')}>
          {/* {!props.appendChild && <img src={props.appendIcon} alt='prependIcon' onClick={this.onAppendIconClick} className={props.onAppendIconClick?'icon-clickable':''}/>} */}
          {!props.appendChild && <img src={props.appendIcon} alt='prependIcon' className={props.onAppendIconClick?'clickable':''} onClick={onAppendIconClick}/>}
          {props.appendChild}
        </span>
      </div>}
      {   suggestions &&   <div className='col-md-12 autoSuggestions-absolute' >
        { suggestions.map((suggestion,i) =>
          <div key={suggestion+i} className="suggestion col-md-12 justify-content-md-center" onClick={e => suggestionClicked(suggestion) } >{suggestion}</div>
        )}
      </div>}
    </div>
  )
  
}
