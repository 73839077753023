import React, { Component, Fragment } from 'react'
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api'
import prepareEndpoint from '../../Utils/EndPoints';
import { deleteData, getData, postData } from '../../Utils/API';
import { IS_ASSISTANT } from '../../Utils/Generic'
import InputRange from 'react-input-range';
import { Checkbox } from 'pretty-checkbox-react';
import moment from 'moment';
import './SelectDoctor.scss'
import 'react-input-range/lib/css/index.css'
import { appContext } from '../../Utils/Context';
import ReactStars from 'react-stars';
import _ from 'lodash';
import Modal from '../Common/modal/Modal'
import FlowControl from './Flow/FlowControl';
import SortIcons from '../Common/sortIcons';
import Loading from '../Common/loading/Loading';
import ProfileImage from '../../Assets/icons/ICONO HOMBRE.svg'
import back from '../../Assets/icons/Agenda semana-atras.svg'
import next from '../../Assets/icons/Agenda semana-adelante.svg'
import backDisabled from '../../Assets/icons/BackArrowDisabled.svg'
import nextDisabled from '../../Assets/icons/NextArrowDisabled.svg'
import LikeDislike from '../Common/likeDislike/LikeDislike';
import authConfig from '../../Initialization/auth-config';


class SelectDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dayStartIndex: 0,
      dayEndIndex: 3,
      center: {
        lat: 0,
        lng: 0
      },
      markers: [],
      priceRange: {
        min: 0,
        max: 5000,
      },
      distanceRange: {
        min: 0,
        max: 30,
      },
      locationTypes: {
        hospital: {
          checked: true,
          value: 'Hospital'
        },
        clinic: {
          checked: true,
          value: 'Clínica'
        },
        indp: {
          checked: true,
          value: 'Consultorio'
        }
      },
      doctors: [],
      filteredDoctors: [],
      initcoordinates: false,
      hoursModal: false,
      availableDays: [],
      showServices: false,
      date: new Date(),
      loading: false,
      isFirstTime: false
    }
    this.NUMBERS_OF_DAYS = 4;
    this.MAX_NUMBERS_OF_DAYS = 8;
    this.maxRangeRequested = 5;
    this.flowRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this.setHours();
    const contextState = this.context.state;
    const contextLat = contextState.lat;
    const contextLng = contextState.lng;
    const { specialty, address, date } = this;
    const isSameDate = moment(date).isSame(contextState.date, 'day');
    if (specialty !== contextState.specialty || address !== contextState.address || !isSameDate) {
      const { address, specialty, date } = contextState;
      this.address = address;
      this.specialty = specialty;
      this.date = date;
      const center = {
        lat: contextLat,
        lng: contextLng
      }
      await this.getDoctors(specialty, address, center);
      this.setState({ center, initcoordinates: true, address, specialty });
    };
    this.setAvailableDays(new moment());

    this.setState({ loading: false });
  }

  setAvailableDays = (currentDate) => {
    let availableDays = [];
    for (let i = 1; i <= this.MAX_NUMBERS_OF_DAYS; i++) {
      availableDays.push({
        date: currentDate.clone(),
        dateFormatted: currentDate.format('D MMM'),
        text: this.getDayBaseOnDate(currentDate)
      })
      currentDate.add(1, 'day')
    }
    this.setState({ availableDays });
    return availableDays;
  }

  async componentDidUpdate() {
    const contextState = this.context.state;
    const contextLat = contextState.lat;
    const contextLng = contextState.lng;
    const { specialty, address, date } = this;
    const isSameDate = moment(date).isSame(contextState.date, 'day');
    if (contextState.forceUpdate || (specialty !== contextState.specialty || address !== contextState.address || !isSameDate)) {
      this.context.updateForce(false);
      this.setState({ loading: true });
      const { address, specialty, date } = contextState;
      this.address = address;
      this.specialty = specialty;
      this.date = date;
      const center = {
        lat: contextLat,
        lng: contextLng
      }
      const distanceRange = {
        min: 0,
        max: 30,
      }
      this.maxRangeRequested = 5;
      this.setState({ distanceRange }, async () => {
        await this.getDoctors(specialty, address, center);
        this.setState({ center, initcoordinates: true, address, specialty, loading: false });
      })

    }

  }
  filterDoctors = (doctors, distance, price, locationTypes) => {
    doctors = this.filterByDistance(doctors, distance);
    doctors = this.filterByPrice(doctors, price);
    doctors = this.filterByLocation(doctors, locationTypes);
    return doctors;
  }
  filterByDistance = (doctors, distance) => {
    return _.filter(doctors, d => { return d.calculatedDistance <= distance.max && d.calculatedDistance >= distance.min });
  }
  filterByPrice = (doctors, price) => {
    return _.filter(doctors, d => {
      return !!!_.isEmpty(_.filter(d.doctorServices, s => { return s.price <= price.max && s.price >= price.min }))
    });
  }
  filterByLocation = (doctors, locationTypes) => {
    locationTypes = _.filter(locationTypes, l => { return l.checked })
    let locationValues = locationTypes.map(l => l.value)
    return _.filter(doctors, d => { return locationValues.includes(d.locationType) });
  }
  startFlow = (selectedDoctor) => {
    this.hideServicesModal();
    this.setState({ selectedDoctor })
    this.flowRef.current.start();
  }
  getDoctors = async (specialty, address) => {
    this.setState({ loading: true });
    const distanceRange = this.state.distanceRange;
    const contextDate = new Date(this.context.state.date);//.toLocaleDateString('en-US').replace('/', '-');
    const currentDate = new Date();

    let dateWithCurrentTime = new Date(contextDate.getFullYear(), contextDate.getMonth(), contextDate.getDate(), currentDate.getHours(), contextDate.getMinutes());
    const dateLocaleNotime = dateWithCurrentTime.toLocaleDateString('en-US').replace('/', '-');
    const timeLocale = dateWithCurrentTime.toLocaleTimeString();
    const dateLocaleWithTime = dateLocaleNotime + ' ' + timeLocale;

    let url = `${prepareEndpoint('DoctorSearch')}?specialty=${specialty}&address=${address}&distanceRange=${distanceRange.max}&dateAvailability=${dateLocaleWithTime}`;
    const response = await getData(url);
    let doctorsResponse = [];
    if (response && response.status === 200) {
      doctorsResponse = response.data;
    } else {
      //@TODO: Handle error
    }

    let doctors = []
    doctorsResponse.map((d, i) => {
      return d.locations.map((l, index) => {
        const dateAvailables = _.find(d.agendaAvailabilities, a => { return l.id === a.locationId });

        if (dateAvailables && !_.isEmpty(dateAvailables.availability)) {
          l.doctorServices = l.doctorServices.sort(function (a, b) { return a.price - b.price; });
          d.specialty = d.specialtyField;
          d.address = l.streetAndNumber;
          d.locationId = l.id;
          d.latitude = l.latitude;
          d.longitude = l.longitude;
          d.locationType = l.type;
          d.availableDates = dateAvailables.availability
          const firstDateAvailable = dateAvailables.availability[0];
          const availableTimes = firstDateAvailable.availableTimes;
          if (!_.isEmpty(availableTimes)) {
            d.nearDay = this.getDayBaseOnDate(firstDateAvailable.availableDate);
            d.nearDayFormatted = moment(firstDateAvailable.availableDate).format('D MMM')
            d.selectedDate = moment(firstDateAvailable.availableDate).format('YYYY-MM-DD');
            d.selectedHour = this.getFirstAvailableTime(availableTimes);
            d.tempId = `${i}${index}`;
            d.location = `${l.type} ${l.addressName}`;
            d.fullName = `${d.firstName} ${d.lastName}`
            d.img = d.img ?
              d.img.includes('data:image/jpeg;base64,') ? d.img : `data:image/jpeg;base64,${d.img}`
              : ProfileImage;
            doctors.push({ ...d, ...l })
          }
          return l;
        }
        return d;
      })

    });
    // removing doctors which hours are less than current time...we need to hanlde this from backend

    const priceRange = this.state.priceRange;
    const locationTypes = this.state.locationTypes;
    let filteredDoctors = this.filterDoctors(doctors, distanceRange, priceRange, locationTypes);
    this.setDoctorsAndMarkers(filteredDoctors);
    this.setState({ doctors, filteredDoctors, loading: false })
  }

  getFirstAvailableTime(availableTimes) {
    for (var i = 0; i < availableTimes.length; i++) {
      if (this.formatHour(availableTimes[i].start) < this.formatHour(availableTimes[i].end)) {
        return this.formatHour(availableTimes[i].start);
      }
    }
  }

  getDayBaseOnDate = (date) => {
    date = new moment(date);
    let str = '';
    const today = new moment();
    const tomorrow = today.clone().add(1, 'day')
    if (date.isSame(today, 'day')) {
      str = 'Hoy';
    } else if (date.isSame(tomorrow, 'day')) {
      str = 'Mañana';
    } else {
      str = date.format('ddd');
    }
    return str;
  }
  setDoctorsAndMarkers = (filteredDoctors) => {
    this.setState({ filteredDoctors });
  }
  filterByProperty = (doctors, prop, price) => {
    return _.filter(doctors, function (d) { return d[prop] <= price });
  }
  updatePriceRange = priceRange => {
    this.setState({ priceRange });
  }
  onUpdatePriceEnds = priceRange => {
    let filteredDoctors = _.cloneDeep(this.state.doctors);
    const { distanceRange, locationTypes } = this.state;
    filteredDoctors = this.filterDoctors(filteredDoctors, distanceRange, priceRange, locationTypes);
    this.setDoctorsAndMarkers(filteredDoctors);
    this.setState({ priceRange });
  }
  updateDistanceRange = distanceRange => {
    this.setState({ distanceRange });
  }
  onUpdateDistanceEnds = distanceRange => {
    const { max } = distanceRange;
    if (max > this.maxRangeRequested) {
      this.maxRangeRequested = max;
      this.setState({ distanceRange }, () => {
        const { specialty, address, center } = this.state;
        this.getDoctors(specialty, address, center);
      })
    } else {
      let filteredDoctors = _.cloneDeep(this.state.doctors);
      const { priceRange, locationTypes } = this.state;
      filteredDoctors = this.filterByDistance(filteredDoctors, distanceRange, priceRange, locationTypes);
      this.setDoctorsAndMarkers(filteredDoctors);
      this.setState({ distanceRange })
    }
  }
  updateLocationType = e => {
    let filteredDoctors = _.cloneDeep(this.state.doctors);
    const { id, checked } = e.target;
    const locationTypes = { ...this.state.locationTypes }
    locationTypes[id].checked = checked;
    const { distanceRange, priceRange } = this.state;
    filteredDoctors = this.filterDoctors(filteredDoctors, distanceRange, priceRange, locationTypes);
    this.setDoctorsAndMarkers(filteredDoctors);
    this.setState({ locationTypes })
  }
  selectService = e => {
    const { id } = e.target;
    const { doctorServices } = this.state;
    let index = _.findIndex(doctorServices, ['id', id]);
    doctorServices[index].selected = !doctorServices[index].selected;
    this.setState({ doctorServices })
  }
  selectFirsTime = e => {
    this.setState((prevState) => ({
      isFirstTime: !prevState.isFirstTime
    }));
  }
  showHoursModal = (availableDates, tempId, selectedDay, selectedDate) => {
    this.tempId = tempId;
    this.availableDates = availableDates;
    const availableDays = this.setAvailableDays(new moment(selectedDate));
    let index = this.getDateIndex(selectedDay, availableDays);

    this.setState({ hoursModal: true, selectedDay, dayStartIndex: index });
  }
  getDateIndex = (selectedDay, availableDays) => {
    let index = _.findIndex(availableDays, ['dateFormatted', selectedDay]);
    return index;
  }
  hideHoursModal = () => {
    this.setState({ hoursModal: false, dayStartIndex: 0 });
  }
  setHours = () => {
    var workHours = [];
    const date = new moment('0600', 'hmm');
    for (var i = 0; i <= 30; i++) {
      workHours.push(date.format('HH:mm'));
      date.add(30, 'minute')
    }
    this.setState({ workHours });
  }

  selectNewDate = (date, hour, tempId) => {
    let filteredDoctors = _.cloneDeep(this.state.filteredDoctors);
    let doctors = _.cloneDeep(this.state.doctors);
    let index = _.findIndex(filteredDoctors, ['tempId', tempId]);
    filteredDoctors[index].nearDay = this.getDayBaseOnDate(date);
    filteredDoctors[index].nearDayFormatted = moment(date).format('D MMM')
    filteredDoctors[index].selectedDate = moment(date).format('YYYY-MM-DD');
    filteredDoctors[index].selectedHour = hour;


    index = _.findIndex(doctors, ['tempId', tempId]);
    doctors[index].nearDay = this.getDayBaseOnDate(date);
    doctors[index].nearDayFormatted = moment(date).format('D MMM')
    doctors[index].selectedDate = moment(date).format('YYYY-MM-DD');
    doctors[index].selectedHour = hour;

    this.setState({ filteredDoctors, doctors });
    this.hideHoursModal();
  }
  goToMoreHours = () => {
    const { tempId } = this;
    let filteredDoctors = _.cloneDeep(this.state.filteredDoctors);
    let index = _.findIndex(filteredDoctors, ['tempId', tempId]);
    this.goToDoctorAccount(filteredDoctors[index])
  }
  onSelectDoctor = (doctor) => {
    const { doctorServices } = doctor;
    doctorServices.map(s => {
      s.selected = false;
      return s;
    })
    this.selectedDoctor = doctor;
    this.setState({ showServices: true, doctorServices, isFirstTime: false });
  }
  onContinueWithService = () => {
    const { doctorServices, isFirstTime } = this.state;
    this.selectedDoctor.doctorServices = doctorServices;
    this.selectedDoctor.isFirstTime = isFirstTime;
    this.startFlow(this.selectedDoctor);
  }
  hideServicesModal = () => {
    this.setState({ showServices: false, doctorServices: [] });
  }
  getPrice = doctorServices => {
    let consulta = _.find(doctorServices, ['category', 'Consulta']) || {};
    if (consulta && consulta.price > 0) {
      return this.numberWithCommas(consulta.price)
    }
    return 0;
  }

  getPriceNumber = doctorServices => {
    let consulta = _.find(doctorServices, ['category', 'Consulta']) || {};
    if (consulta && consulta.price > 0) {
      return parseInt(consulta.price)
    }
    return 0;
  }

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  goToDoctorAccount = (doctor) => {
    this.props.history.push(`doctorInformation?doctorId=${doctor.userId}`);
  }
  sortBy = (type, order) => {
    let filteredDoctors = _.cloneDeep(this.state.filteredDoctors);
    if (type === 'price') {
      filteredDoctors.sort((a, b) => {
        if (order === 'desc') {
          let x = a;
          a = b;
          b = x;
        }
        return (this.getPriceNumber(a.doctorServices) > this.getPriceNumber(b.doctorServices)) ? 1 : ((this.getPriceNumber(b.doctorServices) > this.getPriceNumber(a.doctorServices)) ? -1 : 0)
      });
    } else if (type === 'selectedDate') {
      filteredDoctors.sort((a, b) => {
        if (order === 'desc') {
          let x = a;
          a = b;
          b = x;
        }
        return this.sortByDate(a, b);
      });
    } else {

      filteredDoctors.sort((a, b) => {
        if (order === 'desc') {
          let x = a;
          a = b;
          b = x;
        }
        return this.sortFunc(a, b, type);
      });

    }
    this.setState({ filteredDoctors });
  }
  sortFunc = (a, b, type) => {
    return (a[type] > b[type]) ? 1 : ((b[type] > a[type]) ? -1 : 0)
  }
  sortByDate = (a, b) => {
    if (a.selectedDate > b.selectedDate) return 1;
    if (b.selectedDate > a.selectedDate) return -1;
    if (a.selectedHour > b.selectedHour) return 1;
    if (b.selectedHour > a.selectedHour) return -1;
  }
  getdaysIndex = () => {
    const { dayStartIndex } = this.state;
    let dayEndIndex = dayStartIndex + this.NUMBERS_OF_DAYS;
    dayEndIndex = Math.floor(dayEndIndex, this.state.availableDays.length);
    return { dayStartIndex, dayEndIndex };
  }
  getDaysHeaders = () => {
    let component = [];
    const { availableDays } = this.state;
    const { dayStartIndex, dayEndIndex } = this.getdaysIndex();
    let i = dayStartIndex;
    for (; i < dayEndIndex; i++) {
      let d = availableDays[i];
      component.push(
        <div className='col-2 header' key={d.text}>
          <div className='text text-center'>{d.text}</div>
          <span className='small text-center'>{d.dateFormatted}</span>
        </div>)
    }
    return component;
  }
  getDaysHours = () => {
    let component = [];
    const { availableDays } = this.state;
    const { dayStartIndex, dayEndIndex } = this.getdaysIndex();
    let i = dayStartIndex;
    for (; i < dayEndIndex; i++) {
      let d = availableDays[i];
      component.push(
        <div className='col-2' key={'c-' + d.text}>
          {this.state.workHours.map((h, i) => {
            return <div className='hours text-center' key={d + i}> {this.checkHour(h, d.date)} </div>
          })}
        </div>
      );
    }
    return component;
  }
  formatHour = (hour) => {
    let formattedHour = hour.split(':');
    if (formattedHour.length > 1) {
      if (formattedHour[1].length === 1) {
        formattedHour[1] = '0' + formattedHour[1];
      }
      else {
        formattedHour[0] = formattedHour[1] === '45' ? +formattedHour[0] + 1 : formattedHour[0];
        formattedHour[1] = formattedHour[1] === '15' ? '30' : formattedHour[1] === '45' ? '00' : formattedHour[1];
      }
    }
    else {
      formattedHour[1] = '00';
    }

    if (formattedHour[0].length === 1) {
      formattedHour[0] = '0' + formattedHour[0];
    }
    return formattedHour.join(':');
  }
  checkHour = (hour, date) => {
    const { availableDates, tempId } = this;
    let i = 0;
    for (; i < availableDates.length; i++) {
      if (availableDates[i]) {
        let availableDate = availableDates[i].availableDate;
        let isAvailable = false;
        if (date.isSame(availableDate, 'day')) {
          const availableTimes = availableDates[i].availableTimes;
          for (let j = 0; j < availableTimes.length; j++) {
            availableTimes[j].start = this.formatHour(availableTimes[j].start);
            availableTimes[j].end = this.formatHour(availableTimes[j].end);
            isAvailable = hour >= availableTimes[j].start && hour < availableTimes[j].end
            if (isAvailable) {
              return <span className='available' onClick={() => { this.selectNewDate(date, hour, tempId) }}>{hour}</span>
            }
          }
          return <span >-</span>
        }
      }
    }
    return <span>-</span>
  }
  decreseDays = () => {
    let { dayStartIndex } = this.getdaysIndex();
    if (dayStartIndex > 0) {
      dayStartIndex--;
      this.setState({ dayStartIndex })
    }
  }
  increaseDays = () => {
    let { dayStartIndex, dayEndIndex } = this.getdaysIndex();
    if (dayEndIndex < this.MAX_NUMBERS_OF_DAYS) {
      dayStartIndex++;
      this.setState({ dayStartIndex })
    }
  }
  getBackButton = () => {
    const { dayStartIndex } = this.getdaysIndex();
    const canDecrease = dayStartIndex > 0;
    const img = canDecrease ? back : backDisabled;
    const classes = `col-2 header text-center ${canDecrease ? 'pointer' : ''}`
    return <div className={classes} onClick={this.decreseDays}>
      <span className={'btn-back'} >
        <img src={img} alt='back' />
      </span>
    </div>
  }
  getNextButton = () => {
    const { dayEndIndex } = this.getdaysIndex();
    const canIncrease = dayEndIndex < this.MAX_NUMBERS_OF_DAYS;
    const img = canIncrease ? next : nextDisabled;
    const classes = `col-2 header text-center ${canIncrease ? 'pointer' : ''}`
    return <div className={classes} onClick={this.increaseDays}>
      <span className={'btn-next'} >
        <img src={img} alt='next' />
      </span>
    </div>
  }

  isContinueDisabled = () => {
    const { doctorServices } = this.state;
    return _.filter(doctorServices, ['selected', true]).length <= 0;
  }
  toggleLike = async (value, userId) => {
    this.setState({ loading: true, isTogglingLike: true });
    let filteredDoctors = _.cloneDeep(this.state.filteredDoctors);
    let doctors = _.cloneDeep(this.state.doctors);

    let url = `${prepareEndpoint('Favorites')}?favoriteUserId=${userId}`;
    let response;
    if (value) {
      response = await deleteData(url);
    } else {
      response = await postData(url);
    }
    if (response && (response.status === 200 || response.status === 201)) {
      _.forEach(filteredDoctors, function (d) {
        if (d.userId === userId) {
          d.isFavorite = !d.isFavorite

        }
      });

      _.forEach(doctors, function (d) {
        if (d.userId === userId) {
          d.isFavorite = !d.isFavorite
        }
      });

      this.setState({ filteredDoctors, doctors });
    } else {
      //@TODO: Handle error
    }
    this.setState({ loading: false, isTogglingLike: false });

  }

  skipExtraLocationTypeString = (locationName) => {
    if (!locationName) {
      return;
    }
    let t = 0;
    locationName = locationName.replace(/hospital/ig, match => ++t === 2 ? '' : match);
    t = 0;
    locationName = locationName.replace(/consultorio/ig, match => ++t === 2 ? '' : match);
    t = 0;
    locationName = locationName.replace(/clínica/ig, match => ++t === 2 ? '' : match);
    t = 0;
    locationName = locationName.replace(/clinica/ig, match => ++t === 2 ? '' : match);
    return locationName;
  }

  render() {
    return (
      <div className='select-doctor'>
        {this.state.loading && <Loading />}
        <div className='row'>
          <div className='col-12 col-lg-3 col-xl-4'>
            <div className='map'>
              <div className='map-container'>
                {this.state.initcoordinates && <GoogleMap
                  id='doctors-location-map'
                  zoom={12}
                  center={this.state.center}
                  mapContainerStyle={{
                    height: '300px'
                  }}
                  clickableIcons={false}
                  options={{
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false
                  }}
                >
                  {this.state.filteredDoctors.map(d => {
                    return (
                      <Fragment key={d.tempId}>
                        <Marker
                          position={{
                            lat: d.latitude,
                            lng: d.longitude
                          }}
                        />
                        <InfoWindow
                          position={{
                            lat: d.latitude,
                            lng: d.longitude
                          }}
                        >
                          <div className='info-marker'>
                            <div className='specialist'>
                              <img className='img-Profile' src={d.img} alt={d.name} />
                              <div>
                                <span>{d.firsName} {d.lastName}</span>
                                <ReactStars
                                  count={5}
                                  value={d.rate}
                                  size={24}
                                  color1={'#FFF'}
                                  color2={'#FDAD7B'}
                                  edit={false}
                                  half={true}
                                />
                                <div className=''>
                                  <div>Distancia: {d.calculatedDistance} KM</div>
                                  <div>Precio: ${d.doctorServices[0].price}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      </Fragment>
                    )
                  })}
                </GoogleMap>}
                <div className='filters'>
                  <div>
                    <p></p>
                    <p><strong>Precio de consulta</strong></p>
                    <span>MXN {this.state.priceRange.min} - MXN {this.state.priceRange.max}</span>
                    <InputRange
                      draggableTrack
                      maxValue={5000}
                      minValue={0}
                      value={this.state.priceRange}
                      onChange={this.updatePriceRange}
                      onChangeComplete={this.onUpdatePriceEnds}
                      formatLabel={() => { return '' }}
                      step={50}
                    />
                  </div>
                  <div>
                    <p><strong>Tipo de Ubicación</strong></p>
                    <div>
                      <Checkbox
                        id='hospital'
                        onChange={this.updateLocationType}
                        checked={this.state.locationTypes.hospital.checked}
                        color='primary'
                      >
                        Hospital
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id='clinic'
                        onChange={this.updateLocationType}
                        checked={this.state.locationTypes.clinic.checked}
                        color='primary'
                      >
                        Clínica
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id='indp'
                        onChange={this.updateLocationType}
                        checked={this.state.locationTypes.indp.checked}
                        color='primary'
                      >
                        Consultorio Independiente
                      </Checkbox>
                    </div>
                  </div>
                  <div>
                    <p><strong>Distancia</strong></p>
                    <span>KM {this.state.distanceRange.min} - KM {this.state.distanceRange.max}</span>
                    <InputRange
                      draggableTrack
                      maxValue={30}
                      minValue={0}
                      value={this.state.distanceRange}
                      onChange={this.updateDistanceRange}
                      onChangeComplete={this.onUpdateDistanceEnds}
                      formatLabel={() => { return '' }}
                      step={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-9 col-xl-8'>
            <div>
              <h4 className='pretty-title'>{this.state.specialty} - {this.state.address}</h4>
              <p></p>
            </div>
            <div className='row no-gutters text-center'>
              <div className={this.state.address ? 'd-none d-lg-block col-3 column-title' : 'd-none d-lg-block col-5 column-title'}>
                <label className='text'>Especialista</label>
                <SortIcons onClickUp={() => { this.sortBy('fullName') }} onClickDown={() => { this.sortBy('fullName', 'desc') }} />
              </div>
              <div className='col-4 col-lg-2 column-title'>
                <label className='text'>Cita Próxima</label>
                <SortIcons onClickUp={() => { this.sortBy('selectedDate') }} onClickDown={() => { this.sortBy('selectedDate', 'desc') }} />
              </div>
              <div className='d-none d-lg-block col-2 column-title'>
                <label className='text'>Ubicación</label>
                <SortIcons onClickUp={() => { this.sortBy('location') }} onClickDown={() => { this.sortBy('location', 'desc') }} />
              </div>
              {this.state.address &&
                            <div className='col-4 col-lg-2 column-title'>
                              <label className='text'> Distancia</label>
                              <SortIcons onClickUp={() => { this.sortBy('calculatedDistance') }} onClickDown={() => { this.sortBy('calculatedDistance', 'desc') }} />
                            </div>
              }
              <div className='col-4 col-lg-auto column-title'>
                <label className='text'>Precio de consulta</label>
                <SortIcons onClickUp={() => { this.sortBy('price') }} onClickDown={() => { this.sortBy('price', 'desc') }} />
              </div>
            </div>
            <div className='divider-2'></div>
            {(!this.state.loading && _.isEmpty(this.state.filteredDoctors)) && (
              <div style={{textAlign:'center'} }>
                <div><strong>Lo sentimos, parece que no hay resultados para tu búsqueda.</strong></div>
                <div><strong>¡No te preocupes!, tenemos otras opciones disponibles para ti. </strong></div>
              </div>
            )}
            {(this.state.loading && !this.state.isTogglingLike) && (
              <strong>Estamos buscando la mejor opción para ti...</strong>
            )}
            <div className='doctors'>
              {this.state.filteredDoctors.map(d => {
                return (
                  <div className='row text-center' key={d.userId + d.tempId}>
                    <div className={this.state.address ? 'col-12 col-lg-3 specialist' : 'col-12 col-lg-5 specialist'}>
                      <img onClick={() => { this.goToDoctorAccount(d) }} className='img-Profile' src={d.img} alt={d.name} />
                      <div>
                        <div>
                          <span onClick={() => { this.goToDoctorAccount(d) }}>{d.firstName} {d.lastName}</span>
                          {authConfig.sessionAvailable && <span onClick={() => this.toggleLike(d.isFavorite, d.userId)}><LikeDislike fill={d.isFavorite} onClick={() => this.toggleLike(d.isFavorite, d.userId)} /></span>}
                        </div>
                        <div className='speciality-div' onClick={() => { this.goToDoctorAccount(d) }}>
                          {d.specialty}
                        </div>
                        <ReactStars
                          count={5}
                          value={d.rate}
                          size={24}
                          color1={'#FFF'}
                          color2={'#FDAD7B'}
                          edit={false}
                          half={true}
                        />
                      </div>
                    </div>
                    <div className='col-4 col-lg-2 '>
                      <div className='flex j-center'>
                        <span className='near-day'>{d.nearDay}</span>
                        <i className='dd-toggle icon shine' onClick={() => { this.showHoursModal(d.availableDates, d.tempId, d.nearDayFormatted, d.selectedDate) }}></i>
                      </div>
                      <span> {d.selectedHour} hrs.</span>
                    </div>
                    <div className='d-none d-lg-block col-2'>
                      <span>{this.skipExtraLocationTypeString(d.location)}</span>
                    </div>
                    {this.state.address &&
                            <div className='col-3 col-lg-2'>
                              <span>{d.calculatedDistance} KM</span>
                            </div>
                    }
                    {!IS_ASSISTANT() && <div className='col-5 col-lg-2 price'>
                      <span>${this.getPrice(d.doctorServices)}</span>
                      <button type="button" className="btn primary-btn reservar" onClick={() => { this.onSelectDoctor(d) }}>Reservar</button>
                    </div>}
                  </div>
                )
              })}
            </div>
            {!_.isEmpty(this.state.filteredDoctors) &&
              <>
                <div className='doctors-footer'>
                  {this.state.filteredDoctors.length} {this.state.specialty} en {this.state.address}
                </div>
                <p>*El precio de consulta está sujeto a cambios sin previo aviso.</p>
              </>
            }
            {this.state.hoursModal && <Modal
              show={true}
              modalClosed={this.hideHoursModal}
              className='modal-hours'
            >
              <div className='row  no-gutters header-container'>
                {this.getBackButton()}

                {this.getDaysHeaders()}

                {this.getNextButton()}

              </div>
              <div className='row all-hours no-gutters'>
                <div className='col-2 header text-center'>

                </div>
                {this.getDaysHours()}
                <div className='col-2 header text-center'>

                </div>
              </div>
              <div className='small text-center margin-10 link' onClick={this.goToMoreHours}>
                Consulta más horarios
              </div>
            </Modal>}
            {this.state.showServices &&
              <Modal
                show={true}
                modalClosed={this.hideServicesModal}
                closeIcon={true}
                className={'services-modal'}
              >
                <h4 className='services-title'>Selecciona los servicios</h4>
                <div className='row services-headers'>
                  <div className='col-8'>Servicio</div>
                  <div className='col-4'>Precio</div>
                </div>

                <div className='row services'>
                  {this.state.doctorServices.map(s => {
                    return (
                      <>
                        <div className='col-8'>
                          <Checkbox
                            id={s.id}
                            onChange={this.selectService}
                            checked={s.selected}
                          >
                            {s.name || s.category}
                          </Checkbox>
                        </div><div className='col-4'>
                          ${s.category === 'Consulta' && this.state.isFirstTime ? s.firstTimePrice : s.price}
                        </div>
                      </>
                    )
                  })}
                </div>
                <div className='btn-container'>
                  <button disabled={this.isContinueDisabled()} type="button" className="btn primary-btn" onClick={() => { this.onContinueWithService() }}>Siguiente</button>
                </div>
              </Modal>}
          </div>
          <FlowControl ref={this.flowRef} doctorInformation={this.state.selectedDoctor} history={this.props.history}></FlowControl>
        </div>
      </div>
    )
  }
}
SelectDoctor.contextType = appContext;
export default SelectDoctor;