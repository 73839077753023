import React, { Component } from 'react'
import './DoctorProfileInformation.scss'
import doctorIcon from '../../../Assets/icons/doctor.svg'
import stethoscopeIcon from '../../../Assets/icons/Ubicaciones hospital-icono.svg'
import locationIcon from '../../../Assets/icons/Ubicaciones hospital-direccion.svg'
import logo from '../../../Assets/logo.svg'
import blueStethoscopeIcon from '../../../Assets/icons/Agenda semana popup-especialidad.svg'
import ReactStars from 'react-stars';
import InformationList from './InformationList';
import { find } from 'lodash';
import { IS_ASSISTANT } from '../../../Utils/Generic'
import { appContext } from '../../../Utils/Context';

class DoctorProfileInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionSelected: 'locations',
      locationSelected: '',
      serviceIdSelected: '',
      servicesUpdated: false,
      locationsUpdated: false,
      updated: false
    };
    this.numberFormat2 = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.services !== this.props.services) {
      this.setState({ servicesUpdated: true });
    }
    if (prevProps.locations !== this.props.locations) {
      this.setState({ locationsUpdated: true });
    }
    if (this.state.servicesUpdated && this.state.locationsUpdated && !this.state.updated) {
      this.setState({ updated: true });
      const { locations, services } = this.props;
      const service = find(services, ['locationId', locations[0]?.id])
      this.onLocationChange(locations[0]?.type);
      this.onServiceChange(service?.id);
    }
  }

  onSectionChange = (section) => {
    this.setState({ sectionSelected: section });
  }

  onLocationChange = (locationType) => {
    let lastLocation = this.state.locationSelected;
    this.setState({ locationSelected: locationType });
    let location = this.props.locations.find(e => e.type === locationType);

    if (lastLocation !== locationType) {
      const { services } = this.props;
      const service = find(services, ['locationId', location.id]);
      if (service) {
        this.onServiceChange(service.id);
      }
    }

    this.props.onLocationChangeEvent(location);
  }

  onServiceChange = (id) => {
    this.setState({ serviceIdSelected: id });
    this.props.onServiceChangeEvent(id);
  }

  showType = (locationType) => {
    const { locations, services } = this.props;
    const location = find(locations, ['type', locationType]);
    if (!location) {
      return false;
    }
    const service = find(services, ['locationId', location.id])
    return service ? true : false;
  }

  getUniqueServices = () => {
    var result = [];
    for (var i = 0; i < this.props.services.length; i++) {
      if (this.props.services[i].category && !result.includes(this.props.services[i].category)) {
        result.push(this.props.services[i].category);
      }
      if (this.props.services[i].name && !result.includes(this.props.services[i].name)) {
        result.push(this.props.services[i].name);
      }
    }
    return result;
  }

  showLocation = (locationType, location) => {
    if (location.type === locationType) {
      const { services } = this.props;
      const service = find(services, ['locationId', location.id])

      return service && (
        <div key={locationType + location.id} className={this.state.locationSelected === locationType ? 'row pl-4 pt-4' : 'hidden'}>
          <div key={location.id}
            className={this.state.serviceIdSelected === service.id ? 'd-flex pointer' : 'd-flex inactive pointer'}
            onClick={() => { this.onServiceChange(service.id) }}
          >
            <img src={locationIcon} alt='location icon' className='img-15' />
            <div className='row ml-2'>
              <div>
                <p className={this.state.serviceIdSelected === service.id ? 'bold' : ''}>{location.name}</p>
                <p>{`${location.streetAndNumber}, ${location.city}`}</p>
                <p>{location.officeNumber}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getNextPrice = () => {
    let price = '';

    if (this.props.nextAppointment !== null) {
      price = this.numberFormat2.format(this.props.nextAppointment.servicePrice);
    }
    else {
      price = ''
    }

    return price;
  }

  getNextDate = () => {
    let date = '';

    if (this.props.nextAppointment !== null) {
      date = this.props.nextAppointment.startDate;
    }
    else {
      date = ''
    }

    return date;
  }

  getNextLocation = () => {
    let location = '';

    if (this.props.nextAppointment !== null) {
      location = this.props.nextAppointment.location;
    }
    else {
      location = ''
    }

    return location;
  }

  startFlow = () => {
    this.props.onStartFlowEvent();
  }

  gotoHome =() => {
    this.context.updateMainIcon(true);
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="col-12 col-md-6 pr-1 pl-0 doctor-information">
        <div className="profileFixed row d-flex">
          <div className='p-2'>
            <img src={this.props.doctor.picture ? `data:image/jpeg;base64,${this.props.doctor.picture}` : doctorIcon} alt='doctor icon' className='img-70 mr-2 ml-2' />
          </div>
          <div className='p-2'>
            <div className='row sm-hide'>
              <div className='inline-space-between'>
                <label className='text pr-2'>{`Dr. ${this.props.doctor.firstName} ${this.props.doctor.lastName}`}</label>
                <div className='pb-1'>
                  <ReactStars
                    count={5}
                    value={this.props.doctor.rate}
                    size={12}
                    color1={'#FFF'}
                    color2={'#FDAD7B'}
                    edit={false}
                    half={true}
                  />
                </div>
              </div>
            </div>
            <div className='d-block d-sm-block d-md-none'>
              <div className='row'>
                <label className='text pr-2'>{`Dr. ${this.props.doctor.firstName} ${this.props.doctor.lastName}`}</label>
              </div>
              <div className='row'>
                <div className='pb-1'>
                  <ReactStars
                    count={5}
                    value={this.props.doctor.rate}
                    size={12}
                    color1={'#FFF'}
                    color2={'#FDAD7B'}
                    edit={false}
                    half={true}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div>
                <p>{this.props.doctor.specialtyField}</p>
                <p>Cédula profesional: {this.props.doctor.professionalLicense}</p>
              </div>
            </div>
          </div>
          <div className='ml-auto p-2 logo' onClick={this.gotoHome}>
            <img src={logo} alt="Icono: Logotipo en la insignia" />
          </div>
        </div>

        <div className="bg-green round-10">
          <div className='row no-gutters'>
            <div className='col-12 col-md-4 mt-4 ml-4'>
              <p className='bold'>Servicios:</p>
              <ul>
                {this.getUniqueServices().map(s => {
                  return <li key={s + Math.random()}>{s}</li>
                })}
              </ul>
            </div>

            <div className='col doctor-availability agenda m-3'>
              <div className="bg-green round-10 m-4">
                <div className='available-section text-center'>
                  Cita más próxima
                </div>
                <div className='row col-12 text-center'>
                  <label className='col-6 text-patient'>Día y Hora</label>
                  <label className='col-6 text'>Ubicación</label>
                </div>
                <div className='divider m-0'></div>
                <div className='row col-12 text-center'>
                  <label className='col-6'>{this.getNextDate()}</label>
                  <label className='col-6'>{this.getNextLocation()}</label>
                </div>
              </div>

              <div className='p-3 text-center'>
                <p>
                  {!IS_ASSISTANT() &&
                    <button className='btn primary-btn' onClick={this.startFlow}
                      disabled={this.state.nextAppointment === null} >
                      Reservar Ahora
                    </button>
                  }
                </p>
                <p>
                  Precio de Consulta:<span>
                    {this.getNextPrice()} </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-green round-10 mt-2 p-2 pb-4">
          <div className='row'>
            <div className='col-1'>
              <img src={stethoscopeIcon} alt='doctor icon' className='img-30' />
            </div>
            <div className='col ml-3 pt-1'>
              <div className='row'>
                <div className='expertise'>
                  <label className='text mb-0'>{`Dr. ${this.props.doctor.firstName} ${this.props.doctor.lastName} Experto en:`}</label>
                  <ul>
                    {
                      (!this.props.doctor.specialties || this.props.doctor.specialties.length === 0) ?
                        <li key={this.props.doctor.specialtyField}>{this.props.doctor.specialtyField}</li> :
                        this.props.doctor.specialties.map(s => {
                          return <li key={s + Math.random()}>{s}</li>
                        })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row px-3'>
            <div className='col-12 toggleButtons'>
              <div
                className={this.state.sectionSelected === 'locations' ? 'selected' : 'unselected'}
                onClick={() => { this.onSectionChange('locations') }}>Ubicaciones</div>
              <div
                className={this.state.sectionSelected === 'resume' ? 'selected' : 'unselected'}
                onClick={() => { this.onSectionChange('resume') }}>Resumen Profesional</div>
            </div>
          </div>
          <div className={this.state.sectionSelected === 'locations' ? 'row' : 'hidden'}>
            <div className='col-12'>
              <div className='row pl-4'>
                <div className='inline-space-between link-container'>
                  {this.props.locationType.map(s => {
                    return this.showType(s) && <p
                      key={s}
                      className={this.state.locationSelected === s ? '' : 'inactive'}
                      onClick={() => { this.onLocationChange(s) }}
                    >{s}</p>
                  })}
                </div>
              </div>
              {
                this.props.locationType.map(s => {
                  return this.props.locations.map(l => {
                    return this.showLocation(s, l);
                  })
                })
              }
            </div>
          </div>
          <div className={this.state.sectionSelected === 'resume' ? 'row' : 'hidden'}>
            <div className='col-12'>
              <div className='row pl-4'>
                <div className='d-flex'>
                  <img src={blueStethoscopeIcon} alt='location icon' className='img-15 mt-1' />
                  <div className='row ml-2'>
                    <div className='pr-5 mb-2'>
                      <label className='text'>Doctor de alto nivel</label>
                      <p>{this.props.doctor.summary !== '' ? this.props.doctor.summary : `Medico registrado en sanapp con cedula profesional ${this.props.doctor.professionalLicense}`}</p>
                    </div>
                    <div>
                      <InformationList title='Experiencia Profesional' items={this.props.doctor.professionalExperiences} />
                      <InformationList title='Cargos en asociaciones públicas' items={this.props.doctor.positionsInPublicAssociations} />
                      <InformationList title='Logros académicos destacados' items={this.props.doctor.academicAchievements} />
                      <InformationList title='Miembro de asociaciones privadas' items={this.props.doctor.memberPrivateMedicalAssociations} />
                      <InformationList title='Idiomas' items={this.props.doctor.languages} />
                      <InformationList title='Enfermedades y tratamientos' items={this.props.doctor.specialtyAreas} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
DoctorProfileInformation.contextType = appContext;
export default DoctorProfileInformation;