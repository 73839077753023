import React from 'react'

import './Inputs.scss'

export default class BasicInput extends React.Component {
  onChange(event) {
    const val = event.target.value
    if (this.props.onChangeFxn) {
      this.props.onChangeFxn(val)
    }
  }
  numeric(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  textOnly(e) {
    const re = /[A-zÀ-ÿ]+/g
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  onBlur(event) {
    if (this.props.onBlurFxn) {
      const val = event.target.value
      this.props.onBlurFxn(val);
    }
  }
  render() {
    return (
      <div className={'basic-input form-group ' + (this.props.classes ? this.props.classes : '')} id={this.props.label}>
        {this.props.label && <label className='text' htmlFor={this.props.id || ''}>{this.props.label}</label>}
        <input
          type={this.props.type || 'text'}
          autoComplete={this.props.autocomplete || 'on'}
          className={'form-control ' + (this.props.error ? 'is-invalid' : '')}
          id={'BasicInput-' + this.props.label + '-' + this.props.id + Math.random()}
          placeholder={this.props.placeHolder}
          aria-label={this.props.placeHolder}
          onChange={e => this.onChange(e)}
          maxLength={this.props.maxLength || 100}
          min = {this.props.min}
          max={this.props.max}
          value={!this.props.basicVal ? '' : this.props.basicVal}
          onKeyPress={e => {
            if (this.props.numeric) {
              this.numeric(e);
            }
            if (this.props.textOnly) {
              this.textOnly(e);
            }
            if (e.key === 'Enter' && this.props.onPressFxn) {
              this.props.onPressFxn(e);
            }
          }}
          onBlur={e => this.onBlur(e)}
          disabled={this.props.disabled ? 'disabled' : ''}
        />
      </div>
    )
  }
}
