import React, { Component } from 'react';
import ReactStars from 'react-stars';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import icono_flecha from '../../../Assets/icons/Flecha.svg';
import icono_flecha_r from '../../../Assets/icons/flecha-d.svg';
import fileDownloaded from '../../../Assets/icons/Expediente medico popup-descargar.svg';
import folderCloseIcon from '../../../Assets/icons/Expediente medico-carpeta.svg';
import folderOpenIcon from '../../../Assets/icons/Expediente medico-carpeta 2.svg'
import fileIcon from '../../../Assets/icons/File-Sheet.svg';
import sortIcon from '../../../Assets/icons/Ordenar.svg';
import notificationIcon from '../../../Assets/icons/Notificacion1.svg';
import notificationIcon2 from '../../../Assets/icons/Notificacion2.svg';
import { getData, getFile, putData, postData } from '../../../Utils/API';
import prepareEndpoint from '../../../Utils/EndPoints';
import BasicInput from '../../Common/inputs/BasicInput';
import Loading from '../../Common/loading/Loading';
import PrescriptionModal from '../../Common/modal/PrescriptionModal';
import DetailHistory from '../../Patient/Account/DetailHistory';
import manIcon from '../../../Assets/icons/ICONO HOMBRE.svg'
import './Patient.scss';

export default class Patient extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedRate: 0,
            isHistoryViewVisible: false,
            studyDownloaded: null,
            loading: true,
            grouped: [

            ],
            prescription: {
                show: false,
                file: null,
                appointmentId: '',
                fromSelector: false
            },
            showShareMedicalFileView: false,
            selectedGroup: {
                key: '',
                patientName: '',
                patientLastName: '',
                lastAppointment: '',
                lastDate: new Date(),
                patientRate: 0,
                appointments: [
                    {
                        id: '',
                        patientName: '',
                        patientLastName: '',
                        patientRate: 0,
                        patientAge: 0,
                        patientProfilePictureBase64: '',
                        startDateTime: new Date(),

                        hours: '',
                        status: '',

                        serviceCategory: '',
                        serviceName: '',
                        servicePrice: '',
                        rate: 0,

                        canceledReason: '',
                        notes: '',
                        hasPrescription: false
                    }
                ]
            },
            selectedAppointment: null,
            notesChanges: false,
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            sortField: 'PatientName',
            sortOrder: 1,//1= ascending, 0 = descending
            filter: '',
            rootfolders: [
                {
                    name: '',
                    description: '',
                    isShared: false,
                    totalChecked: 0,
                    subFolders: [
                        {
                            name: '',
                            description: '',
                            isShared: false,
                            totalChecked: 0,
                            medicalStudies: [
                                {
                                    isShared: false,
                                    name: '',
                                    date: new Date(),
                                    description: '',
                                    fileImageName: '',
                                    path: ''
                                }
                            ]
                        }
                    ]
                }
            ],
            folderSelected: '',
            foldersSelected: [
                {
                    name: '',
                    description: '',
                    medicalStudies: [
                        {
                            name: '',
                            date: new Date(),
                            description: '',
                            fileImageName: '',
                            path: ''
                        }
                    ]
                }
            ],
            subfolderSelected: '',
            studySelected: '',
            typeSelected: '',
            newFile: null,
            studyData: null,
        }
    }
    async componentDidMount() {
        this.setState({ loading: true });
        const grouped = await this.getPatients(this.state.pageIndex, this.state.sortField, this.state.sortOrder);
        this.setState({ grouped })
        this.setState({ loading: false });
    }

    async getPatients(newPageIndex, newSortField, newSortOrder) {
        const url = `${prepareEndpoint('Doctor')}/GetGroupedAppointments/${newPageIndex}/${this.state.pageSize}/${newSortField}/${newSortOrder}?filter=${this.state.filter}`;
        const resp = await getData(url);
        let appointments = [];
        if (resp.status === 200) {
            const options2 = { style: 'currency', currency: 'USD' };
            const numberFormat2 = new Intl.NumberFormat('en-US', options2);
            this.setState({ totalCount: resp.data.appointmentsGroupedTotalCount });
            appointments = resp.data.appointmentsGrouped.map(a => {
                return {
                    key: a.key,
                    patientName: a.patientName,
                    patientLastName: a.patientLastName,
                    lastAppointment: a.lastAppointment,
                    lastDate: new Date(a.lastDate),
                    patientRate: a.patientRate,
                    hasMedicalFileBeenWatchedByDoctor: a.hasMedicalFileBeenWatchedByDoctor,
                    appointments: a.appointments.map(d => {
                        return {
                            id: d.id,
                            patientName: d.patientName,
                            patientLastName: d.patientLastName,
                            patientRate: d.patientRate,
                            patientAge: d.patientAge,
                            patientProfilePictureBase64: d.patientProfilePictureBase64,
                            startDateTime: new Date(d.startDateTime),

                            hours: d.hours,
                            status: d.status,

                            serviceCategory: d.serviceCategory,
                            serviceName: d.serviceName,
                            servicePrice: `${numberFormat2.format(d.servicePrice)}`,
                            rate: d.rate,

                            canceledReason: d.canceledReason,
                            notes: d.notes,

                            hasPrescription: d.hasPrescription
                        }
                    })
                }
            });
        }
        return appointments;
    }

    async GetPrescription(appointmentId) {
        const url = `${prepareEndpoint('Appointment')}/${appointmentId}/Prescription?isDoctor=true`;

        const resp = await getData(url);

        if (resp.status === 200) {
            let prescription = {
                show: true,
                appointmentId: appointmentId,
                file: resp.data
            }
            this.setState({ prescription });
        }
        else {
            let prescription = {
                show: true,
                appointmentId: appointmentId,
                file: null
            }
            this.setState({ prescription });
        }

        return;
    }

    async GetMedicalFile(patientId) {
        const url = `${prepareEndpoint('MedicalHistory')}/Files/Shared?patientId=${patientId}&isDoctorWatching=true`;
        const resp = await getData(url);
        let details = {
            rootfolders: []
        };

        if (resp.status !== 200) {
            return details;
        }

        details.rootfolders = resp.data.rootFolders.map(r => {
            return {
                name: r.name,
                description: r.description,
                isShared: r.isShared,
                subFolders: r.subFolders.map(sf => {
                    return {
                        name: sf.name,
                        description: sf.description,
                        isShared: sf.isShared,
                        medicalStudies: sf.medicalStudies.map(m => {
                            return {
                                name: m.name,
                                date: new Date(m.date),
                                description: m.description,
                                isShared: m.isShared,
                                fileImageName: m.fileImageName,
                                path: m.path
                            }
                        })
                    }
                })
            }
        });
        for (var i = 0; i < details.rootfolders.length; i++) {
            this.setTotalsChecked(details.rootfolders[i]);
        }
        return details;
    }

    updateFoldersSelected = (value) => {
        let folderSelected = this.state.rootfolders.find(x => x.name === value);
        if (folderSelected) {
            this.setState({ foldersSelected: folderSelected.subFolders });
        }
    }

    selectRootFolder = (folder) => {
        this.setState({ folderSelected: folder });
        this.updateFoldersSelected(folder);
        this.setState({ subfolderSelected: '' });
    }

    closePrescriptionModal = () => {
        let prescription = {
            show: false,
            appointmentId: '',
            file: null
        }
        this.setState({ prescription });
    }

    showPrescription = appointmentId => async () => {
        this.setState({ loading: true });
        await this.GetPrescription(appointmentId);

        this.setState({ loading: false });
    }

    async OnRatingChanged(appointmentId, newRating) {
        const url = `${prepareEndpoint('Appointment')}/${appointmentId}/DoctorRate/${newRating}`;

        const resp = await putData(url);
        if (resp.status === 200) {
            this.setState({ loading: true });
            const details = await this.getPatients(this.state.pageIndex, this.state.sortField, this.state.sortOrder);
            this.setState({ grouped: details });
            let selectedGroup = details.find(x => x.key === this.state.selectedGroup.key);
            if (selectedGroup) {
                this.setState({ selectedGroup: selectedGroup });
                this.setState({selectedRate: selectedGroup.patientRate});
            }

            this.setState({ loading: false });
        }
    }

    async startAppointment() {
        const selectedAppointment = this.state.selectedAppointment;
         const url = `${prepareEndpoint('Doctor')}/Appointments/${selectedAppointment.id}/start`;
        const resp = await postData(url);
         if (resp.status === 200) {
            selectedAppointment.status = "En curso";
            this.setState({ selectedAppointment });
        }
        else {
            alert('Ocurrió un error al terminar la cita');
        } 
    }

    async endAppointment() {
        const selectedAppointment = this.state.selectedAppointment;
         const url = `${prepareEndpoint('Doctor')}/Appointments/${selectedAppointment.id}/end`;
        const resp = await postData(url);
         if (resp.status === 200) {
            selectedAppointment.status = "Atendida";
            this.setState({ selectedAppointment });
        }
        else {
            alert('Ocurrió un error al terminar la cita');
        } 
    }

    getPagination() {
        const list = [1]

        var numberOfPages = this.state.totalCount > 0 ? this.state.totalCount / this.state.pageSize : 0;
        for (var i = 2; i < numberOfPages + 1; i++) {
            list.push(i)
        }

        return (
            <div className='paginationSection'>
                <span onClick={() => this.changePage((this.state.pageIndex - 1), numberOfPages)}>&lt;</span>
                {list.map(i => <span key={i} onClick={() => this.changePage(i, numberOfPages)}>{i} </span>)}
                <span onClick={() => this.changePage(this.state.pageIndex + 1, numberOfPages)}>&gt;</span>
            </div>
        );
    }

    onSearchInput = async () => {
        this.setState({ loading: true });
        const details = await this.getPatients(this.state.pageIndex, this.state.sortField, this.state.sortOrder);
        this.setState({ grouped: details });
        this.setState({ loading: false });
    }

    changePage = async (newPageIndex, numberOfPages) => {
        if (newPageIndex > 0 && newPageIndex <= numberOfPages + 1 && newPageIndex !== this.state.pageIndex) {
            this.setState({ loading: true });
            this.setState({ pageIndex: newPageIndex });
            const details = await this.getPatients(newPageIndex, this.state.sortField, this.state.sortOrder);
            this.setState({ grouped: details });
            this.setState({ loading: false });
        }
    }

    changeSorting = async (newSorting) => {
        this.setState({ loading: true });
        var newSortOrder = this.state.sortOrder === 1 ? 0 : 1;
        this.setState({ sortField: newSorting });
        this.setState({ sortOrder: newSortOrder });
        const details = await this.getPatients(this.state.pageIndex, newSorting, newSortOrder);
        this.setState({ grouped: details });
        this.setState({ loading: false });
    }

    getCurrentPageSize() {
        var isLastPage = this.state.pageSize > this.state.grouped.length;
        var maxSize = isLastPage ? (this.state.pageIndex === 1 ? 0 : this.getCurrentInitialCount()) + this.state.grouped.length : this.state.pageSize;
        var currentPageSize = isLastPage ? maxSize : this.state.pageIndex * maxSize
        return currentPageSize;
    }

    getCurrentInitialCount() {
        var currentInitialValue = this.state.pageIndex === 1 ? 1 : ((this.state.pageIndex * this.state.pageSize) - this.state.pageSize);
        return currentInitialValue;
    }

    displayShareMedicalFileView = async (patientId) => {
        if (!patientId) {
            return;
        }

        try {
            const patient = this.state.grouped.find(x => x.key === patientId);
            this.setState({ showShareMedicalFileView: true, loading: true, selectedGroup: patient, selectedRate:patient.patientRate})
            const details = await this.GetMedicalFile(patientId);
            this.setState({ rootfolders: details.rootfolders })
        } catch (e) {
            console.log('Error at displayShareMedicalFileView: ', e);
        }
        this.setState({ loading: false })
    }

    notesUpdate = (value) => {
        let selectedAppointment = this.state.selectedAppointment;
        selectedAppointment.notes = value;
        this.setState({ selectedAppointment, notesChanges: true });
    }

    setTotalsChecked = (rootfolder) => {
        if (!rootfolder || !rootfolder.subFolders) {
            return;
        }

        let rootTotalChecked = 0;
        for (var i = 0; i < rootfolder.subFolders.length; i++) {
            if (!rootfolder.subFolders[i].medicalStudies) {
                continue;
            }
            let subTotalChecked = 0;
            for (var j = 0; j < rootfolder.subFolders[i].medicalStudies.length; j++) {
                subTotalChecked += rootfolder.subFolders[i].medicalStudies[j].isShared ? 1 : 0;
            }
            rootfolder.subFolders[i].isShared = subTotalChecked > 0;
            rootfolder.subFolders[i].totalChecked = subTotalChecked;
            rootTotalChecked += subTotalChecked;
        }
        rootfolder.isShared = rootTotalChecked > 0;
        rootfolder.totalChecked = rootTotalChecked;
    }

    async GetStudy(studySelected, studyFileName) {
        if (!studySelected) {
            return;
        }

        const splittedFileName = studyFileName ? studyFileName.split('.') : null;
        const fileExtension = splittedFileName && splittedFileName.length > 1 ? splittedFileName[splittedFileName.length - 1] : null;
        await this.setState({ loading: true, studySelected, typeSelected: fileExtension, studyData: null, newFile: null, studyDownloaded: false });
        try {
            const fileType = this.getType(fileExtension);
            if (fileType === 0) {
                await this.previewStudy();
            }
            else if (fileType === 1) {
                await this.downloadStudy();
            }
            else if (fileType === 2) {
                await this.renderStudyAsHtml(studySelected);
            }
        } catch (e) {
            console.log('Error at GetStudy: ', e)
        }
        this.setState({ loading: false });
    }

    getType = (studyType) => {
        switch (studyType) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'bmp':
            case 'jfif':
                return 0;
            case 'pdf':
                return 1;
            default:
                return 2;
        }
    }

    previewStudy = async () => {
        const url = `${prepareEndpoint('Patient')}/GetStudyByPatientId/${this.state.selectedGroup.key}/${this.state.folderSelected}/${this.state.subfolderSelected}/${this.state.studySelected}`;
        const resp = await getData(url);

        if (resp.status === 200) {
            this.setState({ newFile: resp.data });
        }
    }

    downloadStudy = async () => {
        const url = `${prepareEndpoint('Patient')}/DownloadStudyByPatientId/${this.state.selectedGroup.key}/${this.state.folderSelected}/${this.state.subfolderSelected}/${this.state.studySelected}`;
        let subfolder = this.state.foldersSelected.find(x => x.name === this.state.subfolderSelected);
        if (subfolder) {
            let study = subfolder.medicalStudies.find(x => x.name === this.state.studySelected);
            if (study) {
                await getFile(url, study.fileImageName);
                this.setState({ studyDownloaded: true });
            }
        }
    };

    renderStudyAsHtml = async (study) => {
        const url = `${prepareEndpoint('MedicalHistory')}/GetStudyByPatientId/${this.state.selectedGroup.key}?studyName=${study}`;
        const resp = await getData(url);

        if (resp.status === 200) {
            this.setState({ studyData: resp.data });
        }
    }

    backMainScreen = async () => {
        this.setState({ loading: true, newFile: null });
        if (this.state.selectedAppointment !== null && this.state.notesChanges) {
            await this.saveNotes(this.state.selectedAppointment.id, this.state.selectedAppointment.notes);
        }
        this.setState({ showShareMedicalFileView: false, selectedAppointment: null, notesChanges: false });
        this.setState({ loading: false });
    }

    selectDate = async (selectedAppointment) => {
        this.setState({ loading: true });
        if (this.state.selectedAppointment !== null && this.state.notesChanges) {
            await this.saveNotes(this.state.selectedAppointment.id, this.state.selectedAppointment.notes);
        }
        this.setState({ selectedAppointment, notesChanges: false });
        this.setState({ loading: false });
    }

    async saveNotes(appointmentId, notes) {
        const url = `${prepareEndpoint('Appointment')}/${appointmentId}/DoctorNotes/${notes}`;
        const resp = await putData(url);
        if (resp.status === 200) {
            const grouped = await this.getPatients(this.state.pageIndex, this.state.sortField, this.state.sortOrder);
            this.setState({ grouped })
        }
    }

    render() {
        const mainPage = (
            <div className='doctor-container patients'>
                {this.state.loading && <Loading />}
                <div className='row mt-2 d-flex justify-content-space-between'>
                    <div className='col-12 col-md-3 mt-3 d-flex justify-content-flex-start'>
                        <label className='text-patient'>Nombre o apellido del paciente</label>
                    </div>
                    <BasicInput
                        classes='col-12 col-md-6 mt-2'
                        onChangeFxn={(val) => { this.setState({ filter: val }) }}
                        basicVal={this.state.filter}
                    />
                    <div className="col-12 col-md-3 mt-2">
                        <button className='btn primary-btn col-xl' onClick={() => { this.onSearchInput() }}>
                            Realizar búsqueda
                        </button>
                    </div>
                </div>
                <div className='divider'></div>
                <div className='col-12 col-md-3 pl-0 d-flex justify-content-flex-start'>
                    <label className='text-patient'>
                        Registro {this.state.totalCount > 0 ? this.getCurrentInitialCount() : 0}{this.state.totalCount > 0 ? `- ${this.getCurrentPageSize()}` : ''} de {this.state.totalCount}
                    </label>
                </div>
                {this.state.totalCount === 0 ? <div className='text-th'><label>No se encontraron resultados</label></div>
                    : <div className='col-12 pr-0 pl-0'>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>
                                        <div className='th-icon d-flex justify-content-space-flex-start'>
                                            <label className='text-patient'>
                                                Nombre
                                            </label>
                                            <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('PatientName')} />
                                        </div>
                                    </Th>
                                    <Th>
                                        <div className='th-icon d-flex justify-content-space-flex-start'>
                                            <label className='text-patient'>
                                                Apellido
                                            </label>
                                            <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('PatientLastName')} />
                                        </div>
                                    </Th>
                                    <Th>
                                        <div className='th-icon d-flex justify-content-space-flex-start'>
                                            <label className='text-patient'>
                                                Antigüedad
                                            </label>
                                            <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('LastDate')} />
                                        </div>
                                    </Th>
                                    <Th>
                                        <div className='th-icon d-flex justify-content-space-flex-start'>
                                            <label className='text-patient'>
                                                Última cita
                                            </label>
                                            <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('LastDate')} />
                                        </div>
                                    </Th>
                                    <Th>
                                        <div className='th-icon d-flex justify-content-space-flex-start'>
                                            <label className='text-patientxt'>
                                                Calificación
                                            </label>
                                            <img src={sortIcon} alt='blocked' onClick={() => this.changeSorting('PatientRate')} />
                                        </div>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {this.state.grouped.map(p => {
                                    return (
                                        <Tr key={p.key} className='col-12 col-md-6 space'>
                                            <Td>
                                                <div className='d-flex justify-content-space-flex-start'>
                                                    {p.patientName}
                                                </div>
                                            </Td>
                                            <Td>
                                                <div className='d-flex justify-content-space-flex-start'>
                                                    {p.patientLastName}
                                                </div>
                                            </Td>
                                            <Td>
                                                <div className='d-flex justify-content-space-flex-start'>
                                                    {p.lastAppointment}
                                                </div>
                                            </Td>
                                            <Td>
                                                <div className='d-flex justify-content-space-flex-start'>
                                                    {`${p.lastDate.getDate().toString().padStart(2, '0')}-${(p.lastDate.getMonth() + 1).toString().padStart(2, '0')}-${p.lastDate.getFullYear()}`}
                                                </div>
                                            </Td>
                                            <Td>
                                                <div>
                                                    <ReactStars
                                                        count={5}
                                                        value={p.patientRate}
                                                        size={24}
                                                        color1={'#FFF'}
                                                        color2={'#FDAD7B'}
                                                        edit={false}
                                                        half={true}
                                                    />
                                                </div>
                                            </Td>
                                            <Td>
                                                <div className='row d-flex justify-content-space-between'>
                                                    <div className='mr-2 pointer'>
                                                        <img src={p.hasMedicalFileBeenWatchedByDoctor ? notificationIcon : notificationIcon2} style={{ height: '20px' }} alt='blocked' onClick={() => this.displayShareMedicalFileView(p.key)} />
                                                    </div>
                                                    <div className='clickable'>
                                                        <label className='text clickable' onClick={() => this.displayShareMedicalFileView(p.key)}>Ver<span className='text light clickable'></span></label>
                                                        <img src={icono_flecha_r} className='configIcon pl-2' alt='configuracion' onClick={() => this.displayShareMedicalFileView(p.key)} />
                                                    </div>
                                                </div>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div>
                }

                <div className='divider'></div>
                <div className='paginationSection'>{this.getPagination()}</div>
            </div >

        )
        return this.state.showShareMedicalFileView ? this.renderMedicalFileView() : mainPage;
    }

    renderMedicalFileView() {
        return (
            <div className='doctor-container patients'>

                <PrescriptionModal className="prescriptionModal" closePrescriptionModal={this.closePrescriptionModal} show={this.state.prescription.show} appointmentId={this.state.prescription.appointmentId} file={this.state.prescription.file} />

                <div className='row'>
                    <div className='col-12 col-md-6'>
                        {this.state.loading && <Loading />}


                        <div className='row mt-2 d-flex justify-content-space-between'>
                            <div className='clickable'>
                                <img src={icono_flecha} className='configIcon pr-2' alt='configuracion' onClick={() => this.backMainScreen()} />
                                <label className='text clickable' onClick={() => this.backMainScreen()}>Búsqueda de paciente<span className='text light clickable'></span></label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4'>
                                <img src={this.state.selectedGroup.appointments[0].patientProfilePictureBase64 === null || !this.state.selectedGroup.appointments[0].patientProfilePictureBase64 ? manIcon
                                    : `data:image/jpeg;base64,${this.state.selectedGroup.appointments[0].patientProfilePictureBase64}`}
                                    alt='location icon'
                                    className='col-lg-1'
                                    style={{ borderRadius: '50%', maxWidth: '200px', height: '150px', marginBottom: '10px' }} />

                            </div>
                            <div className='col-8 without-margin'>
                                <div className='width70'>
                                    <div className="row without-margin">
                                        <label className='text space-right'>Paciente:</label>
                                        {this.state.selectedGroup.patientName} {this.state.selectedGroup.patientLastName}
                                    </div>
                                    <div className="row without-margin">
                                        <label className='text space-right'>Edad:</label>
                                        {this.state.selectedGroup.appointments && this.state.selectedGroup.appointments[0].patientAge} años
                                    </div>
                                    <div className="row without-margin">
                                        <label className='text space-right'>Calificación:</label>
                                        <div className="inline-stars">
                                            <ReactStars
                                                count={5}
                                                value={this.state.selectedRate}
                                                size={24}
                                                color1={'#FFF'}
                                                color2={'#FDAD7B'}
                                                edit={false}
                                                half={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {this.state.selectedAppointment && this.state.selectedAppointment.status === "Nueva" && <button className='btn primary-btn mt-1 heightBtnAuto' onClick={() => this.startAppointment()}>
                                    Iniciar Consulta
                                </button>}
                                {this.state.selectedAppointment && this.state.selectedAppointment.status === "En curso" && <button className='btn primary-btn mt-1 heightBtnAuto' onClick={() => this.endAppointment()}>
                                    Finalizar Consulta
                                </button>}
                            </div>
                        </div>

                        <div className='doctor-container services'>
                            <label
                                className={
                                    'options-text ' +
                                    (this.state.isHistoryViewVisible ? ' inactive' : ' active')
                                }
                                onClick={e => this.setState({ isHistoryViewVisible: false })}
                            >
                                Historial de notas
                            </label>
                            <label
                                className={
                                    'options-text ' +
                                    (!this.state.isHistoryViewVisible ? ' inactive' : ' active')
                                }
                                onClick={e => this.setState({ isHistoryViewVisible: true })}
                            >
                                Información compartida
                            </label>
                            {/* Historial notas tab */}
                            {!this.state.isHistoryViewVisible &&
                                <div >
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th>
                                                    <div className='text-th-left pointer'>
                                                        <label className='text'>
                                                            Fecha
                                                        </label>
                                                    </div>
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.selectedGroup?.appointments?.map(p => {
                                                return (
                                                    <Tr key={p.id} className='col-12 col-md-6 space'>
                                                        <Td>
                                                            <div className='text-th-left'>
                                                                <label className={(this.state.selectedAppointment?.id === p.id ? ' pointer text' : 'pointer')
                                                                }
                                                                    onClick={() => this.selectDate(p)}>
                                                                    {`${p.startDateTime.getDate().toString().padStart(2, '0')} ${(p.startDateTime.getMonth() + 1).toString().padStart(2, '0')} ${p.startDateTime.getFullYear()}`}
                                                                </label>
                                                            </div>
                                                        </Td>
                                                        <Td>
                                                            <div>
                                                                <DetailHistory rateId={p.id} rate={p.rate}
                                                                    onRatingChanged={(newRating) => { this.OnRatingChanged(p.id, newRating) }} />
                                                            </div>
                                                        </Td>
                                                        <Td>
                                                            {p.hasPrescription && <div>
                                                                <button className='btn single-btn button-static-width dynamic-btn'
                                                                    onClick={this.showPrescription(p.id)}
                                                                >
                                                                    Ver Receta
                                                                </button>
                                                            </div>}
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                </div>
                            }

                            {/* Informacion compartida tab */}
                            {this.state.isHistoryViewVisible &&
                                <div className='row'>
                                    <div className='col-12'>
                                        <label><strong>Instrucciones: </strong> selecciona el estudio que necesitas consultar</label>
                                    </div>

                                    <div className="col-12">
                                        {this.state.rootfolders &&
                                            this.state.rootfolders.map(rootfolder => {
                                                return (
                                                    <div key={rootfolder.name} className={`clinical-link ${this.state.folderSelected === rootfolder.name ? 'active' : ''}`} >
                                                        <div className='folders row'>
                                                            <div className='icon-folder col-1' onClick={e => this.selectRootFolder(rootfolder.name)}>
                                                                <img src={this.state.folderSelected === rootfolder.name ? folderOpenIcon : folderCloseIcon} alt='blocked' />
                                                            </div>
                                                            <div className='icon-text col-9' onClick={e => this.selectRootFolder(rootfolder.name)}>
                                                                <label className='text'> {rootfolder.name} &nbsp; </label>
                                                                <label> {!rootfolder.totalChecked ? '' : '(' + rootfolder.totalChecked + ')'} </label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {this.state.folderSelected === rootfolder.name &&
                                                                rootfolder.subFolders.map(f => {
                                                                    return this.getFolderSelectedDetails(f);
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    {/* right panel */}
                    <div className='col-12 col-md-6 divider-4'>
                        {/* Historial notas tab */}
                        {!this.state.isHistoryViewVisible && this.state.selectedAppointment !== null &&
                            <div className='notes-area'>
                                <div>
                                    <label className='text'>
                                        {`${this.state.selectedAppointment.startDateTime.getDate().toString().padStart(2, '0')} ${(this.state.selectedAppointment.startDateTime.getMonth() + 1).toString().padStart(2, '0')} ${this.state.selectedAppointment.startDateTime.getFullYear()}`}
                                    </label>
                                </div>
                                <textarea
                                    id={'notes_' + this.state.selectedAppointment.id} rows='25' value={this.state.selectedAppointment.notes !== null ? this.state.selectedAppointment.notes : ''}
                                    onChange={e => this.notesUpdate(e.target.value)}>

                                </textarea>
                            </div>
                        }

                        {/* Informacion compartida tab */}
                        {this.state.isHistoryViewVisible &&
                            <div>
                                {this.state.newFile &&
                                    <div ref={this.printRef} className='d-flex justify-content-center img-wrapper'>
                                        <img alt='' src={`data:image/${this.state.typeSelected};base64,${this.state.newFile}`} />
                                    </div>}
                            </div>
                        }
                        {this.state.studyDownloaded === true &&
                            <div className='row justify-content-center'>
                                <div ref={this.printRef} className=' justify-content-center img-wrapper'>
                                    <br />
                                    <div className='col-12'>
                                        <p>!Archivo descargado!</p>
                                    </div>
                                    <br />
                                    <div className='col-12' style={{ textAlign: 'center' }}>
                                        <img alt='' src={fileDownloaded} style={{ height: '100px' }} />
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.studyData?.historyLines &&
                            <div style={{ background: 'white' }}>
                                <div className='col-12 text-th' style={{ paddingTop: '20px' }}>
                                    <span><strong>Historial clínico | </strong>{this.state.studyData.studyName.replace('%2F', '/')}</span>
                                    <div className='divider'></div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'></div>
                                    <div className='col-4 text-th'>
                                        <span><strong>{this.state.studyData.studyName.toLowerCase() === 'síntomas actuales' ||
                                            this.state.studyData.studyName.toLowerCase() === 'comentarios adicionales' ? ''
                                            : this.state.studyData.studyName.toLowerCase() === 'heredofamiliares' ? 'Parentezco'
                                                : 'Detalles'}</strong></span>
                                    </div>
                                </div>
                                {this.state.studyData.historyLines.map(hline => {
                                    return (
                                        <div className='row'>
                                            <div className='col-6 text-align-end'>
                                                <span>{hline.question}</span>
                                            </div>
                                            <div className='col-2'>
                                                <span><strong>{hline.value === 'False' ? 'NO' : hline.value === 'True' ? 'SI' : hline.value}</strong></span>
                                            </div>
                                            <div className='col-4 text-th'>
                                                <span>{hline.medicalQuestionValue}</span>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        }
                    </div>

                </div>
            </div >

        );
    }

    getFolderSelectedDetails = (f) => {
        return (
            <div key={f.name} className='col-12'>
                {f.name &&
                    <div>
                        <div className='col-3'>
                        </div>
                        <div className='col-9'>
                            <div className={`folders clinical-link ${this.state.subfolderSelected === f.name ? 'selected' : ''}`}
                                onClick={() => this.setState({ subfolderSelected: f.name })}>
                                <img src={folderOpenIcon} alt='blocked' />
                                <label className='text'> {f.name} &nbsp;</label>
                                <label> {!f.totalChecked ? '' : '(' + f.totalChecked + ')'} </label>
                            </div>
                        </div>
                    </div>
                }
                <div className='col-12'>
                    {f.medicalStudies?.map(m => {
                        if ((f.name && this.state.subfolderSelected !== f.name) || !m.isShared) {
                            return null;
                        }
                        return (
                            <div key={m.name}>
                                <div className=' col-3'></div>
                                <div className='col-9'>
                                    <div className={`folders clinical-link ${this.state.studySelected === m.name ? 'selected' : ''}`}
                                        onClick={() => this.GetStudy(m.name, m.fileImageName)} >
                                        <img src={fileIcon} style={{ height: '15px', width: '15px' }} alt='medical file' />
                                        {m.name}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}
