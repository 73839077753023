import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import './Agenda.scss'
import padlock from '../../../Assets/icons/Bloqueo_2.svg'
import back from '../../../Assets/icons/Agenda semana-atras.svg'
import next from '../../../Assets/icons/Agenda semana-adelante.svg'
import gmail from '../../../Assets/icons/Agenda semana-gmail.svg'
import configIcon from '../../../Assets/icons/Configuracion.svg'
import ApiCalendar from 'react-google-calendar-api';
import Modal from '../../Common/modal/Modal'
import BasicModal from '../../Common/modal/BasicModal'
import { CREATE_ID } from '../../../Utils/Generic';
import BasicInput from '../../Common/inputs/BasicInput';
import prepareEndpoint from '../../../Utils/EndPoints';
import { getData, postData, putData } from '../../../Utils/API';
import { OutlookSignInButton } from '../../Common/outlook/OutlookSignInButton';
import plusIcon from '../../../Assets/icons/mas.svg'
import Loading from '../../Common/loading/Loading'
import { toCurrency } from '../../../Utils/formatters';

const localizer = momentLocalizer(moment)
let allViews = ['month', 'week']
const messages = {
  agenda: 'Agenda',
  allDay: 'All Day',
  date: 'Fecha',
  day: 'Día',
  event: 'Evento',
  month: 'Mes',
  next: 'Siguiente',
  noEventsInRange: 'There are no events in this range.',
  previous: 'Anterior',
  time: 'Tiempo',
  today: 'Hoy',
  tomorrow: 'Mañana',
  week: 'Semana',
  showMore: function () { return '+ ver mas' }
}
export default class Agenda extends Component {
  constructor(props) {
    super(props);
    const todayDate = new Date();
    this.state = {
      loading: true,
      events: [

      ],
      appointment: {
        show: false,
        date: null,
        patient: '',
        time: '',
        type: null
      },
      extraService: {
        show: false,
        concept: '',
        amount: 0
      },
      showextraServiceConfirm: false,
      services: [],
      isGoogleLoaded: false,
    }
    this.year = todayDate.getFullYear();
    this.month = todayDate.getMonth() + 1;
    this.view = 'week';
  }
  async componentDidMount() {
    try {
      this.setState({ loading: true });
      await this.getEvents();
      window.addEventListener('focus', this.onFocus);
    } catch (e) {
      console.log('Exception at Agenda.Mount: ', e)
    }
    this.setState({ loading: false });
  }
  getEvents = async () => {
    const url = `${prepareEndpoint('Doctor')}/Appointments??year=${this.year}&month=${this.month}`
    const resp = await getData(url);
    if (resp.status === 200 && resp.data.length > 0) {
      let events = resp.data.map(element => {
        return {
          id: element.id,
          start: new Date(element.startDateTime.toString().slice(0, -1)),
          end: new Date(element.endDateTime.toString().slice(0, -1)),
          title: element.patientName,
          CalendarSource: element.calendarSource
        }
      });
      this.setState({ events });
    }
  }
  isBlocked = (title) => {
    return title.toLowerCase() === 'blocked';
  }
  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus)
  }
  onFocus = () => {
    if (ApiCalendar.sign) {
      this.setGoogleEvents();
    }
  }
  event = ({ event }) => {
    let blocked = this.isBlocked(event.title)
    return blocked ? <div className='blocked'><img src={padlock} alt='blocked' /></div> : event.title;
  }
  eventMonth = ({ event }) => {
    let blocked = this.isBlocked(event.title)

    return blocked ? <div className='blocked-month'><img src={padlock} alt='blocked' /></div> : (
      <span>
        <span>{event.title}</span>
        {event.desc && ' ' + event.desc}
      </span>
    )
  }
  setGoogleEvents() {
    if (!this.state.isGoogleLoaded) {
      let timeMax = new Date();
      timeMax.setDate(timeMax.getDate() + 10);
      ApiCalendar.listEvents({
        timeMin: new Date().toISOString(),
        showDeleted: false,
        maxResults: 100,
        orderBy: 'updated'
      }).then(({ result }) => {
        result.items.forEach(element => {
          this.setState({
            events: [
              ...this.state.events,
              {
                id: element.id,
                start: new Date(element.start.dateTime.toString().slice(0, -1)),
                end: new Date(element.end.dateTime.toString().slice(0, -1)),
                title: element.summary,
                CalendarSource: 1
              },
            ],
          })
        });
      });
      this.setState({ isGoogleLoaded: true });
    }
  }
  CustomToolbar = (toolbar) => {
    const goToBack = () => {
      switch (this.view) {
      case 'month': goToPreviousMonth(); break;
      case 'week': goToPreviousWeek(); break;
      default: break;
      }
    };

    const goToPreviousMonth = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    }
    const goToPreviousWeek = () => {
      toolbar.date.setDate(toolbar.date.getDate() - 7)
      toolbar.onNavigate('prev');
    }

    const goToNext = () => {
      switch (this.view) {
      case 'month': goToNextMonth(); break;
      case 'week': goToNextWeek(); break;
      default: break;
      }
    };

    const goToNextMonth = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    }

    const goToNextWeek = () => {
      toolbar.date.setDate(toolbar.date.getDate() + 7)
      toolbar.onNavigate('next');
    }

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };
    const goToweek = () => {
      toolbar.onView('week');
    };
    const goToMonth = () => {
      toolbar.onView('month');
    };
    const handleGoogleAuth = () => {
      if (ApiCalendar.sign) {
        this.setGoogleEvents();
      }
      else {
        ApiCalendar.handleAuthClick()
      }
    }
    const setOutlookEvents = (events) => {
      events.forEach(element => {
        this.setState({
          events: [
            ...this.state.events,
            {
              id: element.id,
              start: new Date(element.start.dateTime.toString().slice(0, -1)),
              end: new Date(element.end.dateTime.toString().slice(0, -1)),
              title: element.subject,
              CalendarSource: 2
            },
          ],
        })
      });
    }
    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      );
    };
    return (
      <div className={'toolbar-container'}>
        <div className='toolbar-header'>
          <div className='config' onClick={this.goToConfig}>
            <img src={configIcon} className='configIcon' alt='configuracion' /> <span className='options-text text'>Configuración</span>
          </div>
          <div className='labels'>
            <span className={'btn-current'} onClick={goToCurrent}>Día</span>
            <span className={(this.view === 'week' ? 'selected' : '') + ' btn-next'} onClick={goToweek}>Semana</span>
            <span className={(this.view === 'month' ? 'selected' : '') + ' btn-back'} onClick={goToMonth}>Mes</span>
          </div>
          <div className='accounts'>
            <span>Vincular a mi cuenta de:</span>
            <img src={gmail} alt='gmail' onClick={handleGoogleAuth} />
            <OutlookSignInButton onGetEvents={setOutlookEvents} />
          </div>
        </div>

        <div className={'back-next-buttons'}>
          <span className={'btn-back'} onClick={goToBack}>
            <img src={back} alt='back' />
          </span>
          <label className={'label-date'}>{label()}</label>
          <span className={'btn-next'} onClick={goToNext}>
            <img src={next} alt='next' />
          </span>
        </div>

      </div >
    );
  };
  goToConfig = () => {
    this.props.history.push('/doctoraccount/schedule')
  }
  handleSelectSlot = ({ start, end }) => {
    if (this.view === 'month') {
      end = new Date(start);
      end.setHours(23, 59, 59)
    }
    this.setState({
      events: [
        ...this.state.events,
        {
          id: CREATE_ID(),
          start,
          end,
          title: 'blocked',
          type: 'blocked'
        },
      ],
    })
  }
  onSelectFullday = (start) => {
    let end = new Date(start);
    end.setHours(23, 59, 59);
    this.setState({
      events: [
        ...this.state.events,
        {
          id: CREATE_ID(),
          start,
          end,
          title: 'blocked',
          type: 'blocked'
        },
      ],
    })
  }
  handleSelectEvent = (event) => {
    if (event.type === 'blocked') {
      let id = event.id;
      let events = [...this.state.events];
      let pos = events.findIndex(e => e.id === id)
      events.splice(pos, 1);
      this.setState({ events });
    } else {
      let { appointment } = this.state;
      let date = event.start
      appointment = {
        id: event.id,
        show: true,
        date: date.toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
        patient: event.title,
        time: `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')} hrs`,
        type: event.type,
        CalendarSource: event.CalendarSource
      }
      this.setState({ appointment })
    }

  }
  onChangeView = (view) => {
    this.view = view;
  }
  closeAppointmentModal = () => {
    let appointment = {
      show: false,
      date: null,
      patient: '',
      time: null,
      type: ''
    }
    this.setState({ appointment });
  }
  hideAppointmentModal = () => {
    let appointment = { ...this.state.appointment };
    appointment.show = false;
    this.setState({ appointment });
  }
  openExtraServiceModal = () => {
    let extraService = {
      show: true,
      concept: '',
      amount: 0
    }
    let services = [];
    this.setState({ extraService, services });
  }
  closeExtraServiceModal = () => {
    let extraService = {
      show: false,
      concept: '',
      amount: 0
    }
    this.setState({ extraService });
    this.closeAppointmentModal();
  }
  addExtraCharge = async () => {
    let services = [...this.state.services];
    const data = new FormData();
    data.append('extraCharges', JSON.stringify(services));
    const url = `${prepareEndpoint('Appointment')}/${this.state.appointment.id}/AddExtraCharge`;
    const resp = await putData(url, data);
    if (resp.status === 200) {
      //alert('Se terminó la cita correctamente');
    }
    else {
      alert('Ocurrió un error al madar cargos adicionales');
    }
    this.closeExtraServiceModal();
    this.closeAppointmentModal();
    this.setState({ showextraServiceConfirm: true });
  }
  goToExtraServices = () => {
    this.hideAppointmentModal();
    this.openExtraServiceModal();
  }
  async endAppointment(appointmentId) {
    const url = `${prepareEndpoint('Doctor')}/Appointments/${appointmentId}/end`;
    const resp = await postData(url);
    if (resp.status === 200) {
      alert('Se terminó la cita correctamente');
    }
    else {
      alert('Ocurrió un error al terminar la cita');
    }
  }
  getTotalServices = () => {
    let { services } = this.state;
    return services.reduce((accumulator, current) => accumulator + parseFloat(current.amount), 0);
  }
  updateConcept = (val) => {
    let extraService = { ...this.state.extraService }
    extraService.concept = val
    this.setState({ extraService })
  }
  updateAmount = (val) => {
    let extraService = { ...this.state.extraService }
    extraService.amount = val
    this.setState({ extraService })
  }
  addExtraService = () => {
    let extraService = { ...this.state.extraService };
    if (extraService.concept && extraService.amount && extraService.amount !== 0) {
      this.setState({
        services: [...this.state.services,
          {
            concept: extraService.concept,
            amount: extraService.amount
          }
        ]
      })
      this.setState({
        extraService: {
          show: true,
          concept: '',
          amount: ''
        }
      });
    }
  }
  render() {
    return (
      <div className='doctor-container agenda'>
        {this.state.loading && <Loading />}
        <Modal
          show={this.state.appointment.show}
          modalClosed={this.closeAppointmentModal}
          closeIcon={true}
        >
          <h3 className='text-center'>Cita</h3>
          <div className='appointment-info'>
            <div className='info'>
              <p><strong>Paciente: </strong>{this.state.appointment.patient}</p>
              <p><strong>Fecha: </strong>{this.state.appointment.date}</p>
              <p><strong>Hora: </strong>{this.state.appointment.time}</p>
              <p><strong>Tipo de cita: </strong>{this.state.appointment.type}</p>
            </div>
            <div className='buttons'>
              <button className='btn primary-btn'
                disabled={this.state.appointment.CalendarSource !== 0}
                onClick={async () => await this.endAppointment(this.state.appointment.id)}>
                Finalizar consulta
              </button>
              <button className='btn primary-btn' onClick={this.goToExtraServices}>Realizar cargo adicional</button>
            </div>
          </div>
        </Modal>
        <Modal
          className='additional-modal'
          show={this.state.extraService.show}
          modalClosed={this.closeExtraServiceModal}
          closeIcon={true}
          title="Cargo Adicional"
        >
          <div className='row d-flex justify-content-center'>
            <h3 className='text-center text'>Cargo adicional</h3>
          </div>
          <div className='extra-services'>
            <div className='row pb-2 mt-2'>
              <label className='text'>Ingresa los datos de los cargos adicionales</label>
            </div>
            <div className='row text-center'>
              <p className='col col-6'><strong>Estudio</strong></p>
              <p className='col col-6'><strong>Precio</strong></p>
            </div>
            <div className='divider mt-0 mb-2'></div>
            <div className='row prices text-center'>
              {this.state.services.map(s => {
                return (
                  <>
                    <div className='col col-6'>
                      {s.concept}
                    </div>
                    <div className='col col-6'>
                      {toCurrency(s.amount)}
                    </div>
                  </>
                )
              })}

            </div>
            <div className='row'>
              <div className='col-auto'>
                <strong>Concepto</strong>
              </div>
              <div className='col-auto'>
                <BasicInput basicVal={this.state.extraService.concept} onChangeFxn={this.updateConcept} />
              </div>
              <div className='col-auto'>
                <strong>Monto</strong>
              </div>
              <div className='col-auto'>
                <BasicInput basicVal={this.state.extraService.amount} onChangeFxn={this.updateAmount} type={'number'} onPressFxn={this.addExtraService} />
              </div>
              <div className='col-auto clickable' onClick={this.addExtraService}>
                <img src={plusIcon} className='add-icon' alt='agregar'></img>
              </div>
            </div>
            <div className='row confirm-container d-flex justify-content-center my-4'>
              <strong>Cargo total: {toCurrency(this.getTotalServices())}</strong>
            </div>
            <div className='row d-flex justify-content-center'>
              <button className='btn primary-btn' onClick={this.addExtraCharge}>Confirmar cargo</button>
            </div>
          </div>

        </Modal>

        <BasicModal
          className='additional-modal'
          show={this.state.showextraServiceConfirm}
          modalClosed={() => this.setState({ showextraServiceConfirm: false })}
          title='Cargo adicional actualizado!'>
        </BasicModal>

        <Calendar
          culture={'es'}
          selectable={'ignoreEvents'}
          localizer={localizer}
          views={allViews}
          step={30}
          timeslots={1}
          events={this.state.events}
          style={{ height: 650, width: '100%' }}
          formats={
            {
              //dateFormat: window.screen.width > 575 ? 'dddd DD' : 'DD',
              dayFormat: window.screen.width > 575 ? 'ddd DD' : 'DD'
            }
          }
          components={{
            event: this.event,
            toolbar: this.CustomToolbar,
            month: {
              event: this.eventMonth
            }
          }}
          onDrillDown={this.onSelectFullday}
          onSelectEvent={this.handleSelectEvent}
          onView={this.onChangeView}
          defaultView={this.view}
          popup={true}
          min={new Date(0, 0, 0, 6, 0, 0)}
          max={new Date(0, 0, 0, 22, 0, 0)}
          messages={messages}
        />
        <div className='div-instructions'>
        </div>
      </div>
    )
  }
}
