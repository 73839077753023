import React, { Component } from 'react'
import { FormField } from '../../Common/forms/FormField'
import BasicInput from '../../Common/inputs/BasicInput'
import { LOCATION_TYPES } from '../../../Utils/Constants'
import SuccessModal from '../../Common/modal/SuccessModal'
import './DoctorRegister.scss'
import prepareEndpoint from '../../../Utils/EndPoints'
import saveSvgAsPng from 'save-svg-as-png'
import { postData, getData, deleteData, putData } from '../../../Utils/API'
import { IS_VALID_DR } from '../../../Utils/Generic'
import Loading from '../../Common/loading/Loading'
import history from '../../../history'
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import confirmAlert from '../../Common/confirmAlert/confirmAlert'
import editIcon from '../../../Assets/icons/editar.svg'
import deleteIcon from '../../../Assets/icons/eliminar.svg'
import addIcon from '../../../Assets/icons/agregar.svg'
import plusIcon from '../../../Assets/icons/mas.svg'
import saveIcon from '../../../Assets/icons/guardar.svg';
import closeIcon from '../../../Assets/icons/close.svg';
import Modal from '../../Common/modal/Modal'
import FileViewer from 'react-file-viewer'
import { Checkbox } from 'pretty-checkbox-react';
import authService from '../../../security/auth-service';
import { ROLES } from '../../../Utils/Constants';
import magnifierIcon from '../../../Assets/icons/icono-lupa.svg'
import BasicModal from '../../Common/modal/BasicModal'
import BasicSelect from '../../Common/inputs/BasicSelect'
import QRCode from 'react-qr-code'
import printIcon from '../../../Assets/icons/Expediente medico popup-imprimir.svg'
import downloadIcon from '../../../Assets/icons/download.svg'
import emailIcon from '../../../Assets/icons/email.svg'
import authConfig from '../../../Initialization/auth-config'
import InputPassword from '../../Common/inputs/InputPassword'
const taxRegimes = [
  { key: '601', value: 'General de Ley Personas Morales' },
  { key: '603', value: 'Personas Morales con Fines no Lucrativos' },
  { key: '605', value: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
  { key: '606', value: 'Arrendamiento' },
  { key: '607', value: 'Régimen de Enajenación o Adquisición de Bienes' },
  { key: '608', value: 'Demás ingresos' },
  { key: '609', value: 'Consolidación' },
  { key: '610', value: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
  { key: '611', value: 'Ingresos por Dividendos (socios y accionistas)' },
  { key: '612', value: 'Personas Físicas con Actividades Empresariales y Profesionales' },
  { key: '614', value: 'Ingresos por intereses' },
  { key: '615', value: 'Régimen de los ingresos por obtención de premios' },
  { key: '616', value: 'Sin obligaciones fiscales' },
  { key: '620', value: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
  { key: '621', value: 'Incorporación Fiscal' },
  { key: '622', value: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
  { key: '623', value: 'Opcional para Grupos de Sociedades' },
  { key: '624', value: 'Coordinados' },
  { key: '625', value: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
  { key: '626', value: 'Régimen Simplificado de Confianza' },
  { key: '628', value: 'Hidrocarburos' },
  { key: '629', value: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
  { key: '630', value: 'Enajenación de acciones en bolsa de valores' },
];

export default class DoctorRegister extends Component {

  constructor(props) {
    super(props)
    this.keyInputRef = React.createRef();
    this.certInputRef = React.createRef();
    this.state = {
      id: '',
      firstName: '',
      lastName: '',
      motherName: '',
      specialtyField: '',
      professionalLicense: '',
      currentPosition: '',
      showBillingAlert: false,
      showBillingModal: false,
      editPassword: false,
      showPasswordError:false,
      showSendEmail:false,
      qrEmail:'',
      locations: [{
        id: '',
        locationType: 'Hospital',
        addressName: '',
        zipCode: '',
        colony: '',
        colonysByZipCode: [''],
        city: '',
        consultorio: '',
        street: '',
        locationState: 'Aguascalientes',
        locationNamePlaceHolder: 'Nombre del Hospital',
        phone: '',
      }],
      specialties: [
        '', '', '', ''
      ],
      summary: '',
      bankAccounts: [{
        bank: '',
        holderName: '',
        number: '',
        status: 0
      }],
      editBank: [],
      professionalExperiences: [
        ''
      ],
      positionsInPublicAssociations: [
        ''
      ],
      academicAchievements: [
        ''
      ],
      memberPrivateMedicalAssociations: [
        ''
      ],
      languages: [
        ''
      ],
      specialtyAreas: [
        ''
      ],
      professionalLicenseStatus: '',
      status: '',
      profiles: [{
        firstName: '',
        lastName: '',
        motherName: '',
        email: '',
        password: '',
        type: '',
        accessServices: false,
        accessAgenda: false,
        accessPatients: false,
        accessDrProfile: false,
        accessPatientProfile: false
      }],
      editProfiles: [],
      showSucessModal: false,
      loading: true,
      states: [],
      banks: [],
      subscriptionDetails: null,
      errors: {
        qrEmail:false,
        password: false,
        confirm: false,
        passwordDoesNotMatch: false,
        firstName: false,
        lastName: false,
        motherName: false,
        specialtyField: false,
        professionalLicense: false,
        currentPosition: false,
        profileFirstName: false,
        profileLastName: false,
        profileMotherName: false,
        profileEmail: false,
        profilePassword: false,
        profileType: false,
        billingInformation: {
          rfc: false,
          taxRegime: false,
          zip: false,
          digitalCert: false,
          digitalKey: false,
          password: false
        }
      },
      picture: {
        show: false,
        file: null
      },
      billingInformation: {
        rfc: '',
        taxRegime: '',
        taxRegimeDescription: '',
        zip: '',
        digitalCert: '',
        digitalCertFileName: '',
        digitalKey: '',
        digitalKeyFileName: '',
        password: ''
      },
      password:'password',
      confirm:''
    }

    this.locationTypes = LOCATION_TYPES
    this.myRef = React.createRef()
    this.edit = this.props.edit
    this.isLicensePreviouslySaved = false
    this.banks = [];
    this.profileAssistentType = ['Médico adjunto', 'Asistente']
    this.fileInputRef = React.createRef();
    this.checkEvent = this.checkEvent.bind(this.profiles);
    this.updateBilling = this.updateBilling.bind(this);
  }

  async componentDidMount() {
    try {
      this.GetPicture();
      this.getStates();
      this.getBanks();
      await this.getDoctor();
      await this.getIssuerInformation();
    } catch (e) {
      console.log('Error at componentDidMount: ', e);
    }
    finally { this.setState({ loading: false }); }
  }

  OnDelete = (deleteFunction) => {
    const buttons = [
      {
        label: 'Aceptar',
        onClick: deleteFunction
      },
      {
        label: 'Cancelar'
      }
    ];
    confirmAlert({ message: '¿Estás seguro que quieres borrar este elemento?', buttons })
  }

  getIssuerInformation = async () => {
    this.setState({ loading: true });
    const url = `${prepareEndpoint('Doctor')}/IssuerInformation`;
    const resp = await getData(url);
    if (resp?.status !== 200 || !resp.data?.id) {
      this.setState({ loading: false });

      return;
    }
    let billingInformation = resp.data;
    this.setState({ billingInformation })
  }
  cleanBillingErrors = () => {
    let errors = { ...this.state.errors }
    Object.entries(errors.billingInformation).forEach(error => { error = false });
    this.setState({ errors })
  };
  displayBillingAlert = (success) => {
    let { showBillingAlert } = this.state;
    showBillingAlert = !success;
    this.setState({ showBillingAlert, showBillingModal: success });
    setTimeout(() => { this.setState({ showBillingAlert: false }) }, 2000);
  }
  sendIssuerInformation = async () => {
    this.setState({ loading: true });
    this.cleanBillingErrors();
    const { errors, billingInformation } = this.state;
    const rfcExp = new RegExp('^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$')

    let billingErrors = errors.billingInformation;
    if (!billingInformation.rfc || !rfcExp.test(billingInformation.rfc.toUpperCase()) || billingInformation.rfc === '') {
      billingErrors.rfc = true;

    }
    billingErrors.taxRegime = billingInformation.taxRegime === '';
    billingErrors.zip = billingInformation.zip === '' || billingInformation.zip.length < 5;
    billingErrors.digitalCert = billingInformation.digitalCertFileName === '';
    billingErrors.digitalKey = billingInformation.digitalKeyFileName === '';
    billingErrors.password = billingInformation.password === '';
    errors.billingInformation = billingErrors;
    this.setState({ errors });
    if (billingErrors.rfc || billingErrors.taxRegime || billingErrors.zip || billingErrors.digitalCert || billingErrors.digitalKey || billingErrors.password) {
      this.setState({ loading: false });
      return;
    }

    const url = `${prepareEndpoint('Doctor')}/IssuerInformation`;
    const resp = await putData(url, billingInformation);

    if (resp?.status !== 200) {
      this.setState({ loading: false });
      this.displayBillingAlert(false);
      return;
    }

    this.displayBillingAlert(true);
    this.setState({ loading: false });
  }
  updateBilling = (val, field) => {
    const { billingInformation } = this.state;
    billingInformation[field] = val;
    this.setState({ billingInformation });
  }
  updateRegime = (val) => {
    const index = _.findIndex(taxRegimes, ['key', val]);
    if (index === -1) {
      this.updateBilling(taxRegimes[0].value, 'taxRegimeDescription');
      this.updateBilling(taxRegimes[0].key, 'taxRegime');
    }
    const {billingInformation} = this.state;
    billingInformation.taxRegime = val;
    this.setState({billingInformation})
  }
  handleBillingRFC = (val, field) => {
    const { billingInformation } = this.state;
    billingInformation[field] = val.toUpperCase();
    this.setState({ billingInformation }, function () {
      this.updateBilling(this.state.billingInformation.rfc, 'rfc')
    });
  }
  handleCertChange = async (selectorFiles) => {
    let reader = new FileReader();
    const file = selectorFiles[0];
    const that = this;
    that.updateBilling(file.name, 'digitalCertFileName');
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      that.updateBilling(base64String, 'digitalCert');
    };
  }

  handleKeyChange = async (selectorFiles) => {
    let reader = new FileReader();
    const file = selectorFiles[0];
    const that = this;
    that.updateBilling(file.name, 'digitalKeyFileName');
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      that.updateBilling(base64String, 'digitalKey');
    };
  }
  chooseCert = () => {
    this.certInputRef.current.click();
  }
  chooseKey = () => {
    this.keyInputRef.current.click();
  }
  getSelectedRegime = () => {
    const index = _.findIndex(taxRegimes, ['key', this.state.billingInformation.taxRegime]);
    if (index === -1) {
      this.updateBilling(taxRegimes[0].key, 'taxRegime');
    }
    return this.state.billingInformation.taxRegime && taxRegimes[Math.max(0, index)].value;
  }

  getDoctor = async () => {
    const url = prepareEndpoint('Doctor');
    const resp = await getData(url);

    if (resp?.status !== 200 || !resp.data?.id) {
      return;
    }

    const hasBankAccounts = resp.data.bankAccounts && resp.data.bankAccounts.length > 0;
    const hasProfiles = resp.data.profiles && resp.data.profiles.length > 0;
    this.isLicensePreviouslySaved = resp.data.professionalLicense && resp.data.professionalLicense.length > 5;
    /* if (this.isLicensePreviouslySaved && resp.data.status === 'En Edición') {
      //license is under review
      this.isLicensePreviouslySaved = false;
    } */
    this.setState({
      id: resp.data.id,
      userId: resp.data.userId,
      firstName: resp.data.firstName,
      lastName: resp.data.lastName,
      motherName: resp.data.motherName,
      specialtyField: resp.data.specialtyField,
      professionalLicense: resp.data.professionalLicense,
      currentPosition: resp.data.currentPosition,
      bankAccounts: hasBankAccounts ? resp.data.bankAccounts : [{
        bank: '',
        holderName: '',
        number: '',
        status: 0
      }],
      editBank: hasBankAccounts ? resp.data.bankAccounts.map(() => {
        return false;
      }) : [true],
      locations: resp.data.locations.length > 0 ? resp.data.locations : [{
        locationType: 'Hospital',
        addressName: '',
        zipCode: '',
        colony: '',
        city: '',
        consultorio: '',
        phone: '',
        street: '',
        locationState: 'Aguascalientes',
        locationNamePlaceHolder: 'Nombre del Hospital',
      }],
      specialties: resp.data.specialties,
      subscriptionDetails: resp.data.subscriptionDetails,
      summary: resp.data.summary,
      professionalExperiences: this.ensureData(resp.data.professionalExperiences),
      positionsInPublicAssociations: this.ensureData(resp.data.positionsInPublicAssociations),
      academicAchievements: this.ensureData(resp.data.academicAchievements),
      memberPrivateMedicalAssociations: this.ensureData(resp.data.memberPrivateMedicalAssociations),
      languages: this.ensureData(resp.data.languages),
      specialtyAreas: this.ensureData(resp.data.specialtyAreas),
      professionalLicenseStatus: resp.data.professionalLicenseStatus,
      status: resp.data.status,
      profiles: hasProfiles ? resp.data.profiles.map(p => ({ ...p, persitent: true })) : [{
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        motherName: '',
        doctorId: '',
        email: '',
        password: '',
        type: '',
        accessServices: false,
        accessAgenda: false,
        accessPatients: false,
        accessDrProfile: false,
        accessPatientProfile: false,
        persitent: false
      }],
      editProfiles: hasProfiles ? resp.data.profiles.map(() => {
        return false;
      }) : [true],
    });
  }
  ensureData = (arr) => {
    return arr.length === 0 ? [''] : arr;
  }

  getStates = async () => {
    const url = prepareEndpoint('States');
    const resp = await getData(url);
    let states = [];
    if (resp.status === 200) {
      states = resp.data.map(a => a.value);
      states.sort();
      this.setState({ states })
    }
  }

  getBanks = async () => {
    const url = prepareEndpoint('CatalogBanks');
    const resp = await getData(url);
    if (resp.status === 200 && resp.data) {
      this.banks = resp.data.sort(b => b.value);
    }
  }
  updateState = (val, field) => {
    this.setState({ [field]: val })
    this.cleanError(field)
  }
  updateLocation = (val, index) => {
    let locations = _.cloneDeep(this.state.locations);
    locations[index].locationNamePlaceHolder = this.getLocationName(val);
    locations[index].locationType = val;
    this.setState({ locations });
  }
  getLocationName = (locationType) => {
    const name = 'Nombre';
    if (locationType === 'Clínica') {
      return `${name} de la ${locationType}`;
    }

    return `${name} del ${locationType}`;
  }

  deleteLocation = async (index) => {
    if (index == null) {
      return;
    }

    let locations = _.cloneDeep(this.state.locations);
    const url = prepareEndpoint('DoctorLocations');
    const resp = await deleteData(url + '?locationId=' + locations[index].id);
    if (resp.status !== 200) {
      alert('Lo sentimos no pudimos borrar esta ubicación, intente de nuevo por favor.');
      return;
    }

    locations.splice(index, 1);
    if (locations.length === 0) {
      locations = [{
        id: '',
        locationType: 'Hospital',
        addressName: '',
        zipCode: '',
        colony: '',
        colonysByZipCode: [''],
        city: '',
        consultorio: '',
        phone: '',
        street: '',
        locationState: 'Aguascalientes',
        locationNamePlaceHolder: 'Nombre del Hospital',
      }];
    }
    this.setState({ locations })
  }

  updateStateArray = (val, field, index, subfield) => {
    let fieldArray = this.state[field];
    if (subfield) {
      fieldArray[index][subfield] = val;
    }
    else {
      fieldArray[index] = val;
    }
    this.setState({ [field]: fieldArray })
    if (field === 'profiles') {
      this.cleanError(`profile${subfield.substring(0, 1).toUpperCase()}${subfield.substring(1)}`)
    }
    else {
      this.cleanError(field)
    }

  }
  submitForm = async (e) => {
    e.preventDefault()
    let validForm = this.validateFields()
    if (validForm) {
      this.setState({ loading: true });
      let payload = this.createObj();
      const url = prepareEndpoint('Doctor');
      const resp = await postData(url, payload);

      if (resp.status >= 200 && resp.status < 300) {
        let editBank = this.state.editBank.map(() => false);
        let editProfiles = this.state.editProfiles.map(() => false);
        this.setState({ showSucessModal: true, id: resp.data, editBank, editProfiles })
      }
      this.setState({ loading: false });
    } else {
      this.myRef.current.scrollIntoView()
    }
  }
  createObj = () => {
    let {
      id,
      firstName,
      lastName,
      motherName,
      specialtyField,
      professionalLicense,
      currentPosition,
      locations,
      specialties,
      summary,
      bankAccounts,
      professionalExperiences,
      positionsInPublicAssociations,
      academicAchievements,
      memberPrivateMedicalAssociations,
      languages,
      specialtyAreas,
      profiles
    } = this.state;
    return {
      id,
      firstName,
      lastName,
      motherName,
      specialtyField,
      professionalLicense,
      currentPosition,
      locations,
      bankAccounts,
      specialties,
      summary,
      professionalExperiences,
      positionsInPublicAssociations,
      academicAchievements,
      memberPrivateMedicalAssociations,
      languages,
      specialtyAreas,
      profiles
    }
  }
  validateFields = () => {
    let errors = { ...this.state.errors }
    const {
      firstName,
      lastName,
      motherName,
      specialtyField,
      professionalLicense,
      currentPosition
    } = this.state
    let formIsValid = true
    if (!firstName) {
      formIsValid = false
      errors.firstName = true
    }
    if (!lastName) {
      formIsValid = false
      errors.lastName = true
    }
    if (!motherName) {
      formIsValid = false
      errors.motherName = true
    }
    if (!specialtyField) {
      formIsValid = false
      errors.specialtyField = true
    }
    if (!professionalLicense) {
      formIsValid = false
      errors.professionalLicense = true
    }
    if (!currentPosition) {
      formIsValid = false
      errors.currentPosition = true
    }
    this.setState({ errors })
    return formIsValid
  };

  cleanError = type => {
    let errors = { ...this.state.errors }
    if (errors[type]) {
      errors[type] = false
    }
    this.setState({ errors })
  };
  closeModal = () => {
    this.setState({ showSucessModal: false })
  }
  goToConfiguration = () => {
    history.push('/doctoraccount/services')
  }

  addNewStringFieldToUI = (field, isObject) => {
    let stateField = [...this.state[field]];
    if (isObject) {
      if (stateField && stateField.length > 0) {
        let newObj = {};
        for (const objProp in stateField[0]) {
          newObj[objProp] = typeof stateField[0][objProp] == typeof '' ? '' : 0;
        }
        stateField.push(newObj)
      }
    }
    else {
      stateField.push('')
    }
    this.setState({ [field]: stateField })
  }

  getColonysByZipCode = async (zipCode, index) => {
    const url = prepareEndpoint('ZipCodes');
    const resp = await getData(`${url}/${zipCode}`);

    let zipCodes = [];
    if (resp.status === 200) {
      zipCodes = resp.data;

      if (zipCodes.length > 0) {
        let locations = [...this.state.locations];
        locations[index].colony = zipCodes[0].description;
        locations[index].colonysByZipCode = zipCodes.map(z => z.description);
        this.setState({ locations });
      }
    }
  };

  addNewFieldSetEditBank = () => {
    if (this.state.bankAccounts.length === 1 &&
      (!this.state.bankAccounts[0].bank || !this.state.bankAccounts[0].holderName || !this.state.bankAccounts[0].number)) {
      return;
    }
    this.addNewStringFieldToUI('bankAccounts', true);
    let editBank = _.cloneDeep(this.state.editBank);
    editBank.push(true);
    this.setState({ editBank });
  }

  editBank = (index) => {
    let editBank = _.cloneDeep(this.state.editBank);
    editBank[index] = true;
    this.setState({ editBank })
  }

  deleteBank = (index) => {
    if (index == null) {
      return;
    }
    let bankAccounts = _.cloneDeep(this.state.bankAccounts);
    bankAccounts.splice(index, 1);
    const allBankAccountsDeleted = bankAccounts.length === 0;
    if (allBankAccountsDeleted) {
      bankAccounts = [{
        bank: '',
        holderName: '',
        number: '',
        expDate: ''
      }];
    }
    const editBank = bankAccounts.map(() => {
      return allBankAccountsDeleted;
    });
    this.setState({ bankAccounts, editBank })
  }

  deleteProfile = async (index, id) => {
    if (index == null) {
      return;
    }

    const url = prepareEndpoint('Doctor');
    const resp = await postData(`${url}/DeleteDoctorAssistant/${id}`);

    if (resp?.status !== 200) {
      alert('No se pudo eliminar asistente');
      return;
    }

    let profiles = _.cloneDeep(this.state.profiles);
    profiles.splice(index, 1);
    const allProfilesDeleted = profiles.length === 0;
    if (allProfilesDeleted) {
      profiles = [{
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        motherName: '',
        doctorId: '',
        email: '',
        password: '',
        type: '',
        accessServices: false,
        accessAgenda: false,
        accessPatients: false,
        accessDrProfile: false,
        accessPatientProfile: false,
        persitent: false
      }];
    }
    const editProfiles = profiles.map(() => {
      return allProfilesDeleted;
    });
    this.setState({ profiles, editProfiles })
  }

  editProfile = (index) => {
    let editProfiles = _.cloneDeep(this.state.editProfiles);
    editProfiles[index] = true;
    this.setState({ editProfiles })
  }

  cleanProfileErrors = () => {
    let errors = { ...this.state.errors }

    errors.profileFirstName = false;
    errors.profileLastName = false;
    errors.profileMotherName = false;
    errors.profileEmail = false;
    errors.profilePassword = false;
    errors.profileType = false;

    this.setState({ errors })
  };

  saveProfile = async (index, userId) => {
    this.cleanProfileErrors();
    let isRegister = userId.length === 0;
    if (!this.isValidProfile(index)) {
      return
    }

    this.setState({ loading: true });

    let allFieldArray = this.state.profiles;
    let fieldArray = allFieldArray[index];

    const roleType = ROLES.Assistant;

    if (isRegister) {
      const isAssistant = true;
      const response = await authService.register({ email: fieldArray.email, password: fieldArray.password, roleType, isAssistant });
      if (response.status !== 200 || !response.data || response.data.length <= 0) {
        this.setState({ loading: false, showErrorModal: true });
        return;
      }
      fieldArray.userId = response.data.userId;
      fieldArray.persitent = true;
    }

    allFieldArray[index] = fieldArray;

    const url = prepareEndpoint('Doctor');
    await postData(`${url}/CreateOrUpdateDoctorAssistant`, fieldArray);

    let editProfiles = _.cloneDeep(this.state.editProfiles);
    editProfiles[index] = false;
    this.setState({ editProfiles, loading: false, profiles: allFieldArray });
  }

  addNewFieldSetEditProfile = () => {
    for (let i = 0; i < this.state.editProfiles.length; i++) {
      if (this.state.editProfiles[i] &&
        (!this.state.profiles[i].firstName || !this.state.profiles[i].lastName ||
          !this.state.profiles[i].motherName || !this.state.profiles[i].email ||
          this.state.profiles[i].type.length === 0)) {
        return;
      }
    }

    this.addNewStringFieldToUI('profiles', true);
    let editProfiles = _.cloneDeep(this.state.editProfiles);
    editProfiles.push(true);
    this.setState({ editProfiles });
  }

  checkEvent = e => {
    var tokens = e.target.id.split('_');
    let index = tokens[1];
    let subfield = tokens[0];
    let checked = e.target.checked;

    let fieldArray = this.state.profiles;
    if (fieldArray[index][subfield] !== checked) {
      fieldArray[index][subfield] = !fieldArray[index][subfield];
      this.setState({ profiles: fieldArray })
    }
  }

  isValidProfile = (index) => {
    let fieldArray = this.state.profiles[index];
    let errors = { ...this.state.errors };
    let isValidForm = true;

    if (!fieldArray.firstName || fieldArray.firstName.trim() === '') {
      errors.profileFirstName = true;
      isValidForm = false;
    }
    if (!fieldArray.lastName || fieldArray.lastName.trim() === '') {
      errors.profileLastName = true;
      isValidForm = false;
    }
    if (!fieldArray.motherName || fieldArray.motherName.trim() === '') {
      errors.profileMotherName = true;
      isValidForm = false;
    }
    if (!fieldArray.persitent && (!fieldArray.email || fieldArray.email.trim() === '')) {
      errors.profileEmail = true;
      isValidForm = false;
    }
    if (!fieldArray.persitent && (!fieldArray.password || fieldArray.password.trim() === '')) {
      errors.profilePassword = true;
      isValidForm = false;
    }
    if (!fieldArray.type || fieldArray.type.length === 0) {
      errors.profileType = true;
      isValidForm = false;
    }

    this.setState({ errors: errors });

    return isValidForm;
  }

  deleteData = (fieldArray, index) => {
    if (!fieldArray || index === null) {
      return;
    }

    let fieldValues = _.cloneDeep(this.state[fieldArray]);

    if (!fieldValues) {
      return;
    }

    if (fieldValues.length === 1) {
      fieldValues[0] = '';
      this.setState({ [fieldArray]: fieldValues })
      return;
    }

    fieldValues.splice(index, 1);
    this.setState({ [fieldArray]: fieldValues })
  }

  downloadQR = () => {
    const can = document.getElementById('doctorQr');
    saveSvgAsPng.saveSvgAsPng(can, 'doctor.png',{
      scale: 5,
      encoderOptions: 1,
      backgroundColor: 'white',
    })
  };

  printQr = ()=>{
    var content = document.getElementById('qrCode');
    var pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  getBankLogo = (bankName) => {
    if (!bankName || !this.banks) {
      return 'bankDefaultLogo.png';
    }
    const bank = this.banks.find(b => b.value?.toLowerCase() === bankName.toLowerCase());
    if (!bank) {
      return 'bankDefaultLogo.png';
    }
    return bank?.logo;
  }

  async GetPicture() {
    const url = `${prepareEndpoint('Doctor')}/ProfilePicture`;

    const resp = await getData(url);

    if (resp.status === 200) {
      let picture = {
        show: false,
        fromSelector: false,
        file: resp.data
      }
      this.setState({ picture });
    }
    else {
      let picture = {
        show: false,
        fromSelector: true,
        file: null
      }
      this.setState({ picture });
    }

    return;
  }

  showPicture = () => {
    let picture = { ...this.state.picture }
    picture.show = true;
    this.setState({ picture });
  }

  closePictureModal = () => {
    this.GetPicture();
  }

  uploadFile = () => {
    this.fileInputRef.current.click();
  }

  handleChange = async (selectorFiles) => {
    let picture = {
      show: true,
      fromSelector: true,
      file: selectorFiles[0]
    }
    this.setState({ picture });

    const formData = new FormData();
    formData.append('formFile', selectorFiles[0]);

    const url = `${prepareEndpoint('Doctor')}/UploadProfilePicture`;
    await postData(url, formData);
    this.closePictureModal();
  }

  deletePicture = () => {
    const deleteFunc = () => this.deletePictureProfile();
    const buttons = [
      {
        label: 'Aceptar',
        onClick: deleteFunc
      },
      {
        label: 'Cancelar'
      }
    ];
    confirmAlert({ message: '¿Estás seguro que quieres borrar este elemento?', buttons });
  }

  deletePictureProfile = async () => {
    const url = `${prepareEndpoint('Doctor')}/DeleteProfilePicture`;
    await postData(url);

    this.closePictureModal();
  }

  sendQr=()=>{
    const{qrEmail} = this.state;
    let errors = { ...this.state.errors };
    this.setState({loading:true})
    // eslint-disable-next-line quotes
    const emailExp = new RegExp("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$");

    if (!qrEmail || !emailExp.test(qrEmail)) {
      errors.qrEmail = true
    }
    
    this.setState({errors});

    if(!errors.qrEmail){
      this.send(qrEmail).then((status)=>{
        if(status === 200)
          this.setState({ showBillingModal: true, showSendEmail:false, loading:false })  
      }).finally(()=>{
        this.setState({ loading:false })  
      })
    }
  }

  send = async (email) => {
    let payload = {email};
    const url = `${prepareEndpoint('Doctor')}/SendQrCode`;
    const resp = await postData(url, payload);
    return resp.status;
  }
  editPassword =()=>{
    const{password, confirm} = this.state;
    let errors = { ...this.state.errors };
    if(password === undefined || password ==='')
      errors.password = true;
    
    if(confirm === undefined || confirm === '')
      errors.confirm = true;

    if(!errors.confirm && !errors.password && password !== confirm)
      errors.passwordDoesNotMatch = true;
    
    this.setState({errors});
    
    if(errors.confirm || errors.password || errors.passwordDoesNotMatch)
      return;

    authService.updatePassword(authConfig.email, password, confirm).then(
      (value)=>{
        if(value) {
          this.setState({showBillingModal:true, 'editPassword':!this.state.editPassword, password:'password', confirm:''})
        }
        else 
          this.setState({showPasswordError:true})
      }
    ).catch(()=>{
      this.setState({showPasswordError:true})
    });
  }

  getSubscriptionPlan = () => {
    return null;
    //if (!this.state.subscriptionDetails || !this.state.subscriptionDetails.subscriptionType || this.state.subscriptionDetails.subscriptionType === 'free') {
    //  return 'Plan Libre';
    //}
    //else {
    //  return 'Plan Básico';
    //}
  }

  render() {
    return (
      <div>
        <BasicModal
          className='additional-modal'
          show={this.state.showBillingModal}
          modalClosed={() => this.setState({ showBillingModal: false })}
          title='¡Correcto!'
          subtitle='Se guardó la información correctamente'
        >
          <BasicModal
            className='additional-modal'
            show={this.state.showPasswordError}
            modalClosed={() => this.setState({ showPasswordError: false })}
            title='Ocurrió un error'
            subtitle='Ocurrió un error, por favor intenta nuevamente.'
          ></BasicModal>
        </BasicModal>
        <BasicModal
          className='additional-modal'
          show={this.state.showErrorModal}
          modalClosed={() => this.setState({ showErrorModal: false })}
          title='Error'
          subtitle='Lo sentimos, no se pudo crear asistente.'
        >
        </BasicModal>
        <BasicModal
          className='additional-modal'
          show={this.state.errors.passwordDoesNotMatch}
          modalClosed={() => {const errors = {...this.state.errors}; errors.passwordDoesNotMatch=false; this.setState({ errors })}}
          title='Las contraseñas no coinciden.'
          subtitle='La contraseña debe coincidir con su confirmación.'
        />
        {this.state.loading && <Loading />}
        <SuccessModal show={this.state.showSucessModal} closeFnx={this.closeModal} btnFnx={this.goToConfiguration} btnTxt={'Configurar Servicios'} showLabelMessage={!IS_VALID_DR()} labelMessage={'Tu perfil aún no se publica y se encuentra en revisión, ¡en breve nos pondremos en contacto contigo!'} />
        {!this.props.hideTitle && <h2 className='text title'>Sé parte de Sanapp</h2>}
        <Modal className='email-modal' show={this.state.showSendEmail}
          modalClosed={()=>this.setState({showSendEmail:false})}
          closeIcon={true}>
          <BasicInput
            label='Correo electrónico.'
            onChangeFxn={(val) => { this.updateState(val, 'qrEmail') }}
            error={this.state.errors.qrEmail}
            basicVal={this.state.qrEmail}
          ></BasicInput>
          <div className='text-center'>
            <button type='button' className='btn primary-btn' onClick={this.sendQr}>Envíar</button>
          </div>
        </Modal>
        <Modal
          className='prescriptionModal'
          show={this.state.picture.show}
          modalClosed={this.closePictureModal}
          closeIcon={true}
        >
          <h3 className='text-center'>Cargar Fotografía</h3>

          {this.state.picture.file ?
            this.state.picture.fromSelector ?
              <FileViewer fileType={this.state.picture.file.type.split('/')[1]} filePath={URL.createObjectURL(this.state.picture.file)} onError={e => {
                console.log(e, 'error in file-viewer');
              }} />
              :
              <div className='d-flex justify-content-center img-wrapper'>
                <img alt='' src={`data:image/jpeg;base64,${this.state.picture.file}`} />
              </div>

            :
            <div className='scroll bkg-white'>
              <div className='text-center full-Height'>
                <label>Ninguna fotografía cargada</label>
              </div>
            </div>
          }

          <form>
            <input
              ref={this.fileInputRef}
              type='file'
              className='hide'
              accept='image/*'
              onChange={(e) => this.handleChange(e.target.files)} />
          </form>

          {(this.state.picture.file && !this.state.picture.fromSelector) && <div className='text-center img-hover-delete'>
            <img alt='' src={deleteIcon} onClick={this.deletePicture} />
          </div>}
          <div className='text-center'>
            <label className='text'>Formatos permitidos: .png, .jpg</label>
          </div>
          <div className='text-center'>
            <button type='button' className='btn primary-btn' onClick={this.uploadFile}>Cargar Fotografía</button>
          </div>
        </Modal>

        <div>
          <FormField leftClassName={this.state.picture.file ? 'picture-profile withPicture' : 'picture-profile'} text={'Generales'} secondaryText={this.getSubscriptionPlan()} secondaryTextUrl='/SubscriptionPrices' history={history}
            icon={this.state.picture.file ? `data:image/jpeg;base64,${this.state.picture.file}` : addIcon} iconEvent={() => { this.showPicture() }}>
            <form ref={this.myRef}>
              <div className='row'>
                <div className='col-10'>
                  <div className="form-row">
                    <BasicInput
                      classes='col-12 col-md-4 col-lg-4' label='Nombre(s)'
                      onChangeFxn={(val) => { this.updateState(val, 'firstName') }}
                      error={this.state.errors.firstName}
                      basicVal={this.state.firstName}
                    />
                    <BasicInput
                      classes='col-12 col-md-4 col-lg-4' label='Apellido Paterno'
                      onChangeFxn={(val) => { this.updateState(val, 'lastName') }}
                      error={this.state.errors.lastName}
                      basicVal={this.state.lastName}
                    />
                    <BasicInput
                      classes='col-12 col-md-4 col-lg-4' label='Apellido Materno'
                      onChangeFxn={(val) => { this.updateState(val, 'motherName') }}
                      error={this.state.errors.motherName}
                      basicVal={this.state.motherName}
                    />
                    <BasicInput
                      classes='col-12 col-md-4 col-lg-4' label='Especialidad'
                      onChangeFxn={(val) => { this.updateState(val, 'specialtyField') }}
                      error={this.state.errors.specialtyField}
                      basicVal={this.state.specialtyField}
                    />
                    <BasicInput classes='col-12 col-md-4 col-lg-4' label='Cédula Profesional'
                      onChangeFxn={(val) => { this.updateState(val, 'professionalLicense') }}
                      error={this.state.errors.professionalLicense}
                      basicVal={this.state.professionalLicense}
                      numeric={true}
                      maxLength={8}
                      disabled={this.isLicensePreviouslySaved}
                    />
                    <BasicInput classes='col-12 col-md-4 col-lg-4' label='Puesto Actual'
                      onChangeFxn={(val) => { this.updateState(val, 'currentPosition') }}
                      error={this.state.errors.currentPosition}
                      basicVal={this.state.currentPosition}
                    />
                    <BasicInput classes='col-12 col-md-3 col-lg-4' label='Usuario'
                      onChangeFxn={(val) => { this.updateState(val, 'currentPosition') }}
                      error={this.state.errors.currentPosition}
                      basicVal={authConfig.email}
                      disabled
                    />

                    {!this.state.editPassword&&<BasicInput classes='col-12 col-md-4 col-lg-3' label='Contraseña' basicVal={this.state.password} disabled
                      type="password" />}

                    {this.state.editPassword&&<InputPassword classes='col-12 col-md-3 col-lg-3' label='Contraseña'
                      onChange={(val) => { this.updateState(val, 'password') }}
                      error={this.state.errors.password}
                      value={this.state.password}
                      type="password"
                      disabled={!this.state.editPassword}
                    />}
                    {!this.state.editPassword && <div className='col-2 d-flex align-items-center pt-3 mx-0 px-0'>
                      <img src={editIcon} className='clickable icon-img' alt='edit' onClick={()=>this.setState({'editPassword':!this.state.editPassword, password:'', confirm:''})}></img>
                    </div>}
                    {this.state.editPassword && <><InputPassword classes='col-12 col-md-4 col-lg-3'   label='Confirmar contraseña'
                      onChange={(val) => { this.updateState(val, 'confirm') } }
                      error={this.state.errors.confirm}
                      value={this.state.confirm}
                    />
                    <div className='col-2 d-flex align-items-center pt-3 mx-0 px-0'>
                      <img src={saveIcon} className='clickable icon-img' alt='edit' onClick={() => this.editPassword()}></img>
                      <img src={closeIcon} className='clickable icon-img' alt='edit' onClick={()=>this.setState({'editPassword':!this.state.editPassword, password:'password', confirm:''})}></img>
                    </div></>}
                  </div>
                </div>
                <div className='col-lg-2 col-md-12 col-sm-12 col-xs-12'>
                  <iframe id="ifmcontentstoprint" className='hidden'></iframe>
                  <div className='row'>
                    <div className='col-12 d-flex align-items-center justify-content-center'>
                      <label className='text'>Link Personal</label>
                    </div>
                  </div>
                  <div id="qrCode" className='row'>
                    <div className='col-12 d-flex align-items-center justify-content-center'>
                      <QRCode
                        id="doctorQr"
                        size={256}
                        style={{ height: 'auto', maxWidth: '250px', width: '250px' }}
                        value={`https://sanapp.com/appointment/doctorInformation?doctorId=${this.state.userId}`}
                        viewBox={'0 0 256 256'}
                      />
                    </div>
                  </div>
                  <div className='row pt-2 icon-container'>
                    <div className='col-12 d-flex align-items-center justify-content-center'>
                      <img src={downloadIcon} alt="print-icon" onClick={()=>this.downloadQR()} className='clickable icon-img'></img>
                      <img src={emailIcon} alt="print-icon" onClick={()=>this.setState({showSendEmail:true})} className='clickable icon-img'></img>
                      <img src={printIcon} alt="print-icon" onClick={()=>this.printQr()} className='clickable icon-img'></img>
                    </div>
                  </div>
                </div>
              </div>
              
            </form>
          </FormField>
          
        </div>
        <FormField text={'Emisión de facturas'} className='invoice-information'>
          <form>
            <input
              ref={this.keyInputRef}
              type='file'
              className='hide'
              accept='.key'
              onChange={(e) => this.handleKeyChange(e.target.files)}
            />
          </form>
          <form>
            <input
              ref={this.certInputRef}
              type='file'
              className='hide'
              accept='.cer'
              onChange={(e) => this.handleCertChange(e.target.files)}
            />
          </form>
          <form>
            {this.state.showBillingAlert && <div className='form-row'>
              <div className='col-12'>
                <label className='issuer-error'>Ocurrió un error al guardar la información, por favor verifique que los datos sean correctos</label>
              </div>
            </div>}
            <div className="form-row">
              <BasicInput label='RFC'
                className='col-12 col-lg-4'
                maxLength='13'
                onChangeFxn={(val) => { this.handleBillingRFC(val, 'rfc') }}
                basicVal={this.state.billingInformation.rfc} 
                error={this.state.errors.billingInformation.rfc}
              />
              <div className='col-12 col-lg-4'>
                <BasicSelect
                  title={'Régimen fiscal'}
                  customOptions={taxRegimes}
                  placeholder={'Régimen fiscal'}
                  selectedItem={this.getSelectedRegime()}
                  onChangeFxn={val => { this.updateRegime(val.key) }}
                  error={this.state.errors.billingInformation.taxRegime}
                />
              </div>
              <BasicInput label='C.P.'
                classes='col-12 col-lg-4'
                onChangeFxn={(val) => { this.updateBilling(val, 'zip') }}
                basicVal={this.state.billingInformation.zip}
                maxLength={5}
                numeric={true}
                error={this.state.errors.billingInformation.zip}
              />
            </div>
            <div className="form-row">
              <BasicInput label='Certificado de sello digital'
                classes='col-11 col-lg-4' disabled
                onChangeFxn={(val) => { this.updateBilling(val, 'digitalCert') }}
                basicVal={this.state.billingInformation.digitalCertFileName}
                error={this.state.errors.billingInformation.digitalCert}
              />
              <div className='col-auto de-flex align-items-center justify-content-center align-self-center pt-2 mr-2'>
                <img src={magnifierIcon} alt='buscar' className='clickable magnifier-button' onClick={this.chooseCert} />
              </div>
              <BasicInput label='Clave privada'
                classes='col-12 col-lg-4' disabled
                onChangeFxn={(val) => { this.updateBilling(val, 'digitalKey') }}
                basicVal={this.state.billingInformation.digitalKeyFileName}
                error={this.state.errors.billingInformation.digitalKey}
              />
              <div className='col-auto de-flex align-items-center justify-content-center align-self-center pt-2 mr-2'>
                <img src={magnifierIcon} alt='buscar' className='clickable magnifier-button' onClick={this.chooseKey} />
              </div>
              <BasicInput label='Contraseña clave privada'
                classes='col-12 col-lg-3 ml-2'
                onChangeFxn={(val) => { this.updateBilling(val, 'password') }}
                basicVal={this.state.billingInformation.password}
                type='password'
                autocomplete='new-password'
                error={this.state.errors.billingInformation.password}
              />
            </div>
            <div className="form-row">
              <div className='col-12' >
                <img disabled={!this.state.hasInsurance} src={saveIcon} alt='agregar' className='clickable save-button' onClick={this.sendIssuerInformation} />
              </div>
            </div>
          </form>
        </FormField>
        <FormField text={'Ubicación'}>
          <form >
            <div className="form-row">
              {this.state.locations.map((location, index) =>
                <>
                  <div className='form-row'>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <BasicSelect
                        customOptions={this.locationTypes}
                        title={'Tipo de Ubicación'}
                        selectedItem={location.locationType}
                        onChangeFxn={(val) => { this.updateLocation(val, index) }}
                        placeholder='Clínica, hospital, etc'
                      />
                    </div>
                    <BasicInput
                      classes={'col-12 col-md-6 col-lg-3'}
                      label={'Dirección'}
                      placeHolder={location.locationNamePlaceHolder ?? 'Nombre del lugar'}
                      onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'addressName') }}
                      basicVal={location.addressName}
                    />
                    <BasicInput
                      classes={'col-12 col-md-6 col-lg-3'}
                      label={'C.P.'}
                      placeHolder='C.P.'
                      onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'zipCode') }}
                      onBlurFxn={(val) => { this.getColonysByZipCode(val, index) }}
                      basicVal={location.zipCode}
                      numeric={true}
                      maxLength={5}
                    />
                    <div className='col-12 col-md-6 col-lg-3'>
                      <BasicSelect
                        customOptions={this.state.locations[index].colonysByZipCode}
                        title={'Colonia'}
                        selectedItem={location.colony || ''}
                        onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'colony') }}
                        placeholder='Colonia'
                      />
                    </div>
                    <BasicInput
                      classes='col-12 col-md-6 col-lg-3' label={'Ciudad'} placeHolder='Ciudad'
                      onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'city') }}
                      basicVal={location.city}
                    />
                    <BasicInput
                      classes='col-12 col-md-6 col-lg-3' label={'Calle y número'} placeHolder='Calle y número'
                      onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'street') }}
                      basicVal={location.street}
                    />
                    <BasicInput
                      classes='col-12 col-md-6 col-lg-3' label={'Consultorio'} placeHolder='Consultorio'
                      onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'consultorio') }}
                      basicVal={location.consultorio}
                    />
                    <div className='col-12 col-md-6 col-lg-3'>
                      <BasicSelect
                        customOptions={this.state.states}
                        title={'Estado'}
                        selectedItem={location.locationState}
                        onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'locationState') }}
                        placeholder='Estado'
                      />
                    </div>
                    <BasicInput
                      classes='col-12 col-md-6 col-lg-3'
                      label={'Teléfono de la ubicación'}
                      onChangeFxn={(val) => { this.updateStateArray(val, 'locations', index, 'phone') }}
                      basicVal={location.phone}
                    />
                  </div>
                  <div className='col-12 text-align-last-end align-self-center'>
                    <img src={deleteIcon} alt='Eliminar' className='mr-5' onClick={() => this.OnDelete(() => this.deleteLocation(index))} />
                  </div>
                  <br /><br />
                </>
              )}

              <div className='col-12 mb-3'>
                <img alt='' src={plusIcon} onClick={() => this.addNewStringFieldToUI('locations', true)} />
              </div>
            </div>
          </form>
        </FormField>
        <FormField text={'Cuentas de conciliación'}>
          <form >
            <div className="form-row">
              <label className='col-12 text' style={{ marginBottom: '20px' }}>Indica los datos de tu cuenta bancaria donde se realizarán los depósitos de tus ganancias</label>
              {this.state.bankAccounts.map((bankAccount, index) =>
                <>
                  {this.state.editBank[index] &&
                    <BasicInput
                      classes='col-12 col-lg-3 col-md-3' placeHolder='Nombre del titular'
                      onChangeFxn={(val) => { this.updateStateArray(val, 'bankAccounts', index, 'holderName') }}
                      basicVal={bankAccount.holderName}
                    />
                  }

                  {this.state.editBank[index] &&
                    <div className='col-12 col-lg-3 col-md-3'>
                      <BasicSelect
                        customOptions={this.banks.map(b => b.value)}
                        placeholder={'Banco'}
                        selectedItem={bankAccount.bank}
                        onChangeFxn={(val) => { this.updateStateArray(val, 'bankAccounts', index, 'bank') }}
                      />
                    </div>
                  }
                  {this.state.editBank[index] &&
                    <BasicInput
                      classes='col-12 col-lg-3 col-md-3' placeHolder='Clabe interbancaria'
                      onChangeFxn={(val) => { this.updateStateArray(val, 'bankAccounts', index, 'number') }}
                      maxLength={16}
                      numeric={true}
                      basicVal={bankAccount.number}
                    />
                  }

                  {!this.state.editBank[index] &&
                    <div className='col-2 col-lg-3 text-hcenter-vcenter'>
                      <img src={require('../../../Assets/bankIcons/' + this.getBankLogo(bankAccount.bank))}
                        alt='bank icon' style={{ alignSelf: 'center', borderRadius: '50%', marginRight: '20px', maxWidth: '75px', maxHeight: '75px' }} />
                    </div>
                  }
                  {!this.state.editBank[index] && (
                    <>
                      <div className='col-4 col-lg-6'>
                        <label className='row text text-vcenter'>Terminación {bankAccount.number.substring(bankAccount.number.length - 4, bankAccount.number.length)}</label>
                        <label className='row text-vcenter normal-text'>{bankAccount.bank.substring(0, 17)}</label>
                        <label className='row text-vcenter normal-text'>{bankAccount.holderName.substring(0, 17)}</label>
                      </div>
                    </>)
                  }

                  <div className='col-6 col-lg-3 align-flex-start-center' >
                    {(!this.state.editBank[index]) &&
                      <img src={editIcon} alt='Editar' className='mr-5' onClick={() => this.editBank(index)} />
                    }
                    <img src={deleteIcon} alt='Eliminar' className='mr-5' onClick={() => this.OnDelete(() => this.deleteBank(index))} />
                  </div>

                </>
              )}
              <div className='col-12 mb-3'>
                <img alt='' src={plusIcon} onClick={() => this.addNewFieldSetEditBank()} />
              </div>
            </div>
          </form>
        </FormField>

        <FormField text={'Formación Profesional'}>
          <form >
            <div className="form-row">
              <div className='col-12'>
                <label className='text'>Experto en:</label>
                <p>Enlista las 4 principales áreas de especialización o experiencia</p>
              </div>
              <BasicInput
                classes='col-12 col-md-6 col-lg-3'
                onChangeFxn={(val) => { this.updateStateArray(val, 'specialties', 0) }}
                basicVal={this.state.specialties[0]}
                maxLength={50}
              />
              <BasicInput
                classes='col-12 col-md-6 col-lg-3'
                onChangeFxn={(val) => { this.updateStateArray(val, 'specialties', 1) }}
                basicVal={this.state.specialties[1]}
                maxLength={50}
              />
              <BasicInput
                classes='col-12 col-md-6 col-lg-3'
                onChangeFxn={(val) => { this.updateStateArray(val, 'specialties', 2) }}
                basicVal={this.state.specialties[2]}
                maxLength={50}
              />
              <BasicInput
                classes='col-12 col-md-6 col-lg-3'
                onChangeFxn={(val) => { this.updateStateArray(val, 'specialties', 3) }}
                basicVal={this.state.specialties[3]}
                maxLength={50}
              />
            </div>
            <div className="form-row">
              <div className='col-12'>
                <label className='text' htmlFor='des'>Doctor de Alto nivel</label>
                <p>Describe brevemente tu trayectoria médica</p>
              </div>
              <div className='col-12'>
                <textarea
                  id='des' rows='5'
                  value={this.state.summary}
                  onChange={(e) => { this.updateState(e.target.value, 'summary') }}>
                </textarea>
              </div>

              {this.edit &&
                <>
                  <label className='col-12 text' > Experiencia profesional</label>
                  {this.state.professionalExperiences.map((professionalExp, index) =>
                    <>
                      <BasicInput
                        classes='col-10'
                        label=''
                        onChangeFxn={(val) => { this.updateStateArray(val, 'professionalExperiences', index) }}
                        basicVal={professionalExp}
                        maxLength={200}
                      />
                      <div className='col-2'>
                        <img src={deleteIcon} alt='Eliminar' onClick={() => this.OnDelete(() => this.deleteData('professionalExperiences', index))} />
                      </div>
                    </>
                  )}

                  <div className='col-12 mb-3'>
                    <img alt='' src={plusIcon} onClick={() => this.addNewStringFieldToUI('professionalExperiences')} />
                  </div>

                  <label className='col-12 text' > Cargos en asociaciones públicas</label>
                  {this.state.positionsInPublicAssociations.map((positionInPublicAssociation, index) =>
                    <><BasicInput
                      classes='col-10'
                      label=''
                      onChangeFxn={(val) => { this.updateStateArray(val, 'positionsInPublicAssociations', index) }}
                      basicVal={positionInPublicAssociation}
                      maxLength={200}
                    />
                    <div className='col-2'>
                      <img src={deleteIcon} alt='Eliminar' className='mr-5' onClick={() => this.OnDelete(() => this.deleteData('positionsInPublicAssociations', index))} />
                    </div>
                    </>
                  )
                  }
                  <div className='col-12 mb-3'>
                    <img alt='' src={plusIcon} onClick={() => this.addNewStringFieldToUI('positionsInPublicAssociations')} />
                  </div>

                  <label className='col-12 text' > Logros académicos destacados</label>
                  {this.state.academicAchievements.map((academicAchievement, index) =>
                    <>
                      <BasicInput
                        classes='col-10'
                        label=''
                        onChangeFxn={(val) => { this.updateStateArray(val, 'academicAchievements', index) }}
                        basicVal={academicAchievement}
                        maxLength={200}
                      />
                      <div className='col-2'>
                        <img src={deleteIcon} alt='Eliminar' className='mr-5' onClick={() => this.OnDelete(() => this.deleteData('academicAchievements', index))} />
                      </div>
                    </>
                  )
                  }
                  <div className='col-12 mb-3'>
                    <img alt='' src={plusIcon} onClick={() => this.addNewStringFieldToUI('academicAchievements')} />
                  </div>

                  <label className='col-12 text' >Miembro de asociaciones médicas privadas</label>
                  {this.state.memberPrivateMedicalAssociations.map((memberPrivateMedicalAssociation, index) =>
                    <>
                      <BasicInput
                        classes='col-10'
                        label=''
                        onChangeFxn={(val) => { this.updateStateArray(val, 'memberPrivateMedicalAssociations', index) }}
                        basicVal={memberPrivateMedicalAssociation}
                        maxLength={200}
                      />
                      <div className='col-2'>
                        <img src={deleteIcon} alt='Eliminar' className='mr-5' onClick={() => this.OnDelete(() => this.deleteData('memberPrivateMedicalAssociations', index))} />
                      </div>
                    </>
                  )
                  }
                  <div className='col-12 mb-3'>
                    <img alt='' src={plusIcon} onClick={() => this.addNewStringFieldToUI('memberPrivateMedicalAssociations')} />
                  </div>

                  <label className='col-12 text' >Idiomas</label>
                  {this.state.languages.map((language, index) =>
                    <>
                      <BasicInput
                        classes='col-10'
                        label=''
                        onChangeFxn={(val) => { this.updateStateArray(val, 'languages', index) }}
                        basicVal={language}
                        maxLength={50}
                      />
                      <div className='col-2'>
                        <img src={deleteIcon} alt='Eliminar' className='mr-5' onClick={() => this.OnDelete(() => this.deleteData('languages', index))} />
                      </div>
                    </>
                  )
                  }
                  <div className='col-12 mb-3'>
                    <img alt='' src={plusIcon} onClick={() => this.addNewStringFieldToUI('languages')} />
                  </div>

                  <label className='col-12 text' >Enfermedades, pruebas y tratamientos</label>
                  {this.state.specialtyAreas.map((specialtyArea, index) =>
                    <>
                      <BasicInput
                        classes='col-10'
                        label=''
                        onChangeFxn={(val) => { this.updateStateArray(val, 'specialtyAreas', index) }}
                        basicVal={specialtyArea}
                        maxLength={200}
                      />
                      <div className='col-2'>
                        <img src={deleteIcon} alt='Eliminar' className='mr-5' onClick={() => this.OnDelete(() => this.deleteData('specialtyAreas', index))} />
                      </div>
                    </>
                  )
                  }
                  <div className='col-12 mb-3'>
                    <img alt='' src={plusIcon} onClick={() => this.addNewStringFieldToUI('specialtyAreas')} />
                  </div>
                </>
              }
            </div>

          </form>
        </FormField>


        <FormField text={'Alta de asistentes'}>
          <form >
            <div className="form-row">
              <div className='col-12'>
                <label className='text'>Agrega las cuentas adicionales que administrarán tu perfil.</label>
                <p>No es necesario que compartas tu correo y contraseña con las personas dadas de alta en esta sección, ellas tendrán acceso
                  a tu perfil con los correos que tú des de alta, pero con restricción en ciertos datos.</p>
              </div>

              {this.state.profiles && this.state.profiles.map((profile, index) =>
                <div className='col-12'>
                  <div className='col-12 p-0'>
                    <label className='text'>Perfil {index + 1}</label>
                  </div>

                  {this.state.editProfiles[index] ?
                    <div className='form-row col-12 p-0'>
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Nombre (s)'
                        onChangeFxn={(val) => { this.updateStateArray(val, 'profiles', index, 'firstName') }}
                        basicVal={profile.firstName} autocomplete='new-password'
                        error={this.state.errors.profileFirstName}
                      />
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Apellido Paterno'
                        onChangeFxn={(val) => { this.updateStateArray(val, 'profiles', index, 'lastName') }}
                        basicVal={profile.lastName} autocomplete='new-password'
                        error={this.state.errors.profileLastName}
                      />
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Apellido Materno'
                        onChangeFxn={(val) => { this.updateStateArray(val, 'profiles', index, 'motherName') }}
                        basicVal={profile.motherName} autocomplete='new-password'
                        error={this.state.errors.profileMotherName}
                      />
                      <div className='col-12 col-md-6 col-lg-3'></div>
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Correo'
                        onChangeFxn={(val) => { this.updateStateArray(val, 'profiles', index, 'email') }}
                        basicVal={profile.email} autocomplete='new-password'
                        disabled={profile.persitent}
                        error={this.state.errors.profileEmail}
                      />
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Contraseña'
                        onChangeFxn={(val) => { this.updateStateArray(val, 'profiles', index, 'password') }}
                        basicVal={profile.persitent ? 'PASSWORD' : profile.password} type='password'
                        disabled={profile.persitent} autocomplete='new-password'
                        error={this.state.errors.profilePassword}
                      />
                      <BasicSelect classes='col-12 col-md-6 col-lg-3'
                        customOptions={this.profileAssistentType.map(b => b)}
                        selectedItem={profile.type} placeholder={'Tipo de perfil'}
                        onChangeFxn={(val) => { this.updateStateArray(val, 'profiles', index, 'type') }}
                        error={this.state.errors.profileType}
                      />
                      <div className='col-12 col-md-6 col-lg-3' >
                        <img src={saveIcon} alt='Guardar' className='mr-3' onClick={() => this.saveProfile(index, profile.userId)} />
                      </div>

                      <div className='col-11 pb-3'>
                        <label className='text col-12 col-md-12 col-lg-12 col-xl-2 m-0 p-0'>Permitir acceso a:</label>
                        <Checkbox
                          id={`accessServices_${index}`}
                          onChange={this.checkEvent}
                          checked={profile.accessServices} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Servicios
                        </Checkbox>
                        <Checkbox
                          id={`accessAgenda_${index}`}
                          onChange={this.checkEvent}
                          checked={profile.accessAgenda} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Agenda
                        </Checkbox>
                        <Checkbox
                          id={`accessPatients_${index}`}
                          onChange={this.checkEvent}
                          checked={profile.accessPatients} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Mis pacientes
                        </Checkbox>
                        <Checkbox
                          id={`accessDrProfile_${index}`}
                          onChange={this.checkEvent}
                          checked={profile.accessDrProfile} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Mi perfil
                        </Checkbox>
                        <Checkbox
                          id={`accessPatientProfile_${index}`}
                          onChange={this.checkEvent}
                          checked={profile.accessPatientProfile} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Mi perfil como paciente
                        </Checkbox>
                      </div>
                    </div>
                    :
                    <div className='form-row col-12 p-0'>
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Nombre (s)'
                        disabled={true} autocomplete='new-password'
                        basicVal={profile.firstName}
                      />
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Apellido Paterno'
                        disabled={true} autocomplete='new-password'
                        basicVal={profile.lastName}
                      />
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Apellido Materno'
                        disabled={true} autocomplete='new-password'
                        basicVal={profile.motherName}
                      />
                      <div className='col-12 col-md-6 col-lg-3'></div>
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Correo'
                        disabled={true} autocomplete='new-password'
                        basicVal={profile.email}
                      />
                      <BasicInput
                        classes='col-12 col-md-6 col-lg-3' placeHolder='Contraseña'
                        disabled={true} autocomplete='new-password'
                        basicVal={profile.persitent ? 'PASSWORD' : profile.password} type='password'
                      />
                      <BasicSelect classes='col-12 col-md-6 col-lg-3'
                        customOptions={this.profileAssistentType.map(b => b)}
                        selectedItem={profile.type} placeholder={'Tipo de perfil'}
                        disabled={true}
                      />
                      <div className='col-12 col-md-6 col-lg-3' >
                        <img src={editIcon} alt='Editar' className='mr-3' onClick={() => this.editProfile(index)} />
                        <img src={deleteIcon} alt='Eliminar' className='mr-3' onClick={() => this.OnDelete(() => this.deleteProfile(index, profile.id))} />
                      </div>

                      <div className='col-11 pb-3'>
                        <label className='text col-12 col-md-12 col-lg-12 col-xl-2 m-0 p-0'>Permitir acceso a:</label>
                        <Checkbox
                          id={`accessServices_${index}`}
                          disabled={true}
                          checked={profile.accessServices} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Servicios
                        </Checkbox>
                        <Checkbox
                          id={`accessAgenda_${index}`}
                          disabled={true}
                          checked={profile.accessAgenda} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Agenda
                        </Checkbox>
                        <Checkbox
                          id={`accessPatients_${index}`}
                          disabled={true}
                          checked={profile.accessPatients} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Mis pacientes
                        </Checkbox>
                        <Checkbox
                          id={`accessDrProfile_${index}`}
                          disabled={true}
                          checked={profile.accessDrProfile} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Mi perfil
                        </Checkbox>
                        <Checkbox
                          id={`accessPatientProfile_${index}`}
                          disabled={true}
                          checked={profile.accessPatientProfile} className='col-12 col-md-4 col-lg-3 col-xl-2 m-0 p-0'
                        >
                          Mi perfil como paciente
                        </Checkbox>
                      </div>
                    </div>
                  }
                </div>
              )
              }

              <div className='col-12 mb-3'>
                <img alt='' src={plusIcon} onClick={() => this.addNewFieldSetEditProfile()} />
              </div>
            </div>

            <div className="form-row">
              <div className='register-submit-container'>
                <button className='btn primary-btn register-btn' onClick={this.submitForm}>{this.edit ? 'Guardar' : 'Publicar'}</button>
              </div>
            </div>

          </form>
        </FormField>

        <br />
      </div>
    )
  }
}