import React, { Component } from 'react';
import IconInput from '../Common/inputs/IconInput'
import person from '../../Assets/icons/person.svg'
import homeGraph from '../../Assets/img/Home-grafico.svg'
import localization from '../../Assets/icons/Home-Localizacion.svg'
import './Home.scss'
import { appContext } from '../../Utils/Context';
import HomeFooterDesk from './HomeFooterDesk';
import HomeFooterMobile from './HomeFooterMobile';
import LoginFlowControl from '../Login/LoginFlowControl';
import authConfig from '../../Initialization/auth-config';

class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props)
        this.state = {
            specialty: '',
            specialtySuggestions: [],
            address: ''
        }
        this.flowRef = React.createRef();
    }
    componentDidMount() {
        this.setInitialAddress();
    }
    setInitialAddress = () => {
        this.setState({ address: this.context.state.address });
    }

    specialtyInputChange = async (specialtyKeyword) => {
        this.setState({ specialty: specialtyKeyword })
    }

    suggestionClicked = (specialty) => {
        this.context.updateSpecialty(specialty)
        this.setState({ specialty })
    }
    onChangeAddress = (address) => {
        this.setState({ address })
    }
    onPlaceSelected = (address) => {
        this.onChangeAddress(address);
    }
    findDoctor = async () => {
        await this.context.getcoordinatesfromAddress(this.state.address);
        this.context.updateSpecialty(this.state.specialty);
        this.props.history.push(`/appointment/doctors?address=${this.state.address}&specialty=${this.state.specialty}`)
    }
    goToAppoinments = () => {
        this.hideLogin();
        this.props.history.push('/patient/agenda');
    }
    startFlow = () => {
        this.flowRef.current.start();
    }
    hideLogin = () => {
        this.flowRef.current.resetFlow();
    }
    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.findDoctor();
        }
    }
    render() {
        return (
            <div className='home-page'>
                <h2 className="text title">¿Necesitas atención médica?</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 inputContainer'>
                        <IconInput
                            onChange={this.specialtyInputChange}
                            inputVal={this.state.specialty}
                            prependIcon={person} iconClasses={'big'} placeHolder={'Nombre del doctor, especialidad o padecimiento'}
                            onSuggestionClick={this.suggestionClicked}
                            suggestions={true}
                            onKeyPress={this.handleKeyPress}
                        />
                    </div>
                    <div className='col-12 col-md-5 inputContainer'>
                        <IconInput
                            autompleteAddress={true}
                            appendIcon={localization}
                            placeHolder={'¿Dónde?'}
                            inputVal={this.state.address}
                            onChange={this.onChangeAddress}
                            onKeyPress={this.handleKeyPress}
                            onPlaceSelected={this.onPlaceSelected}
                            onAppendIconClick={this.setInitialAddress}
                        />
                    </div>
                    <div className='col-12 col-md-1 buttonContainer'>
                        <button disabled={!this.state.address && !this.state.specialty} type="button" className="btn confirm-btn" onClick={this.findDoctor}>Buscar</button>
                    </div>
                </div>
                <section className='empty'>
                    {(!authConfig.sessionAvailable) &&
                        <div className='session-link d-none d-sm-none d-md-block'>
                            <span onClick={() => this.startFlow()}>Inicia sesión</span> para ver tus citas { }
                            {/*<div className='row' style={{paddingTop:'50px'} }>*/}
                            {/*    <div className='col-12' >*/}
                            {/*        <button type="button" className="btn primary-btn btnBig" onClick={() => this.props.history.push('/SubscriptionPrices')}>Subscríbete</button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    }
                    <div>
                        <img src={homeGraph} className='doctors-image' alt='home' />
                    </div>
                </section>
                <div className="d-none d-sm-none d-md-block"><HomeFooterDesk></HomeFooterDesk></div>
                <div className="d-block d-sm-block d-md-none"><HomeFooterMobile></HomeFooterMobile></div>
                <LoginFlowControl ref={this.flowRef}
                    onSuccessLogin={this.goToAppoinments}
                ></LoginFlowControl>
            </div>

        );
    }
}
Home.contextType = appContext;
export default Home;