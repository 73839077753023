import React, { Component } from 'react';

export default class HistoryView extends Component {
  render() {
    const {children} = this.props;
    return (
      <>  
        {
          this.props.currentView === this.props.viewNumber && 
            <div className={`${this.props.className|''} p-3`}>
              {children}
            </div> 
        }
      </>
    );
  }
}
