import React, { Component } from 'react'
import DoctorRegister from '../Register/DoctorRegister'
import './Profile.scss'

export default class Profile extends Component {

  constructor(props) {
    super(props)
    this.state = { 
    }
  }
  componentDidMount  () {

  }
 
  render() {
    return (
      <div className='doctor-container profile'>
        <DoctorRegister 
          hideTitle={true}          
          edit={true}
        />
      </div>
    )
  }
}
