import React, { Component } from 'react'
import Modal from './Modal';
import './Modal.scss';
import './NotificationsModal.scss';
/* import califica from '../../../Assets/icons/Notificaciones-califica.svg';
import checkin from '../../../Assets/icons/Notificaciones-checkin.svg';
import checkout from '../../../Assets/icons/Notificaciones-Checkout.svg';
import pago from '../../../Assets/icons/Notificaciones-pago.svg'; */
import tarjeta from '../../../Assets/icons/Notificaciones-tarjeta.svg';
import agenda from '../../../Assets/icons/Notificaciones-agenda.svg';
import { NOTIFICATIONS } from '../../../Utils/Constants';

export default class NotificationsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: [
        {
          id: '',
          userId: '',
          type: '',
          title: '',
          description: [''],
          isReaded: false,
          eventDateTime: new Date(),
        }
      ]
    }
  }

  componentDidMount() {
  }

  getTime = (text, eventDateTime) => {
    const startDateTime = new Date(eventDateTime.toString().slice(0, -1));
    text = text.replace('##Horario', `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`);
    return text;
  }

  getDate = (text, eventDateTime) => {
    const startDateTime = new Date(eventDateTime);
    text = text.replace('##Fecha', `${startDateTime.toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`);
    return text;
  }

  isNottificationType = (notification, type) => {
    let hasType = (notification.type & type) === type;
    return hasType;
  }

  /* notificationAction = (notification) => {
    console.log(`notification click ${JSON.stringify(notification)}`)
  } */

  render() {
    return (
      <Modal className="notificationsModal"
        show={this.props.show}
        modalClosed={this.props.closeFnx}
        closeIcon={true}
      >
        <div>
          <p className="notificationHeader">Notificaciones</p>
        </div>
        <div className='scroll-notification'>
          {/* <div> */}
          {(this.props.notifications?.length > 0 ?
            (this.props.notifications.map((notification, index) =>
              <section key={notification.id} className='row no-gutters notificationDetal cursor' /* onClick={() => this.notificationAction(notification)} */>
                <div className='col-12 col-md-2 notificationDetail-left'>
                  {
                    this.isNottificationType(notification, NOTIFICATIONS.Modify) &&
                    this.isNottificationType(notification, NOTIFICATIONS.Payment) &&
                    <img src={tarjeta} alt='menuIcon' />
                  }
                  {
                    this.isNottificationType(notification, NOTIFICATIONS.Appointment) &&
                    <img src={agenda} alt='menuIcon' />
                  }
                </div>
                <div className='col-12 col-md-10'>
                  <div className="notificationTitle notificationBold">{notification.title}</div>
                  {notification.description.map(item =>
                    <div key={index + Math.random()} className="notificationTitle">
                      {item.includes('##Horario') ? this.getTime(item, notification.eventDateTime) : item.includes('##Fecha') ? this.getDate(item, notification.eventDateTime) : item}
                    </div>
                  )}
                </div>

                <div className='divider mb-0 mt-2 mr-5 ml-5'></div>
              </section>
            ))
            :
            (<div className='notificationsEmpty'><label>No hay notificaciones nuevas.</label></div>))}
          {/* </div> */}
        </div>
      </Modal>
    );
  }
}