import React, { Component } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Modal from '../../Common/modal/Modal'
import Loading from '../../Common/loading/Loading'
import BasicRadio from '../../Common/inputs/BasicRadio'
import BasicInput from '../../Common/inputs/BasicInput'
import BasicModal from '../../Common/modal/BasicModal'
import folder1 from '../../../Assets/icons/Expediente medico-carpeta.svg'
import folder2 from '../../../Assets/icons/Expediente medico-carpeta 2.svg'
import folder3 from '../../../Assets/icons/Carpeta amarilla.svg'
import print from '../../../Assets/icons/Expediente medico popup-imprimir.svg'
import download from '../../../Assets/icons/Expediente medico popup-descargar.svg'
import deleteicon from '../../../Assets/icons/Expediente medico popup-eliminar.svg'
import body from '../../../Assets/img/Cuerpo Sanapp.svg'
import bodyLady from '../../../Assets/img/Cuerpo Mujer.svg'
import prepareEndpoint from '../../../Utils/EndPoints'
import { getData, postData, putData, getFile } from '../../../Utils/API'
import ReactToPrint from 'react-to-print'
import './MedicalFile.scss'
import confirmAlert from '../../Common/confirmAlert/confirmAlert'
import icono_flechad from '../../../Assets/icons/flecha-d.svg';

export default class MedicalFile extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.printRef = React.createRef();
        this.state = {
            loading: true,
            rootfolders: [
                {
                    name: '',
                    description: '',
                    folders: [
                        {
                            name: '',
                            description: '',
                            medicalStudies: [
                                {
                                    name: '',
                                    date: new Date(),
                                    description: '',
                                    fileImageName: '',
                                    path: ''
                                }
                            ]
                        }
                    ]
                }
            ],
            newSubFolderName: '',
            newStudyName: '',
            newDescription: '',
            newFile: null,
            folderSelected: '',
            subfolderSelected: '',
            foldersSelected: [
                {
                    name: '',
                    description: '',
                    medicalStudies: [
                        {
                            name: '',
                            date: new Date(),
                            description: '',
                            fileImageName: '',
                            path: ''
                        }
                    ]
                }
            ],
            studySelected: '',
            typeSelected: '',
            showStudies: false,
            showStudyModal: false,
            showPreviewModal: false,
            newSubFolderEvent: false,
            gender: '',
            errors: {
                newStudyName: false
            },
            modalShowErrorPayment: false,
            modelErrorDetail: '',
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        try {
            let foldersTask = this.GetFolders();
            let profileTask = getData(prepareEndpoint('Patient'));
            await foldersTask;
            const resp = await profileTask;
            if (resp?.status !== 200) {
                return;
            }
            this.setState({ gender: resp.data.gender });
        } catch (e) {
            console.log('Exception at didMount MedicalFile.js: ' + e)
        }
        this.setState({ loading: false });
    }

    async GetFolders() {
        const details = await this.GetMedicalFile();
        this.setState({ rootfolders: details.rootfolders })
        return details.rootfolders;
    }

    async GetMedicalFile() {
        const url = `${prepareEndpoint('Patient')}/GetMedicalFileCollection`;
        const resp = await getData(url);;
        let details = {
            rootfolders: []
        };

        if (resp.status === 200) {
            details.rootfolders = resp.data.folders.map(a => {
                return {
                    name: a.name,
                    description: a.description,
                    folders: a.folders.map(f => {
                        return {
                            name: f.name,
                            description: f.description,
                            medicalStudies: f.medicalStudies.map(m => {
                                return {
                                    name: m.name,
                                    date: new Date(m.date),
                                    description: m.description,
                                    fileImageName: m.fileImageName,
                                    path: m.path
                                }
                            })
                        }
                    })
                }
            });
        }

        return details;
    }

    async GetStudy() {
        this.setState({ loading: true });

        if (this.getType() === 0) {
            await this.previewStudy();
        }
        else {
            await this.downloadStudy();
        }

        this.setState({ loading: false });
    }

    getType = () => {
        let studyType = this.state.typeSelected.toLowerCase();
        switch (studyType) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'bmp':
                return 0;
            case 'pdf':
                return 1;
            default:
                return 2;
        }
    }

    previewStudy = async () => {
        const url = `${prepareEndpoint('Patient')}/GetStudy/${this.state.folderSelected}/${this.state.subfolderSelected}/${this.state.studySelected}`;
        const resp = await getData(url);
        this.setState({ newFile: null });

        if (resp.status === 200) {
            this.setState({ newFile: resp.data });
            this.setState({ showPreviewModal: true });
        }
        if (resp.status === 204) {
            this.setState({ showPreviewModal: true })
        }
    }

    valueChanged = async (val) => {
        if (val !== '') {
            await this.AddSubFolder();
        }
        else {
            this.updateStateNewSubFolder();
        }
    }

    updateStateNewSubFolder = () => {
        this.updateState('', 'newSubFolderName');
        this.setState({ newSubFolderEvent: false });
    }

    updateFoldersSelected = (value) => {
        let folderSelected = this.state.rootfolders.find(x => x.name === value);
        if (folderSelected) {
            this.setState({ showStudies: true });
            this.setState({ foldersSelected: folderSelected.folders });
        }
    }

    updateFoldersSelectedNewFolder = (value, rootFolders) => {
        let folderSelected = rootFolders.find(x => x.name === value);
        if (folderSelected) {
            this.setState({ showStudies: true });
            this.setState({ foldersSelected: folderSelected.folders });
        }
    }

    async AddSubFolder() {
        this.setState({ loading: true });
        const url = `${prepareEndpoint('Patient')}/AddSubFolder/${this.state.folderSelected}/${this.state.newSubFolderName}`;

        const resp = await postData(url);

        if (resp.status === 200) {
            const rootFolders = await this.GetFolders();
            this.updateStateNewSubFolder();
            this.updateFoldersSelectedNewFolder(this.state.folderSelected, rootFolders);
            this.closeStudyModal();
        }
        this.setState({ loading: false });
    }

    handleChange(selectorFiles) {
        this.setState({ newFile: selectorFiles[0] });
    }

    async AddStudy() {
        let validForm = this.validateFields()
        if (!validForm) {
            return;
        }

        this.setState({ loading: true });
        try {
            const formData = new FormData();
            formData.append("formFile", this.state.newFile);
            formData.append("studyName", this.state.newStudyName);
            formData.append("description", this.state.newDescription);

            const folderName = this.state.folderSelected;
            const subfolderName = this.state.subfolderSelected;

            const url = `${prepareEndpoint('Patient')}/${folderName}/${subfolderName}/AddMedicalStudy/`;
            const resp = await postData(url, formData);

            if (resp.status === 200) {
                this.closeStudyModal();
                const rootFolders = await this.GetFolders();
                this.updateFoldersSelectedNewFolder(folderName, rootFolders);
            }
            else {
                this.setState({ modalShowErrorPayment: true, modelErrorDetail: 'Error al intentar subir archivo, intentalo mas tarde.' })
            }
        } catch (e) {

        }
        this.setState({ loading: false });
    }

    closeStudyModal = () => {
        this.setState({ showStudyModal: false });
        this.setState({ newFile: null });
        this.setState({ newStudyName: '' });
        this.setState({ newDescription: '' });
        this.setState({ studySelected: '' });
        this.setState({ typeSelected: '' });
    }

    updateState = (val, field) => {
        this.setState({ [field]: val });
    }

    uploadFile = () => {
        //let validForm = this.validateFields()
        if (true) {
            this.fileInputRef.current.click();
        }
    }

    perviewFile = () => {

    }

    closePreviewModal = () => {
        this.setState({ showPreviewModal: false });
    }

    selectRootFolder = (folder) => {
        this.setState({ folderSelected: folder });
        this.updateFoldersSelected(folder);
        this.updateState('', 'subfolderSelected');
        this.closeStudyModal();
    }

    getFolderSelectedDetails = (f) => {
        return (
            <Table key={f.name} className='col-12 col-md-10 col-lg-10 pr-0 pl-0 foldersTable'>
                <Thead>
                    <Tr>
                        <Th className='col-12 col-md-8 col-lg-8'>
                            <div className={`folders clinical-link ${this.state.subfolderSelected === f.name ? 'selected' : ''}`} onClick={e => { this.updateState(f.name, 'subfolderSelected'); this.closeStudyModal(); }}>
                                <img src={folder2} alt='blocked' />
                                <label className='text-patient clinical-link'>
                                    {this.state.folderSelected} &#62; {f.name}
                                </label>
                            </div>
                        </Th>
                        <Th className='col-12 col-md-4 col-lg-4 hidden'>

                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {f.medicalStudies?.map(p => {
                        return (
                            <Tr key={p.name}>
                                <Td className='col-12 col-md-8 col-lg-8'>
                                    <div className={this.state.subfolderSelected !== f.name ? 'hidden hiddenSpecial' : 'pl-3'}>
                                        <BasicRadio radioValue="study" radioGroup="study" label={p.name} isChecked={this.state.subfolderSelected === f.name && this.state.studySelected === p.name}
                                            onChangeFxn={() => {
                                                this.updateState(f.name, 'subfolderSelected');
                                                this.updateState(p.name, 'studySelected');
                                                let tokens = p.fileImageName.split('.');
                                                this.updateState(tokens.length > 1 ? tokens[tokens.length - 1] : '', 'typeSelected');
                                            }} />
                                    </div>
                                </Td>
                                <Td className='col-12 col-md-4 col-lg-4 pl-0 pr-0'>
                                    <div className={this.state.subfolderSelected !== f.name ? 'hidden hiddenSpecial' : 'pl-3'}>
                                        {`${p.date.getDate().toString().padStart(2, '0')}-${(p.date.getMonth() + 1).toString().padStart(2, '0')}-${p.date.getFullYear()}`}
                                    </div>
                                </Td>
                            </Tr>
                        )
                    })}

                </Tbody>
            </Table>
        );
    }

    reactToPrintTrigger = () => {
        return <img src={print} alt='blocked' />;
    };

    printMedicalFile = () => {
        return this.printRef.current
    }

    onDeleteEvent = (deleteFunction) => {
        const buttons = [
            {
                label: 'Aceptar',
                onClick: deleteFunction
            },
            {
                label: 'Cancelar'
            }
        ];
        confirmAlert({ message: '¿Estás seguro que quieres borrar este elemento?', buttons });
    };

    deleteStudy = async () => {
        this.setState({ loading: true });
        const url = `${prepareEndpoint('Patient')}/DeleteStudy/${this.state.folderSelected}/${this.state.subfolderSelected}/${this.state.studySelected}`;
        const resp = await putData(url);
        if (resp.status === 200) {
            this.setState({ newFile: null });
            this.setState({ studySelected: '' });
            this.setState({ typeSelected: '' });
            const rootFolders = await this.GetFolders();
            this.updateFoldersSelectedNewFolder(this.state.folderSelected, rootFolders);
            this.setState({ showPreviewModal: false });
        }
        this.setState({ loading: false });
    };

    goToHistory = () => {
        this.props.history.push('/patient/medicalHistory')
    }

    goToShareMedicalHistory = () => {
        this.props.history.push('/patient/shareMedicalHistory')
    }

    downloadStudy = async () => {
        const url = `${prepareEndpoint('Patient')}/DownloadStudy/${this.state.folderSelected}/${this.state.subfolderSelected}/${this.state.studySelected}`;
        let subfolder = this.state.foldersSelected.find(x => x.name === this.state.subfolderSelected);
        if (subfolder) {
            let study = subfolder.medicalStudies.find(x => x.name === this.state.studySelected);
            if (study) {
                await getFile(url, study.fileImageName);
            }
        }
    };

    validateFields = () => {
        let errors = { ...this.state.errors }
        const {
            newStudyName
        } = this.state
        let formIsValid = true
        if (!newStudyName) {
            formIsValid = false
            errors.newStudyName = true
        }
        else {
            errors.newStudyName = false
        }
        this.setState({ errors })

        if (this.state.newFile == null) {
            formIsValid = false
            this.setState({ modalShowErrorPayment: true, modelErrorDetail: 'Por favor selecciona archivo.' })
        }
        return formIsValid
    };

    render() {
        return (
            <div className='row margin-bottom'>
                {this.state.loading && <Loading />}

                <div className='doctor-container half-screen margin-right main_div'>
                    <strong className='pl-1 text'>Instrucciones</strong>
                    <p><strong className='green-text'>1</strong> Registra tu historial clínico</p>
                    <p><strong className='green-text'>2</strong> Crea subcarpetas de acuerdo a tus padecimientos o seguimiento a tu check up</p>
                    <div className='row pl-3 pt-3'>
                        <strong className='green-text'>1</strong><strong className='pl-1 text clickable' onClick={this.goToHistory}>Historial clínico<span><img src={icono_flechad} className='arrow-icon' alt='antecedentes médicos' /></span></strong>
                    </div>
                    <div className='row pl-3 pt-3'>
                        <strong className='green-text'>2</strong><strong className='pl-1 text clickable'>Estudios asociados:</strong>
                    </div>
                    <div className="container cel-list">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="row dont-show">
                                    <div className="col">
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {this.state.rootfolders.length > 1 ?
                                            <div className={`list-group-item clinical-link ${this.state.folderSelected === this.state.rootfolders[1].name ? 'active' : ''}`} onClick={() => this.selectRootFolder(this.state.rootfolders[1].name)}
                                            >
                                                <div className='folders'>
                                                    <div className='icon-folder'>
                                                        <img src={this.state.folderSelected === this.state.rootfolders[1].name ? folder3 : folder1} alt='blocked' />
                                                    </div>
                                                    <div className='icon-text'>
                                                        <label className='text'>
                                                            {this.state.rootfolders[1].name}
                                                        </label>
                                                        <p>{this.state.rootfolders[1].description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>

                                </div>
                                <div className="row dont-show">
                                    <div className="col">
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {this.state.rootfolders.length > 2 ?
                                            <div className={`list-group-item clinical-link ${this.state.folderSelected === this.state.rootfolders[2].name ? 'active' : ''}`} onClick={e => this.selectRootFolder(this.state.rootfolders[2].name)}
                                            >
                                                <div className='folders'>
                                                    <div className='icon-folder'>
                                                        <img src={this.state.folderSelected === this.state.rootfolders[2].name ? folder3 : folder1} alt='blocked' />
                                                    </div>
                                                    <div className='icon-text'>
                                                        <label className='text'>
                                                            {this.state.rootfolders[2].name}
                                                        </label>
                                                        <p>{this.state.rootfolders[2].description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                                <div className="row dont-show">
                                    <div className="col">
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                            {this.state.loading ?
                                <Loading />
                                :
                                <div className="col align-self-center dont-show">
                                    <img src={this.state.gender === 'male' ? body : bodyLady} alt='blocked' />
                                </div>
                            }
                            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                <div className="row dont-show">
                                    <div className="col">
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="row dont-show">
                                    <div className="col">
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {this.state.rootfolders.length > 3 ?
                                            <div className={`list-group-item clinical-link ${this.state.folderSelected === this.state.rootfolders[3].name ? 'active' : ''}`} onClick={() => this.selectRootFolder(this.state.rootfolders[3].name)}
                                            >
                                                <div className='folders'>
                                                    <div className='icon-folder'>
                                                        <img src={this.state.folderSelected === this.state.rootfolders[3].name ? folder3 : folder1} alt='blocked' />
                                                    </div>
                                                    <div className='icon-text'>
                                                        <label className='text'>
                                                            {this.state.rootfolders[3].name}
                                                        </label>
                                                        <p>{this.state.rootfolders[3].description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                                <div className="row dont-show">
                                    <div className="col">
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {this.state.rootfolders.length > 4 ?
                                            <div className={`list-group-item clinical-link ${this.state.folderSelected === this.state.rootfolders[4].name ? 'active' : ''}`} onClick={() => this.selectRootFolder(this.state.rootfolders[4].name)}
                                            >
                                                <div className='folders'>
                                                    <div className='icon-folder'>
                                                        <img src={this.state.folderSelected === this.state.rootfolders[4].name ? folder3 : folder1} alt='blocked' />
                                                    </div>
                                                    <div className='icon-text'>
                                                        <label className='text'>
                                                            {this.state.rootfolders[4].name}
                                                        </label>
                                                        <p>{this.state.rootfolders[4].description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pl-3 pt-3 align-end'>
                        <strong className='pl-1 text clickable' onClick={this.goToShareMedicalHistory}>Compartir Información<span><img src={icono_flechad} className='arrow-icon' alt='compartir expediente medico' /></span></strong>
                    </div>
                </div>

                {this.state.showStudies ?
                    <div className='doctor-container half-screen margin-left main_div'>

                        <div className='row col-12 m-0 p-0'>
                            <div className='width-left-sub pr-0 mr-0 pl-0 ml-0 mb-3 col-12 col-md-10 col-lg-10 '>
                                <div className='row'>
                                    <label className='text-patient width-left-sub'>Estudios</label>
                                    <label className='text-patient width-right-sub'>Fecha Carga</label>
                                </div>
                                <div>
                                    {
                                        this.state.foldersSelected.map(f => {
                                            return this.getFolderSelectedDetails(f);
                                        })
                                    }
                                </div>
                            </div>

                            <div className='width-right-sub col-12 col-md-2 col-lg-2 text-center p-0 m-0'>
                                <label className='text-patient dynamic-label'>Opciones</label>
                                {<div>
                                    <button className={`btn button-static-width custom-btn ${this.state.studySelected !== '' ? 'confirm-btn' : 'single-btn'} ${this.state.foldersSelected.length === 0 ? 'hidden' : ''}`} disabled={this.state.studySelected !== '' ? false : true} onClick={() => this.GetStudy()}>
                                        {this.getType() === 0 ? 'Ver' : 'Descargar'}
                                    </button>
                                </div>}
                                <div className='big-btn'>
                                    <button className={`btn button-static-width custom-btn ${this.state.subfolderSelected !== '' ? 'confirm-btn' : 'single-btn'} ${this.state.foldersSelected.length === 0 ? 'hidden' : ''}`} disabled={this.state.subfolderSelected !== '' ? false : true}
                                        onClick={() => this.setState({ showStudyModal: true })}>
                                        Agregar Estudio
                                    </button>
                                </div>
                            </div>
                        </div>
                        {this.state.newSubFolderEvent ?
                            <div className='folders row'>
                                <img src={folder2} alt='blocked' />
                                <BasicInput label=''
                                    classes='col-12 col-lg-4'
                                    onChangeFxn={(val) => { this.updateState(val, 'newSubFolderName'); }}
                                    onPressFxn={() => { this.valueChanged(this.state.newSubFolderName) }}
                                    onBlurFxn={(val) => { this.valueChanged(val) }}
                                    basicVal={this.state.newSubFolderName}
                                    maxLength={20}
                                />
                            </div>
                            : ''
                        }
                        <div className='folders'>
                            <img src={folder1} alt='blocked' />
                            <label className='clinical-underline clinical-link active-text green-text' onClick={() => {
                                if (this.state.newSubFolderEvent) {
                                    this.setState({ newSubFolderEvent: false })
                                    this.valueChanged(this.state.newSubFolderName)
                                }
                                else {
                                    this.setState({ newSubFolderEvent: true })
                                }
                            }}>
                                Crear nueva subcarpeta +
                            </label>
                        </div>
                    </div>
                    : <> </>
                }

                <Modal
                    className='medicalModal'
                    show={this.state.showStudyModal}
                    modalClosed={this.closeStudyModal}
                    closeIcon={true}>
                    <h3 className='text-center'>Agregar nuevo estudio</h3>

                    <div className="form-row">
                        <BasicInput
                            basicVal={this.state.newStudyName}
                            classes='col-6' label='Nombre del estudio'
                            onChangeFxn={val => this.updateState(val, 'newStudyName')}
                            error={this.state.errors.newStudyName}
                        />
                        <BasicInput
                            classes='col-6' label='Fecha' basicVal={`${new Date().getDate().toString().padStart(2, '0')}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getFullYear()}`} disabled='true'
                            onChangeFxn={(val) => { }}
                        />
                    </div>
                    <div className="form-row">
                        <BasicInput
                            basicVal={this.state.newDescription}
                            classes='col-12' label='Descripción'
                            onChangeFxn={(val) => { this.updateState(val, 'newDescription') }}
                        />
                    </div>

                    <label className='text'>Estudio (archivos permitidos: .pdf, .doc, .ppt, .xls, .jpg, .png, .mp4)</label>

                    <form>
                        <input
                            ref={this.fileInputRef}
                            type='file'
                            className='hide'
                            accept='image/*,audio/*,video/*,application/pdf,.doc,.docx,.ppt,.pptx,.csv, xls,.xlsx'
                            onChange={(e) => this.handleChange(e.target.files)} />
                    </form>

                    <label className='clinical-underline clinical-link text active-text blank-space' onClick={() => this.uploadFile()}>
                        Seleccionar Archivo
                    </label>

                    {this.state.newFile ?
                        <label>{this.state.newFile.name}</label>
                        :
                        <label>Ningún archivo seleccionado</label>}

                    <div className='text-center'>
                        <button type='button' className='btn primary-btn' onClick={e => this.AddStudy()} >Subir a la nube</button>
                    </div>

                    <BasicModal
                        className='errorsModal'
                        show={this.state.modalShowErrorPayment}
                        modalClosed={() => this.setState({ modalShowErrorPayment: false })}
                        title='Error al subir estudio'
                        subtitle={this.state.modelErrorDetail}
                    >
                    </BasicModal>
                </Modal>

                <Modal
                    className='studyModal'
                    show={this.state.showPreviewModal}
                    modalClosed={this.closePreviewModal}
                    closeIcon={true}>

                    <h3 className='text-center'>Estudio</h3>

                    {this.state.newFile ?
                        <div ref={this.printRef} className='d-flex justify-content-center img-wrapper'>
                            <img alt='' src={`data:image/${this.state.typeSelected};base64,${this.state.newFile}`} />
                        </div>
                        :
                        <div className='scroll bkg-white'>
                            <div className='text-center'>
                                <span>Estudio no fue encontrado</span>
                            </div>
                        </div>}


                    <div className='text-center'>
                        <div className='study-images'>
                            <ReactToPrint
                                content={this.printMedicalFile}
                                trigger={this.reactToPrintTrigger}
                            />
                            <img src={download} alt='blocked' onClick={() => this.downloadStudy()} />
                            <img src={deleteicon} alt='blocked' onClick={() =>
                                this.onDeleteEvent(() => this.deleteStudy())} />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}