import React, { Component } from 'react';
import valora from '../../Assets/img/Home-valora.svg'
import opciones from '../../Assets/img/Home-Opciones.svg'
import cita from '../../Assets/img/Tu-cita.svg'

import './Home.scss'

class HomeFooterDesk extends Component {
  
  render () {
    return (        
      <section className='home-footer'>
        <h2 className="text title">Obtén grandes beneficios:</h2>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <h4 className='text-underline'>Valora tu mejor opción</h4>
            <p className='paragrap-min-height' >Selecciona un especialista de acuerdo a las recomendaciones de otros pacientes.</p>
            <div className='img-center d-flex justify-content-center'>
              <img src={valora} alt='valora'/>
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <h4 className='text-underline'>Tu cita al instante</h4>
            <p className='paragrap-min-height' >Consigue tu cita médica con un especialista de forma fácil y rápida.</p>
            <div className='img-center d-flex justify-content-center'>
              <img src={cita} alt='cita'/>
            </div>
          </div>

          <div className='col-12 col-md-4'>
            <h4 className='text-underline'>Opciones claras</h4>
            <p className='paragrap-min-height' >Una herramienta sencilla para obtener un servicio médico especializado.</p>
            <div className='img-center d-flex justify-content-center'>
              <img src={opciones} alt='opciones'/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeFooterDesk;