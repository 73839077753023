import React,{useState} from 'react'
import hideIcon from '../../../Assets/icons/eye.svg';
import showIcon from'../../../Assets/icons/eye-closed.svg';
import './Inputs.scss'

const InputPassword =  (props) => {
  let [suggestions, setSuggestions] =useState([])
  const [showPassword, setShowPassword] = useState(false)
  const onAppendIconClick =()=>
  {
    if(props.onAppendIconClick)
    {
      props.onAppendIconClick();
    }
  }
  const onChange = (event) => {
    let value = event.target.value;
    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
  }
  
  return (
  // make sure to add space when pacing class prop or it will
  // merge into existing class
    <div className={'basic-input form-group ' + (props.classes ? props.classes : '')} id={props.label}>
      {props.label && <label className='text' htmlFor={props.id || ''}>{props.label}</label>}
      <div
        className='input-group icon-input'
      >
        
        <input
          id={props.id}
          type={showPassword? 'text' : 'password'}
          className={'form-control ' + (props.error ? 'is-invalid' : '')}
          autoFocus={props.autoFocus}
          placeholder={props.placeHolder}
          aria-label={props.placeHolder}
          onChange={e => onChange(e)}
          value={props.inputVal}
          maxLength={props.maxLength}
        />
        <div className='input-group-append'>
          <span className='input-password'>
            <img src={showPassword?hideIcon:showIcon} alt='prependIcon' className={props.onAppendIconClick?'clickable':'password-icon'} onClick={()=>setShowPassword(!showPassword)}/>
          </span>
        </div>
      </div>
    </div>
    
  )
  
}

export default InputPassword;
